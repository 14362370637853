import table from "@/domains/local-db/account/queue/adapter";
import { objectModule } from "@/modules/object";
import { raiseCriticalError } from "@/domains/critical-errors/critical-errors";
import { logger } from "@/modules/logger";
import { DataFlusher } from "@/domains/data-flushing/DataFlusher";
import {
  OptimisticSyncUpdate,
  SyncModelData,
  SyncQueueItem,
  SyncQueueItemKind,
} from "@/store/sync/types";
import {
  SerializedSyncQueueItem,
  serializeSyncQueueItem,
} from "@/domains/local-db/account/queue/serialization";
import { sortBy } from "lodash-es";
import { SerializedSyncOperation } from "@/store/sync/operations/types";

type FlusherState = Record<string, SyncQueueItem | null>;

// Queue is too fast, use this to inspect the behavior.
const DEBUG_QUEUE = false;

const flusher = new DataFlusher<FlusherState>({
  onFlush: async data => {
    const toRemoveIds: string[] = [];
    const toSaveIds: string[] = [];
    const toSaveValues: SerializedSyncQueueItem[] = [];
    Object.entries(data).forEach(([id, value]) => {
      if (value == null) {
        toRemoveIds.push(id);
      } else {
        toSaveValues.push(serializeSyncQueueItem(value));
        toSaveIds.push(id);
      }
    });
    if (DEBUG_QUEUE) {
      toSaveIds.length && console.log("[QUEUE][IO] Saving", ...sortBy(toSaveIds));
      toRemoveIds.length && console.log("[QUEUE][IO] Removing", ...sortBy(toRemoveIds));
    }
    await Promise.all([
      toSaveIds.length > 0 && table.setAllByIds(toSaveIds, toSaveValues),
      toRemoveIds.length > 0 && table.removeAllByIds(toRemoveIds),
    ]);
  },
  onError: err => {
    logger.error({
      message: "[CDE][Dexie] Repeatedly failed to flush queue",
      info: { error: objectModule.safeErrorAsJson(err as Error) },
    });
    return raiseCriticalError({ message: "Unable to save transactions to your hard drive." });
  },
});

// https://chriscoyier.net/2023/11/15/careful-about-chrome-119-and-beforeunload-event-listeners/
let beforeUnloadListenerAction = false;

function saveSyncQueueItem(id: string, item: SyncQueueItem | null) {
  if (!beforeUnloadListenerAction) {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      const hasUnsavedData = flusher.queryData((pendingData, data) => {
        for (const flusherState of [pendingData ?? {}, data]) {
          for (const syncAction of Object.values(flusherState)) {
            // Since AppStoreSyncStore.lastSyncId is memory-only, any non-processing action won't be lost.
            if (syncAction) {
              return true;
            }
          }
        }
        return false;
      });
      if (!hasUnsavedData) return;

      logger.warn({ message: "[CDE][Dexie] Preventing exit due to unflushed queue." });
      e.preventDefault();
      return "Changes are being saved. Are you sure you want to leave?";
    };
    window.addEventListener("beforeunload", beforeUnload);
    beforeUnloadListenerAction = true;
  }
  return flusher.mutateData(data => {
    data[id] = item;
  });
}

const getSyncOperationId = (id: string) => `sync-operation-${id}`;
const getOptimisticUpdateId = (id: string) => `optimistic-update-${id}`;

export function saveSyncOperation(id: string, data: SerializedSyncOperation) {
  return saveSyncQueueItem(getSyncOperationId(id), {
    kind: SyncQueueItemKind.SyncOperation,
    data,
  });
}

export function saveAcknowledgedSyncOperation(id: string, data: SerializedSyncOperation) {
  return saveSyncQueueItem(getSyncOperationId(id), {
    kind: SyncQueueItemKind.AcknowledgedSyncOperation,
    data,
  });
}

export function saveOptimisticUpdate<ModelData extends SyncModelData>(
  update: OptimisticSyncUpdate<ModelData>
) {
  return saveSyncQueueItem(getOptimisticUpdateId(update.optimistic_update_id), {
    kind: SyncQueueItemKind.OptimisticUpdate,
    data: update,
  });
}

export function removeSyncOperation(id: string) {
  return saveSyncQueueItem(getSyncOperationId(id), null);
}

export function removeOptimisticUpdate(id: string) {
  return saveSyncQueueItem(getOptimisticUpdateId(id), null);
}

// TODO: this should be integrated with flusher to be accessible by non-admins.
export const clear = () => table.clearAll();
