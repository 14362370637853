import { SpaceAccountTopicItemModelData } from "@/store/topics/types";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";

export type SpaceAccountTopicItemIndexData = {
  space_account_topic_id: string;
  item_id: string;
};

export class SpaceAccountTopicItemIndexes {
  private data: SyncUpdateValue<SpaceAccountTopicItemModelData>;

  constructor({
    remoteData,
    optimisticUpdates,
  }: {
    remoteData: Maybe<SyncUpdateValue<SpaceAccountTopicItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<SpaceAccountTopicItemModelData>[];
  }) {
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<SpaceAccountTopicItemModelData>) ||
      remoteData;
  }

  get space_account_topic_id() {
    return this.data.model_data.space_account_topic_id;
  }

  get item_id() {
    return this.data.model_data.item_id;
  }

  get indexes(): SpaceAccountTopicItemIndexData {
    return { space_account_topic_id: this.space_account_topic_id, item_id: this.item_id };
  }
}
