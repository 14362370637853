import { Maybe } from "@/domains/common/types";
import { CollectionItemModelData } from "@/store/collection-items/types";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";

export type CollectionItemIndexData = {
  item_id: string;
  collection_id: string;
};

export class CollectionItemIndexes {
  private data: SyncUpdateValue<CollectionItemModelData>;

  constructor({
    remoteData,
    optimisticUpdates,
  }: {
    remoteData: Maybe<SyncUpdateValue<CollectionItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<CollectionItemModelData>[];
  }) {
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<CollectionItemModelData>) || remoteData;
  }

  get item_id() {
    return this.data.model_data.item_id;
  }

  get collection_id() {
    return this.data.model_data.collection_id;
  }

  get indexes(): CollectionItemIndexData {
    return { item_id: this.item_id, collection_id: this.collection_id };
  }
}
