import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsSpinner } from "@/design-system/components/loader/MdsSpinner";
import { mdsColors } from "@/design-system/foundations/colors";
import { generateRelativePrepositionAdverbialDateString } from "@/domains/date/date";
import { api } from "@/modules/api";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import { css } from "@/domains/emotion";
import { MdsTooltip, MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { copyTextToClipboardWithToast } from "@/actions/copyTextToClipboardWithToast";
import styled from "@emotion/styled";
import { DataImportObservable } from "@/store/data-imports/DataImportObservable";
import { trackEvent, TrackedEvent } from "@/domains/metrics";

export const SettingsAccountMigrationImporterSection: FC = observer(() => {
  const { store } = useAppStore();
  const latestImport = store.dataImports.latestImport;
  const importIsRunning = !!latestImport?.isRunning;

  const importAccountContentFromV1 = useCallback(async () => {
    const isConfirmed = confirm(
      "Are you sure you want to import your notes and collections from Mem 1.0?"
    );

    if (!isConfirmed) {
      return;
    }

    const result = await api.post("/v2/migration/import-account-content", {
      body: {
        config: {
          generate_unique_v2_ids: false,
        },
      },
    });

    if (result.error || !result.data) {
      alert("An error occurred.");
      return;
    }

    /**
     * Track starting an import.
     */
    trackEvent(TrackedEvent.ImportFrom1Dot0StartImport);

    alert(
      "Import started successfully! Note that this may take up to 1 hour to complete. You'll now be redirected to your notes."
    );
    setTimeout(() => {
      store.navigation.goToNotes();
    }, 1_000);
  }, [store.navigation]);

  return (
    <>
      <p>
        Import your notes and collections created in Mem 1.0 to jumpstart your experience in Mem
        2.0.
      </p>
      <h3>How it works</h3>
      <ul>
        <li>
          <b>Only notes and collections you created will be imported.</b> Notes and collections
          shared with you by others will not be imported.
        </li>
        <li>
          <b>Importing may take up to 1 hour.</b> The time is dependent on the number of notes and
          collections you have.
        </li>
        <li>
          <b>You cannot undo this import.</b> To remove imported notes or collections, you will need
          to manually delete the imported notes and collections. You can multi-select notes to
          delete them in bulk.
        </li>
        <li>
          <b>Content will be imported from Mem 1.0 account with the same email.</b> If you need to
          import content from another Mem 1.0 account, please contact support.
        </li>
      </ul>

      <h3>Your imports</h3>
      {!importIsRunning && (
        <>
          <MdsButton
            variant={MdsButtonVariant.Tertiary}
            label="Import all notes and collections from Mem 1.0"
            onClick={importAccountContentFromV1}
          />
          <br />
        </>
      )}
      <ImportsContainer>
        {store.dataImports.getLatestImports(3).map(imp => (
          <ImportItem dataImport={imp} key={imp.id} />
        ))}
      </ImportsContainer>
      <br />
      {importIsRunning && (
        <MdsButton
          variant={MdsButtonVariant.Tertiary}
          label="Import all notes and collections from Mem 1.0"
          onClick={importAccountContentFromV1}
          isDisabled
        />
      )}
    </>
  );
});

interface ImportItemProps {
  dataImport: DataImportObservable;
}
const ImportItem = ({ dataImport }: ImportItemProps) => (
  <ImportItemContainer>
    <DataImportStatusIndicator dataImport={dataImport} />
    <MdsTooltip config={{ label: dataImport.id, placement: MdsTooltipPlacement.Top }}>
      <span
        onClick={() =>
          copyTextToClipboardWithToast({
            text: dataImport.id,
            toastContent: "Import ID copied to clipboard",
          })
        }
      >
        {dataImport.isRunning ? "Started running" : "Imported"}{" "}
        {generateRelativePrepositionAdverbialDateString(
          dataImport.isRunning ? dataImport.startedAt : dataImport.endedAt!
        )}
      </span>
    </MdsTooltip>
  </ImportItemContainer>
);

interface DataImportStatusIndicatorProps {
  dataImport: DataImportObservable;
}
const DataImportStatusIndicator = ({ dataImport }: DataImportStatusIndicatorProps) =>
  dataImport.isRunning ? (
    <MdsSpinner size={16} />
  ) : dataImport.endedWithError ? (
    <MdsIcon kind={MdsIconKind.Danger} className={failureIconStyles} />
  ) : (
    <MdsIcon kind={MdsIconKind.CheckCircleSolid} className={successIconStyles} />
  );

const successIconStyles = css({
  color: mdsColors().green.x400,
});

const failureIconStyles = css({
  color: mdsColors().red.x500,
});

const ImportsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: 8,
});

const ImportItemContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
});
