import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  override,
  runInAction,
} from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppSubStoreArgs } from "@/store/types";
import { ContactModelData } from "@/store/contacts/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { OptimisticSyncUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { resolveContactSyncModelUuid } from "@/modules/uuid/sync-models/resolveContactModelUuid";
import { IndexedBoolean, Maybe } from "@/domains/common/types";
import { Dexie, liveQuery, Subscription, Table } from "dexie";
import { ContactIndexData, ContactIndexes } from "@/store/contacts/ContactIndexes";
import { AppStore } from "@/store/AppStore";

type IndexedContactTuple = [is_direct: IndexedBoolean, model_id: Uuid];

export class AppStoreContactsStore extends BaseSyncModelStore<ContactObservable, ContactModelData> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Contact, ...injectedDeps });
    makeObservable(this, {
      localTable: override,
      computeIndexes: override,
      createSyncModel: false,

      initializeDirectContactsSubscription: action,
      directContactsSubscription: observable,
      directContactIds: observable,
      directContacts: computed,

      initializeIndirectContactsSubscription: action,
      indirectContactsSubscription: observable,
      indirectContactIds: observable,
      indirectContacts: computed,

      getBySpaceAccountId: true,
      getMatchingContacts: false,
    });

    onBecomeObserved(this, "directContacts", () => this.initializeDirectContactsSubscription());
    onBecomeObserved(this, "indirectContacts", () => this.initializeIndirectContactsSubscription());
    onBecomeUnobserved(this, "directContacts", () =>
      this.directContactsSubscription?.unsubscribe()
    );
    onBecomeUnobserved(this, "indirectContacts", () =>
      this.indirectContactsSubscription?.unsubscribe()
    );
  }

  directContactsSubscription: Maybe<Subscription>;
  directContactIds: IndexedContactTuple[] = [];
  initializeDirectContactsSubscription() {
    this.directContactsSubscription?.unsubscribe();
    this.directContactsSubscription = liveQuery(() =>
      this.localTable
        .where("[is_direct+model_id]")
        .between([1, Dexie.minKey], [1, Dexie.maxKey])
        .keys()
    ).subscribe(ids => {
      runInAction(() => (this.directContactIds = ids as unknown as IndexedContactTuple[]));
    });
  }
  get directContacts(): ContactObservable[] {
    return this.directContactIds
      .map(([_is_direct, model_id]) => this.get(model_id))
      .filter(contact => !!contact);
  }

  indirectContactsSubscription: Maybe<Subscription>;
  indirectContactIds: IndexedContactTuple[] = [];
  initializeIndirectContactsSubscription() {
    this.indirectContactsSubscription?.unsubscribe();
    this.indirectContactsSubscription = liveQuery(() =>
      this.localTable
        .where("[is_direct+model_id]")
        .between([0, Dexie.minKey], [0, Dexie.maxKey])
        .keys()
    ).subscribe(ids => {
      this.indirectContactIds = ids as unknown as IndexedContactTuple[];
    });
  }
  get indirectContacts(): ContactObservable[] {
    return this.indirectContactIds
      .map(([_is_direct, model_id]) => this.get(model_id))
      .filter(contact => !!contact);
  }

  createSyncModel(data: SyncUpdateValue<ContactModelData>) {
    return new ContactObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<
      SyncUpdateValue<ContactModelData> & ContactIndexData
    >;
  }

  getBySpaceAccountId(contactSpaceAccountId: Uuid): ContactObservable | undefined {
    const id = resolveContactSyncModelUuid({ spaceAccountId: contactSpaceAccountId });
    return this.get(id);
  }

  getMatchingContacts = async (query: string): Promise<ContactObservable[]> => {
    const needle = query.trim().toLowerCase();
    if (!needle) return [];
    const matchingContactIds = await this.localTable
      .filter(contact => contact.profile_display_name_and_email_address.includes(needle))
      .primaryKeys();
    const contacts = await Promise.all(matchingContactIds.map(id => this.get(id)));
    return contacts.filter(contact => !!contact);
  };

  public computeIndexes({
    store,
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<ContactModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ContactModelData>[];
  }): Record<string, unknown> {
    return new ContactIndexes({ store, remoteData, optimisticUpdates }).indexes;
  }
}
