import { observer } from "mobx-react-lite";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { useAppStore } from "@/store";
import { NotesListPageListEmpty } from "./NotesListPageListEmpty";

export const HomePageNotesList = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.notesListPage;

  if (page.hasNoItems) {
    return <NotesListPageListEmpty area={page.lens} />;
  }

  return (
    <>
      <MdsVirtualizedItemList
        rows={page.itemRows}
        listState={page.listState}
        loading={page.isLoading}
        endReached={page.increaseLimit}
      />
      {page.listState.multiSelectUi}
    </>
  );
});
