import { AnalyticsBrowser } from "@segment/analytics-next";
import { TrackedEvent } from "./tracked-event";
import { clientEnvModule } from "@/modules/client-env";

// record when the bundle first executes
const __appStartTime = performance.now();
let __userProperties: Record<string, unknown> | undefined = undefined;

const writeKey = clientEnvModule.isDesktop()
  ? import.meta.env.VITE_DESKTOP_SEGMENT_WRITE_KEY
  : import.meta.env.VITE_SEGMENT_WRITE_KEY;

const segmentAnalytics = AnalyticsBrowser.load({
  writeKey,
});

export const identifyUserInMetrics = (
  spaceAccountId: string,
  userProperties: Record<string, unknown>
) => {
  segmentAnalytics.identify(spaceAccountId, userProperties);
  __userProperties = userProperties;
};

export const trackEvent = (event: TrackedEvent, properties?: Record<string, unknown>) => {
  segmentAnalytics.track(event, {
    nodeEnv: clientEnvModule.nodeEnv(),
    isProd: clientEnvModule.isProd(),
    isDev: clientEnvModule.isDev(),
    isTest: clientEnvModule.isTest(),
    localBackendApiIsEnabled: clientEnvModule.localBackendApiIsEnabled(),
    clientId: clientEnvModule.clientId(),
    clientGroupId: clientEnvModule.clientGroupId(),
    buildInfo: clientEnvModule.buildInfo(),
    packageVersion: clientEnvModule.packageVersion(),
    isDesktop: clientEnvModule.isDesktop(),
    navigatorPlatform: navigator.platform, // this is technically deprecated, but it's still the best way to get the OS
    ...__userProperties,
    ...properties,
  });
};

let __didCapturePerformanceAppBoot = false;
export const capturePerformanceAppBoot = (details: Record<string, unknown>) => {
  // only do this once
  if (__didCapturePerformanceAppBoot) return;
  __didCapturePerformanceAppBoot = true;

  const now = performance.now();
  segmentAnalytics.track("performance_app_boot", {
    seconds: (now - __appStartTime) / 1000,
    ...details,
  });
};
