import { IndexedBoolean, Maybe } from "@/domains/common/types";
import { AppStore } from "@/store/AppStore";
import { CollectionModelData } from "@/store/collections/types";
import { SpaceAccountCollectionModelData } from "@/store/recent-items/types";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { maxBy } from "lodash-es";

export type CollectionIndexData = {
  title: string;
  lowercase_title: string;
  // Timestamps
  modified_at: string;
  created_at: string;
  last_viewed_at: string;
  last_added_to_at: string;
  last_interacted_at: string;
  // Status
  is_owned_by_me: IndexedBoolean;
  is_shared: IndexedBoolean;
  is_available: IndexedBoolean;
};

export class CollectionIndexes {
  private store: AppStore;
  private data: SyncUpdateValue<CollectionModelData>;
  private spaceAccountCollection: SyncUpdateValue<SpaceAccountCollectionModelData> | undefined;
  private optimisticUpdates: OptimisticSyncUpdate<CollectionModelData>[];

  constructor({
    store,
    remoteData,
    optimisticUpdates,
    spaceAccountCollection,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<CollectionModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<CollectionModelData>[];
    spaceAccountCollection: Maybe<SyncUpdateValue<SpaceAccountCollectionModelData>>;
  }) {
    this.store = store;
    this.optimisticUpdates = optimisticUpdates;
    this.spaceAccountCollection = spaceAccountCollection;
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<CollectionModelData>) || remoteData;
  }

  get title() {
    return this.data.model_data.title;
  }

  get lowercase_title() {
    return this.title.toLowerCase();
  }

  get modified_at() {
    return this.optimisticUpdates.reduce((latest, update) => {
      const actionDate = update.locally_committed_at;
      return actionDate > latest ? actionDate : latest;
    }, this.data.model_data.locally_modified_at);
  }

  get created_at() {
    return this.data.model_data.locally_created_at;
  }

  get last_viewed_at() {
    return this.spaceAccountCollection?.model_data.last_viewed_at || this.created_at;
  }

  get last_added_to_at() {
    return this.spaceAccountCollection?.model_data.last_added_to_at || "";
  }

  get last_interacted_at() {
    const maxTimestamp = maxBy(
      [this.last_viewed_at, this.last_added_to_at],
      dateStr => new Date(dateStr || 0)
    );
    return maxTimestamp || this.created_at;
  }

  get is_available() {
    // TODO: This might need to incorporate access
    return true;
  }

  get is_owned_by_me() {
    const myPersonalSpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const owner_scope = this.data.model_scopes.find(scope => scope.role_kind === "OWNER");
    if (!owner_scope) return false;
    if ("space_account_id" in owner_scope.value)
      return owner_scope.value.space_account_id === myPersonalSpaceAccountId;
    return false;
  }

  get is_shared() {
    return !!this.data.model_data.shared_at;
  }

  get indexes(): CollectionIndexData {
    return {
      title: this.title,
      lowercase_title: this.lowercase_title,

      modified_at: this.modified_at,
      created_at: this.created_at,
      last_viewed_at: this.last_viewed_at,
      last_added_to_at: this.last_added_to_at,
      last_interacted_at: this.last_interacted_at,

      is_available: this.is_available ? 1 : 0,
      is_owned_by_me: this.is_owned_by_me ? 1 : 0,
      is_shared: this.is_shared ? 1 : 0,
    };
  }
}
