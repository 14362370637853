import { SpaceAccountTopicItemObservable } from "@/store/topics/SpaceAccountTopicItemObservable";
import { SpaceAccountTopicItemModelData } from "@/store/topics/types";
import { makeObservable, override } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { OptimisticSyncUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { SpaceAccountTopicItemIndexes } from "@/store/topics/SpaceAccountTopicItemIndexes";
import { Maybe } from "@/domains/common/types";
import { AppStore } from "@/store/AppStore";

export class AppStoreSpaceAccountTopicItemsStore extends BaseSyncModelStore<
  SpaceAccountTopicItemObservable,
  SpaceAccountTopicItemModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountTopicItem, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
      computeIndexes: override,
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountTopicItemModelData>
  ): SpaceAccountTopicItemObservable {
    return new SpaceAccountTopicItemObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<SpaceAccountTopicItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<SpaceAccountTopicItemModelData>[];
  }): Record<string, unknown> {
    return new SpaceAccountTopicItemIndexes({ remoteData, optimisticUpdates }).indexes;
  }
}
