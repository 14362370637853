export enum EventContext {
  GlobalActions = "global_actions",
  KeyboardShortcut = "keyboard_shortcut",
  NotesActions = "notes_actions",
  NotesRowActions = "notes_row_actions",
  NotesMultiselectActions = "notes_multiselect_actions",
  CollectionsActions = "collections_actions",
  CollectionsRowActions = "collections_row_actions",
  CollectionsMultiselectActions = "collections_multiselect_actions",
  CollectionActions = "collection_actions",
  CollectionRowActions = "collection_row_actions",
  CollectionMultiselectActions = "collection_multiselect_actions",
  SearchRowActions = "search_row_actions",
  SearchMultiselectActions = "search_multiselect_actions",
  EditorActions = "editor_actions",
  EditorInline = "editor_inline",
  ErrorModal = "error_modal",
  ShareModal = "share_modal",
  TrashMultiselectActions = "trash_multiselect_actions",
  /**
   * This is used in cases where the caller doesn't have access to the event context.
   */
  Unknown = "unknown",
  /**
   * @todo - Use this.
   *
   * This isn't used yet because creation from @mention menu inside of a chat message draft
   * is indistinguishable from creation from within a note editor.
   */
  ChatMessageInline = "chat_message_inline",
  /**
   * @todo - Use this.
   *
   * This isn't used yet because "save as note" is implemented as a different operation
   * than "create note".
   */
  ChatMessageActions = "chat_message_actions",
}
