import { AppStoreNavigationStore } from "@/store/navigation/AppStoreNavigationStore";
import { action } from "mobx";
import { NavigateFunction } from "react-router";

export const goBack = action((store: INavigationStore, navigate: NavigateFunction) => {
  store.virtuosoStateStack.pop();
  const head = store.stack.pop();

  if (!head) return;

  navigate(-1);
  store.forwardStack.push(head);
});

export type INavigationStore = Pick<
  AppStoreNavigationStore,
  "stack" | "forwardStack" | "virtuosoStateStack"
>;
