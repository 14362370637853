import { mdsPanelWidths, mdsSidebarWidths } from "@/design-system/foundations";
import localDb from "@/domains/local-db";
import { windowModule } from "@/modules/window";
import { CollectionsViewPageStore } from "@/store/pages/CollectionsViewPageStore";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import {
  RelatedCard,
  SidePanelRoute,
  SidePanelRouteType,
  SidePanelState,
  SidePanelTab,
} from "@/store/routing/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MemCommonMentionKind } from "@mem-labs/common-editor";
import { isEmpty, isEqual } from "lodash-es";
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
  reaction,
  runInAction,
} from "mobx";

export class AppStoreSidePanelStore extends AppSubStore {
  collectionsViewPage: CollectionsViewPageStore;

  sidePanelFocused: boolean;
  sidePanelState: SidePanelState;
  sidePanelSize = 0;

  activeTabId: SidePanelTab;
  tabs: ObservableMap<SidePanelTab, SidePanelRoute[]>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.collectionsViewPage = new CollectionsViewPageStore(injectedDeps);

    this.sidePanelState = SidePanelState.SPLIT_VIEW;
    this.sidePanelFocused = false;

    this.activeTabId = SidePanelTab.CHAT;
    this.tabs = new ObservableMap<SidePanelTab, SidePanelRoute[]>();

    makeObservable(this, {
      // PERSISTENCE
      initialize: false,
      persistTabs: false,

      // PAGE STORES
      collectionsViewPage: true,

      // SIDE PANEL STATE
      sidePanelSize: observable,
      sidePanelState: observable,
      canRenderSplitView: computed,
      isMainPanelOpen: computed,
      isSidePanelOpen: computed,
      isSplitView: computed,
      sidePanelWidth: computed,

      // SIDE PANEL FOCUS
      sidePanelFocused: observable,
      setSidePanelFocused: action,

      // TABS
      activeTabId: observable,
      tabs: observable,
      isCopilotActive: computed,
      isChatActive: computed,
      activeRoute: computed,
      activeTabStack: computed,
      defaultTabRoute: computed,

      // ACTIONS
      setSidePanelWidth: action,
      switchTab: action,
      navigateTo: action,
      goToChat: action,
      goToCopilot: action,
      goToNote: action,
      goToCollection: action,
      goToMention: action,
      toggle: action,
      open: action,
      close: action,
      goUp: action,
    });

    reaction(
      () => this.sidePanelState,
      state => {
        this.store.memDb?.settings.setSidePanelState(state);
      }
    );

    reaction(
      () => this.activeTabId,
      activeTab => {
        this.store.memDb?.settings.setSidePanelActiveTabId(activeTab);
      }
    );

    reaction(
      () => this.activeRoute,
      activeRoute => {
        if (activeRoute.type === SidePanelRouteType.COLLECTION) {
          this.collectionsViewPage.setCollectionId({ collectionId: activeRoute.collectionId });
        }
      }
    );
  }

  // SIDE PANEL STATE
  get isSplitView() {
    return this.sidePanelState === SidePanelState.SPLIT_VIEW;
  }

  get isMainPanelOpen() {
    if (!this.canRenderSplitView && this.isSplitView) {
      if (!this.sidePanelFocused) return true;
      else return false;
    } else {
      return this.sidePanelState === SidePanelState.MAIN_PANEL_ONLY || this.isSplitView;
    }
  }

  get isSidePanelOpen() {
    if (!this.canRenderSplitView && this.isSplitView) {
      if (this.sidePanelFocused) return true;
      else return false;
    } else {
      return this.sidePanelState === SidePanelState.SIDE_PANEL_ONLY || this.isSplitView;
    }
  }

  get canRenderSplitView() {
    if (!this.store.interface.dimensionsState.data) return true;
    const { min, gutter } = mdsPanelWidths();
    const { collapsed } = mdsSidebarWidths();
    return this.store.interface.dimensionsState.data?.width > min * 2 + gutter * 2 + collapsed;
  }

  get sidePanelWidth() {
    if (!this.isSidePanelOpen) return "auto";

    return this.sidePanelSize;
  }

  // SIDE PANEL FOCUS
  setSidePanelFocused = (focused: boolean) => {
    this.sidePanelFocused = focused;
  };

  // TABS
  get isCopilotActive() {
    return (
      this.activeRoute.type === SidePanelRouteType.COPILOT ||
      this.activeRoute.type === SidePanelRouteType.NOTE_DETAIL ||
      this.activeRoute.type === SidePanelRouteType.COLLECTION
    );
  }

  get isChatActive() {
    return this.activeRoute.type === SidePanelRouteType.CHAT;
  }

  get activeRoute(): SidePanelRoute {
    return this.activeTabStack[this.activeTabStack.length - 1] || this.defaultTabRoute;
  }

  get activeTabStack(): SidePanelRoute[] {
    return this.tabs.get(this.activeTabId) || [this.defaultTabRoute];
  }

  get defaultTabRoute(): SidePanelRoute {
    return { type: SidePanelRouteType.CHAT };
  }

  // ACTIONS
  setSidePanelWidth = (width: number) => {
    this.sidePanelSize = width;
  };

  switchTab = (tabId: SidePanelTab) => {
    this.activeTabId = tabId;
  };

  navigateTo = (route: SidePanelRoute, { resetStack = false }: { resetStack?: boolean } = {}) => {
    const activeStack = this.tabs.get(this.activeTabId);

    if (!activeStack || resetStack) {
      this.tabs.set(this.activeTabId, [route]);
    } else {
      // push the route unless it's already the top of the stack
      const lastRoute = activeStack[activeStack.length - 1];
      if (!lastRoute || !isEqual(lastRoute, route)) {
        activeStack.push(route);
      }
    }

    this.persistTabs();
  };

  goToCopilot = (card?: RelatedCard) => {
    this.switchTab(SidePanelTab.COPILOT);
    this.navigateTo({ type: SidePanelRouteType.COPILOT, card }, { resetStack: !card });
    this.open();
  };

  goToChat = () => {
    this.switchTab(SidePanelTab.CHAT);
    this.navigateTo({ type: SidePanelRouteType.CHAT }, { resetStack: true });
    this.open();
  };

  goToNote = ({ noteId, resetStack }: { noteId: string; resetStack?: boolean }) => {
    this.switchTab(SidePanelTab.COPILOT);
    this.navigateTo({ type: SidePanelRouteType.NOTE_DETAIL, noteId }, { resetStack });
    this.open();
  };

  goToCollection = ({ collectionId }: { collectionId: string }) => {
    this.switchTab(SidePanelTab.COPILOT);
    this.navigateTo({ type: SidePanelRouteType.COLLECTION, collectionId });
    this.open();
  };

  goToMention = ({
    id,
    mentionKind,
    url,
    highlightText,
    keys,
  }: {
    url: string;
    mentionKind: MemCommonMentionKind;
    id: string;
    highlightText?: string;
    keys?: Parameters<INotesViewPageStore["goToMention"]>[0]["keys"];
  }) => {
    // Other clients and systems MAY store IDs in all caps, so we normalize them to lowercase for the web client router.
    const lowerCaseId = id.toLowerCase();

    switch (mentionKind) {
      case MemCommonMentionKind.Collection: {
        const openInMainPanel = keys?.altKey && this.store.sidePanel.canRenderSplitView;
        const navigator = openInMainPanel ? this.store.navigation : this.store.sidePanel;
        return navigator.goToCollection({ collectionId: lowerCaseId });
      }
      case MemCommonMentionKind.Note: {
        const openInMainPanel = keys?.altKey && this.store.sidePanel.canRenderSplitView;
        const navigator = openInMainPanel ? this.store.navigation : this.store.sidePanel;
        return navigator.goToNote({ noteId: lowerCaseId, highlightText });
      }
      default: {
        windowModule.openInNewTab({ url: new URL(url) });
      }
    }
  };

  goUp = () => {
    const activeStack = this.tabs.get(this.activeTabId);
    if (activeStack && activeStack.length > 1) {
      activeStack.pop();
    } else {
      this.close();
    }
    this.persistTabs();
  };

  toggle = () => {
    if (this.isSplitView) this.close();
    else this.open();
  };

  open = () => {
    if (!this.canRenderSplitView) this.sidePanelFocused = true;
    this.sidePanelState = SidePanelState.SPLIT_VIEW;
  };

  close = () => {
    this.sidePanelFocused = false;
    this.sidePanelState = SidePanelState.MAIN_PANEL_ONLY;
  };

  initialize = async () => {
    const [res, memDbState] = await Promise.all([
      localDb.settings.getSidePanelState(),
      this.store.memDb?.settings.getSidePanelState(),
    ]);

    if (!isEmpty(res)) {
      if (res.sidePanelState) {
        await this.store.memDb?.settings.setSidePanelState(res.sidePanelState);
        await localDb.settings.removeSidePanelState();
      }
      if (res.activeTabId) {
        await this.store.memDb?.settings.setSidePanelActiveTabId(res.activeTabId);
        await localDb.settings.removeSidePanelActiveTabId();
      }
      if (res.tabs) {
        await this.store.memDb?.settings.setSidePanelTabs(Array.from(res.tabs.entries()));
        await localDb.settings.removeSidePanelTabs();
      }
      runInAction(() => {
        if (res.activeTabId) this.activeTabId = res.activeTabId;
        if (res.sidePanelState) this.sidePanelState = res.sidePanelState;
        if (res.tabs) this.tabs.replace(res.tabs);
      });
      return;
    }

    if (!isEmpty(memDbState)) {
      runInAction(() => {
        if (memDbState.activeTabId) this.activeTabId = memDbState.activeTabId;
        if (memDbState.sidePanelState) this.sidePanelState = memDbState.sidePanelState;
        if (memDbState.tabs) this.tabs.replace(memDbState.tabs);
      });
    }
  };

  persistTabs = () => {
    this.store.memDb?.settings.setSidePanelTabs(this.tabs.toJSON());
  };
}
