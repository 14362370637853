import { Maybe } from "@/domains/common/types";
import {
  MessageModal,
  MessageModalDefinition,
  ModalDefinition,
  ModalDefinitionKind,
} from "@/store/modals/types";
import { CollectionsViewPageStore } from "@/store/pages/CollectionsViewPageStore/CollectionsViewPageStore";
import { ObservableObjectCondition } from "@/store/pages/types";
import { isEqual } from "lodash-es";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";

export class LeaveCollectionMessageModalStore implements MessageModalDefinition, MessageModal {
  private collectionsViewPageStore: CollectionsViewPageStore;

  get kind(): ModalDefinitionKind.Message {
    return ModalDefinitionKind.Message;
  }

  get modal(): MessageModal {
    return this;
  }

  closed = false;
  userNeedsToAcknowledgeLostWriteAccess = false;

  constructor(collectionsViewPageStore: CollectionsViewPageStore) {
    this.collectionsViewPageStore = collectionsViewPageStore;

    makeObservable<this, "collectionsViewPageStore">(this, {
      collectionsViewPageStore: false,
      kind: computed,
      modal: computed,
      collectionObservable: computed,
      condition: computed,
      buttonLabel: computed,
      closed: observable,
      userNeedsToAcknowledgeLostWriteAccess: observable,
      title: computed,
      message: computed,
      isModalOpen: computed,
      hasLostAccess: computed,
      modalDefinition: computed,
      setClosed: action,
      handleButtonClick: action,
      handleClose: action,
    });

    reaction(
      () => this.collectionsViewPageStore.canAccessCollection,
      (canAccess, previousCanAccess) => {
        if (!canAccess && previousCanAccess && this.closed) {
          this.setClosed(false);
        }
      }
    );

    reaction(
      () => this.condition,
      (condition, previousCondition) => {
        this.setClosed(false);
        if (
          condition === ObservableObjectCondition.ReadAccess &&
          previousCondition === ObservableObjectCondition.WriteAccess
        ) {
          // Users needs to confirm they are aware.
          runInAction(() => {
            this.userNeedsToAcknowledgeLostWriteAccess = true;
          });
        }
      }
    );

    reaction(
      () => this.modalDefinition,
      (current, previous) => {
        if (isEqual(current, previous)) return;

        if (previous) this.collectionsViewPageStore.store?.modals.removeModal(previous);
        if (current) this.collectionsViewPageStore.store?.modals.addModal(current);
      }
    );
  }

  get collectionObservable() {
    return this.collectionsViewPageStore.collectionObservable;
  }

  get title() {
    const maybeEdit = this.collectionObservable?.canAccess ? "edit" : "";
    const collectionTitle = this.collectionObservable
      ? `“${this.collectionObservable?.title}”`
      : "this collection";
    return `You no longer have ${maybeEdit} access to ${collectionTitle}`;
  }

  get message() {
    switch (this.condition) {
      case ObservableObjectCondition.NoAccess:
      case ObservableObjectCondition.ReadAccess:
        return "You can request access again from the owner if this was an error";
      case ObservableObjectCondition.InTrash:
        return "The collection was moved to the trash by the collection owner";
      case ObservableObjectCondition.Deleted:
        return "The collection was permanently deleted";
      case ObservableObjectCondition.WriteAccess:
      case ObservableObjectCondition.Unknown:
        return "";
    }
  }

  get condition(): ObservableObjectCondition {
    if (!this.collectionObservable) return ObservableObjectCondition.Unknown;
    if (this.collectionObservable.isDeleted) return ObservableObjectCondition.Deleted;
    if (!this.collectionObservable.canAccess) return ObservableObjectCondition.NoAccess;
    if (this.collectionObservable.isTrashed) return ObservableObjectCondition.InTrash;
    if (!this.collectionObservable.canWrite) return ObservableObjectCondition.ReadAccess;
    return ObservableObjectCondition.WriteAccess;
  }

  get buttonLabel() {
    return "Got it";
  }

  get isModalOpen() {
    return this.modalDefinition === this;
  }

  get hasLostAccess() {
    if (
      // If the user didn't have access initially we show not found instead.
      !this.collectionsViewPageStore.couldAccessCollectionOnMount ||
      // Wait until collection is loaded.
      !this.collectionObservable ||
      // Owner shouldn't see this.
      this.collectionObservable.isOwnedByMe
    ) {
      return false;
    }

    switch (this.condition) {
      case ObservableObjectCondition.Unknown:
      case ObservableObjectCondition.WriteAccess:
        // Everything is OK.
        return false;

      case ObservableObjectCondition.NoAccess:
      case ObservableObjectCondition.InTrash:
      case ObservableObjectCondition.Deleted:
        // User must confirm and we leave the collection.
        return true;

      case ObservableObjectCondition.ReadAccess:
        // User can confirm and continue reading.
        return this.userNeedsToAcknowledgeLostWriteAccess;
    }
  }

  get modalDefinition(): Maybe<ModalDefinition> {
    // User dismissed lost access modal.
    if (this.closed) return;

    if (this.hasLostAccess) return this;
  }

  setClosed(closed: boolean) {
    this.closed = closed;
  }

  handleButtonClick = () => {
    this.handleClose();

    this.userNeedsToAcknowledgeLostWriteAccess = false;

    const userHasReadOnlyAccess =
      this.collectionObservable &&
      this.collectionObservable.canAccess &&
      !this.collectionObservable.canWrite &&
      !this.collectionObservable.isDeleted &&
      !this.collectionObservable.isTrashed;

    if (!userHasReadOnlyAccess) {
      this.collectionsViewPageStore.store.navigation.goUp();
    }
  };

  handleClose() {
    this.setClosed(true);
    this.userNeedsToAcknowledgeLostWriteAccess = false;
  }
}
