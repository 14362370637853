export const mdsFontSizes = () =>
  ({
    xxxsmall: "10px",
    xxsmall: "12px",
    xsmall: "13px",
    small: "14px",
    smedium: "15px",
    medium: "16px",
    large: "20px",
    xlarge: "24px",
    xxlarge: "30px",
  }) as const;

export const mdsLineHeights = () =>
  ({
    xxxsmall: "12px",
    xxsmall: "14px",
    xsmall: "16px",
    small: "20px",
    smedium: "22px",
    medium: "24px",
    large: "28px",
    xlarge: "32px",
    xxlarge: "40px",
  }) as const;

export const mdsLineSpacings = () =>
  ({
    medium: "-0.16px",
    mediumLarge: "-0.4px",
    large: "-0.48px",
  }) as const;

export const mdsFontWeights = () =>
  ({
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extrabold: 800,
  }) as const;

export const mdsSpacings = () =>
  ({
    xxs: "2px",
    xs: "4px",
    xsPlus: "6px",
    sm: "8px",
    smd: "12px",
    md: "16px",
    lg: "24px",
    xl: "32px",
    xxl: "40px",
  }) as const;
