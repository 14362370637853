import { Color } from "@/modules/color/types";
import { RuntimeArgumentError } from "@/domains/errors";
import { round } from "lodash-es";

/**
 * Implementation inspired by: https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
 */
export const _hexToColor = ({ hex }: { hex: string }): Color => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    throw new RuntimeArgumentError({
      message: "[_hexToColor] Unable to parse hex color.",
    });
  }

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = (max + min) / 2;
  const l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return {
    hue: round(h * 360.0, 2),
    saturation: round(s, 2),
    lightness: round(l, 2),
  };
};

export const _safeHexToColor = ({ hex }: { hex: string | undefined }): Color => {
  if (!hex) {
    return {
      hue: 0,
      saturation: 0,
      lightness: 0,
    };
  }

  try {
    return _hexToColor({ hex });
  } catch (_unknownErr) {
    return {
      hue: 0,
      saturation: 0,
      lightness: 0,
    };
  }
};
