import { clientEnvNodeEnvironmentIsProd } from "@/modules/client-env/nodeEnvironment";
import { loadStripe } from "@stripe/stripe-js";

const PROD_STRIPE_PUBLISHABLE_KEY =
  "pk_live_51KEGvgI6Iawb6GMgDUduCoGdjZIW3wWZUkaLCszRBGxS6oWxoACnCon5XKm0SFRtCyNgP5WXPU5sX4Rq9XCD4xYL00e1IB83Ov";

const DEV_STRIPE_PUBLISHABLE_KEY =
  "pk_test_51KEGvgI6Iawb6GMgGSyA96EcmHr8bpxVMWjRUaKRUACU7xRWEyDW8n8ZBLZmiTBgY9DCA6LnbR9CMpuFtJ65irJu00uUK6Y4Wn";

const FORCE_PROD_KEY = true;

export const clientEnvStripeConfig = () => {
  if (clientEnvNodeEnvironmentIsProd() && !FORCE_PROD_KEY) {
    return {
      STRIPE_PUBLISHABLE_KEY: DEV_STRIPE_PUBLISHABLE_KEY,
    };
  }
  return {
    STRIPE_PUBLISHABLE_KEY: PROD_STRIPE_PUBLISHABLE_KEY,
  };
};

export const stripePromise = loadStripe(clientEnvStripeConfig().STRIPE_PUBLISHABLE_KEY);
