export const forceReloadWindow = async () => {
  // eslint-disable-next-line no-restricted-properties
  window.location.reload();
};

export const forceHardReloadWindow = async () => {
  // eslint-disable-next-line no-restricted-properties
  await fetch(window.location.href, {
    headers: {
      Pragma: "no-cache",
      Expires: "-1",
      "Cache-Control": "no-cache",
    },
  });

  // eslint-disable-next-line no-restricted-properties
  window.location.reload();
};
