import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import React, { FC } from "react";
import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsDropdownButton } from "@/design-system/components/dropdown";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsPanelBreakpoint } from "@/design-system/foundations";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { EventContext } from "@/domains/metrics/context";

export const NotesListPageHeader: FC = observer(() => {
  const { store, pageStore } = useAppStore();
  const page = pageStore.notesListPage;

  return (
    <Wrapper>
      <MdsPanelHeader
        heading="Notes"
        icon={MdsIconKind.Notebook}
        actions={
          <MdsPanelResponsiveBtn
            threshold={mdsPanelBreakpoint}
            fullSizeLabel="Create note"
            fullSizeIcon={MdsIconKind.ComposeInline}
            collapsedIcon={MdsIconKind.ComposeInline}
            collapsedTooltipConfig={{
              label: "Create note",
              placement: MdsTooltipPlacement.Top,
              delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
            }}
            onClick={() => store.notes.composeNewNote({ eventContext: EventContext.NotesActions })}
          />
        }
        filters={[
          {
            label: "All",
            onClick: () =>
              page.setParams({
                lens: LensKind.All,
                sortBy: SortByKind.LastModified,
              }),
            isActive: page.lens === LensKind.All,
          },
          {
            label: "Created by me",
            onClick: () =>
              page.setParams({
                lens: LensKind.AddedByMe,
                sortBy: SortByKind.LastModified,
              }),
            isActive: page.lens === LensKind.AddedByMe,
          },
          {
            label: "Shared with me",
            onClick: () =>
              page.setParams({
                lens: LensKind.SharedWithMe,
                sortBy: SortByKind.LastModified,
              }),
            isActive: page.lens === LensKind.SharedWithMe,
          },
        ]}
      />
      <WrapperBottom>
        <FiltersContainer>
          <div /> {/* Placeholder for filters */}
        </FiltersContainer>
        <MdsDropdownButton
          contentList={page.sortOptions}
          label={page.sortLabel}
          iconKind={MdsIconKind.AngleDown}
          iconPosition={MdsButtonIconPosition.Right}
          placement="below-right-alignment"
        />
      </WrapperBottom>
    </Wrapper>
  );
});

const Wrapper = styled.div(() => ({}));

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
  padding: theme.spacing.md,
}));

const FiltersContainer = styled(FadeHorizontalScroll)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
}));
