import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import {
  CollectionItemModelData,
  IndexedCollectionItemSyncUpdateValue,
} from "@/store/collection-items/types";
import { makeObservable, override } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { OptimisticSyncUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { CollectionItemIndexes } from "@/store/collection-items/CollectionItemIndexes";
import { Table } from "dexie";
import { Maybe } from "@/domains/common/types";
import { AppStore } from "@/store/AppStore";

export class AppStoreCollectionItemsStore extends BaseSyncModelStore<
  CollectionItemObservable,
  CollectionItemModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.CollectionItem, ...injectedDeps });
    makeObservable(this, {
      computeIndexes: override,
      localTable: override,
      remoteTable: override,
      recompute: override,
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<CollectionItemModelData>): CollectionItemObservable {
    return new CollectionItemObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
  public get remoteTable() {
    return this.db.mappedTables[this.modelKind].remote as Table<
      SyncUpdateValue<CollectionItemModelData>
    >;
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind]
      .local as Table<IndexedCollectionItemSyncUpdateValue>;
  }

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<CollectionItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<CollectionItemModelData>[];
  }): Record<string, unknown> {
    return new CollectionItemIndexes({ remoteData, optimisticUpdates }).indexes;
  }
}
