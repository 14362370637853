import { MdsTabBarHorizontalItemProps } from "@/design-system/components/tab-bar-horizontal/types";
import styled from "@emotion/styled";

export const MdsTabBarHorizontalItem = ({
  id,
  label,
  onClick,
  isActive,
}: MdsTabBarHorizontalItemProps) => {
  return (
    <Button onClick={() => onClick({ itemId: id })} isActive={isActive}>
      {label}
    </Button>
  );
};

const Button = styled.button<{ isActive?: boolean }>(({ theme, isActive }) => ({
  display: "flex",
  padding: theme.spacing.sm,
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
  borderBottom: isActive ? `2px solid ${theme.colors.grey.x600}` : "2px solid transparent",
  color: isActive ? theme.colors.grey.x600 : theme.colors.grey.x400,
  fontWeight: theme.fontWeights.semiBold,
  transform: "translateY(1px)",
  transition: "all 0.1s ease-in-out",
  "&:active": {
    borderBottom: `2px solid ${theme.colors.grey.x600}`,
    color: theme.colors.grey.x600,
  },
  "&:hover": {
    color: theme.colors.grey.x600,
  },
}));
