import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionModelData, CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IGrantCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  OptimisticSyncUpdate,
} from "@/store/sync/types";

export class GrantCollectionAclViaEmailAddressOperation extends BaseSyncOperation<IGrantCollectionAclViaEmailAddressOperation> {
  private collection?: CollectionObservable;

  get operationKind(): SyncOperationKind {
    return "GRANT_COLLECTION_ACL_VIA_EMAIL_ADDRESS";
  }

  get successToastMessage() {
    return (
      <>
        Sharing {this.mediumTitle} with {this.mediumEmail}...
      </>
    );
  }

  generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[] {
    if (!this.collection || this.collection.isShared) return [];

    const collectionValue: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "COLLECTION",
      model_version: this.collection.modelVersion,
      model_data: {
        ...this.collection.modelData,
        shared_at: null, // Don't update shared_at optimistically
      },
      model_scopes: this.collection.modelScopes,
    };
    const collectionUpdate: OptimisticSyncUpdate<CollectionModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: collectionValue,
    };

    return [collectionUpdate];
  }

  async execute() {
    this.collection = await this.store.collections.getAsync(this.payload.id);
    await super.execute();
  }

  get title() {
    return this.store.collections.get(this.payload.id)?.title || UNTITLED_COLLECTION_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  get mediumEmail() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.payload.email_address}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be shared. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>You do not have permissions to share {this.mediumTitle}. Please contact the note owner.</>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be shared. If this error continues, please contact support.</>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  async triggerRecompute() {
    this.store.collections.recompute(this.payload.id);
  }
}
