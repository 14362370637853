import { getElectronAPI } from "@/modules/electron/api";
import { URL } from "url";

export const electronBindingNavigateToInternalUrl = async ({ url }: { url: URL }) => {
  const electronApi = getElectronAPI();

  await electronApi?.navigateToInternalUrl({ url: url.toString() });

  return null;
};

export const electronBindingNavigateToExternalUrl = async ({ url }: { url: URL }) => {
  const electronApi = getElectronAPI();

  await electronApi?.navigateToExternalUrl({ url: url.toString() });

  return null;
};

export const electronBindingFindInPage = async ({
  text,
  options,
}: {
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
}) => {
  const electronApi = getElectronAPI();

  await electronApi?.findInPage({ text, options });
};

export const electronBindingStopFindInPage = async ({
  action,
}: {
  action: "clearSelection" | "keepSelection";
}) => {
  const electronApi = getElectronAPI();

  await electronApi?.stopFindInPage({ action });
};

export const electronBindingOnFindInPageResult = async ({
  callback,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (event: any, result: any) => void;
}) => {
  const electronApi = getElectronAPI();

  electronApi?.onFindInPageResult(callback);
};

export const electronBindingUpdateNow = async () => {
  const electronApi = getElectronAPI();

  electronApi?.updateNow();
};
