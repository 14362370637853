import { MdsText, MdsTextWeight } from "@/design-system/components/text";
import { MdsTextStylingMode } from "@/design-system/components/text/types";
import { UNTITLED_CONTACT_TITLE, UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IGrantNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind } from "@/store/sync/types";

export class GrantNoteAclViaSpaceAccountOperation extends BaseSyncOperation<IGrantNoteAclViaSpaceAccountOperation> {
  get operationKind(): SyncOperationKind {
    return "GRANT_NOTE_ACL_VIA_SPACE_ACCOUNT";
  }

  get successToastMessage() {
    return (
      <>
        Sharing {this.mediumTitle} with {this.mediumContactName}...
      </>
    );
  }

  get title() {
    return this.store.notes.get(this.payload.id)?.title || UNTITLED_NOTE_TITLE;
  }

  get contactName() {
    return (
      this.store.contacts.getBySpaceAccountId(this.payload.space_account_id)?.profileDisplayName ||
      UNTITLED_CONTACT_TITLE
    );
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  get mediumContactName() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.contactName}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be shared. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>You do not have permissions to share {this.mediumTitle}. Please contact the note owner.</>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be shared. If this error continues, please contact support.</>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  async triggerRecompute() {
    await this.store.notes.recompute(this.payload.id);
  }
}
