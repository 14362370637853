import { observer } from "mobx-react-lite";
import {
  MdsItemListSkeletonRowData,
  MdsItemListAsyncNoteRowData,
  MdsItemListRowType,
} from "@/design-system/components/item-list/types";
import { FC } from "react";
import { useAppStore } from "@/store";
import { MdsItemListSkeletonRow } from "@/design-system/components/item-list/rows/MdsItemListSkeletonRow";
import { MdsItemListItemRow } from "@/design-system/components/item-list/rows/MdsItemListItemRow";

export interface MdsItemListAsyncNoteRowProps {
  data: MdsItemListAsyncNoteRowData;
  className?: string;
}

export const MdsItemListAsyncNoteRow: FC<MdsItemListAsyncNoteRowProps> = observer(
  ({ data, className }) => {
    const { store } = useAppStore();
    const note = store.notes.get(data.payload.noteId);

    const skeletonRowData: MdsItemListSkeletonRowData = {
      key: data.key,
      type: MdsItemListRowType.Skeleton,
      size: data.size,
      payload: {},
    };

    if (!note) return <MdsItemListSkeletonRow data={skeletonRowData} className={className} />;

    const itemRowData =
      typeof data.payload.itemRow === "function"
        ? data.payload.itemRow(note)
        : data.payload.itemRow;
    return <MdsItemListItemRow data={itemRowData} className={className} />;
  }
);
