import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { SettingsDebugSandboxContent } from "@/pages/settings/components/debug-sandbox/SettingsDebugSandboxContent";
import { FC } from "react";

export const SettingsDebugSandboxPage: FC = () => {
  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Debug Sandbox">
        <div>
          <SettingsDebugSandboxContent />
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
};
