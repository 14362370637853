import { AppOrigin } from "@/domains/global/config";
import { clientEnvModule } from "@/modules/client-env";

export const buildAppWindowUrl = ({ path }: { path: string }) => {
  const isDesktop = clientEnvModule.isDesktop();

  if (isDesktop) {
    const desktopUrl = new URL(path, AppOrigin.Production);

    return desktopUrl;
  }

  // eslint-disable-next-line no-restricted-properties
  const windowOrigin = window.location.origin;

  const url = new URL(path, windowOrigin);

  return url;
};
