import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { IUpdateNoteAclViaCollectionOperation } from "@/store/sync/operations/types";
import { SyncOperationKind } from "@/store/sync/types";

export class UpdateNoteAclViaCollectionOperation extends BaseSyncOperation<IUpdateNoteAclViaCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "UPDATE_NOTE_ACL_VIA_COLLECTION";
  }

  async triggerRecompute() {
    await this.store.notes.recompute(this.payload.id);
  }
}
