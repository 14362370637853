import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";

export const removeNoteFromCollection = async ({
  note,
  collection,
  store,
}: {
  note: INoteObservable;
  collection: CollectionObservable;
  store: AppStore;
}) => {
  await note.collectionList?.removeCollection({ collectionId: collection.id });
  toastModule.triggerToast({
    content: (
      <div>
        {"Note removed from "}
        <a onClick={() => store.navigation.goToCollection({ collectionId: collection.id })}>
          {collection.label}
        </a>
        {"."}
      </div>
    ),
  });
};
