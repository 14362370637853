import { GLOBAL_ENV } from "@/modules/global-state/globalEnv";

export const clientEnvIsDesktop = (): boolean => {
  const isDesktop = GLOBAL_ENV.IS_DESKTOP;

  if (isDesktop === "true") {
    return true;
  }

  return false;
};
