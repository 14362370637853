import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsCarouselItem } from "@/design-system/components/item-list/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { SearchEngineSortByKind } from "@/modules/url-params/search-engine-params/types";
import { AppStore } from "@/store/AppStore";
import { trackEvent, TrackedEvent } from "@/domains/metrics";

export const getMdsCarouselItemForCollection = ({
  position,
  collection,
  store,
  query,
  sortBy,
}: {
  position: number;
  collection: Pick<CollectionObservable, "id" | "label" | "overview">;
  store: AppStore;
  query: string;
  sortBy: SearchEngineSortByKind;
}): MdsCarouselItem => ({
  id: collection.id,
  label: collection.label,
  secondary: collection.overview ?? "",
  icon: () => {
    return <CollectionIcon collectionId={collection.id} size={32} />;
  },
  onClick: ({ itemId }: { itemId: string }) => {
    store.navigation.goToCollection({ collectionId: itemId });

    /**
     * Track the navigation to a collection from within a carousel
     */
    trackEvent(TrackedEvent.SearchResultsOpenCollection, {
      query: query,
      sort_by: sortBy,
      position: position,
      collection_id: collection.id,
      collection_primary_label: collection.label,
      is_in_carousel: true,
    });
  },
});
