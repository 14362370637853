import { isObject, isPlainObject } from "lodash-es";

import { _parseObjectFromJson } from "@/modules/object/parsers/fromJson";
import { _transformObjectToJson } from "@/modules/object/toJson";
import {
  _safeTransformObjectToJsonFriendlyFormat,
  _transformObjectToJsonFriendlyFormat,
} from "@/modules/object/transformers/asJson";
import { validationModule } from "@/modules/validation";
import { JsonObject, JsonValue } from "@/domains/common/types";

/**
 * This is in a separate file from `toJson` and `asJson` to avoid circular dependencies.
 */

export const _safeTransformObjectToJson = (value?: Record<string, unknown>): string => {
  const result = _safeTransformObjectToJsonFriendlyFormat(value);

  return _transformObjectToJson(result);
};

export const _safeTransformErrorToJson = (err?: Error): string => {
  const name = err?.name ?? "No name value.";
  const message = err?.message ?? "No message value.";
  const stack = err?.stack ?? "No stack value.";

  const parsedDetails: { [key: string]: JsonValue } = {
    name,
    message,
    stack,
  };

  try {
    /**
     * We augment the error with additional details.
     */
    if (validationModule.objectHasKey(err, "response")) {
      const errResponse = err.response;

      if (validationModule.objectHasKey(errResponse, "data")) {
        /** We need to do both checks to help TS with type-narrowing. */
        if (isObject(errResponse.data) && isPlainObject(errResponse.data)) {
          parsedDetails.responseData = _safeTransformObjectToJson({
            ...errResponse.data,
          });
        }
      }
    }

    const result = _transformObjectToJsonFriendlyFormat({
      ...parsedDetails,
      originalError: err,
    });

    return _transformObjectToJson(result);
  } catch {
    return _safeTransformObjectToJson({ ...parsedDetails });
  }
};

export const _safeTransformErrorAsJson = (err?: Error): JsonObject => {
  const errorJson = _safeTransformErrorToJson(err);

  try {
    const result = _parseObjectFromJson(errorJson);
    return result;
  } catch (_err) {
    return {
      error: true,
      message: "Unable to transform error into JSON object.",
    };
  }
};
