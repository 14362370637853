import { ZERO_DATE_STRING } from "@/domains/date/date";
import { Uuid } from "@/domains/global/identifiers";
import { resolveSpaceAccountContactSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountContactSyncModelUuid";
import { SpaceAccountContactObservable } from "@/store/contacts/SpaceAccountContactObservable";
import { ContactModelData, IContactModel } from "@/store/contacts/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable, observable } from "mobx";

export class ContactObservable extends BaseSyncModel<ContactModelData> implements IContactModel {
  public modelKind = SyncModelKind.Contact;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<ContactModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      // PROPERTIES
      modelKind: observable,
      contactSpaceAccountId: computed,
      profileDisplayName: computed,
      profileEmailAddress: computed,
      profilePhotoUrl: computed,
      isDirect: computed,
      spaceAccountContactId: computed,
      spaceAccountContact: computed,
      lastSharedWithAt: computed,
    });
  }

  get contactSpaceAccountId(): string {
    return this.data.model_data.space_account_id;
  }

  get profileDisplayName(): string {
    return this.data.model_data.profile_display_name;
  }

  get profileEmailAddress(): string {
    return this.data.model_data.profile_email_address;
  }

  get profilePhotoUrl(): string | null {
    return this.data.model_data.profile_photo_url;
  }

  get spaceAccountContactId(): string {
    return resolveSpaceAccountContactSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      contactId: this.id,
    });
  }

  get spaceAccountContact(): SpaceAccountContactObservable | undefined {
    return this.store.spaceAccountContacts.get(this.spaceAccountContactId);
  }

  get lastSharedWithAt(): string {
    return this.spaceAccountContact?.lastSharedWithAt ?? ZERO_DATE_STRING;
  }

  get isDirect(): boolean {
    const userScope = this.remotePermissions.find(
      permission =>
        permission.scope_kind === "SPACE_ACCOUNT_SCOPE" &&
        permission.space_account_id === this.store.spaceAccounts.myPersonalSpaceAccountId
    );
    return userScope?.role_kind === "DIRECT_CONTACT";
  }
}
