import { MouseEvent, KeyboardEvent } from "react";
import { MdsButtonProps } from "@/design-system/components/button";
import { OnClick } from "@/domains/react/types";

export enum MdsDropdownItemKind {
  Button = "button",
  Divider = "divider",
  Detail = "detail",
  Other = "other",
  ScrollableSection = "scrollable-section",
}

export type GenericMdsDropdownItem<TKind extends MdsDropdownItemKind, TAttrs = unknown> = {
  id: string;
  kind: TKind;
  className?: string;
} & TAttrs;

export type MdsDropdownButtonItem = GenericMdsDropdownItem<
  MdsDropdownItemKind.Button,
  {
    iconSize?: number;
    iconKind?: MdsButtonProps["iconKind"];
    label: string;
    selectedLabelDetail?: string | null;
    content?: () => React.ReactNode;
    isChecked?: boolean;
    onClick: OnClick<{
      itemId: string;
      event?: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>;
    }>;
    isDisabled?: boolean;
    isSelected?: boolean;
    keyboardShortcut?: string;
    tooltip?: string;
    scrollIntoView?: boolean;
    animate?: boolean;
  }
>;

export type MdsDropdownDetailItem = GenericMdsDropdownItem<
  MdsDropdownItemKind.Detail,
  {
    text: string;
  }
>;

export type MdsDropdownDividerItem = GenericMdsDropdownItem<MdsDropdownItemKind.Divider>;

export type MdsDropdownOtherItem = GenericMdsDropdownItem<
  MdsDropdownItemKind.Other,
  {
    content: React.ReactNode;
  }
>;

export type MdsDropdownScrollableSection = GenericMdsDropdownItem<
  MdsDropdownItemKind.ScrollableSection,
  {
    className?: string;
    items: MdsDropdownItem[];
  }
>;

export type MdsDropdownItem =
  | MdsDropdownScrollableSection
  | MdsDropdownButtonItem
  | MdsDropdownDividerItem
  | MdsDropdownDetailItem
  | MdsDropdownOtherItem;

export type MdsDropdownContentList = {
  items: MdsDropdownItem[];
  header?: {
    children: React.ReactNode;
  };
  footer?: {
    children: React.ReactNode;
  };
};
