import { SyncCustomErrorData } from "@/store/sync/types";
import { BaseExternalOperation } from "@/store/sync/operations/BaseExternalOperation";
import { ExternalOperationKind, IUploadFileExternalOperation } from "@/store/sync/operations/types";
import { AppStore } from "@/store/AppStore";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsSpacer } from "@/design-system/components/spacer";
import { fileModule } from "@/modules/file";
import { MemCommonEditorFileInfo } from "@mem-labs/common-editor";
import { logger } from "@/modules/logger";
import { toastModule } from "@/modules/toast";

export class UploadFileExternalOperation extends BaseExternalOperation<IUploadFileExternalOperation> {
  triggerRecompute(): Promise<void> {
    return Promise.resolve();
  }

  get operationKind(): ExternalOperationKind.UPLOAD_FILE {
    return ExternalOperationKind.UPLOAD_FILE;
  }

  async executeExternalOperation(): Promise<void> {
    const info: MemCommonEditorFileInfo = this.payload.info;

    const canBeUploaded = fileModule.checkIfFileCanBeUploaded({ info });

    if (!canBeUploaded) {
      /**
       * It can be dangerous to load everything into memory if the file is too large.
       * So, we log an error and exit early (instead of throwing, and retrying.)
       *
       * This fails silently - mem-common-editor should not trigger "file-added" events
       * if the file is too large, so this is purely defensive code to prevent app crashes.
       */
      logger.error({
        message: `[SYNC][UploadFileExternalOperation] File cannot be uploaded. fileId=${info.fileId} fileSizeBytes=${info.fileSizeBytes}. Exiting early.`,
      });

      toastModule.triggerToast({ content: "Failed to upload file - too large." });

      return;
    }

    const fileBlob = fileModule.convertEncodedFileContentToBlob({
      encodedFileContent: info.encodedFileContent,
      fileMimeType: info.fileMimeType,
    });

    await fileModule.upload({
      info: {
        fileId: info.fileId,
        fileMimeType: info.fileMimeType,
        normalizedFileName: info.normalizedFileName,
        encodedFileContent: info.encodedFileContent,
        fileSizeBytes: info.fileSizeBytes,
      },
      blob: fileBlob,
    });
  }

  get syncErrorModalFieldsGenerator() {
    return this.getSyncErrorModalFieldsGenerator("UNKNOWN");
  }

  private getSyncErrorModalFieldsGenerator(kind: SyncCustomErrorData["kind"]) {
    return (store: AppStore) =>
      new UploadFileExternalOperationModalFieldsGenerator(store, this.id, kind, this);
  }
}

class UploadFileExternalOperationModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected id: string;
  protected kind: SyncCustomErrorData["kind"];
  protected UploadFileOperation: UploadFileExternalOperation;

  constructor(
    store: AppStore,
    id: string,
    kind: SyncCustomErrorData["kind"],
    UploadFileOperation: UploadFileExternalOperation
  ) {
    super(store);

    this.id = id;
    this.kind = kind;
    this.UploadFileOperation = UploadFileOperation;
  }

  get title() {
    return `Unable to upload file`;
  }

  get message() {
    return `If this error continues, please contact support`;
  }

  downloadFile = () => {
    const fileInfo = this.UploadFileOperation.payload.info;

    fileModule.downloadFileFromContent({
      info: {
        fileId: fileInfo.fileId,
        fileMimeType: fileInfo.fileMimeType,
        normalizedFileName: fileInfo.normalizedFileName,
        encodedFileContent: fileInfo.encodedFileContent,
        fileSizeBytes: fileInfo.fileSizeBytes,
      },
    });
  };

  tryAgain = () => {
    this.store.sync.actionQueue.resume();
  };

  get extraActionButtons() {
    return (
      <>
        <MdsButton
          label="Download file"
          variant={MdsButtonVariant.Outlined}
          onClick={this.downloadFile}
        />
        <MdsSpacer />
        <MdsButton
          label="Try again"
          variant={MdsButtonVariant.FilledDark}
          onClick={this.tryAgain}
        />
      </>
    );
  }

  modalActionHandler = async () => {
    this.store.sync.actionQueue.skipAndRevertOperationById(this.id);
  };
}
