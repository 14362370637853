import { Uuid } from "@/domains/global/identifiers";
import { NoteContentDocumentModelData } from "@/store/note/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable, observable } from "mobx";

export class NoteContentDocumentObservable extends BaseSyncModel<NoteContentDocumentModelData> {
  public modelKind = SyncModelKind.NoteContentDocument;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<NoteContentDocumentModelData>;
  } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: observable,
      remoteContent: computed,
    });
  }

  get remoteContent(): string | null {
    return this.remoteData.model_data.encoded_content;
  }
}
