import { type ChangeEventHandler, type PropsWithChildren, useCallback, useState } from "react";
import {
  makeSimpleCollection,
  OrganizeComboBox,
  CollectionForOrganize,
} from "@/components/note/editor/top-bar/organize";
import { type INoteObservable } from "@/store/note/types";
import { actions } from "@/actions";
import { useAppStore } from "@/store/utils/hooks";
import { useLiveQuery } from "dexie-react-hooks";
import { observer } from "mobx-react-lite";
import { EventContext } from "@/domains/metrics/context";

interface OrganizeProps extends PropsWithChildren, OrganizeNoteState {
  note: INoteObservable;
}

const Component = ({ note, children, organizeIsOpen, setOrganizeIsOpen }: OrganizeProps) => {
  const { store } = useAppStore();
  const [organizeSearchText, setOrganizeSearchText] = useState("");
  const searchSuggestions =
    useLiveQuery(() => store.search.forSuggestions(organizeSearchText), [organizeSearchText]) ?? [];
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const collections: (CollectionForOrganize | undefined)[] =
    organizeSearchText.length === 0 // no search entered, show all recent collections
      ? store.recentItems.sortedRecentCollectionsInteractedWithByMe.map(collection =>
          makeSimpleCollection(
            collection,
            note.collectionList?.hasCollection({ collectionId: collection.id }) ?? false
          )
        )
      : searchSuggestions // user searched, show filtered collections
          .map(result =>
            makeSimpleCollection(
              store.collections.get(result.modelId), // it can undefined until observable populates
              note.collectionList?.hasCollection({ collectionId: result.modelId }) ?? false
            )
          );

  const simpleCollections = collections.filter(Boolean) as CollectionForOrganize[];
  const organizeCollectionsHeader =
    simpleCollections.length === 0
      ? null
      : organizeSearchText.trim().length > 0
        ? "Collections"
        : "Recent collections";

  const handleSearchCollections: ChangeEventHandler<HTMLInputElement> = e => {
    setOrganizeSearchText(e.target.value);

    if (e.target.value.length >= 2) {
      setShowCreateCollection(true);
    } else {
      setShowCreateCollection(false);
    }
  };

  const handleClearSearchCollections = () => {
    setOrganizeSearchText("");
    setShowCreateCollection(false);
  };

  const handleSelectCollection = async (collectionId: string) => {
    if (!note) return;
    const collection = await store.collections.getAsync(collectionId);
    if (!collection) return;

    if (note.collectionList?.hasCollection({ collectionId: collection.id })) {
      await actions.removeNoteFromCollection({
        note,
        collection,
        store,
      });
    } else {
      await actions.addNotesToCollection({
        notes: [note],
        collection,
        store,
        eventContext: EventContext.EditorActions,
      });
    }

    handleOrganizeIsOpenChange(false);
  };

  const handleCreateNewCollection = async (collectionName: string) => {
    if (!note) return;

    await actions.createCollection({
      title: collectionName,
      store,
      notes: [note],
      eventContext: EventContext.EditorActions,
    });

    setOrganizeSearchText("");
    setShowCreateCollection(false);
  };

  const handleOrganizeIsOpenChange = (isOpen: boolean) => {
    setOrganizeIsOpen(isOpen);
    if (!isOpen) {
      setOrganizeSearchText("");
      setShowCreateCollection(false);
    }
  };

  const _handleClickOrganize = () => {
    handleOrganizeIsOpenChange(!organizeIsOpen);
  };

  return (
    <OrganizeComboBox
      items={simpleCollections}
      isOpen={organizeIsOpen}
      searchText={organizeSearchText}
      header={organizeCollectionsHeader}
      showCreateNewItem={showCreateCollection}
      onInputChange={handleSearchCollections}
      onClear={handleClearSearchCollections}
      onSelectItem={handleSelectCollection}
      onOpenChange={handleOrganizeIsOpenChange}
      onCreateNewItem={handleCreateNewCollection}
    >
      {children}
    </OrganizeComboBox>
  );
};

export const useOrganizeNoteState = () => {
  const [organizeIsOpen, setOrganizeIsOpen] = useState(false);

  const toggleOrganizeIsOpen = useCallback(() => {
    setOrganizeIsOpen(!organizeIsOpen);
  }, [organizeIsOpen]);

  return {
    organizeIsOpen,
    setOrganizeIsOpen,
    toggleOrganizeIsOpen,
  };
};

type OrganizeNoteState = ReturnType<typeof useOrganizeNoteState>;

export const OrganizeNote = observer(Component);
