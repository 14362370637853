import {
  MEM_COMMON_EDITOR_MAXIMUM_UPLOADABLE_IMAGE_SIZE_BYTES,
  MEM_COMMON_EDITOR_MAXIMUM_UPLOADABLE_FILE_SIZE_BYTES,
  MemCommonEditorFileInfo,
  MemCommonEditorImageInfo,
} from "@mem-labs/common-editor";

export const checkIfFileCanBeUploaded = ({ info }: { info: MemCommonEditorFileInfo }): boolean => {
  if (info.fileSizeBytes > MEM_COMMON_EDITOR_MAXIMUM_UPLOADABLE_FILE_SIZE_BYTES) {
    return false;
  }

  return true;
};

export const checkIfImageCanBeUploaded = ({
  info,
}: {
  info: MemCommonEditorImageInfo;
}): boolean => {
  if (info.imageSizeBytes > MEM_COMMON_EDITOR_MAXIMUM_UPLOADABLE_IMAGE_SIZE_BYTES) {
    return false;
  }

  return true;
};
