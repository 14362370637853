import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { GuestAppStore } from "@/store";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { BaseSyncOperationGuestMode } from "@/store/sync/operations/BaseSyncOperationGuestMode";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import {
  GuestModeSupportedSyncOperationKind,
  IRevokeNoteAclViaEmailAddressOperation,
  IRevokeNoteAclViaEmailAddressOperationGuestMode,
} from "@/store/sync/operations/types";
import { SyncCustomErrorData } from "@/store/sync/types";

export class RevokeNoteAclViaEmailAddressOperation extends BaseSyncOperation<IRevokeNoteAclViaEmailAddressOperation> {
  get operationKind(): GuestModeSupportedSyncOperationKind {
    return "REVOKE_NOTE_ACL_VIA_EMAIL_ADDRESS";
  }

  get successToastMessage() {
    return <>Updating access to {this.mediumTitle}...</>;
  }

  get title() {
    return (
      (this.store instanceof GuestAppStore
        ? this.store.note.getNoteObservableById({ noteId: this.payload.id })
        : this.store.notes.get(this.payload.id)
      )?.title || UNTITLED_NOTE_TITLE
    );
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        Access to {this.mediumTitle} could not be updated. If this error continues, please contact
        support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to update access to {this.mediumTitle}. Please contact the note
        owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        Access to {this.mediumTitle} could not be updated. If this error continues, please contact
        support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  async triggerRecompute() {
    await this.store.notes.recompute(this.payload.id);
  }
}

export class RevokeNoteAclViaEmailAddressOperationGuestMode extends BaseSyncOperationGuestMode<
  IRevokeNoteAclViaEmailAddressOperation,
  IRevokeNoteAclViaEmailAddressOperationGuestMode
> {
  triggerRecompute(): Promise<void> {
    return Promise.resolve();
  }

  get operationKind(): GuestModeSupportedSyncOperationKind {
    return "REVOKE_NOTE_ACL_VIA_EMAIL_ADDRESS";
  }
}
