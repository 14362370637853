import { getChatItems } from "@/store/chat/getChatItems";
import { AppStore } from "@/store/AppStore";
import { ChatMessageContext, ChatItem, ChatItemKind, ChatHistoryIndexes } from "@/store/chat/types";
import { makeObservable, observable, action } from "mobx";
import { MentionChip } from "@/pages/chat/ChatInput";
import { UNTITLED_COLLECTION_TITLE, UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { MENTION_PREFIX_COLLECTION, MENTION_PREFIX_NOTE } from "@/store/chat/constants";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { getCollectionItemSubtitle } from "@/components/collection-item-subtitle";
import { Maybe } from "@/domains/common/types";
import styled from "@emotion/styled";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { uuidModule } from "@/modules/uuid";
import { NoteIcon } from "@/design-system/components/item-list/rows/icon/note/note";
import { noop } from "lodash-es";
import { css } from "@/domains/emotion";
import { mdsFontSizes } from "@/design-system/foundations";
import { generateRecentDateString } from "@/domains/date/date";
import { DateTime } from "luxon";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { notesModule } from "@/modules/notes";
import { INoteObservable } from "@/store/note";
import { MemCommonEditorActionKind } from "@mem-labs/common-editor";
import { Subscription } from "dexie";
import { EventContext } from "@/domains/metrics/context";

const MAX_RESULTS = 100;

export class ChatHistory {
  private store: AppStore;

  context?: ChatMessageContext;
  items: ChatItem[] = [];
  contextStartedAt?: string;
  liveQuerySubscription: Maybe<Subscription>;
  searchSuggestions: SearchSuggestion[] = [];
  mentionQuery = "";

  constructor({
    indexes,
    context,
    store,
  }: {
    indexes: ChatHistoryIndexes[];
    context?: ChatMessageContext;
    store: AppStore;
  }) {
    this.context = context;
    this.store = store;

    this.items = getChatItems(indexes);
    const lastSectionHeader = this.items.findLast(item => item.kind === ChatItemKind.SectionHeader);
    if (
      lastSectionHeader &&
      "locallyCreatedAt" in lastSectionHeader &&
      typeof lastSectionHeader.locallyCreatedAt === "string"
    ) {
      this.contextStartedAt = lastSectionHeader.locallyCreatedAt;
    }

    makeObservable<ChatHistory, "store" | "getSlashCommandChips" | "getSearchSuggestionType">(
      this,
      {
        getSearchSuggestionType: true,
        liveQuerySubscription: true,
        store: false,
        getSlashCommandChips: false,
        getAvailableChips: false,
        contextStartedAt: false,
        context: observable,
        items: observable,
        submitChatMessage: action,
        mentionQuery: observable,
        searchSuggestions: observable,
        search: action,
      }
    );
  }

  private getSearchSuggestionType(mentionChar: string) {
    if (mentionChar === MENTION_PREFIX_NOTE) {
      return SearchSuggestionType.NOTE;
    }

    if (mentionChar === MENTION_PREFIX_COLLECTION) {
      return SearchSuggestionType.COLLECTION;
    }

    return SearchSuggestionType.OTHER;
  }

  getSlashCommandChips = (mentionQueryText: string) => {
    const INSERT_TABLE_ID = "insert-table";
    const SLASH_COMMANDS: (SearchSuggestion & { action: MentionChip["action"] })[] = [
      {
        modelId: INSERT_TABLE_ID,
        type: SearchSuggestionType.OTHER,
        label: "Table",
        lastViewedAt: "",
        sortKey: 0,
        action: {
          kind: MemCommonEditorActionKind.InsertTable,
          payload: {},
        },
        isAvailable: 1,
      },
    ];
    const suggestions = mentionQueryText
      ? this.store.search.inMemory(mentionQueryText, SLASH_COMMANDS)
      : SLASH_COMMANDS;

    const chips = suggestions.map(e => {
      const chip: MentionChip = {
        id: e.modelId,
        iconKind: MdsIconKind.Table,
        label: e.label,
        action: SLASH_COMMANDS.find(c => e.modelId === c.modelId)?.action,
      };
      return chip;
    });

    return chips;
  };

  search = async (mentionQueryText: string, mentionChar: string, excludeCurrent?: boolean) => {
    const suggestionType = this.getSearchSuggestionType(mentionChar);
    let andCondition = (item: SearchSuggestion) => item.type === suggestionType;
    if (excludeCurrent) {
      andCondition = item => item.modelId !== this.context?.id && item.type === suggestionType;
    }

    const suggestions = await this.store.search.forSuggestions(
      mentionQueryText,
      "mentions",
      andCondition
    );

    return suggestions;
  };

  // TODO: extract to a separate module
  getAvailableChips = async (
    mentionQuery: string,
    opts?: { inlineCreation?: boolean; excludeCurrent?: boolean }
  ): Promise<MentionChip[]> => {
    const mentionChar = mentionQuery[0];
    const mentionQueryText = mentionQuery.slice(1).trim();

    if (mentionChar === "/") return this.getSlashCommandChips(mentionQueryText);
    const suggestions = await this.search(mentionQueryText, mentionChar, opts?.excludeCurrent);

    const isSearchingCollections = mentionQuery.startsWith("#");
    const iconKind = isSearchingCollections ? MdsIconKind.Collection : MdsIconKind.Document;

    const lowercaseMentionQueryText = mentionQueryText.toLowerCase();

    let addCreateNew = !!opts?.inlineCreation && !!lowercaseMentionQueryText.length;

    const getDropdownButtonContentForNote = (
      id: string,
      label: string
    ): Maybe<{
      isOwnedByMe: INoteObservable["isOwnedByMe"];
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
    }> => {
      const note = this.store.notes.get(id);
      if (note) {
        const subtitle = generateRecentDateString(
          DateTime.fromISO(
            note.lastMentionedAt || note.lastViewedAt || note.receivedAt || note.createdAt || ""
          ),
          { skipFullDayName: true }
        );
        return {
          isOwnedByMe: note.isOwnedByMe,
          iconKind: () => <NoteIcon toggleSelected={noop} />,
          content: () => (
            <MentionContent>
              <MentionTitle>
                <span>{label}</span>
                {note.isShared && (
                  <SmallerIcon
                    kind={MdsIconKind.Shared}
                    innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
                  />
                )}
              </MentionTitle>
              <MentionSubtitle>{subtitle}</MentionSubtitle>
            </MentionContent>
          ),
        };
      }
    };

    const getDropdownButtonContentForCollection = (
      id: string,
      label: string
    ): Maybe<{
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
      isOwnedByMe: CollectionObservable["isOwnedByMe"];
    }> => {
      const collection = this.store.collections.get(id);
      if (collection) {
        return {
          iconKind: () => <CollectionIcon collectionId={id} />,
          content: () => (
            <MentionContent>
              <MentionTitle>
                <span>{label}</span>
                {collection.isShared && (
                  <SmallerIcon
                    kind={MdsIconKind.Shared}
                    innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
                  />
                )}
              </MentionTitle>
              <MentionSubtitle>
                {getCollectionItemSubtitle(collection.itemList.sizeData)}
              </MentionSubtitle>
            </MentionContent>
          ),
          isOwnedByMe: collection.isOwnedByMe,
        };
      }
    };

    const prepareChips = (getExtraInfo?: typeof getDropdownButtonContentForCollection) => {
      const exactMatchIndex = suggestions.findIndex(
        suggestion => suggestion.label.trim().toLowerCase() === lowercaseMentionQueryText
      );
      if (exactMatchIndex >= 0) {
        const exactMatch = suggestions[exactMatchIndex];
        suggestions.splice(exactMatchIndex, 1);
        suggestions.unshift(exactMatch);
      }
      suggestions.slice(0, MAX_RESULTS).forEach(suggestion => {
        const { modelId: id, label } = suggestion;
        const { iconKind: icon, content, isOwnedByMe } = getExtraInfo?.(id, label) ?? {};
        chips.push({
          id,
          label,
          iconKind: icon ?? iconKind,
          content,
        });
        if (label.trim().toLowerCase() === lowercaseMentionQueryText && isOwnedByMe) {
          addCreateNew = false;
        }
      });
    };

    const chips: MentionChip[] = [];
    switch (mentionChar) {
      case MENTION_PREFIX_NOTE: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentNotesInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, MAX_RESULTS)
            .forEach(collection => {
              const { id, title } = collection;
              const label = title || UNTITLED_NOTE_TITLE;
              const {
                iconKind: icon,
                content,
                isOwnedByMe,
              } = getDropdownButtonContentForNote(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
              if (label.trim().toLowerCase() === lowercaseMentionQueryText && isOwnedByMe) {
                addCreateNew = false;
              }
            });
          break;
        }

        prepareChips(getDropdownButtonContentForNote);
        break;
      }
      case MENTION_PREFIX_COLLECTION: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentCollectionsInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, 5)
            .forEach(collection => {
              const { id, title } = collection;
              const label = title || UNTITLED_COLLECTION_TITLE;
              const { iconKind: icon, content } =
                getDropdownButtonContentForCollection(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
            });
          break;
        }

        prepareChips(getDropdownButtonContentForCollection);
        break;
      }
    }
    const exactMatchIndex = chips.findIndex(
      chip => chip.label.trim().toLowerCase() === lowercaseMentionQueryText
    );
    if (exactMatchIndex >= 0) {
      const exactMatch = chips[exactMatchIndex];
      chips.splice(exactMatchIndex, 1);
      chips.unshift(exactMatch);
    }
    if (addCreateNew) {
      const id = uuidModule.generate();
      chips.push({
        alwaysVisible: true,
        id: id,
        label: mentionQueryText,
        iconKind: () => (
          <PlusIconWrapper>
            <MdsIcon kind={MdsIconKind.Plus} />
          </PlusIconWrapper>
        ),
        content: () => (
          <MentionContent>
            <MentionTitle>“{mentionQueryText}”</MentionTitle>
            <MentionSubtitle>
              Create new {isSearchingCollections ? "collection" : "note"}
            </MentionSubtitle>
          </MentionContent>
        ),
        beforeSelection: async () => {
          if (isSearchingCollections) {
            await this.store.collections.createCollection({
              collectionId: id,
              title: mentionQueryText,
              description: "",
              eventContext: EventContext.EditorInline,
            });
            return;
          }
          this.store.notes.createNote({
            noteId: id,
            eventContext: EventContext.EditorInline,
          });

          const encodedContent = notesModule.convertMdxToEncodedContent("# " + mentionQueryText);
          await new UpdateNoteContentUsingDiffOperation({
            store: this.store,
            payload: {
              id,
              encoded_content_diff: encodedContent || "",
            },
            primaryLabel: mentionQueryText,
            secondaryLabel: "",
          }).execute();
        },
      });
    }
    return chips;
  };

  submitChatMessage = async ({ markdownContent }: { markdownContent: string }) => {
    if (!markdownContent.trim()) return;

    await this.store.chatMessages.sendNewMessage(
      markdownContent,
      this.context,
      this.contextStartedAt
    );
  };
}

const MentionContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
}));

const MentionTitle = styled.div(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.grey.x600,
  display: "flex",
  gap: theme.spacing.sm,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const SmallerIcon = styled(MdsIcon)({
  height: 12,
  width: 12,
});

const smallerIconFontSizeStyles = css({
  fontSize: mdsFontSizes().xxsmall,
  width: 12,
});

const MentionSubtitle = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const PlusIconWrapper = styled.div({
  width: 40,
  height: 40,
  // borderRadius: theme.borderRadius.mediumLarge,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
