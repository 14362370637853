import { Uuid } from "@/domains/global/identifiers";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { SpaceAccountCollectionModelData } from "@/store/recent-items/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { maxBy } from "lodash-es";
import { computed, makeObservable, observable } from "mobx";

export class SpaceAccountCollectionObservable extends BaseSyncModel<SpaceAccountCollectionModelData> {
  public modelKind = SyncModelKind.SpaceAccountCollection;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountCollectionModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      // PROPERTIES
      modelKind: observable,
      itemId: computed,
      item: computed,
      isItemTrashedOrDeleted: computed,
      lastViewedAt: computed,
      lastAddedToAt: computed,
      lastInteractedAt: computed,
    });
  }

  get itemId() {
    return this.modelData.collection_id;
  }

  get item(): CollectionObservable | undefined {
    return this.store.collections.getCollectionObservableById({ collectionId: this.itemId });
  }

  get isItemTrashedOrDeleted() {
    return (
      this.isDeleted ||
      !this.item ||
      this.item.isTrashed ||
      this.item.isDeleted ||
      !this.item.canAccess
    );
  }

  get lastViewedAt() {
    return this.modelData.last_viewed_at || "";
  }

  get lastAddedToAt() {
    return this.modelData.last_added_to_at || "";
  }

  get lastInteractedAt() {
    return maxBy([this.lastViewedAt, this.lastAddedToAt], dateStr => new Date(dateStr || 0));
  }
}
