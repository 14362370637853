import { Maybe } from "@/domains/common/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import {
  QueueProcessingState,
  SyncModelData,
  SyncUpdate,
  SyncUpdateResponse,
} from "@/store/sync/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { BaseSyncActionQueue } from "@/store/sync/BaseSyncActionQueue";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store/AppStore";
import { GuestAppStore } from "@/store";
import { DateTime, Duration } from "luxon";
import {
  InboundSyncState,
  InboundSyncStatus,
  OutboundSyncState,
  OutboundSyncStatus,
} from "@/components/sync/types";
import { first } from "lodash-es";
import localDb from "@/domains/local-db";
import { standardizeSyncId } from "@/store/sync/utils";
import { controlFlowModule } from "@/modules/control-flow";

const ONE_MINUTE = 60 * 1000;

const MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED = Duration.fromObject({ seconds: 90 });
const SYNC_IS_UP_TO_DATE_DURATION = Duration.fromObject({ seconds: 10 });

export interface BaseQueryValue {
  results: SyncUpdateResponse[];
  /** Matches the return type from the server. */
  latest_sync_id?: string | null;
}

export abstract class AppStoreBaseSyncStore<
  Store extends AppStore | GuestAppStore,
  SyncActionQueue extends BaseSyncActionQueue<AppStore | GuestAppStore>,
  QueryValue extends BaseQueryValue,
> extends AppSubStore<Store> {
  public actionQueue: SyncActionQueue;

  /**
   * These are "Inbound" sync attributes.
   * "Outbound" sync attributes are stored on our ActionQueue.
   */
  protected lastSyncId: Maybe<string> = undefined;
  latestSpaceAccountSequenceId: Maybe<number> = undefined;

  lastSyncedAt: Maybe<DateTime> = undefined;
  isBootstrapping = false;
  isSyncing = false;
  hasCompletedInitialSync = false;

  private pollingDisposer: Maybe<IReactionDisposer> = undefined;
  protected pollingInterval = ONE_MINUTE;

  abstract createSyncActionQueue(): SyncActionQueue;
  abstract finishProcessingQueryResponse(_: QueryValue): Promise<void>;
  abstract syncQuery: QueryObservable<QueryValue>;
  abstract subscribe(): void;
  abstract unsubscribe(): void;
  abstract processSyncUpdate(
    update: SyncUpdate<SyncModelData>,
    options?: { hydrating?: boolean }
  ): Promise<void>;
  abstract bulkProcessSyncUpdates(syncUpdates: SyncUpdate<SyncModelData>[]): Promise<void>;
  abstract hydrateFromStorage(): Promise<void>;
  abstract fetchBootstrapEvents(): Promise<{
    lastSyncId: Maybe<string>;
    allEvents: SyncUpdate<SyncModelData>[];
  }>;

  constructor(injectedDeps: AppSubStoreArgs<Store>) {
    super(injectedDeps);

    this.actionQueue = this.createSyncActionQueue();

    makeObservable<this, "lastSyncId" | "pollingDisposer" | "pollingInterval">(this, {
      startPolling: true,
      actionQueue: observable,
      lastSyncId: observable,
      latestSpaceAccountSequenceId: observable,

      pollingDisposer: false,
      pollingInterval: true,

      createSyncActionQueue: false,
      finishProcessingQueryResponse: action,
      syncQuery: computed,
      subscribe: action,
      unsubscribe: action,

      processSyncUpdate: action,
      hydrateFromStorage: action,
      fetchBootstrapEvents: action,
      bootstrap: action,
      stopSync: action,
      resetSync: action,
      isBootstrapping: observable,
      hasCompletedInitialSync: observable,
      isSyncing: observable,
      lastSyncedAt: observable,
      initialize: action,
      isReady: computed,
      isUpToDate: computed,
      restoreLastSyncId: action,
      saveLastSyncId: action,

      inboundSyncStatus: computed,
      inboundSyncState: computed,
      outboundSyncStatus: computed,
      outboundLastSyncedAt: computed,
      pendingOutboundOperationCount: computed,
      earliestPendingOutboundOperationCommittedAt: computed,
      outboundSyncState: computed,
      bulkProcessSyncUpdates: action,
    });
  }

  public async initialize() {
    runInAction(() => {
      this.isBootstrapping = true;
    });

    try {
      await this.hydrateFromStorage();
      await this.bootstrap();
      this.actionQueue.start();
      this.startPolling();
      this.subscribe();
    } catch (err) {
      logger.error({
        message: "[SYNC][AppStoreSyncStore] [useEffectOnMount] AppStore failed to initialize.",
        info: { err: objectModule.safeErrorAsJson(err as Error) },
      });
    } finally {
      runInAction(() => {
        this.isBootstrapping = false;
      });
    }
  }

  /**
   * We use this pollingDisposer to observe the `this.syncQuery` and call.
   *
   * (It is a QueryObservable, so that when it starts being observed, it
   * automatically starts polling.)
   *
   * In the future, we might consider refactoring `this.syncQuery` to use a more
   * linear flow (rather than the indirect one polling via QueryObservable).
   */
  public startPolling() {
    if (this.pollingDisposer) return;

    console.debug(
      "[SYNC][AppStoreBaseSyncStore][startPolling] Started observing the `this.syncQuery`."
    );

    this.pollingDisposer = reaction(
      () => this.syncQuery.data,
      data => {
        console.debug(
          `[SYNC][AppStoreBaseSyncStore][startPolling] Received data up to latest_sync_id=${data?.latest_sync_id ?? "null"}`
        );
      }
    );
  }

  public async saveLastSyncId(syncId: string) {
    this.lastSyncId = standardizeSyncId(syncId);

    await this.store.memDb?.settings.setLastSyncId({ syncId });
  }

  public async restoreLastSyncId() {
    const syncId = await this.store.memDb?.settings.getLastSyncId();

    if (!syncId) {
      /**
       * If it isn't set, we try the backwards-compatible version.
       *
       * Wrapped in a try-catch because `standardizeSyncId` may
       * throw an error if the syncId is malformed.
       *
       * We can remove this once everyone is cut-over to CVRs, using
       * the force-upgrade-client flow.
       *
       * TODO: @MacroMackie follow up with this on Friday, Nov 15.
       */
      try {
        const lastUpdate = await this.store.memDb?.syncUpdates
          .orderBy("locally_committed_at")
          .last();

        if (lastUpdate) {
          const lastSyncId = standardizeSyncId(lastUpdate.sync_id);

          logger.debug({
            message: "[Sync] Set lastSyncId from db",
            info: { lastSyncId: `${lastSyncId}` },
          });

          this.lastSyncId = lastSyncId;
        }
      } catch (unknownErr) {
        logger.error({
          message: "[Sync] Failed to restore lastSyncId using legacy logic.",
          info: { unknownErr: objectModule.safeErrorAsJson(unknownErr as Error) },
        });
      }
    }

    this.lastSyncId = syncId;
  }

  public async bootstrap() {
    if (this.lastSyncId !== undefined) return;

    console.debug("[SYNC][AppStoreSyncStore] Starting Bootstrap...");

    const { lastSyncId, allEvents } = await this.fetchBootstrapEvents();

    if (lastSyncId === undefined) return; // Bootstrap failed, fallback on sync with undefined lastSyncId

    // NOTE: All actions need to be persisted at once because bootstrapSyncEvents are only valid as a full set

    const now = performance.now();
    logger.debug({
      message: "[SYNC][AppStoreSyncStore] Start bulk processing sync updates...",
      info: { updates: allEvents.length },
    });

    await this.bulkProcessSyncUpdates(allEvents);

    const end = performance.now();
    logger.debug({
      message: "[SYNC][AppStoreSyncStore] End bulk processing sync updates...",
      info: { duration: `${end - now}ms` },
    });

    this.lastSyncedAt = DateTime.now();
    await this.saveLastSyncId(lastSyncId);

    console.debug("[SYNC][AppStoreSyncStore] Completed Bootstrap", {
      lastSyncId: this.lastSyncId,
      events: allEvents.length,
    });

    console.debug("[SYNC][AppStoreSyncStore] Clear up deprecated sync updates");

    await localDb.syncUpdates.clear();
  }

  get isReady() {
    return !!this.lastSyncedAt;
  }

  get isUpToDate() {
    const lastSync = this.lastSyncedAt ?? DateTime.fromMillis(0);

    /**
     * If we've synced within the last "SYNC_IS_UP_TO_DATE_DURATION" seconds,
     * we consider ourselves "up to date."
     */
    return lastSync.diffNow().milliseconds <= SYNC_IS_UP_TO_DATE_DURATION.milliseconds;
  }

  public stopSync() {
    this.actionQueue.pause();
    this.unsubscribe();
    this.pollingDisposer?.();
    this.pollingDisposer = undefined;
  }

  public resetSync() {
    this.actionQueue.reset();
  }

  get inboundSyncStatus(): InboundSyncStatus {
    /**
     * @todo - Check network status here and return "PausedOffline" if we're offline.
     * See - https://linear.app/mem-labs/issue/MEM-8072/introduce-an-online-offline-network-checker-which-is-tied-into-mobx
     */

    if (this.isBootstrapping) {
      return InboundSyncStatus.Bootstrapping;
    }

    if (this.isSyncing) {
      if (!this.hasCompletedInitialSync) {
        return InboundSyncStatus.PerformingInitialSync;
      }

      return InboundSyncStatus.Syncing;
    }

    return InboundSyncStatus.Idle;
  }

  get inboundSyncState(): InboundSyncState {
    return {
      status: this.inboundSyncStatus,
      info: {
        lastSyncedAt: this.lastSyncedAt,
      },
    };
  }

  get outboundSyncStatus(): OutboundSyncStatus {
    /**
     * @todo - Check network status here and return "PausedOffline" if we're offline.
     * See - https://linear.app/mem-labs/issue/MEM-8072/introduce-an-online-offline-network-checker-which-is-tied-into-mobx
     */

    const queueProcessingState = this.actionQueue.processingState;

    /**
     * If we have a pending operation which has been in queue for over a minute...
     */
    if (
      this.earliestPendingOutboundOperationCommittedAt &&
      this.earliestPendingOutboundOperationCommittedAt <
        DateTime.utc().minus(MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED)
    ) {
      /**
       *  ...and we haven't synced in over a minute, we mark our status as "paused"
       */
      if (
        !this.outboundLastSyncedAt ||
        this.outboundLastSyncedAt <
          DateTime.utc().minus(MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED)
      ) {
        return OutboundSyncStatus.PausedUnknown;
      }
    }

    /**
     * Otherwise, we can just return a mapping of the queue processing state.
     */
    switch (queueProcessingState) {
      case QueueProcessingState.NotReady:
        return OutboundSyncStatus.PausedUnknown;

      case QueueProcessingState.Paused:
        return OutboundSyncStatus.PausedUnknown;

      case QueueProcessingState.Processing:
        return OutboundSyncStatus.Syncing;

      case QueueProcessingState.Ready:
        return OutboundSyncStatus.Idle;

      default:
        return controlFlowModule.assertUnreachableCase(queueProcessingState);
    }
  }

  get outboundLastSyncedAt(): Maybe<DateTime> {
    return this.actionQueue.lastProcessedAt;
  }

  get pendingOutboundOperationCount(): number {
    return this.actionQueue.processing.length;
  }

  get earliestPendingOutboundOperationCommittedAt(): Maybe<DateTime> {
    const firstOperation = first(this.actionQueue.processing);

    if (!firstOperation) {
      return undefined;
    }

    return DateTime.fromISO(firstOperation.committedAt);
  }

  get outboundSyncState(): OutboundSyncState {
    return {
      status: this.outboundSyncStatus,
      info: {
        lastSyncedAt: this.outboundLastSyncedAt,
        pendingOperationCount: this.pendingOutboundOperationCount,
        earliestPendingOperationCommittedAt: this.earliestPendingOutboundOperationCommittedAt,
      },
    };
  }
}
