import { copyCollectionLinkToClipboard } from "@/actions/copyCollectionLinkToClipboard";
import { copyNoteLinkToClipboard } from "@/actions/copyNoteLinkToClipboard";
import { copyUrlToClipboard } from "@/actions/copyUrlToClipboard";
import { deleteCollectionPermanently } from "@/actions/deleteCollectionPermanently";
import { deleteNotesPermanently } from "@/actions/deleteNotesPermanently";
import { moveNotesToTrash } from "@/actions/moveNoteToTrash";
import { restoreNotesFromTrash } from "@/actions/restoreNotesFromTrash";
import { removeNoteFromCollection } from "@/actions/removeNoteFromCollection";
import { addNotesToCollection } from "@/actions/addNotesToCollection";
import { removeNotesFromCollection } from "@/actions/removeNotesFromCollection";
import { openCollectionInNewTab } from "@/actions/openCollectionInNewTab";
import { openNoteInNewTab } from "@/actions/openNoteInNewTab";
import { openSearchInNewTab } from "@/actions/openSearchInNewTab";
import { createCollection } from "@/actions/createCollection";

export const actions = {
  addNotesToCollection,
  copyUrlToClipboard,
  copyCollectionLinkToClipboard,
  copyNoteLinkToClipboard,
  createCollection,
  deleteCollectionPermanently,
  deleteNotesPermanently,
  moveNotesToTrash,
  restoreNotesFromTrash,
  removeNoteFromCollection,
  removeNotesFromCollection,
  openCollectionInNewTab,
  openNoteInNewTab,
  openSearchInNewTab,
} as const;
