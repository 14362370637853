import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import {
  IndexedNoteSyncUpdateValue,
  NoteUpsertedSyncUpdateValue,
  INoteObservable,
  NoteObservable,
} from "@/store/note";
import { uuidModule } from "@/modules/uuid";
import { NoteModelData } from "@/store/note/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { CreateNoteOperation } from "@/store/sync/operations/notes/CreateNoteOperation";
import { DeleteNoteOperation } from "@/store/sync/operations/notes/DeleteNoteOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, override } from "mobx";
import { Table } from "dexie";
import { SearchSuggestionType } from "@/domains/search";
import { NoteIndexes } from "@/store/note/NoteIndexes";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { logger } from "@/modules/logger";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { objectModule } from "@/modules/object";
import { EventContext } from "@/domains/metrics/context";
import { trackEvent, TrackedEvent } from "@/domains/metrics";

export interface NotesStore {
  allNotes: INoteObservable[];
  createNote: (payload: { noteId: Uuid; eventContext: EventContext }) => void;
  getNoteObservableById: (payload: { noteId: Uuid }) => Maybe<INoteObservable>;
}

export class AppStoreNoteStore
  extends BaseSyncModelStore<NoteObservable, NoteModelData>
  implements NotesStore
{
  public static searchTitleLimit = 100;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Note, ...injectedDeps });
    makeObservable<this, "updateSearchSuggestions">(this, {
      remoteTable: override,
      localTable: override,
      recompute: override,

      createSyncModel: false,
      getNoteObservableById: false,

      allNotes: computed,

      composeNewNote: action,
      createNote: action,
      deleteNote: action,
      trashNote: action,

      updateSearchSuggestions: false,
    });
  }

  createSyncModel(updateValue: IndexedNoteSyncUpdateValue): NoteObservable {
    return new NoteObservable({ id: updateValue.model_id, data: updateValue, store: this.store });
  }

  getNoteObservableById = ({ noteId }: { noteId?: Uuid }): Maybe<INoteObservable> => {
    return noteId ? this.get(noteId) : undefined;
  };

  get allNotes(): INoteObservable[] {
    // DEXIE REFACTOR TODO: REFACTOR FOR SEARCH
    return [];
  }

  // ACTIONS
  public composeNewNote = async ({ eventContext }: { eventContext: EventContext }) => {
    const noteId = uuidModule.generate();
    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({ noteId });
    await this.createNote({ noteId, eventContext });
    await Promise.all([
      this.store.notes.getAsync(noteId),
      this.store.noteContentDocuments.getAsync(noteContentDocumentId),
    ]);
    this.store.navigation.goToNote({ noteId, autoFocus: true });
  };

  public async createNote({ noteId, eventContext }: { noteId: Uuid; eventContext: EventContext }) {
    await new CreateNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
    trackEvent(TrackedEvent.NoteCreate, { note_id: noteId, context: eventContext });
  }

  public async deleteNote({ noteId }: { noteId: Uuid }) {
    await new DeleteNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
  }

  public async trashNote({ noteId }: { noteId: Uuid }) {
    await new TrashNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
  }

  public get remoteTable() {
    return this.db.mappedTables[this.modelKind].remote as Table<SyncUpdateValue<NoteModelData>>;
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<IndexedNoteSyncUpdateValue>;
  }

  public async recompute(modelId: Uuid) {
    const remoteData = await this.remoteTable.get(modelId);
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_id: modelId })
      .sortBy("locally_committed_at");

    const lastOptimisticUpdate = optimisticUpdates.at(-1);
    if (lastOptimisticUpdate?.kind === "DELETED" || lastOptimisticUpdate?.kind === "ACL_REVOKED") {
      logger.debug({
        message: "[APP STORE NOTE STORE] deleting note / revoking access",
        info: { modelId },
      });

      await this.localTable.delete(modelId);
      this.pool.delete(modelId);
      await this.store.search.remove(modelId);

      return;
    }

    // Fetch other required data
    const spaceAccountNoteId = resolveSpaceAccountNoteSyncModelUuid({
      noteId: modelId,
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
    });

    const spaceAccountNote = (await this.db.mappedTables[SyncModelKind.SpaceAccountNote].local.get(
      spaceAccountNoteId
    )) as SyncUpdateValue<SpaceAccountNoteModelData> | undefined;

    // Optimistic updates
    const data = lastOptimisticUpdate?.value || remoteData; // At least one of these should be defined
    if (!data) {
      await this.localTable.delete(modelId);
      await this.store.search.remove(modelId);
      return;
    }

    const indexes = new NoteIndexes({
      store: this.store,
      remoteData,
      optimisticUpdates,
      spaceAccountNote,
    }).indexes;

    const dataWithIndexes: IndexedNoteSyncUpdateValue = {
      ...(data as NoteUpsertedSyncUpdateValue),
      ...indexes,
    };

    await this.localTable.put(dataWithIndexes, dataWithIndexes.model_id);
    await this.updateSearchSuggestions(dataWithIndexes);

    // NOTE: we update fullText search content not here but in the NoteContentDocumentStore to
    // a. detach its potentially heavy computation from note content update
    // b. get a direct access to the most up-to-date note content (we don't optimistically update note content)
  }

  private async updateSearchSuggestions(value: IndexedNoteSyncUpdateValue) {
    try {
      const generateSortKey = (
        value: IndexedNoteSyncUpdateValue
        // includeMentionedAt: boolean
      ): number => {
        const sortKey = value.last_viewed_at || value.created_at;

        // TODO: add lastMentionedAt for includeMentionedAt

        return new Date(sortKey || "").getTime();
      };

      const suggestion = {
        modelId: value.model_id,
        label: value.primary_label.slice(0, AppStoreNoteStore.searchTitleLimit),
        type: SearchSuggestionType.NOTE,
        lastViewedAt: value.last_viewed_at,
        sortKey: generateSortKey(value),
        mentionKey: generateSortKey(value),
        isAvailable: value.is_available,
      };

      await this.store.search.updateSuggestion(suggestion);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreNoteStore] Error generating search suggestion",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }
}
