import { IContact } from "@/store/contacts/types";

export const isMemAccount = (contact?: IContact) => {
  return (
    !!contact?.profileEmailAddress.endsWith("@mem.ai") ||
    contact?.profileEmailAddress == "copilotpriscilla@gmail.com" ||
    contact?.profileEmailAddress == "copilotkevin1@gmail.com" ||
    contact?.profileEmailAddress == "alex.o.tsai@gmail.com"
  );
};
