// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isClientUpgradeError(e: any) {
  return (
    e.error_category === "CUSTOM_ERROR" && e.error_metadata.error_kind === "CLIENT_UPGRADE_REQUIRED"
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isClientResetRequiredError(e: any) {
  return (
    e.error_category === "CUSTOM_ERROR" && e.error_metadata.error_kind === "CLIENT_RESET_REQUIRED"
  );
}
