import { RuntimeUnreachableCaseError } from "@/domains/errors";

/**
 * This function should be used to introduce type-checking issues when
 * case statements are missed.
 *
 * (Eg. this is used for scenarios where we are switching based on an enum value.)
 */
export const _assertUnreachableCase = ({
  message,
  value,
}: {
  message: string;
  value: never;
}): never => {
  throw new RuntimeUnreachableCaseError({ message, value });
};
