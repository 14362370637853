import { FC } from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  StripePaymentFormContent,
  StripePaymentFormContentProps,
} from "@/components/subscriptions/form/StripePaymentFormContent";
import { observer } from "mobx-react-lite";
import { StripeFormInfo } from "@/components/subscriptions/form/types";
import { AsyncResult } from "@/modules/async-result/types";
import { AnimatePresence, motion, AnimationProps } from "framer-motion";
import { MdsSkeletonLoader } from "@/design-system/components/loader";
import { css } from "@/domains/emotion";
import { STRIPE_PAYMENT_FORM_MIN_HEIGHT_PX } from "@/components/subscriptions/form/constants";
import { MdsSkeletonLoaderShape } from "@/design-system/components/loader/types";
import { stripePromise } from "@/modules/client-env/stripeConfig";

export interface StripePaymentFormWrapperProps
  extends Omit<StripePaymentFormContentProps, "stripeClientSecret"> {
  stripeFormInfoState: AsyncResult<StripeFormInfo>;
}

const initialStyles: AnimationProps["initial"] = {
  opacity: 0,
};

const animateStyles: AnimationProps["initial"] = {
  opacity: 1,
};

export const StripePaymentFormWrapper: FC<StripePaymentFormWrapperProps> = observer(
  ({ stripeFormInfoState, ...props }) => {
    const stripeFormInfo = stripeFormInfoState.data;
    const stripeClientSecret = stripeFormInfo?.stripeClientSecret ?? null;

    const isLoading = !stripeFormInfoState.called || stripeFormInfoState.loading;

    return (
      <div className={wrapperStyles} key="abc">
        <AnimatePresence>
          {isLoading ? (
            <motion.div
              key="stripeFormInfoStateLoader"
              initial={{ ...initialStyles }}
              animate={{ ...animateStyles, opacity: 0.5 }}
              exit={{ ...initialStyles, transition: { delay: 0 } }}
              transition={{ duration: 1, delay: 1.5 }}
              className={loaderStyles}
            >
              <MdsSkeletonLoader
                className={skeletonOneStyles}
                shape={MdsSkeletonLoaderShape.Rectangle}
                height={44}
              />
              <MdsSkeletonLoader
                className={skeletonTwoStyles}
                shape={MdsSkeletonLoaderShape.Rectangle}
                height={20}
                width={120}
              />
            </motion.div>
          ) : (
            <motion.div
              key="stripeFormInfoStateContent"
              initial={{ ...initialStyles }}
              animate={{ ...animateStyles }}
              exit={{ ...initialStyles }}
              transition={{ duration: 1.5 }}
            >
              <Elements stripe={stripePromise}>
                <StripePaymentFormContent {...props} stripeClientSecret={stripeClientSecret} />
              </Elements>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

const wrapperStyles = css({
  position: "relative",
  height: "100%",
  width: "100%",
  minHeight: STRIPE_PAYMENT_FORM_MIN_HEIGHT_PX,
});

const loaderStyles = css({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
});

const skeletonOneStyles = css({
  position: "absolute",
  top: 0,
  left: 0,
});

const skeletonTwoStyles = css({
  position: "absolute",
  top: 52,
  left: 0,
});
