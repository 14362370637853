import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { usePublicAppStore } from "@/store";

export const useLogOutPageLogic = () => {
  const { publicStore } = usePublicAppStore();

  useEffectOnMount(() => {
    publicStore.auth.logOut();
  });
};
