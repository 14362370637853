import { useAppStore, useGuestAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { SyncErrorOverlayContent } from "@/components/error-handling/SyncErrorOverlayContent";
import { useCallback, useEffect, useRef } from "react";
import { runInAction } from "mobx";
import { DateTime } from "luxon";
import { AppSyncActionQueue } from "@/store/sync/AppSyncActionQueue";
import { GuestSyncActionQueue } from "@/store/sync/GuestSyncActionQueue";
import { TrackedEvent, trackEvent } from "@/domains/metrics";

export const StandardSyncErrorOverlay = observer(function StandardSyncErrorOverlay() {
  const { store } = useAppStore();
  return (
    <SyncErrorOverlay
      actionQueue={store.sync.actionQueue}
      reset={store.resetStorageAndReload}
      resume={store.sync.actionQueue.resume}
    />
  );
});

export const GuestModeSyncErrorOverlay = observer(function GuestModeSyncErrorOverlay() {
  const { guestStore } = useGuestAppStore();
  return (
    <SyncErrorOverlay
      actionQueue={guestStore.sync.actionQueue}
      reset={guestStore.sync.resetSync}
      resume={guestStore.sync.actionQueue.resume}
    />
  );
});

interface SyncErrorOverlayProps {
  actionQueue: AppSyncActionQueue | GuestSyncActionQueue;
  reset: () => void;
  resume: () => void;
}

const SyncErrorOverlay = observer<SyncErrorOverlayProps>(function SyncErrorOverlay({
  actionQueue,
  reset,
  resume,
}) {
  const errorDetails = actionQueue.syncErrorModalFields;
  const errorDetailsRef = useRef(errorDetails);
  errorDetailsRef.current = errorDetails;

  const handleResetClick = useCallback(async () => {
    const errorDetails = errorDetailsRef.current;
    if (!errorDetails?.modalActionHandler) {
      reset();
      return;
    }

    await runInAction(async () => {
      await errorDetails?.modalActionHandler?.();
      resume();
    });
  }, [reset, resume]);

  const lastSentOperationForGenericSyncErrorDialog =
    !errorDetails?.modalActionHandler && actionQueue.lastSentOperation?.committedAt;

  const isModalOpen = !!errorDetails;
  const errorTitle = !errorDetails ? "Please reset syncing" : errorDetails?.title;
  const errorMessage = errorDetails?.message;

  useEffect(() => {
    if (!isModalOpen) return;

    trackEvent(TrackedEvent.SyncErrorShown, {
      title: errorTitle,
      message: errorMessage,
    });
  }, [errorMessage, errorTitle, isModalOpen]);

  return (
    <SyncErrorOverlayContent
      isModalOpen={isModalOpen}
      title={errorTitle}
      message={errorMessage}
      resetActionLabel={errorDetails?.resetActionLabel}
      extraActionButtons={errorDetails?.extraActionButtons}
      includeContactSupportButton={errorDetails?.includeContactSupportButton ?? true}
      lastSyncActionTime={
        lastSentOperationForGenericSyncErrorDialog
          ? DateTime.fromISO(lastSentOperationForGenericSyncErrorDialog).toLocaleString(
              DateTime.TIME_SIMPLE
            )
          : ""
      }
      handleResetClick={handleResetClick}
    />
  );
});
