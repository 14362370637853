import { actions } from "@/actions";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { EventContext } from "@/domains/metrics/context";
import { SearchSuggestionType } from "@/domains/search";
import { uuidModule } from "@/modules/uuid";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { filter } from "lodash-es";
import { action, computed, makeObservable, observable, reaction } from "mobx";

export class AddToCollectionModalStore extends AppSubStore {
  private handleConfirm?: () => void;
  public collection?: CollectionObservable;
  public selectedNoteIds: string[] = [];
  public searchQuery: string;
  public keyboardSelectedIndex = -1;
  public displayedCollections: CollectionObservable[] = [];
  public eventContext?: EventContext;

  constructor(injectedDeps: AppSubStoreArgs, { handleConfirm }: { handleConfirm?: () => void }) {
    super(injectedDeps);

    this.handleConfirm = handleConfirm;
    this.searchQuery = "";

    makeObservable<this, "handleConfirm">(this, {
      handleConfirm: false,

      displayedCollections: observable,
      collection: observable,
      selectedNoteIds: observable,
      searchQuery: observable,
      keyboardSelectedIndex: observable,
      eventContext: observable,

      isOpen: computed,
      selectedNote: computed,
      selectedNotes: computed,
      dropdownContentList: computed,

      open: action,
      close: action,
      setSearchQuery: action,
      incrementSelectedIndex: action,
      decrementSelectedIndex: action,
      resetSelectedIndex: action,
      onKeyDown: action,
      setDisplayedCollections: action,
      queryCollections: action,
    });

    reaction(() => this.searchQuery, this.queryCollections);
  }

  get isOpen() {
    return this.selectedNoteIds.length > 0;
  }

  get selectedNote(): INoteObservable | undefined {
    return this.selectedNotes[0];
  }

  get selectedNotes() {
    return filter(
      this.selectedNoteIds.map(noteId => this.store.notes.getNoteObservableById({ noteId }))
    ) as INoteObservable[];
  }

  get dropdownContentList(): MdsDropdownContentList {
    const output: MdsDropdownContentList = { items: [] };

    const itemIds = this.selectedNoteIds;
    const items = this.selectedNotes;
    if (!itemIds.length || !items.length) return output;

    const displayedCollections = this.displayedCollections;
    if (displayedCollections.length > 0) {
      output.items.push({
        id: "collections-title",
        kind: MdsDropdownItemKind.Detail,
        text: "Collections",
      });
      displayedCollections.forEach((collection, index) => {
        output.items.push({
          id: `collection-${collection.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: collection.label,
          className: index + 1 === this.keyboardSelectedIndex ? selectedOptionStyles : undefined,
          onClick: () => {
            actions.addNotesToCollection({
              notes: items,
              collection,
              store: this.store,
              eventContext: this.eventContext ?? EventContext.Unknown,
            });
            this.handleConfirm?.();
            this.close();
          },
        });
      });
    }

    if (this.searchQuery.length > 0) {
      output.items.push({
        id: "create-collection-title",
        kind: MdsDropdownItemKind.Detail,
        text: "Create collection",
      });
      output.items.push({
        id: "create-collection-button",
        kind: MdsDropdownItemKind.Button,
        iconKind: MdsIconKind.Plus,
        label: `Collection named ${this.searchQuery}`,
        className:
          this.keyboardSelectedIndex > displayedCollections.length
            ? selectedOptionStyles
            : undefined,
        onClick: async () => {
          const collectionId = uuidModule.generate();
          await this.store.collections.createCollection({
            collectionId,
            title: this.searchQuery,
            eventContext: this.eventContext ?? EventContext.Unknown,
          });
          const collectionObservable = await this.store.collections.getAsync(collectionId);
          actions.addNotesToCollection({
            notes: items,
            collection: collectionObservable!,
            store: this.store,
            eventContext: this.eventContext ?? EventContext.Unknown,
          });
          this.handleConfirm?.();
          this.close();
        },
      });
    }
    return output;
  }

  public open({
    noteIds,
    collection,
    eventContext,
  }: {
    noteIds: string[];
    collection?: CollectionObservable;
    eventContext: EventContext;
  }) {
    this.selectedNoteIds = noteIds;
    this.collection = collection;
    this.eventContext = eventContext;
  }

  public close() {
    this.selectedNoteIds = [];
    this.collection = undefined;
    this.eventContext = undefined;
  }

  public setSearchQuery(query: string) {
    this.searchQuery = query;
    this.resetSelectedIndex();
  }

  resetSelectedIndex() {
    this.keyboardSelectedIndex = 1;
  }

  incrementSelectedIndex() {
    const listLength = this.dropdownContentList.items.length;
    const nextItemIndex = (this.keyboardSelectedIndex + 1) % listLength;
    const nextItem = this.dropdownContentList.items[nextItemIndex];
    if (nextItem.kind === MdsDropdownItemKind.Button) {
      this.keyboardSelectedIndex = nextItemIndex;
    } else {
      this.keyboardSelectedIndex = (this.keyboardSelectedIndex + 2) % listLength;
    }
  }

  decrementSelectedIndex() {
    const listLength = this.dropdownContentList.items.length;
    const prevItemIndex = (this.keyboardSelectedIndex - 1 + listLength) % listLength;
    const prevItem = this.dropdownContentList.items[prevItemIndex];
    if (prevItem.kind === MdsDropdownItemKind.Button) {
      this.keyboardSelectedIndex = prevItemIndex;
    } else {
      this.keyboardSelectedIndex = (this.keyboardSelectedIndex - 2 + listLength) % listLength;
    }
  }

  onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    switch (e.key) {
      case "ArrowUp": {
        e.preventDefault();
        this.decrementSelectedIndex();
        break;
      }
      case "ArrowDown": {
        e.preventDefault();
        this.incrementSelectedIndex();
        break;
      }
      case "Enter": {
        e.preventDefault();
        const selectedItem = this.dropdownContentList.items[this.keyboardSelectedIndex];
        if (selectedItem.kind === MdsDropdownItemKind.Button) {
          selectedItem.onClick({ itemId: selectedItem.id });
        }
        break;
      }
    }
  };

  setDisplayedCollections(collections: CollectionObservable[]) {
    this.displayedCollections = collections;
  }

  queryCollections = async (query: string) => {
    const suggestions = await this.store.search.forSuggestions(
      query,
      "default",
      item => item.type === SearchSuggestionType.COLLECTION && item.modelId !== this.collection?.id,
      5
    );

    const collections = (
      await Promise.all(
        suggestions.map(suggestion => this.store.collections.getAsync(suggestion.modelId))
      )
    ).filter(Boolean) as CollectionObservable[]; // there should be no situation where we can't initialize a collection

    this.setDisplayedCollections(collections);
  };
}

const selectedOptionStyles = css({
  background: mdsColors().grey.x50,
});
