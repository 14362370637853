import { FC } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { useAppStore } from "@/store";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsIconKind } from "@/design-system/components/icon";
import { TopicChip } from "@/pages/explore/TopicChip";
import { isMemAccount } from "@/store/contacts/isMemAccount";
import { Navigate } from "react-router";

export const ExplorePage: FC = observer(() => {
  const { store } = useAppStore();
  const topics = store.spaceAccountTopics.all || [];

  if (!isMemAccount(store.account.myAccount)) return <Navigate to="/notes" />;
  return (
    <Wrapper>
      <MdsPanelHeader heading="Explore" icon={MdsIconKind.Compass} />
      <ScrollablePanelContainer>
        <TopicMosaic>
          {topics.map(topic => (
            <TopicChip key={topic.id} topic={topic} />
          ))}
        </TopicMosaic>
      </ScrollablePanelContainer>
    </Wrapper>
  );
});

const Wrapper = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const ScrollablePanelContainer = styled(MdsPanelContainer)({
  flex: 1,
  overflowY: "auto",
});

const TopicMosaic = styled.div({
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
  padding: "16px",
});

export default ExplorePage;
