import { isAvailable as isGlobalStoreAvailable } from "@/domains/local-db/global/configure";
import * as global from "@/domains/local-db/global/general";
import {
  addOnCloseStoreListener,
  addOnOpenStoreListener,
  initializeStore as initializeUserDb,
  isAvailable as isUserStoreAvailable,
  removeOnCloseStoreListener,
  removeOnOpenStoreListener,
} from "@/domains/local-db/account/configure";
import * as queue from "@/domains/local-db/account/queue";
import * as syncUpdates from "@/domains/local-db/account/syncUpdates";
import * as sync from "@/domains/local-db/account/sync";
import * as settings from "@/domains/local-db/account/settings";

const isAvailable = () => isGlobalStoreAvailable() && isUserStoreAvailable();

// Ask browser to allow larger limit for persisted data
if (typeof window !== "undefined") {
  window.navigator?.storage?.persist();
}

const localDb = {
  addOnCloseStoreListener,
  addOnOpenStoreListener,
  removeOnCloseStoreListener,
  removeOnOpenStoreListener,
  global,
  queue,
  initializeUserDb,
  isAvailable,
  syncUpdates,
  sync,
  settings,
} as const;

export default localDb;
