import { CollectionItemModelData } from "@/store/collection-items/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import { Uuid } from "@/domains/global/identifiers";
import { computed, makeObservable, observable } from "mobx";
import { WithAppStore } from "@/store/types";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { ContactObservable } from "@/store/contacts/ContactObservable";

export class CollectionItemObservable extends BaseSyncModel<CollectionItemModelData> {
  public modelKind = SyncModelKind.CollectionItem;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<CollectionItemModelData>;
  } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: observable,
      collectionId: computed,
      itemId: computed,
      itemKind: computed,
      collection: computed,
      item: computed,
      addedToCollectionBy: computed,
    });
  }

  // PROPERTIES
  get collectionId() {
    return this.modelData.collection_id;
  }

  get itemId() {
    return this.modelData.item_id;
  }

  get itemKind() {
    return this.modelData.item_kind;
  }

  get addedToCollectionBy(): ContactObservable | undefined {
    const myPersonalSpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const addedBySpaceAccountId = this.modelData.added_by_space_account_id;
    if (!addedBySpaceAccountId || addedBySpaceAccountId === myPersonalSpaceAccountId) return;
    const contactObservable = this.store.contacts.getBySpaceAccountId(addedBySpaceAccountId);
    return contactObservable;
  }

  get collection(): CollectionObservable | undefined {
    return this.store.collections.get(this.collectionId);
  }

  get item(): INoteObservable | undefined {
    return this.store.notes.get(this.itemId);
  }
}
