import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { AnimatePresence } from "framer-motion";
import { ChatMessage } from "@/domains/chat";
import memLogomarkImage from "@/assets/images/mem-logomark.svg";
import { Section } from "@/components/messages/item/section";
import { Loader } from "@/components/messages/item/loader";
import { Sending } from "@/components/messages/item/sending";
import { observer } from "mobx-react-lite";
import { ItemActions } from "@/components/messages/item/actions";
import { useAppStore } from "@/store";
import { Animator } from "@/components/messages/item/animator";
import { Error } from "@/components/messages/item/error";
import { CommonMentionKindForRoutableKind, OnClickMemItem } from "@mem-labs/common-editor";

interface Props {
  message: ChatMessage;
  inSidePanel?: boolean;
}

const SENDING_INDICATOR_DELAY = 2000;

export const Message = observer(function ({ message, inSidePanel }: Props) {
  const { store } = useAppStore();

  const [isActionsVisible, setActionsVisible] = useState(false);
  const [shouldAlwaysShowActions, setShouldAlwaysShowActions] = useState(false);

  const { unsentMessages, lastSystemMessageId } = store.chatMessages;
  const { id, isLastUserMessage, isSystemMessage, status, sections, chatConversationId } = message;
  const isProcessing = status === "PROCESSING";
  const isCompleted = status === "COMPLETED";
  const isFailed = status === "FAILED";
  const isRetried = status === "RETRIED";
  const hasContent = sections.length > 0;
  const showLoader = isSystemMessage && isProcessing;
  const showSending = useSendingIndicator(
    message,
    message.indexInUnsentMessages,
    unsentMessages.length
  );

  const dataTestId = "chat-message-item";
  const shouldAnimate = isProcessing;
  const isLastSystemMessage =
    isSystemMessage && (!lastSystemMessageId || id === lastSystemMessageId);
  const showError = isLastSystemMessage && isFailed;
  const isIncomplete = !isLastSystemMessage && isRetried && hasContent;

  const handleMouseEnter = useCallback(() => {
    if (shouldAlwaysShowActions) {
      return;
    }

    setActionsVisible(true);
  }, [shouldAlwaysShowActions]);

  const handleMouseLeave = useCallback(() => {
    if (shouldAlwaysShowActions) {
      return;
    }

    setActionsVisible(false);
  }, [shouldAlwaysShowActions]);

  const handleClickMemItem: OnClickMemItem = useCallback(
    (itemKind, itemId, target) => {
      const mentionKind = CommonMentionKindForRoutableKind(itemKind);
      if (mentionKind) {
        store.navigation.goToMention({
          id: itemId,
          mentionKind,
          url: "",
          highlightText: target.textContent ?? undefined,
        });
      }
    },
    [store.navigation]
  );

  useEffect(() => {
    setShouldAlwaysShowActions(isLastSystemMessage);
    setActionsVisible(isLastSystemMessage);
  }, [isLastSystemMessage]);

  if (!isLastSystemMessage && !hasContent && (isFailed || isRetried)) {
    return null;
  }

  return (
    <AnimatePresence>
      <Animator
        shouldAnimate={shouldAnimate}
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.3, delay: 0.2, ease: "easeOut" }}
        exit={{
          opacity: 0,
          translateY: 20,
          transition: { delay: 0.08, duration: 0.12, ease: "easeIn" },
        }}
      >
        <Wrapper
          isSystemMessage={isSystemMessage}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          data-test-id={`${dataTestId}-${message.id}`}
        >
          <Aligner isSystemMessage={isSystemMessage}>
            <Inner
              isSystemMessage={isSystemMessage}
              isLastUserMessage={isLastUserMessage}
              isLastSystemMessage={isLastSystemMessage}
            >
              {isSystemMessage && <Icon src={memLogomarkImage} alt="Mem" />}
              <SectionsAndActions>
                {message.sections.map((section, index) => {
                  const spaceAccountSection = message.spaceAccountChatMessageSectionMap[section.id];
                  return (
                    <Section
                      key={index}
                      section={section}
                      spaceAccountSection={spaceAccountSection}
                      isSystemMessage={isSystemMessage}
                      isIncomplete={isIncomplete}
                      inSidePanel={inSidePanel}
                      dataTestId={dataTestId}
                      onClickMemItem={handleClickMemItem}
                    />
                  );
                })}
                {isSystemMessage && isCompleted && (
                  <ItemActions
                    message={message}
                    isVisible={isActionsVisible}
                    inSidePanel={inSidePanel}
                  />
                )}
                {showLoader && (
                  <LoaderWrapper addSpace={hasContent}>
                    <Loader dataTestId={dataTestId} />
                  </LoaderWrapper>
                )}
                {showError && <Error chatConversationId={chatConversationId} />}
              </SectionsAndActions>
            </Inner>
            {!isSystemMessage && <Sending isVisible={showSending} />}
          </Aligner>
        </Wrapper>
      </Animator>
    </AnimatePresence>
  );
});

interface WrapperProps {
  isSystemMessage: boolean;
}

const Wrapper = styled.div<WrapperProps>(({ isSystemMessage }) => ({
  display: "flex",
  gap: "1rem",
  flexDirection: isSystemMessage ? "row" : "row-reverse",

  "p + p": {
    marginTop: "0.75em",
  },
}));

interface InnerProps {
  isLastUserMessage: boolean;
  isSystemMessage: boolean;
  isLastSystemMessage: boolean;
}

const Inner = styled.div<InnerProps>(
  ({ isLastUserMessage, isSystemMessage, isLastSystemMessage, theme }) => ({
    alignSelf: isSystemMessage ? "flex-start" : "flex-end",
    background: isSystemMessage ? "none" : theme.colors.grey.x100,
    color: theme.colors.grey.x600,
    borderRadius: isLastUserMessage
      ? `${theme.spacing.lg} ${theme.spacing.lg} 0 ${theme.spacing.lg}`
      : theme.spacing.lg,
    padding: `${theme.spacing.smd} ${theme.spacing.md} ${isSystemMessage ? "0px" : theme.spacing.smd}  ${theme.spacing.md}`,
    ...(isSystemMessage ? { borderTopLeftRadius: "auto" } : {}),
    display: isSystemMessage ? "flex" : "inline-block",
    flexDirection: "row",
    alignItems: "top",
    justifyContent: isSystemMessage ? "left" : "right",
    gap: "1rem",
    maxWidth: isSystemMessage ? "100%" : "80%",
    width: "fit-content",
    marginBottom: isLastSystemMessage ? theme.spacing.md : 0,
  })
);

interface AlignerProps {
  isSystemMessage: boolean;
}

const Aligner = styled.div<AlignerProps>(({ isSystemMessage }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  textAlign: isSystemMessage ? "left" : "right",
  maxWidth: "100%",
}));

const Icon = styled.img(({ src }) => ({
  width: 24,
  height: 24,
  src,
}));

const SectionsAndActions = styled.div(({ theme }) => ({
  display: "flex",
  flex: 1,
  minWidth: 0,
  flexDirection: "column",
  gap: theme.spacing.sm,

  ".message-actions": {
    // override the gap to hug the actions closer to the message
    marginTop: `calc(0px - ${theme.spacing.sm})`,
  },
}));

interface LoaderWrapper {
  addSpace: boolean;
}

const LoaderWrapper = styled.div<LoaderWrapper>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 48,
}));

const useSendingIndicator = (message: ChatMessage, index: number, totalUnsent: number) => {
  const [showSending, setShowSending] = useState(false);
  const [hasShownBefore, setHasShownBefore] = useState(false);

  useEffect(() => {
    if (index !== 0) {
      setShowSending(false);
      setHasShownBefore(true);
      return;
    }

    if (hasShownBefore) {
      setShowSending(true);
      return;
    }

    const timer = setTimeout(() => {
      setShowSending(true);
      setHasShownBefore(true);
    }, SENDING_INDICATOR_DELAY);

    return () => clearTimeout(timer);
  }, [index, totalUnsent, hasShownBefore]);

  return showSending;
};
