import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { INoteObservable } from "@/store/note/types";

export const deleteNotesPermanently = async ({
  notes,
  store,
}: {
  notes: Pick<INoteObservable, "id" | "delete">[];
  store: AppStore;
}) => {
  await Promise.all(notes.map(note => note.delete()));
  if (notes.length > 1) {
    store.navigation.goToNotes();
  }
  toastModule.triggerToast({
    content: <div>Note{notes.length > 1 ? "s" : ""} deleted permanently</div>,
  });
};
