import { FC } from "react";
import styled from "@emotion/styled";
import { MdsText } from "@/design-system/components/text";
import ColorHash from "color-hash";
import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";
import { useAppStore } from "@/store";

interface TopicChipProps {
  topic: SpaceAccountTopicObservable;
}

const bgColorHash = new ColorHash({ lightness: 0.92, saturation: 0.45 });
const textColorHash = new ColorHash({ lightness: 0.35, saturation: 0.45 });

export const TopicChip: FC<TopicChipProps> = ({ topic }) => {
  const { store } = useAppStore();
  const bgColor = bgColorHash.hex(topic.id);
  const textColor = textColorHash.hex(topic.id);

  return (
    <ChipWrapper
      style={{ backgroundColor: bgColor, color: textColor }}
      onClick={() => store.navigation.goToExploreTopicDetail({ topicId: topic.id })}
    >
      <MdsText>{topic.title}</MdsText>
    </ChipWrapper>
  );
};

const ChipWrapper = styled.div({
  padding: "8px 16px",
  borderRadius: "16px",
  cursor: "pointer",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
});
