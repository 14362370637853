import { moduleFactory } from "@/factories/module";
import { buildAppWindowUrl } from "@/modules/window/build-url";
import { _getCurrentWindowUrlSearchParams } from "@/modules/window/url-params";
import { getWindowGoogleIdentityOAuthRedirectUrl } from "@/modules/window/google-redirect";
import { forceHardReloadWindow, forceReloadWindow } from "@/modules/window/reload";
import {
  navigateToExternalWindowUrl,
  navigateToInternalWindowUrl,
  openWindowUrlInNewTab,
} from "@/modules/window/navigation";

export const windowModule = moduleFactory.create({
  currentUrlSearchParams: _getCurrentWindowUrlSearchParams,
  buildUrl: buildAppWindowUrl,
  forceReload: forceReloadWindow,
  forceHardReload: forceHardReloadWindow,
  navigateToInternal: navigateToInternalWindowUrl,
  navigateToExternal: navigateToExternalWindowUrl,
  openInNewTab: openWindowUrlInNewTab,
  getGoogleIdentityOAuthRedirectUrl: getWindowGoogleIdentityOAuthRedirectUrl,
});
