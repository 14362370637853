import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { action, computed, observable, makeObservable, reaction, runInAction } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListAsyncCollectionRowData,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { uuidModule } from "@/modules/uuid";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { actions } from "@/actions";
import { DeleteCollectionModalStore } from "@/components/modal/delete-collection/DeleteCollectionModalStore";
import { isEmpty } from "lodash-es";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { groupLens, LensGroup } from "@/modules/timeline";
import { CollectionItemSubtitle } from "@/components/collection-item-subtitle";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { collectionsLensModule, lensModule } from "@/modules/lenses";
import localDb from "@/domains/local-db";
import { Dexie, liveQuery, Subscription } from "dexie";
import { IndexedBoolean, Maybe } from "@/domains/common/types";
import { EventContext } from "@/domains/metrics/context";

export type CollectionListPageLensKind = LensKind.All | LensKind.AddedByMe | LensKind.SharedWithMe;
export type CollectionListPageSortByKind = SortByKind;
const DEFAULT_SORT_BY = SortByKind.LastModified;
const DEFAULT_LENS = LensKind.All;

export interface CollectionsListPageParams {
  sortBy: CollectionListPageSortByKind;
  lens: CollectionListPageLensKind;
}

export type IndexTuple = [
  is_available: IndexedBoolean,
  is_owned_by_me: IndexedBoolean,
  datetime: string,
  model_id: string,
];

export class CollectionsListPageStore extends AppSubStore {
  params: CollectionsListPageParams;

  deleteCollectionModal: DeleteCollectionModalStore;
  shareSheetModal: ShareSheetModalStore;

  currentCollectionId: string | undefined;
  collectionsFilterQuery: string = "";

  isLoading: boolean;
  isSubscriptionInitialized: boolean;
  liveQuerySubscription: Maybe<Subscription> = undefined;
  subscribedCollectionRows: IndexTuple[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.params = { sortBy: DEFAULT_SORT_BY, lens: DEFAULT_LENS };
    this.deleteCollectionModal = new DeleteCollectionModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });

    this.isLoading = true;
    this.isSubscriptionInitialized = false;

    makeObservable<this, "generateItemDropdown">(this, {
      isLoading: true,
      itemRows: true,
      groupedLensItems: true,
      isSubscriptionInitialized: true,
      subscribedCollectionRows: true,
      liveQuerySubscription: true,
      initializeLiveQuery: true,
      deleteCollectionModal: false,
      shareSheetModal: false,
      currentCollectionId: observable,
      collectionsFilterQuery: observable,

      collection: computed,
      setCurrentCollectionId: action,
      generateItemDropdown: false,
      shouldShowEmptyState: computed,
      getGroupCriteria: false,
      mapCollectionToRow: false,
      orderedItemIds: computed,

      // FILTERING
      setCollectionsFilterQuery: action,
      shouldApplyCollectionsFilterQuery: computed,
      normalizedCollectionsFilterQuery: computed,

      // LENSES + PARAMS
      params: observable,
      setParams: action,
      setLens: action,
      setSortBy: action,
      sortOptions: computed,
      lens: computed,
      sortBy: computed,
      sortLabel: computed,

      // ACTIONS
      handleCreateNewCollection: action,
      resetState: action,
      initialize: action,
    });

    reaction(
      () => this.params,
      params => {
        this.store.memDb?.settings.setCollectionsListPageParams(params);
      }
    );

    reaction(
      () => this.isSubscriptionInitialized,
      initialized => {
        if (initialized) setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
      }
    );
  }

  get collection(): CollectionObservable | undefined {
    if (!this.currentCollectionId) return;
    return this.store.collections.get(this.currentCollectionId);
  }

  setCurrentCollectionId(collectionId: string | undefined) {
    this.currentCollectionId = collectionId;
  }

  private generateItemDropdown({
    collectionObservable,
  }: {
    collectionObservable: CollectionObservable;
  }): MdsItemDropdown {
    const ownerActions: MdsDropdownItem[] = collectionObservable.isOwnedByMe
      ? [
          {
            id: `delete-${collectionObservable.id}`,
            kind: MdsDropdownItemKind.Button,
            iconKind: MdsIconKind.Trash,
            label: "Delete",
            onClick: () => {
              this.deleteCollectionModal.open({ collectionId: collectionObservable.id });
            },
          },
        ]
      : [];
    return {
      items: [
        {
          id: `copy-link-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => {
            actions.copyCollectionLinkToClipboard({ collectionId: collectionObservable.id });
          },
          iconKind: MdsIconKind.Copy,
        },
        {
          id: `share-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: collectionObservable.id,
              entityKind: ShareSheetEntityKind.Collection,
              eventContext: EventContext.CollectionsRowActions,
            }),
        },
        ...ownerActions,
      ],
    };
  }

  get shouldShowEmptyState() {
    return isEmpty(this.itemRows);
  }

  getGroupCriteria(collection: CollectionObservable): string {
    switch (this.sortBy) {
      case SortByKind.LastModified:
        return collection.modifiedAt;
      case SortByKind.LastCreated:
        return collection.createdAt;
      case SortByKind.LastViewed:
        return collection.spaceAccountCollection?.lastViewedAt || collection.createdAt;
      case SortByKind.Alphabetical:
        return "";
    }
  }

  get groupedLensItems(): LensGroup<IndexTuple>[] {
    return groupLens(
      this.subscribedCollectionRows.map(tuple => ({
        dateTime: new Date(tuple[2]),
        item: tuple,
      }))
    );
  }

  get itemRows(): MdsItemListRowData[] {
    if (this.sortBy === SortByKind.Alphabetical) {
      return this.subscribedCollectionRows.map(tuple => this.mapCollectionToRow(tuple[3]));
    } else {
      const output: MdsItemListRowData[] = [];
      for (const group of this.groupedLensItems) {
        output.push({
          type: MdsItemListRowType.SectionHeader,
          key: group.title,
          payload: { title: group.title },
        });
        for (const item of group.items) {
          output.push(this.mapCollectionToRow(item.item[3]));
        }
      }
      output.push({
        type: MdsItemListRowType.Padding,
        key: "padding",
        payload: { height: 50 },
      });
      return output;
    }
  }

  mapCollectionToRow(collectionId: string): MdsItemListAsyncCollectionRowData {
    return {
      type: MdsItemListRowType.AsyncCollection,
      key: collectionId,
      size: MdsItemListSize.XLarge,
      payload: {
        collectionId,
        itemRow: (collection: CollectionObservable) => ({
          type: MdsItemListRowType.Item,
          key: collection.id,
          size: MdsItemListSize.XLarge,
          payload: {
            id: collection.id,
            kind: MdsItemKind.Collection,
            titleIcon: collection.sharedAt ? { kind: MdsIconKind.Shared } : undefined,
            createPreviewState: () =>
              new ItemPreviewState({
                store: this.store,
                id: collection.id,
                kind: MdsItemKind.Collection,
              }),
            label: collection.label,
            onClick: () => this.store.navigation.goToCollection({ collectionId: collection.id }),
            sharedBy: collection.sharedBy,
            dateLabel: collectionsLensModule.dateLabelMap[this.sortBy](collection),
            dropdown: this.generateItemDropdown({ collectionObservable: collection }),
            action: undefined,
            extraRows: [
              {
                id: "collection-description",
                content: () => {
                  return (
                    <CollectionItemSubtitle
                      sizeData={collection.itemList.sizeData}
                      description={collection.description}
                    />
                  );
                },
              },
            ],
          },
        }),
      },
    };
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  get shouldApplyCollectionsFilterQuery() {
    return !isEmpty(this.normalizedCollectionsFilterQuery);
  }

  get normalizedCollectionsFilterQuery() {
    return this.collectionsFilterQuery.trim().toLowerCase();
  }

  setCollectionsFilterQuery({ query }: { query: string }) {
    this.collectionsFilterQuery = query;
  }

  handleCreateNewCollection = async () => {
    const collectionId = uuidModule.generate();
    await this.store.collections.createCollection({
      collectionId,
      title: "",
      description: "",
      eventContext: EventContext.CollectionsActions,
    });
    this.store.navigation.goToCollection({ collectionId });
  };

  resetState = () => {
    this.collectionsFilterQuery = "";
  };

  // PARAMS + LENSES
  setParams = (params: CollectionsListPageParams) => {
    this.params = params;
  };

  setLens = (lens: CollectionListPageLensKind) => {
    this.setParams({ ...this.params, lens });
  };

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.setParams({ ...this.params, sortBy: itemId as SortByKind });
  };

  get lens() {
    return this.params.lens;
  }

  get sortBy() {
    return this.params.sortBy;
  }

  get sortLabel(): string {
    return lensModule.sortKindLabelMap[this.sortBy];
  }

  get sortOptions(): MdsDropdownContentList {
    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: SortByKind.LastModified,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastModified],
          isChecked: this.sortBy === SortByKind.LastModified,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastCreated,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastCreated],
          isChecked: this.sortBy === SortByKind.LastCreated,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastViewed,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.LastViewed],
          isChecked: this.sortBy === SortByKind.LastViewed,
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.Alphabetical,
          kind: MdsDropdownItemKind.Button,
          label: lensModule.sortKindLabelMap[SortByKind.Alphabetical],
          isChecked: this.sortBy === SortByKind.Alphabetical,
          onClick: this.setSortBy,
        },
      ],
    };
  }

  initialize = async () => {
    const [localDBParams, memDbParams] = await Promise.all([
      localDb.settings.getCollectionsListPageParams(),
      this.store.memDb?.settings.getCollectionsListPageParams(),
    ]);
    if (localDBParams?.sortBy && this.store.memDb) {
      await this.store.memDb.settings.setCollectionsListPageParams(localDBParams);
      await localDb.settings.removeCollectionsListPageParams();
    }
    this.setParams({
      sortBy: localDBParams?.sortBy || memDbParams?.sortBy || DEFAULT_SORT_BY,
      lens: localDBParams?.lens || memDbParams?.lens || DEFAULT_LENS,
    });
    this.initializeLiveQuery();
  };

  initializeLiveQuery() {
    reaction(
      () => ({ params: this.params, filterQuery: this.normalizedCollectionsFilterQuery }),
      ({ params, filterQuery }) => {
        const { sortBy, lens } = params;
        this.liveQuerySubscription?.unsubscribe();
        this.liveQuerySubscription = liveQuery(() => {
          const filteredSortMap: Record<CollectionListPageSortByKind, string> = {
            [SortByKind.LastCreated]: `[is_available+is_owned_by_me+created_at+model_id]`,
            [SortByKind.LastModified]: `[is_available+is_owned_by_me+modified_at+model_id]`,
            [SortByKind.LastViewed]: `[is_available+is_owned_by_me+last_viewed_at+model_id]`,
            [SortByKind.Alphabetical]: `[is_available+is_owned_by_me+lowercase_title+model_id]`,
          };
          const betweenClause = {
            [LensKind.All]: [
              [1, Dexie.minKey, Dexie.minKey, Dexie.minKey],
              [1, Dexie.maxKey, Dexie.maxKey, Dexie.maxKey],
            ],
            [LensKind.AddedByMe]: [
              [1, 1, Dexie.minKey, Dexie.minKey],
              [1, 1, Dexie.maxKey, Dexie.maxKey],
            ],
            [LensKind.SharedWithMe]: [
              [1, 0, Dexie.minKey, Dexie.minKey],
              [1, 0, Dexie.maxKey, Dexie.maxKey],
            ],
          };
          let query = this.store.collections.localTable
            .where(filteredSortMap[sortBy])
            .between(betweenClause[lens][0], betweenClause[lens][1])
            .filter(collection => collection.lowercase_title.includes(filterQuery));
          if (sortBy !== SortByKind.Alphabetical) query = query.reverse();
          return query.keys();
        }).subscribe({
          next: rows => {
            runInAction(() => {
              this.subscribedCollectionRows = rows as unknown as IndexTuple[];
              this.isSubscriptionInitialized = true;
            });
          },
        });
      },
      { fireImmediately: true }
    );
  }
}
