import { MdsChipInput } from "@/design-system/components/input";
import { MdsChipInputProps } from "@/design-system/components/input/MdsChipInput";
import { noop } from "lodash-es";
import { FC } from "react";

export type MdsInputProps = Omit<MdsChipInputProps, "getChips" | "chips" | "setChips" | "onSubmit">;

export const MdsInput: FC<MdsInputProps> = props => {
  return (
    <MdsChipInput getChips={async () => []} chips={[]} setChips={noop} onSubmit={noop} {...props} />
  );
};
