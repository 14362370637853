import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";
import { SpaceAccountTopicModelData } from "@/store/topics/types";
import { makeObservable, override } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

export class AppStoreSpaceAccountTopicsStore extends BaseSyncModelStore<
  SpaceAccountTopicObservable,
  SpaceAccountTopicModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountTopic, ...injectedDeps });
    makeObservable<this>(this, {
      computeIndexes: override,
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<SpaceAccountTopicModelData>): SpaceAccountTopicObservable {
    return new SpaceAccountTopicObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
