import { IndexedBoolean, Maybe } from "@/domains/common/types";
import { AppStore } from "@/store/AppStore";
import { NoteModelData } from "@/store/note/types";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";

export type NoteIndexData = {
  primary_label: string;
  modified_at: string;
  created_at: string;
  last_viewed_at: string;
  last_interacted_at: string;
  is_owned_by_me: IndexedBoolean;
  trashed_at: string;
  is_trashed: IndexedBoolean;
  is_available: IndexedBoolean;
};

export class NoteIndexes {
  private store: AppStore;
  private data: SyncUpdateValue<NoteModelData>;
  private spaceAccountNote: SyncUpdateValue<SpaceAccountNoteModelData> | undefined;
  private optimisticUpdates: OptimisticSyncUpdate<NoteModelData>[];

  constructor({
    store,
    remoteData,
    optimisticUpdates,
    spaceAccountNote,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<NoteModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<NoteModelData>[];
    spaceAccountNote: Maybe<SyncUpdateValue<SpaceAccountNoteModelData>>;
  }) {
    this.store = store;
    this.optimisticUpdates = optimisticUpdates;
    this.spaceAccountNote = spaceAccountNote;
    this.data = (optimisticUpdates.at(-1)?.value as SyncUpdateValue<NoteModelData>) || remoteData;
  }

  get primary_label() {
    return this.data.model_data.primary_label;
  }

  get modified_at() {
    return this.optimisticUpdates.reduce((latest, update) => {
      const actionDate = update.locally_committed_at;
      return actionDate > latest ? actionDate : latest;
    }, this.data.model_data.locally_modified_at);
  }

  get created_at() {
    return this.data.model_data.created_at;
  }

  get last_viewed_at() {
    return this.spaceAccountNote?.model_data.last_viewed_at || this.created_at;
  }

  get last_interacted_at() {
    return this.last_viewed_at;
  }

  get trashed_at() {
    return this.data.model_data.trashed_at || "";
  }

  get is_trashed() {
    return !!this.trashed_at;
  }

  get is_available() {
    // DEXIE REFACTOR TODO: Should we have an can_access as well?
    return !this.is_trashed;
  }

  get is_owned_by_me() {
    const myPersonalSpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const owner_scope = this.data.model_scopes.find(scope => scope.role_kind === "OWNER");
    if (!owner_scope) return false;
    if ("space_account_id" in owner_scope.value)
      return owner_scope.value.space_account_id === myPersonalSpaceAccountId;
    return false;
  }

  get indexes(): NoteIndexData {
    return {
      primary_label: this.primary_label,
      modified_at: this.modified_at,
      created_at: this.created_at,
      last_viewed_at: this.last_viewed_at,
      last_interacted_at: this.last_interacted_at,
      trashed_at: this.trashed_at,
      is_owned_by_me: this.is_owned_by_me ? 1 : 0,
      is_trashed: this.is_trashed ? 1 : 0,
      is_available: this.is_available ? 1 : 0,
    };
  }
}
