import { Uuid } from "@/domains/global/identifiers";
import { NoteObservable } from "@/store/note/NoteObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";
import { SpaceAccountTopicItemKind, SpaceAccountTopicItemModelData } from "@/store/topics/types";
import { WithAppStore } from "@/store/types";
import { makeObservable, computed, observable } from "mobx";

export class SpaceAccountTopicItemObservable extends BaseSyncModel<SpaceAccountTopicItemModelData> {
  public modelKind = SyncModelKind.SpaceAccountTopicItem;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountTopicItemModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: observable,
      itemId: computed,
      item: computed,
      itemKind: computed,
      spaceAccountTopicId: computed,
      spaceAccountTopic: computed,
      itemRelevanceScore: computed,
      topicRelevanceScore: computed,
    });
  }

  // PROPERTIES
  get itemId(): Uuid {
    return this.modelData.item_id;
  }

  get item(): NoteObservable | undefined {
    if (this.itemKind === "NOTE") {
      return this.store.notes.get(this.itemId);
    }
  }

  get spaceAccountTopicId(): Uuid {
    return this.modelData.space_account_topic_id;
  }

  get spaceAccountTopic(): SpaceAccountTopicObservable | undefined {
    return this.store.spaceAccountTopics.get(this.spaceAccountTopicId);
  }

  get itemKind(): SpaceAccountTopicItemKind {
    return this.modelData.item_kind;
  }

  get itemRelevanceScore(): number {
    return this.modelData.item_relevance_score;
  }

  get topicRelevanceScore(): number {
    return this.modelData.topic_relevance_score;
  }
}
