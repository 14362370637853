import { ObservableMap, makeObservable, observable, computed, action } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { GuestAppSubStoreArgs } from "@/store/types";
import { ContactModelData } from "@/store/contacts/types";
import { resolveContactSyncModelUuid } from "@/modules/uuid/sync-models/resolveContactModelUuid";
import { SyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { GuestAppStore } from "@/store";
import { GuestContactObservable } from "@/store/guest-contacts/GuestContactObservable";

export class AppStoreGuestContactsStore {
  store: GuestAppStore;

  private remotePool = new ObservableMap<Uuid, GuestContactObservable>();

  constructor({ store }: GuestAppSubStoreArgs) {
    this.store = store;
    makeObservable<this, "remotePool">(this, {
      store: false,
      remotePool: observable,
      processSyncUpdate: action,
      createSyncModel: false,
      getContactObservableById: false,
      getBySpaceAccountId: false,
      getMatchingContacts: false,
      allContacts: computed,
      directContacts: computed,
      indirectContacts: computed,
    });
  }

  // TODO: Consider migrating to dexie too under "guest" account
  async processSyncUpdate(update: SyncUpdate<ContactModelData>) {
    if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED") {
      const updateValue = update.value as SyncUpdateValue<ContactModelData>;
      if (this.remotePool.has(updateValue.model_id)) {
        const observable = this.remotePool.get(updateValue.model_id)!;
        observable.updateFromRemote({ data: updateValue });
      } else {
        const syncModel = this.createSyncModel(updateValue);
        this.remotePool.set(updateValue.model_id, syncModel);
      }
    }
    if (update.kind === "DELETED" || update.kind === "ACL_REVOKED") {
      const observable = this.remotePool.get(update.value.model_id);
      if (observable) observable.deleteFromRemote();
      this.remotePool.delete(update.value.model_id);
    }
  }

  createSyncModel(data: SyncUpdateValue<ContactModelData>) {
    return new GuestContactObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  getContactObservableById({
    contactId,
  }: {
    contactId?: Uuid;
  }): GuestContactObservable | undefined {
    if (!contactId) return undefined;
    return this.remotePool.get(contactId);
  }

  getBySpaceAccountId(contactSpaceAccountId: Uuid): GuestContactObservable | undefined {
    const id = resolveContactSyncModelUuid({
      spaceAccountId: contactSpaceAccountId,
    });
    return this.remotePool.get(id);
  }

  getMatchingContacts = (query: string): GuestContactObservable[] => {
    const needle = query.trim().toLowerCase();
    if (!needle) return [];

    const contacts: GuestContactObservable[] = [];
    for (const contact of this.directContacts) {
      const haystack = `${contact.profileDisplayName.toLowerCase()} ${contact.profileEmailAddress.toLowerCase()}`;
      if (haystack.includes(needle)) contacts.push(contact);
    }
    return contacts;
  };

  get allContacts() {
    return Array.from(this.remotePool.values());
  }

  get directContacts() {
    return this.allContacts.filter(contact => contact.isDirect);
  }

  get indirectContacts() {
    return this.allContacts.filter(contact => !contact.isDirect);
  }
}
