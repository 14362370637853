import { isString } from "lodash-es";
import { type To } from "react-router";

export const arePathsEqual = (a: To | Location | undefined, b: To | Location | undefined) => {
  const aPathName = isString(a) ? a : a?.pathname;
  const bPathName = isString(b) ? b : b?.pathname;

  if (!aPathName || !bPathName) {
    return false;
  }

  return aPathName === bPathName;
};
