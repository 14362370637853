import { appRoutes } from "@/app/router";
import { Uuid } from "@/domains/global/identifiers";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { FavoriteItemModelData } from "@/store/favorite-items/types";
import { INoteObservable } from "@/store/note";
import { SavedSearchObservable } from "@/store/saved-searches/SavedSearchObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable, observable } from "mobx";

export class FavoriteItemObservable extends BaseSyncModel<FavoriteItemModelData> {
  public modelKind = SyncModelKind.FavoriteItem;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<FavoriteItemModelData> } & WithAppStore) {
    super({ id, data, store });

    makeObservable(this, {
      // PROPERTIES
      modelKind: observable,
      itemId: computed,
      itemKind: computed,
      sortKey: computed,
      path: computed,
      item: computed,
      isItemTrashedOrDeleted: computed,
    });
  }

  // PROPERTIES
  get itemId() {
    return this.modelData.item_id;
  }

  get itemKind() {
    return this.modelData.item_kind;
  }

  get sortKey() {
    return this.modelData.sort_key;
  }

  get path() {
    switch (this.itemKind) {
      case "COLLECTION": {
        return appRoutes.collectionsView({ params: { collectionId: this.itemId } }).path;
      }
      case "NOTE": {
        return appRoutes.notesView({ params: { noteId: this.itemId } }).path;
      }
      case "SAVED_SEARCH": {
        const savedSearchObservable = this.store.savedSearches.getSavedSearchObservableById({
          savedSearchId: this.itemId,
        });
        if (!savedSearchObservable) return appRoutes.search({}).path;
        return `${appRoutes.search({}).path}?${savedSearchObservable.savedSearchQueryString}`;
      }
    }
  }

  get item(): CollectionObservable | INoteObservable | SavedSearchObservable | undefined {
    switch (this.itemKind) {
      case "COLLECTION": {
        return this.store.collections.get(this.itemId);
      }
      case "NOTE": {
        return this.store.notes.get(this.itemId);
      }
      case "SAVED_SEARCH": {
        return this.store.savedSearches.get(this.itemId);
      }
    }
  }

  get isItemTrashedOrDeleted() {
    return (
      this.isDeleted ||
      !this.item ||
      this.item.isTrashed ||
      this.item.isDeleted ||
      !this.item.canAccess
    );
  }
}
