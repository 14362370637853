import { FC } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { useAppStore } from "@/store";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsItemList } from "@/design-system/components/item-list";
import { Navigate, useParams } from "react-router-dom";
import { getRowForNoteId } from "@/store/note/getRowForNoteId";
import { isMemAccount } from "@/store/contacts/isMemAccount";

export const TopicDetailPage: FC = observer(() => {
  const { store } = useAppStore();
  const { topicId } = useParams<{ topicId: string }>();
  const topic = topicId ? store.spaceAccountTopics.get(topicId) : null;

  if (!topic) {
    return <div>Topic not found</div>;
  }

  const items = topic.allTopicItems
    .map(topicItem => {
      const note = topicItem.item;
      if (!note) return null;
      const row = getRowForNoteId({
        dateTime: note?.lastViewedAt,
        inMainPanel: true,
        noteId: note?.id,
        store: store,
      });
      if (!row) return null;
      return row;
    })
    .filter(row => !!row);

  const card = {
    id: topic.id,
    title: topic.title,
    items: items,
  };

  if (!isMemAccount(store.account.myAccount)) return <Navigate to="/notes" />;
  return (
    <Wrapper>
      <MdsPanelHeader
        heading={topic.title}
        icon={null}
        onBackClick={() => store.navigation.goToExplore()}
      />
      <ScrollablePanelContainer>
        <MdsItemList rows={card.items} />
      </ScrollablePanelContainer>
    </Wrapper>
  );
});

const Wrapper = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const ScrollablePanelContainer = styled(MdsPanelContainer)({
  flex: 1,
  overflowY: "auto",
});

export default TopicDetailPage;
