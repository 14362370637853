import { Maybe, Optional } from "@/domains/common/types";
import { formatDateString, generateShortDateString, isToday } from "@/domains/date/date";
import {
  ChatHistoryIndexes,
  ChatItem,
  ChatItemKind,
  ChatItemSectionHeader,
} from "@/store/chat/types";
import { DateTime } from "luxon";

// - Handling Zero State + Chat History
//   - Heuristic for deciding what goes behind the “See History” button? [link]
//       - Metadata divider
//         - When there is a time gap (e.g. 1hr for now)
//         - When there is a “context switch” (send a message in a new thread)
export const getChatItems = (messagesIndexes: ChatHistoryIndexes[]): ChatItem[] => {
  const items: ChatItem[] = [];

  // Includes extra fields to detect when a section header should be created.
  interface ComparableChatItemSectionHeader extends ChatItemSectionHeader {
    // Broad separator for the chat history, with context and a coarse timestamp.
    groupId: string;
    // Within the same day a new section is created every hour.
    dateTime: DateTime;
    locallyCreatedAt: string;
  }
  let previousHeader: Maybe<ComparableChatItemSectionHeader> = undefined;

  for (const message of messagesIndexes) {
    const { context, locallyCreatedAt, isSystemMessage } = message;
    const sectionRelativeTime = (() => {
      const timeString = formatDateString(locallyCreatedAt, "hh:mm a");
      if (isToday(locallyCreatedAt)) return timeString;

      return `${generateShortDateString(locallyCreatedAt)} ${timeString}`;
    })();
    // Using (.) separators for readability.
    const coarseTime = formatDateString(locallyCreatedAt, "yyyy.MM.dd");
    const wouldBeHeader: ComparableChatItemSectionHeader = {
      kind: ChatItemKind.SectionHeader,
      groupId: `${coarseTime}-${context.kind}-${context.id}`,
      dateTime: DateTime.fromISO(locallyCreatedAt),
      locallyCreatedAt,
      id: `section-${sectionRelativeTime}-${context.kind}-${context.id}`,
      header: {
        title: sectionRelativeTime,
        locallyCreatedAt,
        context,
      },
    };
    // Only create a section header for user messages or if a long time has passed.
    const includeHeader = (() => {
      if (!previousHeader) return true;

      if (isSystemMessage) {
        // New section for a different day.
        return wouldBeHeader.dateTime.diff(previousHeader.dateTime, "days").days >= 1;
      }

      return (
        // New section for a different context.
        previousHeader.groupId !== wouldBeHeader.groupId ||
        // New section for a different hour.
        wouldBeHeader.dateTime.diff(previousHeader.dateTime, "hours").hours >= 1
      );
    })();
    if (includeHeader) {
      const copyHeader: Optional<ComparableChatItemSectionHeader, "dateTime" | "groupId"> = {
        ...wouldBeHeader,
      };
      delete copyHeader.dateTime;
      delete copyHeader.groupId;
      items.push(copyHeader);
      previousHeader = wouldBeHeader;
    }
    items.push({
      kind: ChatItemKind.Message,
      id: message.id,
    });
  }
  return items;
};
