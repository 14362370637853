import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { OptimisticSyncUpdate, SyncCustomErrorData, SyncOperationKind } from "@/store/sync/types";
import { IProvideChatMessageFeedbackOperation } from "@/store/sync/operations/types";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import {
  SpaceAccountChatMessageModelData,
  SpaceAccountChatMessageUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { resolveSpaceAccountChatMessageSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountChatMessageSyncModelUuid";
import { generateDefaultOwnerScopes } from "../helpers/common";
import { uuidModule } from "@/modules/uuid";
import { logger } from "@/modules/logger";
import { ChatMessageObservable } from "@/store/chat/ChatMessageObservable";
import { SpaceAccountChatMessageObservable } from "@/store/chat/SpaceAccountChatMessageObservable";

export class ProvideChatMessageFeedbackOperation extends BaseSyncOperation<IProvideChatMessageFeedbackOperation> {
  private chatMessage?: ChatMessageObservable;
  private spaceAccountChatMessage?: SpaceAccountChatMessageObservable;

  get operationKind(): SyncOperationKind {
    return "PROVIDE_CHAT_MESSAGE_FEEDBACK";
  }

  private get spaceAccountChatMessageId(): string {
    return resolveSpaceAccountChatMessageSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      chatMessageId: this.payload.chat_message_id,
    });
  }

  async execute() {
    this.chatMessage = await this.store.chatMessages.getAsync(this.payload.chat_message_id);
    this.spaceAccountChatMessage = await this.store.spaceAccountChatMessages.getAsync(
      this.spaceAccountChatMessageId
    );

    await super.execute();
  }

  generateOptimisticUpdates(): OptimisticSyncUpdate<SpaceAccountChatMessageModelData>[] {
    const optimisticUpdates: OptimisticSyncUpdate<SpaceAccountChatMessageModelData>[] = [];

    const chatMessageUpsertedSyncUpdate = this.generateProvideChatMessageFeedbackSyncUpdate();
    if (chatMessageUpsertedSyncUpdate) {
      optimisticUpdates.push(chatMessageUpsertedSyncUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.chatMessages.recompute(this.payload.chat_message_id);
    await this.store.spaceAccountChatMessages.recompute(this.spaceAccountChatMessageId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send feedback. Please try again.");
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("You don't have permission to provide feedback on that message.");
  }

  handleTransientError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "Failed to send feedback. Please try again.",
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send feedback. Please try again.");
  }

  protected generateProvideChatMessageFeedbackSyncUpdate() {
    const spaceAccountChatMessage = this.spaceAccountChatMessage;
    const chatMessage = this.chatMessage;
    if (!chatMessage) {
      logger.debug({
        message: "Attempted to provide feedback on a chat message that doesn't exist",
        info: { chat_message_id: this.payload.chat_message_id },
      });

      return;
    }

    const spaceAccountChatMessageValue: SpaceAccountChatMessageUpsertedSyncUpdateValue = {
      model_id: this.spaceAccountChatMessageId,
      model_kind: "SPACE_ACCOUNT_CHAT_MESSAGE",
      model_version: spaceAccountChatMessage?.modelVersion ?? 0,
      model_data: {
        space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        saved_as_note_at: spaceAccountChatMessage?.modelData.saved_as_note_at ?? null,
        saved_as_note_id: spaceAccountChatMessage?.modelData.saved_as_note_id ?? null,
        sections: spaceAccountChatMessage?.modelData.sections ?? [],

        chat_message_id: this.payload.chat_message_id,
        feedback_responded_at: new Date().toISOString(),
        feedback_response: this.payload.feedback_response_kind || null,
        feedback_user_text: this.payload.feedback_user_text ?? null,
        feedback_selected_suggestions: this.payload.feedback_selected_suggestions ?? [],
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const spaceAccountChatMessageUpsertedSyncUpdate: OptimisticSyncUpdate<SpaceAccountChatMessageModelData> =
      {
        optimistic_update_id: uuidModule.generate(),
        locally_committed_at: this.committedAt,
        kind: "UPSERTED",
        value: spaceAccountChatMessageValue,
      };

    return spaceAccountChatMessageUpsertedSyncUpdate;
  }
}
