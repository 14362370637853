import { FC, useMemo, memo } from "react";

import {
  MdsIconButtonShape,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button/types";
import { MdsIcon } from "@/design-system/components/icon";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { ClassNamesArg, css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { OnClick } from "@/design-system/constants/handlers/types";
import {
  mdsFontSizes,
  mdsFontWeights,
  mdsBorderRadius,
  mdsColors,
} from "@/design-system/foundations";
import { MdsTooltip, MdsTooltipConfig } from "@/design-system/components/tooltip";

export interface MdsIconButtonProps extends EmotionClassStyles {
  variant?: MdsIconButtonVariant;
  shape?: MdsIconButtonShape;
  size?: MdsIconButtonSize;
  onClick?: OnClick;
  isLoading?: boolean;
  isDisabled?: boolean;
  iconKind: MdsIconKind;
  // Overrides dropdown for MdsButtonVariant.OutlineAccent, MdsButtonVariant.Outline, MdsButtonVariant.Transparent.
  selected?: boolean;
  tooltipConfig?: MdsTooltipConfig;
  iconStyles?: ClassNamesArg;
}

const buttonStyles = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
  borderRadius: mdsBorderRadius().mediumLarge,
  fontWeight: mdsFontWeights().semiBold,
  fontSize: mdsFontSizes().small,
  cursor: "pointer",
  transition: "0.25s ease",
  "&:disabled": {
    cursor: "none",
    pointerEvents: "none",
    backgroundColor: mdsColors().grey.x300,
  },
  "& > div > div": {
    transition: "0.25s ease",
  },
  WebkitAppRegion: "no-drag",
});

const buttonShapeStyleMapping: { [key in MdsIconButtonShape]: string } = {
  [MdsIconButtonShape.Round]: css({
    borderRadius: 100,
  }),
  [MdsIconButtonShape.Square]: css({
    borderRadius: 4,
  }),
};

const buttonVariantStyleMapping: { [key in MdsIconButtonVariant]: string } = {
  [MdsIconButtonVariant.Brand]: css({
    backgroundColor: mdsColors().primary.x500,
    color: mdsColors().grey.x25,
    "&:hover": {
      backgroundColor: mdsColors().primary.x600,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().primary.x800,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x25,
    },
  }),

  [MdsIconButtonVariant.Dark]: css({
    alignItems: "center",
    background: `var(--color-grey-600, #3F424D)`,
    border: `1px solid transparent`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-grey-25, #F8F8FA)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-grey-25, #F8F8FA)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: `var(--color-grey-600, #3F424D)`,
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-25, #F8F8FA)`,
    },
    "&:hover": {
      background: `var(--color-grey-500, #696E7D)`,
    },
  }),

  [MdsIconButtonVariant.Outlined]: css({
    alignItems: "center",
    border: `1px solid var(--color-grey-100, #E8E9ED)`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: "unset",
      border: `1px solid var(--color-grey-100, #E8E9ED)`,
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
    },
    "&:hover": {
      background: `var(--color-grey-100, #E8E9ED)`,
      border: `1px solid var(--color-grey-100, #E8E9ED)`,
    },
    "&.mds-btn-toggled": {
      background: `var(--color-grey-50, #F3F3F5)`,
      border: `1px solid var(--color-grey-50, #F3F3F5)`,
    },
    "&.mds-btn-toggled-dark": {
      background: `var(--color-grey-600, #3F424D)`,
      border: `1px solid var(--color-grey-600, #3F424D)`,
    },
    "&.mds-btn-toggled-dark .mds-btn-icon, &.mds-btn-toggled-dark .mds-btn-label": {
      color: `var(--color-grey-25, #F8F8FA)`,
    },
  }),

  [MdsIconButtonVariant.Transparent]: css({
    alignItems: "center",
    border: `0px solid transparent`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: "unset",
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
    },
    "&:hover": {
      background: `var(--color-grey-100, #E8E9ED)`,
    },
    "&.mds-btn-toggled": {
      background: `var(--color-grey-50, #F3F3F5)`,
    },
  }),

  [MdsIconButtonVariant.FullyTransparent]: css({
    alignItems: "center",
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: "unset",
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
    },
    "&.mds-btn-toggled": {
      background: `unset`,
    },
  }),
};

const buttonSizeStyleMapping: { [key in MdsIconButtonSize]: string } = {
  [MdsIconButtonSize.Small]: css({
    padding: 8,
    maxHeight: 32,
    maxWidth: 32,
    "& .mds-btn-icon": {
      fontSize: 16,
    },
  }),

  [MdsIconButtonSize.Medium]: css({
    padding: 8,
    "& .mds-btn-icon": {
      fontSize: 20,
    },
  }),

  [MdsIconButtonSize.Large]: css({
    padding: 8,
    "& .mds-btn-icon": {
      fontSize: 24,
    },
  }),

  [MdsIconButtonSize.XLarge]: css({
    padding: "8px",
    "& .mds-btn-icon": {
      fontSize: 32,
    },
  }),

  [MdsIconButtonSize.XSmall]: css({
    padding: "4px",
    maxHeight: 24,
    maxWidth: 24,
    "& .mds-btn-icon": {
      fontSize: 16,
    },
  }),

  [MdsIconButtonSize.XXSmall]: css({
    padding: "2px",
    maxHeight: 20,
    maxWidth: 20,
    "& .mds-btn-icon": {
      fontSize: 12,
    },
  }),
};

export const MdsIconButton: FC<MdsIconButtonProps> = memo(
  ({
    variant = MdsIconButtonVariant.Transparent,
    shape = MdsIconButtonShape.Square,
    size = MdsIconButtonSize.Medium,
    selected,
    isLoading,
    isDisabled,
    onClick,
    iconKind,
    className,
    tooltipConfig,
    iconStyles,
  }) => {
    const variantStyles = buttonVariantStyleMapping[variant];
    const shapeStyles = buttonShapeStyleMapping[shape];

    const sizeStyles = buttonSizeStyleMapping[size];

    const dynamicLoadingStyles = useMemo(() => {
      if (isLoading) {
        return css({
          pointerEvents: "none",
        });
      }

      return css({});
    }, [isLoading]);

    const iconButtonDisabledStyles = css({
      "&:disabled": {
        backgroundColor: iconKind ? "transparent" : mdsColors().grey.x50,
        color: mdsColors().grey.x400,
      },
    });

    const combinedStyles = cx(
      buttonStyles,
      variantStyles,
      shapeStyles,
      sizeStyles,
      dynamicLoadingStyles,
      iconButtonDisabledStyles,
      selected && "mds-btn-toggled",
      className
    );

    const combinedIconStyles = css(
      {
        color: isDisabled
          ? mdsColors().grey.x400
          : selected
            ? mdsColors().grey.x600
            : mdsColors().grey.x700,
      },
      iconStyles
    );

    const fullButtonContent = (() => (
      <button className={combinedStyles} onClick={onClick} disabled={isDisabled}>
        {isLoading && <MdsIcon kind={MdsIconKind.Loader} className="fa-pulse mds-btn-icon" />}
        {!isLoading && (
          <MdsIcon
            kind={iconKind}
            className="mds-btn-icon"
            innerStyles={{ Icon: { className: combinedIconStyles } }}
          />
        )}
      </button>
    ))();

    if (tooltipConfig) {
      return <MdsTooltip config={tooltipConfig}>{fullButtonContent}</MdsTooltip>;
    }

    return fullButtonContent;
  }
);
