import { observer } from "mobx-react-lite";
import { useAppStore, useGuestAppStore } from "@/store";
import { EntityNotFoundType, NotFoundPage } from "@/pages/not-found/NotFoundPage";
import { StandardModePadding } from "@/app/outlets/StandardModePadding";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { NoteEditor } from "@/pages/notes-view/NoteEditor";
import { AddToCollectionModal } from "@/components/modal/add-to-collection/AddToCollectionModal";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { useLocation } from "react-router";
import { FileUploadRejectedModal } from "@/components/modal/file-upload-rejected/FileUploadRejectedModal";
import { ImageUploadRejectedModal } from "@/components/modal/image-upload-rejected/ImageUploadRejectedModal";

export const StandardNotesViewPage = observer(function StandardNotesViewPage() {
  const { store, pageStore } = useAppStore();
  pageStore.notesViewPage.useEffects();

  return (
    <NotesViewPage
      chatHistory={store.chatMessages.chatHistory}
      store={pageStore.notesViewPage}
      isSidePanelOpen={store.interface.isChatSidebarOpen}
    />
  );
});

export const GuestNotesViewPage = observer(function GuestNotesViewPage() {
  const { guestStore } = useGuestAppStore();
  guestStore.notesViewPage.useNoteIdFromRoutePath();
  return <NotesViewPage store={guestStore.notesViewPage} />;
});

interface NotesViewPageProps {
  store: INotesViewPageStore;
  isSidePanelOpen?: boolean;
  chatHistory?: ChatHistory;
}

const NotesViewPage = observer<NotesViewPageProps>(({ store, isSidePanelOpen, chatHistory }) => {
  const { state: routerLocationState } = useLocation();

  if (store.showNotFound) {
    // Apply consistent padding for the NotFoundPage.
    return (
      <StandardModePadding>
        <NotFoundPage entityType={EntityNotFoundType.Note} />
      </StandardModePadding>
    );
  }

  return (
    <>
      {store.addToCollectionModal && <AddToCollectionModal store={store.addToCollectionModal} />}
      {store.fileUploadRejectedModal && (
        <FileUploadRejectedModal store={store.fileUploadRejectedModal} />
      )}
      {store.imageUploadRejectedModal && (
        <ImageUploadRejectedModal store={store.imageUploadRejectedModal} />
      )}
      <NoteEditor
        isSidePanelOpen={!!isSidePanelOpen}
        chatHistory={chatHistory}
        noteObservable={store.noteObservable}
        openFileUploadRejectedModal={store.openFileUploadRejectedModal}
        openImageUploadRejectedModal={store.openImageUploadRejectedModal}
        myAccount={store.myAccount}
        goToMention={store.goToMention}
        highlightText={routerLocationState?.highlightText}
        autoFocus={routerLocationState?.autoFocus}
      />
    </>
  );
});
