import { SyncCustomErrorData } from "@/store/sync/types";
import { BaseExternalOperation } from "@/store/sync/operations/BaseExternalOperation";
import {
  ExternalOperationKind,
  IUploadImageExternalOperation,
} from "@/store/sync/operations/types";
import { AppStore } from "@/store/AppStore";
import { AbstractSyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsSpacer } from "@/design-system/components/spacer";
import { fileModule } from "@/modules/file";
import { MemCommonEditorImageInfo } from "@mem-labs/common-editor";
import { logger } from "@/modules/logger";
import { toastModule } from "@/modules/toast";

export class UploadImageExternalOperation extends BaseExternalOperation<IUploadImageExternalOperation> {
  triggerRecompute(): Promise<void> {
    return Promise.resolve();
  }

  get operationKind(): ExternalOperationKind.UPLOAD_IMAGE {
    return ExternalOperationKind.UPLOAD_IMAGE;
  }

  async executeExternalOperation(): Promise<void> {
    const info: MemCommonEditorImageInfo = this.payload.info;

    const canBeUploaded = fileModule.checkIfImageCanBeUploaded({ info });

    if (!canBeUploaded) {
      /**
       * It can be dangerous to load everything into memory if the file is too large.
       * So, we log an error and exit early (instead of throwing, and retrying.)
       *
       * This fails silently - mem-common-editor should not trigger "file-added" events
       * if the file is too large, so this is purely defensive code to prevent app crashes.
       */
      logger.error({
        message: `[SYNC][UploadImageExternalOperation] Image cannot be uploaded. imageId=${info.imageId} imageSizeBytes=${info.imageSizeBytes}. Exiting early.`,
      });

      toastModule.triggerToast({ content: "Failed to upload image - too large." });

      return;
    }

    const fileBlob = fileModule.convertEncodedFileContentToBlob({
      encodedFileContent: info.encodedImageContent,
      fileMimeType: info.imageMimeType,
    });

    await fileModule.upload({
      info: {
        fileId: info.imageId,
        fileMimeType: info.imageMimeType,
        normalizedFileName: info.normalizedImageName,
        encodedFileContent: info.encodedImageContent,
        fileSizeBytes: info.imageSizeBytes,
      },
      blob: fileBlob,
    });
  }

  get syncErrorModalFieldsGenerator() {
    return this.getSyncErrorModalFieldsGenerator("UNKNOWN");
  }

  private getSyncErrorModalFieldsGenerator(kind: SyncCustomErrorData["kind"]) {
    return (store: AppStore) =>
      new UploadImageExternalOperationModalFieldsGenerator(store, this.id, kind, this);
  }
}

class UploadImageExternalOperationModalFieldsGenerator extends AbstractSyncErrorModalFieldsGenerator {
  protected id: string;
  protected kind: SyncCustomErrorData["kind"];
  protected UploadImageOperation: UploadImageExternalOperation;

  constructor(
    store: AppStore,
    id: string,
    kind: SyncCustomErrorData["kind"],
    UploadImageOperation: UploadImageExternalOperation
  ) {
    super(store);

    this.id = id;
    this.kind = kind;
    this.UploadImageOperation = UploadImageOperation;
  }

  get title() {
    return `Unable to upload image`;
  }

  get message() {
    return `If this error continues, please contact support`;
  }

  downloadFile = () => {
    const imageInfo = this.UploadImageOperation.payload.info;

    fileModule.downloadFileFromContent({
      info: {
        fileId: imageInfo.imageId,
        fileMimeType: imageInfo.imageMimeType,
        normalizedFileName: imageInfo.normalizedImageName,
        encodedFileContent: imageInfo.encodedImageContent,
        fileSizeBytes: imageInfo.imageSizeBytes,
      },
    });
  };

  tryAgain = () => {
    this.store.sync.actionQueue.resume();
  };

  get extraActionButtons() {
    return (
      <>
        <MdsButton
          label="Download file"
          variant={MdsButtonVariant.Outlined}
          onClick={this.downloadFile}
        />
        <MdsSpacer />
        <MdsButton
          label="Try again"
          variant={MdsButtonVariant.FilledDark}
          onClick={this.tryAgain}
        />
      </>
    );
  }

  modalActionHandler = async () => {
    this.store.sync.actionQueue.skipAndRevertOperationById(this.id);
  };
}
