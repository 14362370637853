import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { api } from "@/modules/api";
import { validationModule } from "@/modules/validation";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";

export const SettingsAccountMigrationDebugSection: FC = observer(() => {
  const { store } = useAppStore();

  const importNoteAndAssociatedContentFromV1 = useCallback(async () => {
    const targetNoteId = prompt("Target Note Id");

    if (!targetNoteId) {
      return;
    }

    if (!validationModule.isUuid(targetNoteId)) {
      alert("Invalid Note Id");
      return;
    }

    const result = await api.post("/v2/migration/import-note-and-associated-content", {
      body: {
        note_id: targetNoteId,
        config: {
          generate_unique_v2_ids: true,
        },
      },
    });

    if (result.error || !result.data) {
      alert("An error occurred.");
      return;
    }

    console.log(result);
    alert("Imported successfully");
  }, []);

  const importCollectionAndAssociatedContentFromV1 = useCallback(async () => {
    const targetCollectionId = prompt("Targe Collection Id");

    if (!targetCollectionId) {
      return;
    }

    if (!validationModule.isUuid(targetCollectionId)) {
      alert("Invalid Collection Id");
      return;
    }

    const result = await api.post("/v2/migration/import-collection-and-associated-content", {
      body: {
        collection_id: targetCollectionId,
        config: {
          generate_unique_v2_ids: true,
        },
      },
    });

    if (result.error || !result.data) {
      alert("An error occurred.");
      return;
    }

    console.log(result);
    alert("Imported successfully");
  }, []);

  if (!store.debug.debugModeEnabled) {
    return null;
  }

  return (
    <>
      <br />
      <br />
      <MdsButton
        variant={MdsButtonVariant.Danger}
        label="DEBUG - Import Note and associated content from Mem 1.0"
        onClick={importNoteAndAssociatedContentFromV1}
      />
      <br />
      <MdsButton
        variant={MdsButtonVariant.Danger}
        label="DEBUG - Import Collection and associated content from Mem 1.0"
        onClick={importCollectionAndAssociatedContentFromV1}
      />
    </>
  );
});
