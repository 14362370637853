import { FavoriteItemModelData } from "@/store/favorite-items/types";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";

export type FavoriteItemIndexData = {
  sort_key: string;
  item_id: string;
};

export class FavoriteItemIndexes {
  private data: SyncUpdateValue<FavoriteItemModelData>;

  constructor({
    remoteData,
    optimisticUpdates,
  }: {
    remoteData: Maybe<SyncUpdateValue<FavoriteItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<FavoriteItemModelData>[];
  }) {
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<FavoriteItemModelData>) || remoteData;
  }

  get item_id() {
    return this.data.model_data.item_id;
  }

  get sort_key() {
    return this.data.model_data.sort_key;
  }

  get indexes(): FavoriteItemIndexData {
    return { sort_key: this.sort_key, item_id: this.item_id };
  }
}
