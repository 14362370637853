import { BaseError } from "@/domains/errors/kinds/BaseError";

export type InfoObject = Record<string, JsonValue>;

export type Result<TResult, TError extends BaseError = BaseError> =
  | {
      data: TResult;
      error?: undefined;
    }
  | {
      error: TError;
      data?: undefined;
    };

export enum ContentType {
  Json = "application/json",
  Text = "text/plain",
  Form = "application/x-www-form-urlencoded",
}

export type Promisable<T> = T | Promise<T>;

export enum NodeEnvironment {
  Development = "development",
  Production = "production",
  Test = "test",
}

export type Primitive = boolean | number | string | symbol | null | undefined;

export type JsonPrimitive = string | number | boolean | null;

export type JsonValue = JsonPrimitive | JsonObject | JsonArray;

export type JsonObject = { [key: string]: JsonValue };

export interface JsonArray extends Array<JsonValue> {}

export type Nullable<T> = { [P in keyof T]: T[P] | null };

export type ValueOf<T> = T[keyof T];

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type Maybe<T> = T | undefined;

export type MaybeNull<T> = T | null;

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Constructor<T> = new (...args: any) => T;

export type IndexedBoolean = 0 | 1;
