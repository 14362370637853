import { observer } from "mobx-react-lite";
import { useState } from "react";
import { toastModule } from "@/modules/toast";
import { api } from "@/modules/api";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { useAppStore } from "@/store/utils/hooks";
import { css } from "@/domains/emotion";
import styled from "@emotion/styled";

interface SearchResultVotingButtonsProps {
  itemId: string;
  searchEngineResultId: string;
  pageCursor: string | null;
  searchSessionId: string;
  userSpaceId: string;
  additionalElements?: React.ReactNode;
}

enum SearchResultVote {
  None,
  Upvote,
  Downvote,
}

export const SearchResultVotingButtons = observer<SearchResultVotingButtonsProps>(
  function SearchResultVotingButtons({
    itemId,
    searchEngineResultId,
    pageCursor,
    searchSessionId,
    userSpaceId,
    additionalElements,
  }) {
    const [vote, setVote] = useState(SearchResultVote.None);
    const { pageStore } = useAppStore();
    const searchPageListState = pageStore.searchPage.listState;
    const highlightedItemId = searchPageListState?.highlightedItemId;
    const isVisible = itemId === highlightedItemId;

    async function submitFeedbackReason(feedbackEventId: string, userResponse: string) {
      try {
        const response = await api.post(`/v2/search/feedback/{feedback_event_id}/reason`, {
          params: {
            path: {
              feedback_event_id: feedbackEventId,
            },
          },
          body: {
            reason: userResponse,
          },
        });

        if (response.error) {
          toastModule.triggerToast({
            content: `Failed to submit reason for Note with SearchEngineResultID ${feedbackEventId}`,
          });
        } else {
          toastModule.triggerToast({
            content: `Your response has been submitted`,
          });
        }
      } catch (_error) {
        toastModule.triggerToast({
          content: `An error occurred while submitting the response for Note with SearchEngineResultID ${feedbackEventId}`,
        });
      }
    }

    async function submitFeedback(
      voteType: SearchResultVote,
      searchEngineResultId: string,
      searchSessionID: string,
      pageCursor: string | null
    ) {
      try {
        const response = await api.post("/v2/search/feedback", {
          params: {
            query: {
              space_id: userSpaceId,
            },
          },
          body: {
            search_engine_result_id: searchEngineResultId,
            search_session_page_cursor: pageCursor,
            session_id: searchSessionID,
            feedback_kind: voteType === SearchResultVote.Upvote ? "UPVOTE" : "DOWNVOTE",
          },
        });
        if (response.error) {
          toastModule.triggerToast({
            content: `Failed to submit Feedback for Note with SearchEngineResultID ${searchEngineResultId}`,
          });
          return null;
        } else {
          const result = await response.data;
          if (result["feedback_event_id"] !== null) {
            return result["feedback_event_id"];
          }
        }
      } catch (_error) {
        toastModule.triggerToast({
          content: `An error occurred while submitting the response for Note with SearchEngineResult ID  ${searchEngineResultId}`,
        });
        return null;
      }
    }

    const handleVoteReasonClick = (feedBackEventId: string) => {
      const userResponse = prompt("Please enter reasoning for vote.");
      if (userResponse !== null) {
        submitFeedbackReason(feedBackEventId, userResponse);
      }
    };

    const handleSearchResultVote = async (
      event: React.MouseEvent<HTMLButtonElement>,
      voteType: SearchResultVote,
      searchEngineResultId: string,
      searchSessionId: string,
      pageCursor: string | null
    ) => {
      event.preventDefault();
      event.stopPropagation();
      setVote(voteType);
      const feedbackEventId = await submitFeedback(
        voteType,
        searchEngineResultId,
        searchSessionId,
        pageCursor
      );
      const voteTypeString = voteType === SearchResultVote.Upvote ? "Upvoted" : "Downvoted";
      const content = (
        <div>
          <div>{voteTypeString} search result</div>
          <button
            style={{
              backgroundColor: "red",
              color: "white",
              opacity: 0.7,
              borderRadius: "8px",
              alignItems: "center",
            }}
            onClick={() => handleVoteReasonClick(feedbackEventId as string)}
          >
            Provide Reason
          </button>
        </div>
      );
      toastModule.triggerToast({ content: content });
    };

    const handleSearchResultVoteWithArgs =
      (
        voteType: SearchResultVote,
        result_id: string,
        searchSessionID: string,
        pageCursor: string | null
      ) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        handleSearchResultVote(event, voteType, result_id, searchSessionID, pageCursor);
      };

    const buttonsDisabled = vote !== SearchResultVote.None;

    return (
      <div>
        {isVisible && (
          <>
            <MdsIconButton
              iconKind={MdsIconKind.Upvote}
              className={css({ display: "inline-block" })}
              isDisabled={buttonsDisabled}
              onClick={handleSearchResultVoteWithArgs(
                SearchResultVote.Upvote,
                searchEngineResultId,
                searchSessionId,
                pageCursor
              )}
            />

            <MdsIconButton
              iconKind={MdsIconKind.Downvote}
              className={css({ display: "inline-block" })}
              isDisabled={buttonsDisabled}
              onClick={handleSearchResultVoteWithArgs(
                SearchResultVote.Downvote,
                searchEngineResultId,
                searchSessionId,
                pageCursor
              )}
            />

            <AdditionalElementsContainer>{additionalElements}</AdditionalElementsContainer>
          </>
        )}
      </div>
    );
  }
);

const AdditionalElementsContainer = styled.div(({ theme }) => ({
  display: "inline-block",
  marginLeft: theme.spacing.sm,
  height: "30px",
}));
