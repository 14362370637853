import EmptyCollections from "@/assets/images/empty-collections.svg";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsButtonShape, MdsButtonSize } from "@/design-system/components/button/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { EmptyContainer, EmptyText } from "@/components/styling/empty";

export const CollectionsListPageListEmpty = () => {
  const { pageStore } = useAppStore();
  const page = pageStore.collectionsListPage;

  return (
    <EmptyContainer>
      <img src={EmptyCollections} alt="No collections" />
      <EmptyText>No Collections yet. Create a Collection to organize your items in Mem.</EmptyText>
      <MdsButton
        label="New collection"
        variant={MdsButtonVariant.FilledDark}
        shape={MdsButtonShape.Square}
        size={MdsButtonSize.Medium}
        onClick={async () => await page.handleCreateNewCollection()}
        iconKind={MdsIconKind.ComposeInline}
      />
    </EmptyContainer>
  );
};
