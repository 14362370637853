import { Constructor } from "@/domains/common/types";
import { GenericSyncOperationKind } from "@/store/sync/types";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  ExternalOperationKind,
  IExternalOperation,
  ISyncOperation,
  ISyncOperationGuestMode,
} from "@/store/sync/operations/types";

import { CreateNoteOperation } from "@/store/sync/operations/notes/CreateNoteOperation";
import { DeleteNoteOperation } from "@/store/sync/operations/notes/DeleteNoteOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { RestoreNoteOperation } from "@/store/sync/operations/notes/RestoreNoteOperation";
import { GrantNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/GrantNoteAclViaSpaceAccountOperation";
import { GrantNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/GrantNoteAclViaEmailAddressOperation";
import { UpdateNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaSpaceAccountOperation";
import { UpdateNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaEmailAddressOperation";
import { RevokeNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaSpaceAccountOperation";
import { RevokeNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaEmailAddressOperation";
import { UpdateNoteAclViaCollectionOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaCollectionOperation";
import { CreateCollectionOperation } from "@/store/sync/operations/collections/CreateCollectionOperation";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { DeleteCollectionOperation } from "@/store/sync/operations/collections/DeleteCollectionOperation";
import { AddNoteToCollectionOperation } from "@/store/sync/operations/collections/AddNoteToCollectionOperation";
import { RemoveNoteFromCollectionOperation } from "@/store/sync/operations/collections/RemoveNoteFromCollectionOperation";
import { GrantCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaSpaceAccountOperation";
import { GrantCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaEmailAddressOperation";
import { UpdateCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaSpaceAccountOperation";
import { UpdateCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaEmailAddressOperation";
import { RevokeCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaSpaceAccountOperation";
import { RevokeCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaEmailAddressOperation";
import { AddNoteToFavoritesOperation } from "@/store/sync/operations/favorites/AddNoteToFavoritesOperation";
import { AddCollectionToFavoritesOperation } from "@/store/sync/operations/favorites/AddCollectionToFavoritesOperation";
import { AddSavedSearchToFavoritesOperation } from "@/store/sync/operations/favorites/AddSavedSearchToFavoritesOperation";
import { RemoveNoteFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveNoteFromFavoritesOperation";
import { RemoveCollectionFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveCollectionFromFavoritesOperation";
import { RemoveSavedSearchFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveSavedSearchFromFavoritesOperation";
import { UpdateFavoriteItemSortKeyOperation } from "@/store/sync/operations/favorites/UpdateFavoriteItemSortKeyOperation";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { MarkNoteViewedOperation } from "@/store/sync/operations/recents/MarkNoteViewedOperation";
import { MarkCollectionViewedOperation } from "@/store/sync/operations/recents/MarkCollectionViewedOperation";
import { MakeCollectionPrivateOperation } from "@/store/sync/operations/collections/MakeCollectionPrivateOperation";
import { CreateChatConversationOperation } from "@/store/sync/operations/chat/CreateChatConversationOperation";
import { SubmitChatMessageOperation } from "@/store/sync/operations/chat/SubmitChatMessageOperation";
import { SaveDraftNoteOperation } from "@/store/sync/operations/chat/SaveDraftNoteOperation";
import { SaveChatMessageOperation } from "@/store/sync/operations/chat/SaveChatMessageOperation";
import { UploadImageExternalOperation } from "@/store/sync/operations/external/UploadImageExternalOperation";
import { UploadFileExternalOperation } from "@/store/sync/operations/external/UploadFileExternalOperation";
import { ProvideChatMessageFeedbackOperation } from "@/store/sync/operations/chat/ProvideChatMessageFeedbackOperation";

/**
 * A mapping of operation kinds to their respective operation classes.
 *
 * Note that this is marked as "Partial" - if we add a new operation to the backend,
 * we don't want to break the API typings here. (Callers should handle the case where
 * the operation kind is not found in this map.)
 */
export const getOperationKindToClassMap = (): Partial<
  Record<
    GenericSyncOperationKind,
    Constructor<
      BaseSyncOperationGeneric<ISyncOperation | ISyncOperationGuestMode | IExternalOperation>
    >
  >
> => ({
  /**
   * Standard Sync Operations
   */
  CREATE_NOTE: CreateNoteOperation,
  DELETE_NOTE: DeleteNoteOperation,
  TRASH_NOTE: TrashNoteOperation,
  RESTORE_NOTE: RestoreNoteOperation,
  UPDATE_NOTE_CONTENT_USING_DIFF: UpdateNoteContentUsingDiffOperation,
  UPDATE_NOTE_CONTENT_USING_FULL_STATE: UpdateNoteContentUsingDiffOperation,
  GRANT_NOTE_ACL_VIA_SPACE_ACCOUNT: GrantNoteAclViaSpaceAccountOperation,
  GRANT_NOTE_ACL_VIA_EMAIL_ADDRESS: GrantNoteAclViaEmailAddressOperation,
  UPDATE_NOTE_ACL_VIA_SPACE_ACCOUNT: UpdateNoteAclViaSpaceAccountOperation,
  UPDATE_NOTE_ACL_VIA_EMAIL_ADDRESS: UpdateNoteAclViaEmailAddressOperation,
  REVOKE_NOTE_ACL_VIA_SPACE_ACCOUNT: RevokeNoteAclViaSpaceAccountOperation,
  REVOKE_NOTE_ACL_VIA_EMAIL_ADDRESS: RevokeNoteAclViaEmailAddressOperation,
  UPDATE_NOTE_ACL_VIA_COLLECTION: UpdateNoteAclViaCollectionOperation,
  CREATE_COLLECTION: CreateCollectionOperation,
  UPDATE_COLLECTION: UpdateCollectionOperation,
  DELETE_COLLECTION: DeleteCollectionOperation,
  ADD_NOTE_TO_COLLECTION: AddNoteToCollectionOperation,
  REMOVE_NOTE_FROM_COLLECTION: RemoveNoteFromCollectionOperation,
  GRANT_COLLECTION_ACL_VIA_SPACE_ACCOUNT: GrantCollectionAclViaSpaceAccountOperation,
  GRANT_COLLECTION_ACL_VIA_EMAIL_ADDRESS: GrantCollectionAclViaEmailAddressOperation,
  UPDATE_COLLECTION_ACL_VIA_SPACE_ACCOUNT: UpdateCollectionAclViaSpaceAccountOperation,
  UPDATE_COLLECTION_ACL_VIA_EMAIL_ADDRESS: UpdateCollectionAclViaEmailAddressOperation,
  REVOKE_COLLECTION_ACL_VIA_SPACE_ACCOUNT: RevokeCollectionAclViaSpaceAccountOperation,
  REVOKE_COLLECTION_ACL_VIA_EMAIL_ADDRESS: RevokeCollectionAclViaEmailAddressOperation,
  MAKE_COLLECTION_PRIVATE: MakeCollectionPrivateOperation,
  ADD_NOTE_TO_FAVORITES: AddNoteToFavoritesOperation,
  ADD_COLLECTION_TO_FAVORITES: AddCollectionToFavoritesOperation,
  ADD_SAVED_SEARCH_TO_FAVORITES: AddSavedSearchToFavoritesOperation,
  REMOVE_NOTE_FROM_FAVORITES: RemoveNoteFromFavoritesOperation,
  REMOVE_COLLECTION_FROM_FAVORITES: RemoveCollectionFromFavoritesOperation,
  REMOVE_SAVED_SEARCH_FROM_FAVORITES: RemoveSavedSearchFromFavoritesOperation,
  UPDATE_FAVORITE_ITEM_SORT_KEY: UpdateFavoriteItemSortKeyOperation,
  MARK_NOTE_VIEWED: MarkNoteViewedOperation,
  MARK_COLLECTION_VIEWED: MarkCollectionViewedOperation,
  CREATE_CHAT_CONVERSATION: CreateChatConversationOperation,
  SUBMIT_CHAT_MESSAGE: SubmitChatMessageOperation,
  SAVE_DRAFT_NOTE: SaveDraftNoteOperation,
  SAVE_CHAT_MESSAGE: SaveChatMessageOperation,
  PROVIDE_CHAT_MESSAGE_FEEDBACK: ProvideChatMessageFeedbackOperation,
  /**
   * External Operations
   */
  [ExternalOperationKind.UPLOAD_IMAGE]: UploadImageExternalOperation,
  [ExternalOperationKind.UPLOAD_FILE]: UploadFileExternalOperation,
});
