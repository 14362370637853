import { MdsText } from "@/design-system/components/text";
import { MdsTextAlign, MdsTextSize, MdsTextWeight } from "@/design-system/components/text/types";
import { mdsColors } from "@/design-system/foundations";
import styled from "@emotion/styled";
import Image from "@/assets/images/empty-copilot.svg";

interface Props {
  isTopLevel: boolean;
}
export const NoRelatedNotes = ({ isTopLevel }: Props) => {
  return (
    <Wrapper>
      <img src={Image} alt="No content" />
      <div style={{ maxWidth: 280 }}>
        <MdsText
          color={mdsColors().grey.x500}
          align={MdsTextAlign.Center}
          size={MdsTextSize.Medium}
          weight={MdsTextWeight.Regular}
        >
          {isTopLevel
            ? "Mem Copilot automatically shows notes related to the one you have open. Simply open a note, and Mem Copilot will start helping."
            : "Nothing related to this note yet. As you add more to your Mem, related notes will show up here."}
        </MdsText>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: 19,
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
  maxHeight: 560,
});
