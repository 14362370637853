import { backendApiClientManager } from "@/manager/backend-api-client";
import { BackendApiClientType } from "@/manager/backend-api-client/types";
import { forceLogOutNotAuthenticatedUser } from "@/modules/api/authUtils";
import { responseMatchesBackendApiErrorKind } from "@/modules/api/errorHandling";
import { BackendApiErrorKind } from "@/modules/api/types";
import { clientEnvModule } from "@/modules/client-env";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ClientMethodOptions = Record<any, any>;

let _authToken: string | null = null;

const customMethodHandler = async (
  method: keyof BackendApiClientType,
  url: string,
  options: ClientMethodOptions
) => {
  /**
   * Grab the global base client.
   */
  const apiClient = backendApiClientManager.instance();
  const packageVersion = clientEnvModule.packageVersion();
  const clientId = clientEnvModule.clientId();
  const { clientGroupId } = await clientEnvModule.clientGroupId();

  /**
   * Build the headers.
   * Adds in a Firebase Bearer token if the user is logged in.
   */
  const headers: HeadersInit = {};
  const bearerToken = _authToken;

  if (bearerToken) {
    headers["Authorization"] = `OAuthAccessToken ${bearerToken}`;
  }

  if (packageVersion) {
    headers["X-Mem-Client-Version"] = packageVersion;
    headers["X-Mem-Client-Platform"] = "web";
  }

  if (clientId) {
    headers["X-Mem-Client-ID"] = clientId;
  }

  if (clientGroupId) {
    headers["X-Mem-Client-Group-ID"] = clientGroupId;
  }

  /**
   * Execute the function.
   * We overwrite the typings here because it significantly simplifies the implementation.
   */
  const clientMethod = apiClient[method] as Function;
  const result = await clientMethod(url, { ...options, headers });

  if (
    responseMatchesBackendApiErrorKind({
      response: result,
      errorKind: BackendApiErrorKind.NotAuthenticated,
    })
  ) {
    await forceLogOutNotAuthenticatedUser();
  }

  return result;
};

const POST_MOCKS = new Map<string, { operationKind?: string; response: unknown }>();

export const mockResponseForPost = (url: string, operationKind?: string, response?: unknown) => {
  if (!response) {
    POST_MOCKS.delete(url);
    return;
  }
  POST_MOCKS.set(url, { operationKind, response });
};

const customPostHandler = async (url: string, options: ClientMethodOptions) => {
  const { operationKind, response } = POST_MOCKS.get(url) ?? {};
  if (!response || (!!operationKind && operationKind !== options.body.operation_kind)) {
    return customMethodHandler("POST", url, options);
  }

  return response;
};

export type GlobalAPIClientType = {
  get: BackendApiClientType["GET"];
  patch: BackendApiClientType["PATCH"];
  post: BackendApiClientType["POST"];
  rawPost: BackendApiClientType["POST"];
  put: BackendApiClientType["PUT"];
  delete: BackendApiClientType["DELETE"];
};

export const BASE_GLOBAL_API_CLIENT = {
  get: (url: string, options: ClientMethodOptions) => customMethodHandler("GET", url, options),
  post: (url: string, options: ClientMethodOptions) => customPostHandler(url, options),
  rawPost: (url: string, options: ClientMethodOptions) => customMethodHandler("POST", url, options),
  put: (url: string, options: ClientMethodOptions) => customMethodHandler("PUT", url, options),
  delete: (url: string, options: ClientMethodOptions) =>
    customMethodHandler("DELETE", url, options),
} as GlobalAPIClientType;

export const GLOBAL_API_CLIENT = {
  ...BASE_GLOBAL_API_CLIENT,
  /**
   * Returns true if the user is authenticated.
   * This matches the implementation that the request middleware uses to inject auth headers.
   */
  setAuthToken: (authToken: string | null) => {
    _authToken = authToken;
  },
  checkIfClientIsAuthenticated: async () => {
    return Boolean(_authToken);
  },
};
