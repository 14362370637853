import { useLogOutPageLogic } from "@/pages/log-out/utils/useLogOutPageLogic";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { css } from "@/domains/emotion";

const wrapperStyles = css({
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  /** We make the whole outer page draggable on desktop. */
  WebkitAppRegion: "drag",
});

export const LogOutPage: FC = observer(() => {
  useLogOutPageLogic();

  /**
   * We display a blank screen while logging the user out.
   */
  return <div className={wrapperStyles} />;
});
