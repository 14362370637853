import { MdsItemDropdown } from "@/design-system/components/item-list/types/SharedTypes";
import { AppStore } from "@/store/AppStore";
import { getRowForNoteObservable } from "@/store/note/getRowForNoteObservable";
import { INoteObservable } from "@/store/note";
import { ListStateObservable } from "@/store/pages/ListStateObservable";

export const getRowForNoteId = ({
  dateTime,
  generateItemDropdown,
  inMainPanel,
  listState,
  noteId,
  store,
  trackClick,
}: {
  dateTime: Date | string;
  generateItemDropdown?: ({
    noteObservable,
  }: {
    noteObservable: INoteObservable;
  }) => MdsItemDropdown;
  inMainPanel: boolean;
  listState?: ListStateObservable;
  noteId: string;
  store: AppStore;
  trackClick?: () => void;
}) => {
  const noteObservable = store.notes.getNoteObservableById({
    noteId,
  });
  if (!noteObservable) return;

  return getRowForNoteObservable({
    dateTime,
    dropdown: generateItemDropdown?.({ noteObservable }) ?? { items: [] },
    inMainPanel,
    listState,
    noteObservable,
    store,
    trackClick,
  });
};
