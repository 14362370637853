import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind } from "@/store/sync/types";

export class UpdateNoteAclViaSpaceAccountOperation extends BaseSyncOperation<IUpdateNoteAclViaSpaceAccountOperation> {
  get operationKind(): SyncOperationKind {
    return "UPDATE_NOTE_ACL_VIA_SPACE_ACCOUNT";
  }

  get successToastMessage() {
    return <>Updating access to {this.mediumTitle}...</>;
  }

  get title() {
    return this.store.notes.get(this.payload.id)?.title || UNTITLED_NOTE_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        Access to {this.mediumTitle} could not be updated. If this error continues, please contact
        support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to update access to {this.mediumTitle}. Please contact the note
        owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        Access to {this.mediumTitle} could not be updated. If this error continues, please contact
        support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  async triggerRecompute() {
    await this.store.notes.recompute(this.payload.id);
  }
}
