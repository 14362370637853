import { observer } from "mobx-react-lite";

import { MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { FC } from "react";
import { ImageUploadRejectedModalStore } from "@/components/modal/image-upload-rejected/ImageUploadRejectedModalStore";

export interface ImageUploadRejectedModalProps {
  store: ImageUploadRejectedModalStore;
}

export const ImageUploadRejectedModal: FC<ImageUploadRejectedModalProps> = observer(({ store }) => {
  return (
    <MdsModal
      isModalOpen={store.isOpen}
      rootElementId="root"
      handleCloseModal={() => store.close()}
      className={modalStyles}
    >
      <div className={rowStyles}>
        <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
          Upload failed
        </MdsText>
        <MdsSpacer />
        <MdsIconButton
          variant={MdsIconButtonVariant.Transparent}
          iconKind={MdsIconKind.Exit}
          onClick={() => store.close()}
        />
      </div>

      <MdsText
        lineHeight={MdsTextSize.Small}
        color={mdsColors().grey.x600}
        size={MdsTextSize.Small}
      >
        {store.errorSummary}
      </MdsText>

      <MdsText
        lineHeight={MdsTextSize.Small}
        color={mdsColors().grey.x600}
        size={MdsTextSize.Small}
      >
        {store.errorMessage}
      </MdsText>
    </MdsModal>
  );
});

const modalStyles = css({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: "16px",
  width: "min(600px, 90vw)",
  padding: "24px",
});

const rowStyles = css({
  alignItems: "center",
  display: "flex",
  gap: "12px",
  width: "100%",
});
