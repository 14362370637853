import { appRoutes } from "@/app/router";
import { windowModule } from "@/modules/window";

export const openCollectionInNewTab = ({ collectionId }: { collectionId: string }) => {
  const targetUrl = windowModule.buildUrl({
    path: appRoutes.collectionsView({ params: { collectionId } }).path,
  });

  windowModule.openInNewTab({ url: targetUrl });
};
