import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { appRoutes } from "@/app/router";
import { googleIdentityModule } from "@/modules/google-identity";
import { AuthCardSignUpWithGoogleButton } from "@/components/auth/google/AuthCardSignUpWithGoogleButton";
import { AuthCardBase } from "@/components/auth/AuthCardBase";
import { authCardStyles } from "@/components/auth/utils/authCardStyles";

export interface AuthCardSignUpProps {}

export const AuthCardSignUp: FC<AuthCardSignUpProps> = observer(() => {
  const signUpWithGoogle = useCallback(async () => await googleIdentityModule.triggerSignUp(), []);

  return (
    <AuthCardBase title="Sign up for Mem">
      <div className={authCardStyles.actionSection}>
        <AuthCardSignUpWithGoogleButton onClick={signUpWithGoogle} />
      </div>

      <div className={authCardStyles.altText}>
        <span>Have an account?</span>{" "}
        <span>
          <Link className={authCardStyles.link} to={appRoutes.logIn({}).path}>
            Log in
          </Link>
        </span>
      </div>
    </AuthCardBase>
  );
});
