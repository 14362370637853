import { makeObservable, computed, runInAction, onBecomeObserved } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable, NoteObservable } from "@/store/note";
import { Uuid } from "@/domains/global/identifiers";
import Dexie, { liveQuery, Subscription } from "dexie";
import { IndexedBoolean, Maybe } from "@/domains/common/types";
import { orderBy } from "lodash-es";

const MAX_RECENTS_TO_SHOW = 10;

type SortedRecentNotesIndex = [
  isAvailable: IndexedBoolean,
  lastInteractedAt: string,
  modelId: string,
];

type SortedRecentCollectionsIndex = [
  isAvailable: IndexedBoolean,
  lastInteractedAt: string,
  modelId: string,
];

export class AppStoreRecentItemsStore extends AppSubStore {
  isInitialized = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);
    makeObservable(this, {
      isInitialized: true,
      initialize: true,
      initializeLiveQuery: true,
      sortedRecentItemsSubscription: true,
      sortedRecentCollectionsNotInteractedWithByMeIds: true,
      sortedRecentCollectionsInteractedWithByMeIds: true,
      sortedRecentNotesInteractedWithByMeIds: true,
      sortedRecentCollectionsNotInteractedWithByMe: computed,
      sortedRecentCollectionsInteractedWithByMe: computed,
      sortedRecentNotesInteractedWithByMe: computed,
      sortedRecentItemsInteractedWithByMe: computed,
      sortedRecentCollections: computed,
      sortedRecentItems: computed,
      getRecentNotesByCollectionId: false,
    });

    onBecomeObserved(this, "sortedRecentItems", () => this.initialize());
  }

  sortedRecentItemsSubscription: Maybe<Subscription>;

  initialize() {
    if (this.isInitialized) return;
    this.initializeLiveQuery();
    runInAction(() => (this.isInitialized = true));
  }

  initializeLiveQuery() {
    this.sortedRecentItemsSubscription = liveQuery(async () => {
      const [collectionsNotInteracted, collectionsInteracted, notesInteracted] = await Promise.all([
        // Collections not interacted with me = include only empty last_interacted_at
        this.store.collections.localTable
          .where("[is_available+last_interacted_at+model_id]")
          .between([1, "", Dexie.minKey], [1, "", Dexie.maxKey])
          .reverse()
          .limit(MAX_RECENTS_TO_SHOW)
          .keys(),
        // Collections interacted with by me = include only non-empty last_interacted_at
        this.store.collections.localTable
          .where("[is_available+last_interacted_at+model_id]")
          .aboveOrEqual([1, "\0", Dexie.minKey])
          .reverse()
          .limit(MAX_RECENTS_TO_SHOW)
          .keys(),
        // Notes interacted with by me = include only non-empty last_interacted_at
        this.store.notes.localTable
          .where("[is_available+last_interacted_at+model_id]")
          .aboveOrEqual([1, "\0", Dexie.minKey])
          .reverse()
          .limit(MAX_RECENTS_TO_SHOW)
          .keys(),
      ]);
      return {
        collectionsNotInteracted,
        collectionsInteracted,
        notesInteracted,
      };
    }).subscribe({
      next: ({ collectionsNotInteracted, collectionsInteracted, notesInteracted }) => {
        runInAction(() => {
          this.sortedRecentCollectionsNotInteractedWithByMeIds =
            collectionsNotInteracted as unknown as SortedRecentCollectionsIndex[];
          this.sortedRecentCollectionsInteractedWithByMeIds =
            collectionsInteracted as unknown as SortedRecentCollectionsIndex[];
          this.sortedRecentNotesInteractedWithByMeIds =
            notesInteracted as unknown as SortedRecentNotesIndex[];
        });
      },
    });
  }

  sortedRecentCollectionsNotInteractedWithByMeIds: SortedRecentCollectionsIndex[] = [];
  get sortedRecentCollectionsNotInteractedWithByMe(): CollectionObservable[] {
    return this.sortedRecentCollectionsNotInteractedWithByMeIds
      .map(([_isAvailable, _lastInteractedAt, modelId]) => this.store.collections.get(modelId))
      .filter(collection => !!collection)
      .filter(collection => collection?.isAvailable);
  }

  sortedRecentCollectionsInteractedWithByMeIds: SortedRecentCollectionsIndex[] = [];
  get sortedRecentCollectionsInteractedWithByMe(): CollectionObservable[] {
    return this.sortedRecentCollectionsInteractedWithByMeIds
      .map(([_isAvailable, _lastInteractedAt, modelId]) => this.store.collections.get(modelId))
      .filter(collection => !!collection)
      .filter(collection => collection?.isAvailable);
  }

  sortedRecentNotesInteractedWithByMeIds: SortedRecentNotesIndex[] = [];
  get sortedRecentNotesInteractedWithByMe(): INoteObservable[] {
    return this.sortedRecentNotesInteractedWithByMeIds
      .map(([_isAvailable, _lastInteractedAt, modelId]) => this.store.notes.get(modelId))
      .filter(note => !!note)
      .filter(note => note?.isAvailable);
  }

  // COMBPUTED
  get sortedRecentItemsInteractedWithByMe(): (INoteObservable | CollectionObservable)[] {
    return orderBy(
      [
        ...this.sortedRecentNotesInteractedWithByMe,
        ...this.sortedRecentCollectionsInteractedWithByMe,
      ],
      "lastViewedAt",
      "desc"
    );
  }

  get sortedRecentCollections(): CollectionObservable[] {
    return [
      ...this.sortedRecentCollectionsInteractedWithByMe,
      ...this.sortedRecentCollectionsNotInteractedWithByMe,
    ].slice(0, MAX_RECENTS_TO_SHOW);
  }

  get sortedRecentItems(): (INoteObservable | CollectionObservable)[] {
    return [
      ...this.sortedRecentItemsInteractedWithByMe,
      ...this.sortedRecentCollectionsNotInteractedWithByMe,
    ].slice(0, MAX_RECENTS_TO_SHOW);
  }

  getRecentNotesByCollectionId(collectionId: Uuid, limit: number = Infinity): INoteObservable[] {
    const recentNotes = this.store.recentItems.sortedRecentItems.filter(
      item => item instanceof NoteObservable
    );
    const collection = this.store.collections.get(collectionId);
    const collectionItems = collection?.itemList.allCollectionItemIds;
    const output: INoteObservable[] = [];
    for (const recentNote of recentNotes) {
      if (collectionItems?.has(recentNote.id)) output.push(recentNote);
      if (output.length === limit) break;
    }
    return output;
  }
}
