import { ChatMessage } from "@/domains/chat";
import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { resolveSpaceAccountChatMessageSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountChatMessageSyncModelUuid";
import { SYNC_ENGINE_CHAT_SPACE_ACCOUNT_ID } from "@/store/chat/constants";
import { SpaceAccountChatMessageObservable } from "@/store/chat/SpaceAccountChatMessageObservable";
import {
  ChatMessageContext,
  ChatMessageContextKind,
  ChatMessageCollectionIdContext,
  ChatMessageModelData,
  ChatMessageNoteIdContext,
  ChatMessageSyncModelSection,
  ChatMessageSyncModelStatus,
  ChatMessageSyncModelSource,
  SpaceAccountChatMessageSection,
} from "@/store/chat/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable, observable } from "mobx";

export class ChatMessageObservable
  extends BaseSyncModel<ChatMessageModelData>
  implements ChatMessage
{
  public modelKind = SyncModelKind.ChatMessage;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<ChatMessageModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: observable,
      chatConversationId: computed,
      status: computed,
      savedAsNoteId: computed,
      locallyCreatedAt: computed,
      sections: computed,
      sources: computed,
      content: computed,
      sectionMap: computed,

      context: computed,
      isLastUserMessage: computed,
      isSystemMessage: computed,
      indexInUnsentMessages: computed,

      // SPACE ACCOUNT CHAT MESSAGE
      spaceAccountChatMessage: computed,
      spaceAccountChatMessageId: computed,
      spaceAccountChatMessageSections: computed,
      spaceAccountChatMessageSectionMap: computed,
    });
  }

  get chatConversationId(): Uuid {
    return this.modelData.chat_conversation_id;
  }

  get status(): ChatMessageSyncModelStatus {
    return this.modelData.status;
  }

  get savedAsNoteId(): Uuid | undefined {
    return this.spaceAccountChatMessage?.savedAsNoteId;
  }

  get locallyCreatedAt(): string {
    return this.modelData.locally_created_at;
  }

  get sections(): ChatMessageSyncModelSection[] {
    return this.modelData.sections;
  }

  get sources(): ChatMessageSyncModelSource[] {
    return this.modelData.sources;
  }

  get content(): string {
    return this?.modelData.sections.map(section => section.value.content).join("\n");
  }

  get sectionMap(): Record<Uuid, ChatMessageSyncModelSection> {
    return this.sections.reduce(
      (acc, section) => {
        acc[section.id] = section;
        return acc;
      },
      {} as Record<Uuid, ChatMessageSyncModelSection>
    );
  }

  get spaceAccountChatMessageId(): Uuid {
    return resolveSpaceAccountChatMessageSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      chatMessageId: this.id,
    });
  }

  get spaceAccountChatMessage(): SpaceAccountChatMessageObservable | undefined {
    return this.store.spaceAccountChatMessages.get(this.spaceAccountChatMessageId);
  }

  get spaceAccountChatMessageSections(): SpaceAccountChatMessageSection[] {
    return this.spaceAccountChatMessage?.sections ?? [];
  }

  get spaceAccountChatMessageSectionMap(): Record<Uuid, SpaceAccountChatMessageSection> {
    return this.spaceAccountChatMessageSections.reduce(
      (acc, section) => {
        acc[section.section_id] = section;
        return acc;
      },
      {} as Record<Uuid, SpaceAccountChatMessageSection>
    );
  }

  get context(): ChatMessageContext {
    const noteContext = this.modelData.contexts.find(
      e => (e.value as Partial<ChatMessageNoteIdContext>).note_id
    )?.value as Maybe<Partial<ChatMessageNoteIdContext>>;
    if (noteContext?.note_id) {
      return {
        id: noteContext.note_id,
        kind: ChatMessageContextKind.NoteDetailView,
        observable: () =>
          noteContext.note_id ? this.store.notes.get(noteContext.note_id) : undefined,
      };
    }

    const collectionContext = this.modelData.contexts.find(
      e => (e.value as Partial<ChatMessageCollectionIdContext>).collection_id
    )?.value as Maybe<Partial<ChatMessageCollectionIdContext>>;
    if (collectionContext?.collection_id) {
      return {
        id: collectionContext.collection_id,
        kind: ChatMessageContextKind.CollectionDetailView,
        observable: () =>
          collectionContext.collection_id
            ? this.store.collections.get(collectionContext.collection_id)
            : undefined,
      };
    }

    return {
      id: GLOBAL_CONTEXT_ID,
      kind: ChatMessageContextKind.Global,
    };
  }

  get isLastUserMessage(): boolean {
    return this.id === this.store.chatMessages.lastUserMessageId;
  }

  get isSystemMessage(): boolean {
    return (
      this.modelData.sent_by_space_account_id.toUpperCase() ===
      SYNC_ENGINE_CHAT_SPACE_ACCOUNT_ID.toUpperCase()
    );
  }

  get indexInUnsentMessages(): number {
    if (this.isSystemMessage) return -1;
    return this.store.chatMessages.unsentMessages.findIndex(message => message.id === this.id);
  }
}

export const GLOBAL_CONTEXT_ID = "GLOBAL";
