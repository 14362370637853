import { mdsSidebarWidths } from "@/design-system/foundations";

export enum SidebarVariant {
  Expanded = "expanded",
  Collapsed = "collapsed",
  Hidden = "hidden",
}

export const SIDEBAR_VARIANTS = {
  [SidebarVariant.Expanded]: { width: mdsSidebarWidths().expanded, x: 0, opacity: 1 },
  [SidebarVariant.Collapsed]: { width: mdsSidebarWidths().collapsed, x: 0, opacity: 1 },
  [SidebarVariant.Hidden]: { width: 0, x: -mdsSidebarWidths().expanded, opacity: 0 },
};
