import { appRoutes } from "@/app/router";
import { windowModule } from "@/modules/window";

export const openNoteInNewTab = ({ noteId }: { noteId: string }) => {
  const targetUrl = windowModule.buildUrl({
    path: appRoutes.notesView({ params: { noteId } }).path,
  });

  windowModule.openInNewTab({ url: targetUrl });
};
