import { CollectionsViewPage } from "@/pages/collections-view";
import styled from "@emotion/styled";

export const CollectionsInSidePanel = () => {
  return (
    <Wrapper>
      <CollectionsViewPage isSidePanel />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`;
