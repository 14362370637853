import table from "@/domains/local-db/account/queue/adapter";
import { deserializeSyncQueueItemData } from "@/domains/local-db/account/queue/serialization";
import { AppStore } from "@/store";
import { deserializeSyncOperation } from "@/store/sync/operations/helpers/common";
import { SyncOperationGeneric } from "@/store/sync/operations/types";
import { OptimisticSyncUpdate, SyncModelData, SyncQueueItemKind } from "@/store/sync/types";
import { groupBy, sortBy } from "lodash-es";
import pRetry from "p-retry";

export async function getAllItems(store: AppStore): Promise<{
  acknowledgedSyncOperations: SyncOperationGeneric[];
  syncOperations: SyncOperationGeneric[];
  optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[];
}> {
  const queueItems = await pRetry(() => table.getAll({ mayFail: true }));
  if (!queueItems.length) {
    return {
      acknowledgedSyncOperations: [],
      syncOperations: [],
      optimisticUpdates: [],
    };
  }
  const grouped = groupBy(queueItems, item => item.kind);
  const {
    [SyncQueueItemKind.AcknowledgedSyncOperation]: acknowledgedSyncOperations,
    [SyncQueueItemKind.SyncOperation]: syncOperations,
    [SyncQueueItemKind.OptimisticUpdate]: optimisticUpdates,
  } = grouped;

  return {
    acknowledgedSyncOperations: sortBy(
      acknowledgedSyncOperations?.map(item =>
        deserializeSyncOperation(store, JSON.parse(item.stringifiedData))
      ),
      item => item.committedAt
    ),
    syncOperations: sortBy(
      syncOperations?.map(item =>
        deserializeSyncOperation(store, JSON.parse(item.stringifiedData))
      ),
      item => item.committedAt
    ),
    optimisticUpdates: sortBy(
      optimisticUpdates?.map(
        item =>
          deserializeSyncQueueItemData(item.stringifiedData) as OptimisticSyncUpdate<SyncModelData>
      ),
      item => item.locally_committed_at
    ),
  };
}
