import { SidebarVariant } from "@/components/layout/components/sidebar/constants";
import { mdsSidebarWidths, PanelMode } from "@/design-system/foundations";
import { useAppStore } from "@/store";
import { useEffect, useMemo, useState } from "react";

export const useSidebarBehavior = () => {
  const { store } = useAppStore();
  const [collapsedSidebarWidth, setCollapsedSidebarWidth] = useState(0);

  const [isExpanded, setIsExpanded] = useState(true);
  const isSplitView = store.sidePanel.isSplitView;

  const breakpoints = store.interface.matchesPanelModeBreakpoint;
  const matchesNarrowView = !!breakpoints.get(PanelMode.Narrow);
  const matchesWideSinglePanel = !!breakpoints.get(PanelMode.WideSinglePanel);
  const matchesWideSplitView = !!breakpoints.get(PanelMode.WideSplitView);

  const { isWideEnough, currentVariant } = useMemo(() => {
    if (matchesNarrowView) return { isWideEnough: false, currentVariant: SidebarVariant.Hidden };

    const isWideEnough = isSplitView ? matchesWideSplitView : matchesWideSinglePanel;

    return {
      isWideEnough,
      currentVariant:
        isWideEnough && isExpanded ? SidebarVariant.Expanded : SidebarVariant.Collapsed,
    };
  }, [isSplitView, isExpanded, matchesNarrowView, matchesWideSinglePanel, matchesWideSplitView]);

  const isCollapsed = currentVariant === SidebarVariant.Collapsed;

  useEffect(() => {
    setCollapsedSidebarWidth(isCollapsed ? mdsSidebarWidths().collapsed : 0);
  }, [isCollapsed, setCollapsedSidebarWidth]);

  return {
    toggleSidebar: () => setIsExpanded(prev => !prev),
    shouldHideSidebarToggle: !isWideEnough,
    currentSidebarVariant: currentVariant,
    collapsedSidebarWidth,
  };
};
