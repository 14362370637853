import styled from "@emotion/styled";

interface Props {
  time?: string;
  color?: string;
}

export const IndeterminateLoaderBar = ({ time, color }: Props) => {
  return <Container time={time} color={color} />;
};

// adapted from https://uiball.com/ldrs/ #17

const Container = styled.div<Props>`
  --uib-size: 100%;
  --uib-color: ${props => props.color || props.theme.colors.grey.x600};
  --uib-stroke: 2px;
  --uib-bg-opacity: 0;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--uib-stroke);
  width: var(--uib-size);
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--uib-color);
    opacity: var(--uib-bg-opacity);
    transition: background-color 0.3s ease;
  }

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: calc(var(--uib-stroke) / 2);
    animation: zoom ${props => props.time || "2s"} ease-in-out infinite;
    transform: translateX(-100%);
    background-color: var(--uib-color);
    transition: background-color 0.3s ease;
  }

  @keyframes zoom {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
