import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store/utils/hooks";
import styled from "@emotion/styled";
import { IndeterminateLoaderBar } from "@/components/loaders/IndeterminateLoaderBar";
import { useEffect, useRef, useState } from "react";
import { MAX_NOTE_EXTRACTION_MINUTES } from "@/store/pages/CopilotViewStore/CopilotViewStore";
import { mdsColors } from "@/design-system/foundations";

const TIMER_INTERVAL = MAX_NOTE_EXTRACTION_MINUTES * 60 * 1_000;

export const CopilotLoaderBar = observer(() => {
  const { pageStore } = useAppStore();
  const [isTimedOut, setIsTimedOut] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const page = pageStore.copilotView;

  useEffect(() => {
    if (page.isRunningPipeline && timer.current === null) {
      timer.current = setTimeout(() => {
        setIsTimedOut(true);
        timer.current = null;
      }, TIMER_INTERVAL);
    } else if (!page.isRunningPipeline) {
      if (timer.current) clearTimeout(timer.current);
      timer.current = null;
      setIsTimedOut(false);
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = null;
      setIsTimedOut(false);
    };
  }, [page.isRunningPipeline]);

  return (
    <OuterWrapper>
      <AnimatePresence mode="wait">
        {page.isRunningPipeline && !isTimedOut && (
          <motion.div
            key="copilot-loader"
            // The bar is 2 pixels high and takes 2 seconds to animate. These values should match if those change.
            initial={{ y: -2 }}
            animate={{ y: 0 }}
            exit={{ y: -2 }}
            transition={{ duration: 2 }}
          >
            <InnerWrapper>
              <IndeterminateLoaderBar color={mdsColors().grey.x300} />
            </InnerWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </OuterWrapper>
  );
});

const OuterWrapper = styled.div`
  position: relative;
  margin-left: -8px;
  margin-right: -8px;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;
