import { appRoutes } from "@/app/router";
import { windowModule } from "@/modules/window";
import { AppStore } from "@/store";

export const openSearchInNewTab = ({
  savedSearchId,
  store,
}: {
  savedSearchId: string;
  store: AppStore;
}) => {
  const savedSearchObservable = store.savedSearches.getSavedSearchObservableById({
    savedSearchId,
  });

  if (!savedSearchObservable) return;

  const targetUrl = windowModule.buildUrl({
    path: `${appRoutes.search({}).path}?${savedSearchObservable.savedSearchQueryString}`,
  });

  windowModule.openInNewTab({ url: targetUrl });
};
