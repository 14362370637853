import { Maybe, Optional } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { uuidModule } from "@/modules/uuid";
import { AppStore, GuestAppStore } from "@/store";
import {
  CustomSyncErrorKind,
  SyncError,
  SyncErrorDisplayType,
  SyncErrorHandlingType,
} from "@/store/sync/operations/errors/SyncError";
import { SyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import {
  IExternalOperation,
  ISyncOperation,
  ISyncOperationGuestMode,
} from "@/store/sync/operations/types";
import { OptimisticSyncUpdate, SyncCustomErrorData, SyncModelData } from "@/store/sync/types";
import { logger } from "@/modules/logger";

export interface BaseSyncOperationGenericParams<
  T extends ISyncOperation | ISyncOperationGuestMode | IExternalOperation =
    | ISyncOperation
    | ISyncOperationGuestMode
    | IExternalOperation,
> {
  store: AppStore | GuestAppStore;
  payload: Optional<T["payload"], "schema_version">;
  operationId?: Uuid;
  committedAt?: string;
  latestSpaceAccountSequenceId?: number; // Add this line
  triggerSuccessToast?: boolean;
}

export abstract class BaseSyncOperationGeneric<
  SyncOperation extends ISyncOperation | ISyncOperationGuestMode | IExternalOperation,
> {
  protected store: AppStore | GuestAppStore;
  protected triggerSuccessToast: boolean;

  abstract get operationKind(): SyncOperation["operation_kind"];
  payload: SyncOperation["payload"];
  operationId: Uuid;
  committedAt: string;
  latestSpaceAccountSequenceId: Maybe<number>;

  constructor({
    store,
    payload,
    operationId,
    committedAt,
    latestSpaceAccountSequenceId,
    triggerSuccessToast = true,
  }: BaseSyncOperationGenericParams<SyncOperation>) {
    this.store = store;
    this.operationId = operationId ?? uuidModule.generate();
    this.committedAt = committedAt ?? new Date().toISOString();
    this.latestSpaceAccountSequenceId = latestSpaceAccountSequenceId || undefined;
    this.triggerSuccessToast = triggerSuccessToast;
    this.payload = { ...payload, schema_version: 1 } as SyncOperation["payload"];
  }

  abstract generateSyncOperation(): SyncOperation;
  abstract generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[];
  abstract execute(): Promise<void>;
  abstract triggerRecompute(): Promise<void>;

  acknowledge(latestSpaceAccountSequenceId: number): void {
    this.latestSpaceAccountSequenceId = latestSpaceAccountSequenceId;
  }

  get id() {
    return this.operationId;
  }

  get acknowledged() {
    return this.latestSpaceAccountSequenceId !== undefined;
  }

  protected triggerToast(
    toastMessage: React.ReactNode,
    handlingType = SyncErrorHandlingType.Revert
  ) {
    if (!toastMessage) return;

    throw new SyncError({
      operationId: this.id,
      displayType: SyncErrorDisplayType.Toast,
      message: "An error occurred.",
      toastMessage,
      handlingType,
      retryEndActionHandler:
        handlingType === SyncErrorHandlingType.RetryWithLimit
          ? () => this.store.sync.actionQueue.skipAndRevertRelatedOperations(this)
          : undefined,
    });
  }

  protected triggerModal(
    syncErrorModalFieldsGenerator?: SyncErrorModalFieldsGenerator,
    handlingType = SyncErrorHandlingType.Fail,
    retryEndActionHandler?: () => void
  ) {
    throw new SyncError({
      operationId: this.id,
      displayType: SyncErrorDisplayType.Modal,
      message: `An error occurred.`,
      handlingType,
      syncErrorModalFieldsGenerator,
      retryEndActionHandler,
    });
  }

  protected ignoreError(
    handlingType = SyncErrorHandlingType.Revert,
    retryEndActionHandler?: () => void
  ) {
    throw new SyncError({
      operationId: this.id,
      displayType: SyncErrorDisplayType.None,
      message: "We're having a temporary issue syncing your data. Retrying...",
      handlingType,
      retryEndActionHandler,
    });
  }

  protected getToastMessage(syncErrorKind: CustomSyncErrorKind): React.ReactNode {
    switch (syncErrorKind) {
      case "NOT_FOUND":
        return "A sync operation failed. If this continues to happen, please contact support.";
      case "INVALID":
        return "A sync operation failed. Please try again.";
      case "PERMISSION_DENIED":
        return "You don't have permission to perform that operation.";
      case "TRANSIENT":
        return "We're having a temporary issue syncing your data. Retrying...";
      case "UNKNOWN":
        return "A sync operation failed. Please try again. If the error continues, please contact support.";
    }
  }

  handleInvalidError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(this.getToastMessage("INVALID"));
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(this.getToastMessage("PERMISSION_DENIED"));
  }

  handleTransientError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(this.getToastMessage("TRANSIENT"), SyncErrorHandlingType.RetryForever);
  }

  handleNotFoundError(_errorData: SyncCustomErrorData): void {
    logger.info({
      message: "[BaseSyncOperationGeneric] [handleNotFoundError] encountered a NOT_FOUND error",
      info: {
        operationId: this.id,
        operationKind: this.operationKind,
      },
    });

    this.triggerToast(this.getToastMessage("NOT_FOUND"), SyncErrorHandlingType.Revert);
  }

  get syncErrorModalFieldsGenerator(): Maybe<SyncErrorModalFieldsGenerator> {
    return;
  }

  handleUnknownError(_errorData: SyncCustomErrorData): void {
    this.triggerModal(
      this.syncErrorModalFieldsGenerator,
      SyncErrorHandlingType.RetryWithLimit,
      !this.syncErrorModalFieldsGenerator
        ? async () => {
            await this.store.sync.actionQueue.skipAndRevertOperationById(this.operationId);
          }
        : undefined
    );
  }
}
