import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { INoteObservable } from "@/store/note/types";

export const moveNotesToTrash = async ({
  notes,
  store,
}: {
  notes: INoteObservable[];
  store: AppStore;
}) => {
  await Promise.all(
    notes.map(note => note.moveToTrash({ triggerSuccessToast: notes.length === 1 }))
  );

  if (notes.length === 1) return;

  toastModule.triggerToast({
    content: (
      <div>
        {`Note${notes.length > 1 ? "s" : ""} moved to `}
        <a onClick={() => store.navigation.goToTrash()}>Trash</a>
      </div>
    ),
  });
};
