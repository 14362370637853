import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountFeatureFlagsObservable } from "./FeatureFlagsObservable";
import { SpaceAccountFeatureFlagsModelData } from "./types";
import { computed, makeObservable } from "mobx";

type PostHogFeatureFlagIds = "related-notes" | "copilot";

export class AppStoreSpaceAccountFeatureFlagsStore extends BaseSyncModelStore<
  SpaceAccountFeatureFlagsObservable,
  SpaceAccountFeatureFlagsModelData
> {
  private lazyFlags: Record<string, boolean> | undefined;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountFeatureFlagsConfig, ...injectedDeps });

    makeObservable<AppStoreSpaceAccountFeatureFlagsStore, "lazyFlags">(this, {
      createSyncModel: false,
      featureFlags: computed,
      lazyFlags: false,
      getFlagStatus: false,
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountFeatureFlagsModelData>
  ): SpaceAccountFeatureFlagsObservable {
    return new SpaceAccountFeatureFlagsObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  // Feature flags are "locked in" after first access by design.
  // They will not change until the user refreshes the page.
  get featureFlags(): Record<string, boolean> {
    if (this.lazyFlags === undefined) {
      if (this.pool.size > 0) {
        // save a non-observable version of the flags
        const val = this.pool.values().next().value;
        const plainObject = Object.assign({}, val.modelData.flags);
        this.lazyFlags = plainObject;
      } else {
        this.lazyFlags = {};
      }
    }

    return this.lazyFlags!;
  }

  getFlagStatus(flag: PostHogFeatureFlagIds): boolean {
    const val = this.featureFlags[flag];
    return val ?? false;
  }
}
