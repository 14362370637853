import { FC } from "react";
import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import { useAppStore } from "@/store";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import trialOverviewImage from "@/assets/images/trial-overview@2x.png";
import { MdsLinkButton } from "@/design-system/components/button/link/MdsLinkButton";
import { motion } from "framer-motion";
import { windowModule } from "@/modules/window";

export const ActivateTrialStage: FC = () => {
  const { store } = useAppStore();

  return (
    <>
      <div className={container}>
        <div className={headerSection}>{"Your 7-day trial of Mem starts today"}</div>
        <div className={subHeaderSection}>
          {
            "Your trial gives you access to all of Mem, the only notes app designed to keep busy professionals organized with AI."
          }
        </div>
        <div className={mainSection}>
          <div className={imageContainer}>
            <motion.img
              className={imageStyles}
              src={trialOverviewImage}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <MdsButton
            label="Let's get started"
            variant={MdsButtonVariant.Brand}
            size={MdsButtonSize.Medium}
            onClick={() => store.platformInfo.onboardingInfo.saveAndContinue()}
          />
        </div>
        <div className={finePrintSection}>
          {
            "You will not be charged unless you choose to subscribe when your free trial ends in 7 days. Learn more about "
          }
          <MdsLinkButton
            onClick={() => {
              windowModule.openInNewTab({ url: new URL("https://get.mem.ai/pricing") });
            }}
          >
            Mem pricing plans
          </MdsLinkButton>
          {"."}
        </div>
      </div>
    </>
  );
};

const container = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "16px",
  width: "min(576px, calc(100vw - 64px))",
  "@media (min-width: 672px) and (min-height: 632px)": {
    justifyContent: "center",
  },
});

const subHeaderSection = css({
  display: "flex",
  justifyContent: "center",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: mdsColors().grey.x600,
});

const headerSection = css({
  display: "flex",
  justifyContent: "center",
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 700,
  color: mdsColors().grey.x600,
  textAlign: "center",
});

const imageContainer = css({
  width: "100%",
  backgroundColor: mdsColors().grey.x100,
  borderRadius: "32px",
  overflow: "hidden",
  padding: 0,
});

const imageStyles = css({
  display: "block",
  width: "100%",
  padding: 0,
  margin: 0,
});

const mainSection = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100px",
  width: "100%",
  gap: "16px",
});

const finePrintSection = css({
  color: mdsColors().grey.x500,
  fontSize: "14px",
  lineHeight: "20px",
});
