import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { FC, useMemo } from "react";
import { SyncStatusLabel } from "@/components/sync/SyncStatusLabel";
import { InboundSyncState, OutboundSyncState } from "@/components/sync/types";
import { SidebarAccountInfo } from "@/components/layout/components/sidebar/SidebarAccountInfo";
import styled from "@emotion/styled";
import { ProfileSize } from "@/components/layout/components/account-profile";

export type SidebarDropdownMenuContentHandlers = {
  handleMobile: () => void;
  handleLogOut: () => void;
  handleOpenSettings: () => void;
  handleOpenImports: () => void;
  handleOpenIntegrations: () => void;
  handleTrashClick: () => void;
  handleNavigateToAdminPanel: () => void;
  handleToggleDebugging: () => void;
  handleToggleNoteContentPreloading: () => void;
  handleResetSync: () => void;
  handlePushFailedSyncOperation: () => void;
  handlePushPermissionDeniedSyncOperation: () => void;
  handleNavigateToHelpAndSupport: () => void;
  handlePauseSync: () => void;
  handleResumeSync: () => void;
};

export interface SidebarDropdownMenuContentProps {
  className?: string;
  handlers: SidebarDropdownMenuContentHandlers;
  isDebugMode?: boolean;
  accountInfo?: {
    profilePhotoUrl: string | null;
    profileDisplayName: string;
    profileEmailAddress: string;
  };
  syncInfo: {
    inboundSyncState: InboundSyncState;
    outboundSyncState: OutboundSyncState;
    isNoteContentPreloadingDisabled: boolean;
  };
}

export const SidebarDropdownMenuContent: FC<SidebarDropdownMenuContentProps> = ({
  className,
  accountInfo,
  syncInfo,
  handlers,
  isDebugMode,
}) => {
  const contentListItems: MdsDropdownItem[] = useMemo(
    () => [
      {
        id: "account-info",
        kind: MdsDropdownItemKind.Other,
        content: accountInfo ? (
          <AccountInfoWrapper>
            <SidebarAccountInfo
              profileSize={ProfileSize.Large}
              profilePhotoUrl={accountInfo.profilePhotoUrl}
              profileDisplayName={accountInfo.profileDisplayName}
              profileEmailAddress={accountInfo.profileEmailAddress}
            />
          </AccountInfoWrapper>
        ) : null,
      },
      {
        id: "imports",
        kind: MdsDropdownItemKind.Button,
        label: "Import from Mem 1.0",
        iconKind: MdsIconKind.Import,
        onClick: handlers.handleOpenImports,
      },
      // {
      //   id: "setup-integrations",
      //   kind: MdsDropdownItemKind.Button,
      //   label: "Set up integrations",
      //   iconKind: MdsIconKind.Link,
      //   onClick: handlers.handleOpenIntegrations,
      // },
      {
        id: "divider-2",
        kind: MdsDropdownItemKind.Divider,
      },
      {
        id: "settings",
        kind: MdsDropdownItemKind.Button,
        label: "Settings",
        iconKind: MdsIconKind.Settings,
        onClick: handlers.handleOpenSettings,
      },
      {
        id: "view-trash",
        kind: MdsDropdownItemKind.Button,
        label: "Trash",
        iconKind: MdsIconKind.Trash,
        onClick: handlers.handleTrashClick,
      },
      {
        id: "sign-out",
        kind: MdsDropdownItemKind.Button,
        label: "Sign out",
        iconKind: MdsIconKind.LogOut,
        onClick: handlers.handleLogOut,
      },
      {
        id: "divider-3",
        kind: MdsDropdownItemKind.Divider,
      },
      {
        id: "get-ios-app",
        kind: MdsDropdownItemKind.Button,
        label: "Download Mem for iOS",
        iconKind: MdsIconKind.PhoneSms,
        onClick: handlers.handleMobile,
      },
      {
        id: "help-and-support",
        kind: MdsDropdownItemKind.Button,
        label: "Help and support",
        iconKind: MdsIconKind.Help,
        onClick: handlers.handleNavigateToHelpAndSupport,
      },
      {
        id: "divider-4",
        kind: MdsDropdownItemKind.Divider,
      },
      {
        id: "sync-state",
        kind: MdsDropdownItemKind.Other,
        content: (
          <SyncStatusLabel
            inboundSyncState={syncInfo.inboundSyncState}
            outboundSyncState={syncInfo.outboundSyncState}
          />
        ),
      },
    ],
    [
      accountInfo,
      handlers.handleLogOut,
      handlers.handleMobile,
      handlers.handleNavigateToHelpAndSupport,
      handlers.handleOpenImports,
      handlers.handleOpenSettings,
      handlers.handleTrashClick,
      syncInfo.inboundSyncState,
      syncInfo.outboundSyncState,
    ]
  );

  const debugModeContentListItems: MdsDropdownItem[] = useMemo(
    () => [
      {
        id: "divider-5",
        kind: MdsDropdownItemKind.Divider,
      },
      {
        id: "header",
        kind: MdsDropdownItemKind.Detail,
        text: "Admin Features",
      },
      {
        id: "debug-mode-panel",
        kind: MdsDropdownItemKind.Button,
        label: "Admin Panel",
        iconKind: MdsIconKind.GearCode,
        onClick: handlers.handleNavigateToAdminPanel,
      },
      {
        id: "toggle-queue-debug",
        kind: MdsDropdownItemKind.Button,
        label: "Toggle queue debug panel",
        iconKind: MdsIconKind.Question,
        onClick: handlers.handleToggleDebugging,
      },
      {
        id: "toggle-note-content-preloading",
        kind: MdsDropdownItemKind.Button,
        label: syncInfo.isNoteContentPreloadingDisabled
          ? "Enable note content preloading"
          : "Disable note content preloading",
        iconKind: MdsIconKind.Question,
        onClick: handlers.handleToggleNoteContentPreloading,
      },
      {
        id: "reset-sync",
        kind: MdsDropdownItemKind.Button,
        label: "Reset Sync",
        iconKind: MdsIconKind.Warning,
        onClick: handlers.handleResetSync,
      },
      {
        id: "pause-sync",
        kind: MdsDropdownItemKind.Button,
        label: "Pause Sync",
        iconKind: MdsIconKind.Exit,
        onClick: handlers.handlePauseSync,
      },
      {
        id: "resume-sync",
        kind: MdsDropdownItemKind.Button,
        label: "Resume Sync",
        iconKind: MdsIconKind.Play,
        onClick: handlers.handleResumeSync,
      },
      {
        id: "push-failed-sync-action",
        kind: MdsDropdownItemKind.Button,
        label: "Push failed sync operation",
        iconKind: MdsIconKind.Warning,
        onClick: handlers.handlePushFailedSyncOperation,
      },
      {
        id: "push-rejected-sync-operation",
        kind: MdsDropdownItemKind.Button,
        label: "Push permission denied sync operation",
        iconKind: MdsIconKind.Warning,
        onClick: handlers.handlePushPermissionDeniedSyncOperation,
      },
    ],
    [
      handlers.handleNavigateToAdminPanel,
      handlers.handlePauseSync,
      handlers.handlePushFailedSyncOperation,
      handlers.handlePushPermissionDeniedSyncOperation,
      handlers.handleResetSync,
      handlers.handleResumeSync,
      handlers.handleToggleDebugging,
      handlers.handleToggleNoteContentPreloading,
      syncInfo.isNoteContentPreloadingDisabled,
    ]
  );

  const contentList: MdsDropdownContentList = useMemo(() => {
    const items: MdsDropdownItem[] = [...contentListItems];

    if (isDebugMode) {
      items.push(...debugModeContentListItems);
    }

    return { items };
  }, [debugModeContentListItems, contentListItems, isDebugMode]);

  return <MdsDropdownContent className={className} contentList={contentList} />;
};

const AccountInfoWrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.sm,
  borderBottom: `1px solid ${theme.colors.grey.x100}`,
}));
