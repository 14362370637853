import { FadeVerticalScroll } from "@/components/vertical-scroll/FadeVerticalScroll";
import { MdsDropdownItem, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsDropdownButtonItemComponent } from "@/design-system/components/dropdown/MdsDropdownButtonItemComponent";
import { MdsDropdownDetailItemComponent } from "@/design-system/components/dropdown/MdsDropdownDetailItemComponent";
import { MdsDropdownDividerItemComponent } from "@/design-system/components/dropdown/MdsDropdownDividerItemComponent";
import { MdsDropdownScrollableSection } from "@/design-system/components/dropdown/types";
import { mdsColors, mdsFontSizes, mdsLineHeights } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { FC } from "react";

interface MdsDropdownItemComponentProps {
  className?: string;
  tooltipId: string;
  item: MdsDropdownItem;
  onOpenChange?: (value: boolean) => void;
  onHover?: ({ itemId }: { itemId?: string }) => void;
}

const MdsDropdownItemComponent: FC<MdsDropdownItemComponentProps> = ({
  className,
  tooltipId,
  item,
  onOpenChange,
  onHover,
}) => {
  switch (item.kind) {
    case MdsDropdownItemKind.Button: {
      return (
        <MdsDropdownButtonItemComponent
          className={className}
          item={item}
          onOpenChange={onOpenChange}
          tooltipId={tooltipId}
          onHover={onHover}
        />
      );
    }
    case MdsDropdownItemKind.Divider: {
      return <MdsDropdownDividerItemComponent className={className} item={item} />;
    }
    case MdsDropdownItemKind.Detail: {
      return <MdsDropdownDetailItemComponent className={className} item={item} />;
    }
    case MdsDropdownItemKind.Other: {
      return (
        <div className={cx(mdsDropdownItemContentDefaultStyles, className)}>{item.content}</div>
      );
    }
    case MdsDropdownItemKind.ScrollableSection: {
      return (
        <MdsDropdownScrollableSectionItemComponent
          item={item}
          tooltipId={tooltipId}
          onHover={onHover}
          onOpenChange={onOpenChange}
        />
      );
    }
  }
};

interface MdsDropdownScrollableSectionItemComponentProps {
  tooltipId: string;
  item: MdsDropdownScrollableSection;
  onOpenChange?: (value: boolean) => void;
  onHover?: ({ itemId }: { itemId?: string }) => void;
  className?: string;
}

const MdsDropdownScrollableSectionItemComponent: FC<
  MdsDropdownScrollableSectionItemComponentProps
> = ({ tooltipId, item, onOpenChange, onHover, className }) => {
  return (
    <FadeVerticalScroll>
      {item.items.map((item: MdsDropdownItem) =>
        mdsDropdownItemComponentMapper({
          item,
          onOpenChange,
          tooltipId,
          onHover,
          className: cx(fullWidthStyles, className),
        })
      )}
    </FadeVerticalScroll>
  );
};

export const mdsDropdownItemComponentMapper = (args: {
  item: MdsDropdownItem;
  onOpenChange?: (value: boolean) => void;
  tooltipId: string;
  onHover?: ({ itemId }: { itemId?: string }) => void;
  className?: string;
}) => <MdsDropdownItemComponent {...args} key={args.item.id} />;

const mdsDropdownItemContentDefaultStyles = css({
  fontSize: mdsFontSizes().small,
  lineHeight: mdsLineHeights().small,
  color: mdsColors().grey.x600,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const fullWidthStyles = css({
  width: "100%",
});
