import { observer } from "mobx-react-lite";
import { toastModule } from "@/modules/toast";
import { api } from "@/modules/api";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { useState } from "react";
import { MdsModal } from "@/design-system/components/modal";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import styled from "@emotion/styled";
import { MdsText, MdsTextWeight, MdsTextSize } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations/colors";

interface RefreshTopicsForAccountButtonProps {
  spaceAccountId: string;
}

export const RefreshTopicsForAccountButton = observer<RefreshTopicsForAccountButtonProps>(
  function RefreshTopicsForAccountButton({ spaceAccountId }) {
    const [isModalOpen, setModalOpen] = useState(false);

    async function submitRefreshRequest(spaceAccountId: string) {
      try {
        const response = await api.post(`/v2/related-notes/run-topic-generation-for-account`, {
          body: {
            space_account_id: spaceAccountId,
          },
        });

        if (response.error) {
          toastModule.triggerToast({
            content: `Failed refresh topics`,
          });
        } else {
          toastModule.triggerToast({
            content: `Topics are being refreshed. This may take a few minutes.`,
          });
        }
      } catch (_error) {
        toastModule.triggerToast({
          content: `Failed to refresh topics`,
        });
      }
    }

    const handleButtonClick = () => {
      setModalOpen(true); // Open confirmation modal
    };

    const handleConfirm = () => {
      setModalOpen(false);
      submitRefreshRequest(spaceAccountId); // Proceed if confirmed
    };

    const handleCancel = () => {
      setModalOpen(false); // Close modal without refreshing
    };

    return (
      <>
        <MdsIconButton
          onClick={handleButtonClick}
          iconKind={MdsIconKind.Warning}
          tooltipConfig={{
            content:
              "Refresh ALL Topics for your account. Note: This will overwrite any existing topics for every note.",
            placement: MdsTooltipPlacement.Left,
          }}
        />

        {isModalOpen && (
          <MdsModal handleCloseModal={handleCancel} isModalOpen={isModalOpen}>
            <ModalContainer>
              <HeaderContainer>
                <MdsIcon kind={MdsIconKind.Warning} />
                <MdsText
                  size={MdsTextSize.Large}
                  weight={MdsTextWeight.Bold}
                  color={mdsColors().grey.x600}
                >
                  Refresh Topics
                </MdsText>
              </HeaderContainer>
              <p>
                Are you sure you want to refresh the topics? This will overwrite any existing topics
                for ALL notes in your account and will take at least a few minutes.
              </p>
              <ButtonsContainer>
                <MdsButton
                  variant={MdsButtonVariant.Tertiary}
                  label={"No"}
                  onClick={handleCancel}
                />
                <MdsButton
                  label={"Yes"}
                  onClick={handleConfirm}
                  variant={MdsButtonVariant.Danger}
                />
              </ButtonsContainer>
            </ModalContainer>
          </MdsModal>
        )}
      </>
    );
  }
);

const ModalContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  width: "min(530px, 90vw)",
});

const ButtonsContainer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  gap: "8px",
});

const HeaderContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing.xs,
  alignItems: "center",
}));
