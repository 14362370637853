import { MdsDropdownButton, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsSpacer } from "@/design-system/components/spacer";
import { mdsColors } from "@/design-system/foundations/colors";
import { Maybe } from "@/domains/common/types";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { copyTextToClipboard } from "@/modules/clipboard/copyTextToClipboard";
import { toastModule } from "@/modules/toast";
import { useAppStore } from "@/store";
import { CustomSyncErrorKind } from "@/store/sync/operations/errors/SyncError";
import { getOperationKindToClassMap } from "@/store/sync/operations/map";
import { SyncOperationKind } from "@/store/sync/types";
import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { clientEnvModule } from "@/modules/client-env";
import { DateTime } from "luxon";

const customErrorKinds: Maybe<CustomSyncErrorKind>[] = [
  undefined,
  "INVALID",
  "PERMISSION_DENIED",
  "TRANSIENT",
  "UNKNOWN",
];

/**
 * This is a debugging overlay which displays various pieces of info about local application state.
 * Most notably, it displays the current sync action queue, and allows for copying the queue's debug info to the clipboard.
 */
export const SyncActionQueueInfoOverlay = observer(function SyncActionQueueInfoOverlay() {
  const { store } = useAppStore();
  const { buildInfo } = clientEnvModule.buildInfo();

  if (!store.debug.debugModeEnabled) {
    return null;
  }

  const operationKinds: Maybe<SyncOperationKind>[] = [
    undefined,
    ...(Object.keys(getOperationKindToClassMap()) as SyncOperationKind[]),
  ];

  const isOpen = store.debug.debugModeToolbarExpanded;
  const toggleOpen = () => store.debug.toggleDebugModeToolbar();
  const containerClassStyles = containerStyles(isOpen);

  if (!isOpen) {
    return (
      <div className={containerClassStyles}>
        <div className={headerStyles}>
          <span>Debug Toolbar</span>
          <MdsButton
            onClick={toggleOpen}
            size={MdsButtonSize.XSmall}
            label="Expand"
            variant={MdsButtonVariant.Transparent}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={containerClassStyles}>
      <div className={headerStyles}>
        <span>Debug Toolbar</span>
        <MdsButton
          label="Save to file"
          size={MdsButtonSize.XSmall}
          variant={MdsButtonVariant.Transparent}
          onClick={() => store.sync.actionQueue.saveDebugInfoToFile()}
        />
        <MdsButton
          onClick={async () => {
            const fullDebugInfo = store.sync.actionQueue.fullDebugInfo;
            await copyTextToClipboard({
              text: JSON.stringify(fullDebugInfo, null, 4),
            });
            toastModule.triggerToast({ content: "Copied queue debug info to clipboard." });
          }}
          size={MdsButtonSize.XSmall}
          label="Copy Info"
          variant={MdsButtonVariant.Transparent}
        />
        <MdsButton
          onClick={toggleOpen}
          size={MdsButtonSize.XSmall}
          label="Minimize"
          variant={MdsButtonVariant.Transparent}
        />
      </div>
      <div>
        <div className={mockApiErrorRowStyles}>
          <MdsSpacer />
          <MdsDropdownButton
            placement="below-right-alignment"
            iconKind={null}
            label={store.debug.operationsSubmitApiMockErrorKind ?? "disabled"}
            contentList={{
              items: customErrorKinds.map(kind => ({
                id: kind ?? "disabled",
                kind: MdsDropdownItemKind.Button,
                label: kind ?? "disabled",
                isChecked: store.debug.operationsSubmitApiMockErrorKind === kind,
                onClick: () => store.debug.setOperationsSubmitApiMockErrorKind(kind),
              })),
            }}
          />
          {"|"}
          <MdsDropdownButton
            placement="below-right-alignment"
            iconKind={null}
            label={store.debug.operationsSubmitApiMockErrorOperationKind ?? "all"}
            contentList={{
              items: operationKinds.map(kind => ({
                id: kind ?? "all",
                kind: MdsDropdownItemKind.Button,
                label: kind ?? "all",
                isChecked: store.debug.operationsSubmitApiMockErrorOperationKind === kind,
                onClick: () => store.debug.setOperationsSubmitApiMockErrorOperationKind(kind),
              })),
            }}
          />
        </div>
        <pre className={debugInfoStyles}>
          {JSON.stringify(store.sync.actionQueue.friendlyDebugInfo, null, 4)}
        </pre>
        <br />
        <pre className={debugInfoStyles}>
          <span>Commit: {buildInfo.gitCommitSha}</span>
          <br />
          <span>
            Build at:{" "}
            {buildInfo.buildTimestamp.toLocal().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
          </span>
        </pre>
      </div>
    </div>
  );
});

const containerStyles = (isOpen: boolean) =>
  css({
    backgroundColor: mdsColors().white,
    border: `1px solid ${mdsColors().grey.x300}`,
    borderRadius: "4px",
    bottom: 0,
    padding: "4px",
    paddingLeft: "8px",
    position: "fixed",
    right: 0,
    fontSize: "14px",
    width: isOpen ? "600px" : "200px",
    height: isOpen ? "auto" : "34px",
    zIndex: ZIndex.QueueDebugInfo,
    overflow: isOpen ? "auto" : "hidden",
    opacity: isOpen ? 1 : 0.6,
  });

const headerStyles = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const mockApiErrorRowStyles = css({
  alignItems: "center",
  display: "flex",
  gap: "8px",
  marginTop: "8px",
});

const debugInfoStyles = css({
  fontSize: "12px",
  overflow: "auto",
  maxHeight: "300px",
  marginTop: "8px",
});
