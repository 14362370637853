/*
This component composes a prop-driven copy of the Figma component menu-item on top of MDSMenuItem.
TODO: The name probably needs an improvement. The "Figma" prefix is clunky, but I want to make it clear
what we're doing here until we figure out our long-term strategy for Figma <-> React.

Figma component:
https://www.figma.com/design/ZEgqtUJHh5ksFWQ7Nq0P2u/Mem-Design-System?node-id=7335-32433&t=d3DuQFWAgCxW6s3i-4

TODO: 2-line support, enabled by the subtitle prop (update CollectionItem when done)
TODO: keyboard shortcut component via the shortcut prop
*/

import { type IFigmaMenuItemProps } from "./menu-item.types";
import { FigmaStyledMenuItem, StyledMenuItemLeft, StyledMenuItemRight } from "./menu-item.style";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsText } from "@/design-system/components/text";
import { css } from "@/domains/emotion";

export const FigmaMenuItem = ({
  type,
  collectionId,
  text,
  subtitle,
  isShared,
  isSelected,
  rightText,
  icon,
  shortcut,
  rightButton,
  ...rest
}: IFigmaMenuItemProps) => {
  const hasRight = rightText || isSelected || shortcut || rightButton;
  const isTwoLine = !!subtitle;

  const iconKind = collectionId
    ? null // when a collection is specified, the CollectionIcon is used
    : type === "note" // the type prop is a shortcut for setting the icon
      ? MdsIconKind.Document
      : type === "collection"
        ? MdsIconKind.Collection
        : icon; // the icon passed as a prop

  return (
    <FigmaStyledMenuItem {...rest}>
      <StyledMenuItemLeft>
        {collectionId && <CollectionIcon collectionId={collectionId} size={isTwoLine ? 32 : 16} />}
        {iconKind && <MdsIcon kind={iconKind} />}
        <MdsText numberOfLines={1} className={inlineTextStyles}>
          {text}
        </MdsText>
        {isShared && <MdsIcon kind={MdsIconKind.Shared} />}
      </StyledMenuItemLeft>
      {hasRight && (
        <StyledMenuItemRight>
          <MdsText numberOfLines={1} className={inlineTextStyles}>
            {rightText}
          </MdsText>
          {isSelected && <MdsIcon kind={MdsIconKind.Check} />}
          {
            // TODO: implement keyboard shortcut (see Figma)
          }
          {rightButton}
        </StyledMenuItemRight>
      )}
    </FigmaStyledMenuItem>
  );
};

const inlineTextStyles = css({
  // prevents descenders from being cut off
  lineHeight: "1.1rem",
});
