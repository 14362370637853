import { z } from "zod";

export const CACHE_VERSION = 1;
export const CACHE_DB_IDENTIFIER = "mem/cache-v2";
export const CACHE_TABLE_IDENTIFIER = "cache";

export enum CacheKey {
  ActiveClientInfo = "ACTIVE_CLIENT_INFO",
  MyAccount = "MY_ACCOUNT",
  MySpaces = "MY_SPACES",
  MySpaceAccounts = "MY_SPACE_ACCOUNTS",
}

export const ActiveClientInfo = z.object({
  clientGroupId: z.string().uuid(),
});

export const MyAccountCacheSchema = z.object({
  id: z.string().uuid(),
  profileEmailAddress: z.string(),
  profileDisplayName: z.string(),
  profilePhotoUrl: z.string().nullable(),
  settings: z.record(z.unknown()).optional(),
});

export const MySpacesCacheSchema = z.array(
  z.object({
    id: z.string().uuid(),
    title: z.string(),
  })
);

export const MySpaceAccountsCacheSchema = z.array(
  z.object({
    id: z.string().uuid(),
    spaceId: z.string().uuid(),
    accountId: z.string().uuid(),
    profileEmailAddress: z.string(),
    profileDisplayName: z.string(),
    profilePhotoUrl: z.string().nullable(),
  })
);

export const CACHE_SCHEMA = {
  [CacheKey.ActiveClientInfo]: ActiveClientInfo,
  [CacheKey.MyAccount]: MyAccountCacheSchema,
  [CacheKey.MySpaces]: MySpacesCacheSchema,
  [CacheKey.MySpaceAccounts]: MySpaceAccountsCacheSchema,
};

/**
 * Mapping of CacheKey to the type of the data that is stored
 */
export type CacheSchemaType = {
  [K in CacheKey]: z.infer<(typeof CACHE_SCHEMA)[K]>;
};
