import { clientEnvModule } from "@/modules/client-env";

export const _getCurrentWindowUrlSearchParams = () => {
  const isDesktop = clientEnvModule.isDesktop();

  /**
   * On desktop, we use `HashRouter` instead of `BrowserRouter`.
   */
  if (isDesktop) {
    // Get the part of the URL after the hash
    // eslint-disable-next-line no-restricted-properties
    const hashPart = window.location.hash.split("?")[1] || "";

    // Create URLSearchParams from this part
    const currentValue = new URLSearchParams(hashPart);

    return currentValue;
  }

  // eslint-disable-next-line no-restricted-properties
  const currentValue = new URLSearchParams(window.location.search);

  return currentValue;
};
