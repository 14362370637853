import { CollectionObservable } from "@/store/collections/CollectionObservable";

export enum CollectionItemMode {
  SELECT = "select",
  CREATE = "create",
}

export enum Direction {
  UP = "up",
  DOWN = "down",
}

export interface CollectionForOrganize {
  id: string;
  title: string;
  noteCount: number;
  description?: string;
  isShared: boolean;
  isCollected: boolean;
}

export const makeSimpleCollection = (
  collection: CollectionObservable | undefined,
  isCollected: boolean
): CollectionForOrganize | undefined => {
  if (!collection) return;
  return {
    id: collection.id,
    title: collection.title ?? "",
    noteCount: collection.itemList.sizeData.data ?? 0,
    description: collection.description,
    isShared: collection.isShared,
    isCollected: isCollected,
  };
};
