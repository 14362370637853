import { Uuid } from "@/domains/global/identifiers";
import { INoteObservable } from "@/store/note";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable, observable } from "mobx";

export class SpaceAccountNoteObservable extends BaseSyncModel<SpaceAccountNoteModelData> {
  public modelKind = SyncModelKind.SpaceAccountNote;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountNoteModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      // PROPERTIES
      modelKind: observable,
      itemId: computed,
      lastRelevantNoteExtractionStartedAt: computed,
      lastRelevantNoteExtractionCompletedAt: computed,
      isRunningRelevantNoteExtractionPipeline: computed,
      lastRelevantNoteExtractionNoteModelVersion: computed,
      item: computed,
      isItemTrashedOrDeleted: computed,
      lastViewedAt: computed,
      lastInteractedAt: computed,
    });
  }

  get itemId() {
    return this.modelData.note_id;
  }

  get lastRelevantNoteExtractionStartedAt() {
    return this.modelData.last_relevant_note_extraction_started_at;
  }

  get lastRelevantNoteExtractionCompletedAt() {
    return this.modelData.last_relevant_note_extraction_completed_at;
  }

  get isRunningRelevantNoteExtractionPipeline() {
    return this.modelData.is_running_relevant_notes_extraction_pipeline;
  }

  get lastRelevantNoteExtractionNoteModelVersion() {
    return this.modelData.last_relevant_note_extraction_note_model_version;
  }

  get item(): INoteObservable | undefined {
    return this.store.notes.getNoteObservableById({ noteId: this.itemId });
  }

  get isItemTrashedOrDeleted() {
    return (
      this.isDeleted ||
      !this.item ||
      this.item.isTrashed ||
      this.item.isDeleted ||
      !this.item.canAccess
    );
  }

  get lastViewedAt() {
    return this.modelData.last_viewed_at || "";
  }

  get lastInteractedAt() {
    return this.lastViewedAt;
  }
}
