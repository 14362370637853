import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import { MdsPanelResponsive } from "@/design-system/components/panel";
import { MdsPanelHeaderButton } from "@/design-system/components/panel-header/MdsPanelHeaderButton";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsPanelBreakpoint } from "@/design-system/foundations";
import { withLineBreaks } from "@/domains/html/withLineBreaks";
import { EventContext } from "@/domains/metrics/context";
import { renderArrayWithAnds } from "@/domains/string/renderArrayWithAnds";
import { INoteObservable } from "@/store/note/types";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

interface NoteEditorTopBarButtonProps {
  note?: INoteObservable;
  shareSheetModal: ShareSheetModalStore;
}

export const NoteEditorTopBarShareButton = observer<NoteEditorTopBarButtonProps>(
  function NoteEditorTopBarShareButton({ note, shareSheetModal }) {
    const { isDisabled, tooltipLabel, iconKind } = computed(() => {
      if (!note?.canAccess) {
        return {
          isDisabled: true,
          tooltipLabel: "You lost access",
          iconKind: MdsIconKind.LockSolid,
        };
      }

      if (!note?.canWrite) {
        return {
          isDisabled: true,
          tooltipLabel: "You don't have write access",
          iconKind: MdsIconKind.Shared,
        };
      }

      if (note.hasPendingShare) {
        return {
          isDisabled: false,
          tooltipLabel: "Access changes will be applied when you’re online",
          iconKind: MdsIconKind.Spinner,
        };
      }

      if (note?.isShared) {
        const labels = note?.sharedWithLabels;
        if (labels) {
          return {
            isDisabled: false,
            tooltipLabel: withLineBreaks(
              `Shared with ${renderArrayWithAnds(
                labels.length >= 5 ? [...labels.slice(0, 3), `${labels.length - 2} others`] : labels
              )}`
            ),
            iconKind: MdsIconKind.Shared,
          };
        }
      }

      return {
        isDisabled: false,
        tooltipLabel: "Private to only me",
        iconKind: MdsIconKind.LockSolid,
      };
    }).get();

    const tooltipConfig = {
      label: tooltipLabel,
      placement: MdsTooltipPlacement.Top,
      delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
    };

    const onClick = useCallback(() => {
      if (!note?.id) return;
      shareSheetModal.open({
        id: note.id,
        entityKind: ShareSheetEntityKind.Note,
        eventContext: EventContext.EditorActions,
      });
    }, [note, shareSheetModal]);

    return (
      <>
        <ShareSheetModal store={shareSheetModal} />
        <MdsPanelResponsive minWidth={mdsPanelBreakpoint}>
          <MdsPanelHeaderButton
            variant={MdsButtonVariant.Tertiary}
            iconKind={iconKind}
            onClick={onClick}
            label="Share"
            tooltipConfig={tooltipConfig}
            isDisabled={isDisabled}
          />
        </MdsPanelResponsive>
        <MdsPanelResponsive maxWidth={mdsPanelBreakpoint}>
          <MdsIconButton
            size={MdsIconButtonSize.Small}
            variant={MdsIconButtonVariant.Transparent}
            iconKind={iconKind}
            onClick={onClick}
            tooltipConfig={tooltipConfig}
            isDisabled={isDisabled}
          />
        </MdsPanelResponsive>
      </>
    );
  }
);
