import type { AppSubStoreArgs } from "@/store/types";
import { type SyncUpdateValue, SyncModelKind } from "@/store/sync/types";
import type { DataImportSyncModelData } from "@/store/data-imports/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { DataImportObservable } from "@/store/data-imports/DataImportObservable";
import { computed, makeObservable, override } from "mobx";
import { Maybe } from "@/domains/common/types";
import { first } from "lodash-es";

export class AppStoreDataImportsStore extends BaseSyncModelStore<
  DataImportObservable,
  DataImportSyncModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.DataImport, ...injectedDeps });
    makeObservable(this, {
      computeIndexes: override,
      createSyncModel: false,
      imports: computed,
      latestRunningImport: computed,
      runningImports: computed,
      latestImport: computed,
      count: computed,
      getLatestImports: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<DataImportSyncModelData>): DataImportObservable {
    return new DataImportObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get imports(): DataImportObservable[] {
    return this.all;
  }

  get count(): number {
    return this.imports.length;
  }

  get runningImports(): Maybe<DataImportObservable[]> {
    return this.imports.filter(v => v.isRunning);
  }

  get latestImport(): Maybe<DataImportObservable> {
    return first(this.getLatestImports(1));
  }

  get latestRunningImport(): Maybe<DataImportObservable> {
    return first(
      this.runningImports?.toSorted((a, b) => Date.parse(b.startedAt) - Date.parse(a.startedAt))
    );
  }

  getLatestImports(count: number): DataImportObservable[] {
    return this.imports
      .toSorted((a, b) => Date.parse(b.startedAt) - Date.parse(a.startedAt))
      .slice(0, count);
  }
}
