import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";
import { ChatMessageIndexData, ChatMessageModelData } from "@/store/chat/types";
import { SYNC_ENGINE_CHAT_SPACE_ACCOUNT_ID } from "@/store/chat/constants";

export class ChatMessageIndexes {
  private data: SyncUpdateValue<ChatMessageModelData>;

  constructor({
    remoteData,
    optimisticUpdates,
  }: {
    remoteData: Maybe<SyncUpdateValue<ChatMessageModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ChatMessageModelData>[];
  }) {
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<ChatMessageModelData>) || remoteData;
  }

  get is_system_message() {
    return this.data.model_data.sent_by_space_account_id.toUpperCase() ===
      SYNC_ENGINE_CHAT_SPACE_ACCOUNT_ID.toUpperCase()
      ? 1
      : 0;
  }

  get locally_created_at() {
    return this.data.model_data.locally_created_at;
  }

  get chat_conversation_id() {
    return this.data.model_data.chat_conversation_id;
  }

  get status() {
    return this.data.model_data.status;
  }

  get context_ids() {
    return this.data.model_data.contexts.map(context => context.context_id);
  }

  get context_kinds() {
    return this.data.model_data.contexts.map(context => context.kind);
  }

  get indexes(): ChatMessageIndexData {
    return {
      is_system_message: this.is_system_message,
      locally_created_at: this.locally_created_at,
      chat_conversation_id: this.chat_conversation_id,
      status: this.status,
      context_ids: this.context_ids,
      context_kinds: this.context_kinds,
    };
  }
}
