import { ContactModelData } from "@/store/contacts/types";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { IndexedBoolean, Maybe } from "@/domains/common/types";
import { AppStore } from "@/store/AppStore";

export type ContactIndexData = {
  space_account_id: string;
  is_direct: IndexedBoolean;
  profile_display_name: string;
  profile_email_address: string;
  profile_display_name_and_email_address: string;
};

export class ContactIndexes {
  private store: AppStore;
  private data: SyncUpdateValue<ContactModelData>;

  constructor({
    store,
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<ContactModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ContactModelData>[];
  }) {
    this.store = store;
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<ContactModelData>) || remoteData;
  }

  get space_account_id() {
    return this.data.model_data.space_account_id;
  }

  get is_direct() {
    const myPersonalSpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const userScope = this.data.model_scopes.find(
      permission =>
        permission.scope_kind === "SPACE_ACCOUNT_SCOPE" &&
        permission.value.space_account_id === myPersonalSpaceAccountId
    );
    return userScope?.role_kind === "DIRECT_CONTACT";
  }

  get profile_display_name() {
    return this.data.model_data.profile_display_name.toLowerCase();
  }

  get profile_email_address() {
    return this.data.model_data.profile_email_address.toLowerCase();
  }

  get profile_display_name_and_email_address() {
    return `${this.profile_display_name} ${this.profile_email_address}`;
  }

  get indexes(): ContactIndexData {
    return {
      space_account_id: this.space_account_id,
      is_direct: this.is_direct ? 1 : 0,
      profile_display_name: this.profile_display_name,
      profile_email_address: this.profile_email_address,
      profile_display_name_and_email_address: this.profile_display_name_and_email_address,
    };
  }
}
