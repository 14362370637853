import styled from "@emotion/styled";
import { MenuItem } from "react-aria-components";
import type { IMDSMenuItemProps, IFigmaMenuItemProps } from "./menu-item.types";

export const StyledMenuItem = styled(MenuItem)<IMDSMenuItemProps>();

export const FigmaStyledMenuItem = styled(StyledMenuItem)<IFigmaMenuItemProps>(
  ({ theme, cursor }) => ({
    // layout
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing.sm,

    // typography
    fontSize: theme.fontSizes.small,
    fontStyle: "normal",
    fontWeight: theme.fontWeights.regular,
    lineHeight: theme.lineHeights.xsmall,

    // styling
    borderRadius: theme.borderRadius.medium,
    cursor: cursor ?? "pointer",
    "&:hover": {
      backgroundColor: theme.colors.grey.x25,
    },
  })
);

export const StyledMenuItemLeft = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.sm,
}));

export const StyledMenuItemRight = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.sm,
}));
