import { observer } from "mobx-react-lite";
import { RelatedCard } from "@/store/routing/types";
import styled from "@emotion/styled";
import { useAppStore } from "@/store/utils/hooks";
import { CopilotVotingButtons } from "@/components/copilot/TopicItemVotingButtons";
import { RefreshTopicsForNoteButton } from "@/components/copilot/RefreshTopicsForNoteButton";
import { TopicItemList } from "@/components/copilot/TopicItemList";

const MAX_ITEMS_TO_SHOW = 3;

interface Props {
  showContents: boolean;
  card: RelatedCard;
  showAllItems: boolean;
}

export const CollapsedRelatedCard = observer<Props>(function Card({
  card,
  showContents,
  showAllItems,
}) {
  const { store, pageStore } = useAppStore();
  const maxItems = showAllItems ? Infinity : MAX_ITEMS_TO_SHOW;
  const numItemsMore = card.items.length - maxItems;
  const rows = card.items.slice(0, maxItems);

  // Make the buttons always visible (so CopilotVotingButtons does not worry about whether it is highlighted),
  // but only show their enclosing container on hover of the title
  return (
    <>
      <CardHeader>
        <TitleContainer>
          <Text>{card.title}</Text>
          {store.debug.debugModeEnabled && !pageStore.copilotView.isRunningPipeline && (
            <RefreshTopicsForNoteButton
              noteId={pageStore.copilotView.activeItem?.id}
              spaceAccountId={store.spaceAccounts.myPersonalSpaceAccountId}
            />
          )}
        </TitleContainer>
        <VotingButtonsWrapper className="voting-buttons-wrapper">
          <CopilotVotingButtons
            itemId={card.id}
            topicItemId={card.id}
            topicId={card.id}
            feedbackClass="topic"
            alwaysVisible={true}
          />
        </VotingButtonsWrapper>
      </CardHeader>
      <CardContents>
        {showContents && <TopicItemList rows={rows} topicId={card.id} />}
        {card.items.length > maxItems && (
          <SeeMoreButton onClick={() => store.sidePanel.goToCopilot(card)}>
            See {numItemsMore} more
          </SeeMoreButton>
        )}
      </CardContents>
    </>
  );
});

const CardHeader = styled.div(({ theme }) => ({
  alignItems: `center`,
  display: `flex`,
  justifyContent: `space-between`,
  gap: theme.spacing.xs,
  padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.xs} ${theme.spacing.xs}`,
  "&:hover .voting-buttons-wrapper": {
    opacity: 1,
  },
}));

const TitleContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing.xs,
  alignItems: "center",
}));

const VotingButtonsWrapper = styled.div`
  opacity: 0;
`;

const Text = styled.span(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.xsmall,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

const SeeMoreButton = styled.button(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
  marginInline: theme.spacing.sm,
  marginTop: theme.spacing.sm,
  padding: 0,
  "&:hover": {
    textDecoration: `underline`,
  },
}));

const CardContents = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing.sm,
}));
