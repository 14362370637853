import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsMediaQueries, mdsSpacings } from "@/design-system/foundations";
import { ChatMessage } from "@/domains/chat";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { api } from "@/modules/api";
import { useCallback, useState } from "react";
import { windowModule } from "@/modules/window";

interface ChatMessageDebugModalModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: ChatMessage;
}

/**
 * A modal that displays debug information about a chat message.
 */
export const ChatMessageDebugModal = observer(
  ({ isOpen, onClose, message }: ChatMessageDebugModalModalProps) => {
    const [viewMessageTraceLoading, setViewMessageTraceLoading] = useState(false);

    /**
     * Yes, this isn't best practice - I've left this in just because this is
     * a debugging tool (and I didn't want to clutter the main store yet.)
     *
     * We can come back and clean it up in the future if needed.
     */
    const handleClickViewMessageTrace = useCallback(async () => {
      setViewMessageTraceLoading(true);

      try {
        const response = await api.get("/v2/chat-messages/{chat_message_id}/trace", {
          params: { path: { chat_message_id: message.id } },
        });

        if (!response.data?.trace_url) {
          throw new Error("No trace available for this message.");
        }

        windowModule.openInNewTab({ url: new URL(response.data.trace_url) });
      } catch (_unknownErr) {
        alert("Unable to load trace available for this message.");
      } finally {
        setViewMessageTraceLoading(false);
      }
    }, [message.id]);

    const messageDebugInfo = {
      id: message.id,
      sections: message.sections,
      context: message.context,
      sources: message.sources,
      isSystemMessage: message.isSystemMessage,
      locallyCreatedAt: message.locallyCreatedAt,
      status: message.status,
    };

    return (
      <MdsModal rootElementId="root" isModalOpen={isOpen} handleCloseModal={onClose}>
        <ModalBodyWrapper>
          <ModalTitlebar>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              Debug Info
            </MdsText>
            <MdsIconButton iconKind={MdsIconKind.Exit} onClick={onClose} />
          </ModalTitlebar>

          <CodeBlock>{JSON.stringify(messageDebugInfo, null, 2)}</CodeBlock>

          <div>
            <MdsButton
              isLoading={viewMessageTraceLoading}
              onClick={handleClickViewMessageTrace}
              variant={MdsButtonVariant.Primary}
              size={MdsButtonSize.Small}
              label="View Message Trace"
            />
          </div>
        </ModalBodyWrapper>
      </MdsModal>
    );
  }
);

const ModalBodyWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  minWidth: "90dvw",
  maxWidth: "90dvw",
  gap: mdsSpacings().md,
  padding: mdsSpacings().md,
  [mdsMediaQueries().rules.tablet]: {
    minWidth: "500px",
  },
  [mdsMediaQueries().rules.desktop]: {
    padding: mdsSpacings().lg,
    minWidth: "650px",
  },
});

const ModalTitlebar = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: mdsSpacings().sm,
});

const CodeBlock = styled.pre({
  backgroundColor: "#f4f4f4",
  padding: "1rem",
  margin: "1rem 0",
  overflow: "auto",
  borderRadius: "5px",
  fontSize: "0.8rem",
  fontFamily: "monospace",
  maxHeight: "400px",
});
