import { DateTime, Interval } from "luxon";

export const isToday = (d: DateTime | string) => {
  const date = typeof d === "string" ? DateTime.fromISO(d) : d;
  return date.hasSame(DateTime.local(), "day");
};

export const isThisYear = (date: DateTime) => {
  return date.hasSame(DateTime.local(), "year");
};

export const generateShortDateString = (d: DateTime | string) => {
  const date = typeof d === "string" ? DateTime.fromISO(d) : d;
  if (!date.isValid) {
    return "";
  }
  if (isToday(date)) {
    return date.toFormat("h:mm a");
  }
  if (isThisYear(date)) {
    return date.toFormat("ccc M/d");
  }
  return date.toFormat("MM/dd/yy");
};

export const formatDateString = (d: DateTime | string, format: string) => {
  const date = typeof d === "string" ? DateTime.fromISO(d) : d;
  if (!date.isValid) {
    return "";
  }
  return date.toFormat(format);
};

type TGenerateRecentDateStringOptions = {
  skipFullDayName?: boolean;
};

export const generateRecentDateString = (
  v: DateTime | string,
  { skipFullDayName }: TGenerateRecentDateStringOptions = { skipFullDayName: false }
) => {
  const date = typeof v === "string" ? DateTime.fromISO(v) : v;
  if (!date.isValid) {
    return "";
  }

  const now = DateTime.local().endOf("day");
  const dateDiff = Interval.fromDateTimes(date, now).length("days");

  let dateString = "";
  if (dateDiff < 1) {
    dateString = "Today";
  } else if (dateDiff < 2) {
    dateString = "Yesterday";
  } else if (dateDiff < 7 && !skipFullDayName) {
    dateString = date.toFormat("cccc"); // Full day name
  } else if (date.hasSame(now, "year")) {
    dateString = date.toFormat("MMM d");
  } else {
    dateString = date.toFormat("M/d/yy");
  }

  return dateString.charAt(0).toUpperCase() + dateString.slice(1);
};

export const generateRelativePrepositionAdverbialDateString = (date: string) => {
  const recentDateString = generateRecentDateString(date);
  return isToday(date)
    ? `today at ${generateShortDateString(date)}`
    : recentDateString === "Yesterday"
      ? "yesterday"
      : `on ${recentDateString}`;
};

export const ZERO_DATE_STRING = "0000-00-00T00:00:00.000Z";
