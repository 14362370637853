import {
  GOOGLE_IDENTITY_OAUTH_URL,
  GOOGLE_IDENTITY_WEB_CLIENT_ID,
} from "@/domains/google-identity/constants";
import { windowModule } from "@/modules/window";

/**
 * Right now, this just does the exact same thing as sign-in.
 * @todo - Determine whether we need to change this?
 *
 * Details about the oauth flow can be found here:
 * - https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow
 */
export const googleIdentityTriggerSignUp = async () => {
  const redirectUrl = windowModule.getGoogleIdentityOAuthRedirectUrl();

  const params = new URLSearchParams({
    client_id: GOOGLE_IDENTITY_WEB_CLIENT_ID,
    response_type: "code",
    scope: "email profile",
    access_type: "offline",
    redirect_uri: redirectUrl,
    prompt: "select_account",
  });

  const logInUrlPath = GOOGLE_IDENTITY_OAUTH_URL + `?${params}`;
  const logInUrl = new URL(logInUrlPath);

  /**
   * Note that this redirects the user to the google log-in page.
   * (Active state in the app will be lost, but recovered on re-direct.)
   */
  await windowModule.navigateToExternal({
    url: logInUrl,
  });
};
