import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import { NoteObservable } from "@/store/note";
import { NoteModelData, NoteUpsertedSyncUpdateValue } from "@/store/note/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { ITrashNoteOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, OptimisticSyncUpdate } from "@/store/sync/types";
import { getLoggableOperation } from "@/store/sync/utils";

export class TrashNoteOperation extends BaseSyncOperation<ITrashNoteOperation> {
  private existingModel?: NoteObservable;

  get operationKind(): SyncOperationKind {
    return "TRASH_NOTE";
  }

  generateOptimisticUpdates() {
    const existingNote = this.existingModel;
    if (!existingNote) return [];

    const value: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "NOTE",
      model_version: existingNote.modelVersion,
      model_data: {
        ...existingNote.modelData,
        trashed_at: this.committedAt,
      },
      model_scopes: existingNote.modelScopes,
    };
    const syncUpdate: OptimisticSyncUpdate<NoteModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  async execute() {
    this.existingModel = await this.store.notes.getAsync(this.payload.id);
    if (!this.existingModel) {
      logger.error({
        message: "can't find existing note",
        info: {
          operation: getLoggableOperation(this),
        },
      });
    }

    await super.execute();
  }

  async triggerRecompute(): Promise<void> {
    await this.store.notes.recompute(this.payload.id);
  }

  get successToastMessage() {
    if (!this.store.notes.get(this.payload.id)?.isOwnedByMe || !this.triggerSuccessToast) {
      return null;
    }
    return (
      <>
        {this.mediumTitle} moved to the{" "}
        <a onClick={() => this.store.navigation.goToTrash()}>Trash</a>
      </>
    );
  }

  get title() {
    return this.store.notes.get(this.payload.id)?.title || UNTITLED_NOTE_TITLE;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be moved to the trash. If this error continues, please contact
        support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to move {this.mediumTitle} to the trash. Try removing the note
        instead.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be moved to the trash. If this error continues, please contact
        support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
