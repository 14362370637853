import { memo, FC } from "react";
import { APP_CONFIG } from "@/domains/global/config";
import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { MdsButton, MdsButtonSize } from "@/design-system/components/button";
import { MdsButtonShape, MdsButtonVariant } from "@/design-system/components/button/types";
import { windowModule } from "@/modules/window";

const logInCardStyles = css({
  a: {
    display: "inline-block",
    fontWeight: 700,
    color: "#444",
    fontSize: 14,
    transition: "transform 0.2s, color 0.2s",

    "&:hover": {
      transform: "scale(1.05)",
      color: "#f22e61",
    },
  },
});

interface AuthFooterProps extends EmotionClassStyles {}

export const AuthFooter: FC<AuthFooterProps> = memo(({ className }) => {
  const onClick = () => {
    windowModule.openInNewTab({ url: new URL(APP_CONFIG.URLS.LANDING_PAGE) });
  };

  return (
    <div className={cx(logInCardStyles, className)}>
      <MdsButton
        label="Learn more about Mem"
        variant={MdsButtonVariant.TextTertiary}
        size={MdsButtonSize.Large}
        shape={MdsButtonShape.Round}
        onClick={onClick}
      />
    </div>
  );
});
