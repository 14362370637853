import { copyTextToClipboard } from "@/modules/clipboard/copyTextToClipboard";
import { toastModule } from "@/modules/toast";
import { ReactNode } from "react";

export const copyTextToClipboardWithToast = async ({
  text,
  toastContent,
}: {
  text: string;
  toastContent: ReactNode;
}) => {
  await copyTextToClipboard({
    text,
  });
  toastModule.triggerToast({ content: toastContent });
};
