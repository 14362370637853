import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { INoteObservable } from "@/store/note/types";

export const restoreNotesFromTrash = async ({
  notes,
  store,
}: {
  notes: INoteObservable[];
  store: AppStore;
}) => {
  if (!notes.length) return;

  await Promise.all(
    notes.map(note => note.restoreFromTrash({ triggerSuccessToast: notes.length === 1 }))
  );

  if (notes.length === 1) return;

  toastModule.triggerToast({
    content: (
      <div>
        Note{notes.length > 1 ? "s" : ""} restored from{" "}
        <a onClick={() => store.navigation.goToTrash()}>Trash</a>
        {"."}
      </div>
    ),
  });
};
