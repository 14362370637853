import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsCarouselItem,
  MdsItem,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { OnClick } from "@/design-system/constants/handlers/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { ReactNode } from "react";

export enum MdsItemListRowType {
  Skeleton = "Skeleton",
  Carousel = "Carousel",
  Item = "Item",
  ActionButton = "ActionButton",
  SectionHeader = "SectionHeader",
  Padding = "Padding",
  AsyncNote = "AsyncNote",
  AsyncCollection = "AsyncCollection",
}

interface IMdsItemListRowData {
  type: MdsItemListRowType;
  key: string;
  payload: { [key: string]: unknown };
}

export interface MdsItemListAsyncNoteRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.AsyncNote;
  size?: MdsItemListSize;
  payload: {
    noteId: string;
    itemRow: MdsItemListItemRowData | ((note: INoteObservable) => MdsItemListItemRowData);
  };
}

export interface MdsItemListAsyncCollectionRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.AsyncCollection;
  size?: MdsItemListSize;
  payload: {
    collectionId: string;
    itemRow:
      | MdsItemListItemRowData
      | ((collection: CollectionObservable) => MdsItemListItemRowData);
  };
}

export interface MdsItemListSkeletonRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.Skeleton;
  size?: MdsItemListSize;
}

export interface MdsItemListCarouselRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.Carousel;
  payload: {
    items: MdsCarouselItem[];
  };
}

export interface MdsItemListItemRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.Item;
  payload: MdsItem;
  size?: MdsItemListSize;
  mustClickOnRowTitle?: boolean;
}

export interface MdsItemListActionButtonRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.ActionButton;
  payload: {
    label: string;
    onClick: OnClick;
    icon: MdsIconKind;
    listState?: ListStateObservable;
  };
}

export interface MdsItemListSectionHeaderRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.SectionHeader;
  payload: {
    title: string | { primary: string; secondary: string };
    description?: ReactNode | string;
    icon?: ReactNode;
  };
}

export interface MdsItemListPaddingRowData extends IMdsItemListRowData {
  type: MdsItemListRowType.Padding;
  payload: {
    height: number;
  };
}

export type MdsItemListRowData =
  | MdsItemListSkeletonRowData
  | MdsItemListCarouselRowData
  | MdsItemListItemRowData
  | MdsItemListActionButtonRowData
  | MdsItemListSectionHeaderRowData
  | MdsItemListPaddingRowData
  | MdsItemListAsyncNoteRowData
  | MdsItemListAsyncCollectionRowData;
