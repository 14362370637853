import { Uuid } from "@/domains/global/identifiers";
import { SyncModelScopeSpaceAccount } from "@/domains/sync-scopes/types";
import { AppStore, GuestAppStore } from "@/store";
import { getOperationKindToClassMap } from "@/store/sync/operations/map";
import { SerializedSyncOperation, SyncOperationGeneric } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SerializedOptimisticUpdate,
  SyncModelData,
} from "@/store/sync/types";
import cloneDeep from "lodash-es/cloneDeep";

export function generateDefaultOwnerScopes({
  store,
}: {
  store: AppStore;
}): SyncModelScopeSpaceAccount {
  return {
    role_kind: "OWNER",
    scope_kind: "SPACE_ACCOUNT_SCOPE",
    value: {
      space_account_id: store.spaceAccounts.myPersonalSpaceAccountId,
    },
  };
}

export function serializeSyncOperation(operation: SyncOperationGeneric): SerializedSyncOperation {
  const indexedOperation: SerializedSyncOperation = {
    operationId: operation.id,
    committedAt: operation.committedAt,
    payload: operation.payload,
    operationKind: operation.operationKind,
    latestSpaceAccountSequenceId: operation.latestSpaceAccountSequenceId ?? null,
    modelId: "id" in operation.payload ? operation.payload.id : null,
    collectionId: "collection_id" in operation.payload ? operation.payload.collection_id : null,
    noteId: "note_id" in operation.payload ? operation.payload.note_id : null,
  };
  return indexedOperation;
}

export function deserializeSyncOperation(
  store: AppStore | GuestAppStore,
  data: SerializedSyncOperation
): SyncOperationGeneric {
  const operationKind = data.operationKind;
  const OperationClass = getOperationKindToClassMap()[operationKind];

  if (!OperationClass) {
    throw new Error(`[deserializeSyncOperation] Unknown operationKind=${operationKind}`);
  }

  return new OperationClass({
    store,
    payload: data.payload,
    operationId: data.operationId,
    committedAt: data.committedAt,
    latestSpaceAccountSequenceId: data.latestSpaceAccountSequenceId,
  });
}

export function serializeOptimisticUpdate<ModelData extends SyncModelData>(
  update: OptimisticSyncUpdate<ModelData>,
  syncOperationId: Uuid
): SerializedOptimisticUpdate<ModelData> {
  return {
    ...cloneDeep(update),
    model_id: update.value.model_id,
    model_kind: update.value.model_kind,
    sync_operation_id: syncOperationId,
  };
}

export function deserializeOptimisticUpdate<ModelData extends SyncModelData>(
  data: SerializedOptimisticUpdate<ModelData>
): OptimisticSyncUpdate<ModelData> {
  const { model_id: _, model_kind: __, ...rest } = data;
  return rest;
}
