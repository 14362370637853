import { Uuid } from "@/domains/global/identifiers";
import { ChatConversationModelData } from "@/store/chat/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { computed, makeObservable, observable } from "mobx";

export class ChatConversationObservable extends BaseSyncModel<ChatConversationModelData> {
  public modelKind = SyncModelKind.ChatConversation;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<ChatConversationModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: observable,
      ownedBySpaceAccountId: computed,
      isPrimaryChatConversation: computed,
    });
  }

  get ownedBySpaceAccountId() {
    return this.modelData.owned_by_space_account_id;
  }

  get isPrimaryChatConversation() {
    return this.modelData.is_primary_chat_conversation;
  }
}
