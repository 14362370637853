import { BackendApiClientType } from "@/manager/backend-api-client/types";
import { BackendApiErrorKind } from "@/modules/api/types";
import { validationModule } from "@/modules/validation";

/**
 * A basic type for functions that need to work with an API response object.
 */
export type BackendApiResponse = {
  data?: unknown;
  error?: unknown;
  response?: Awaited<ReturnType<BackendApiClientType["GET"]>>["response"];
};

export const responseMatchesBackendApiErrorKind = ({
  response,
  errorKind,
}: {
  response: BackendApiResponse;
  errorKind: BackendApiErrorKind;
}) => {
  if (!validationModule.objectHasKey(response, "error")) {
    return false;
  }

  const errorInfo = response.error as unknown;

  if (!validationModule.objectHasKey(errorInfo, "error_metadata")) {
    return false;
  }

  const errorMetadata = errorInfo.error_metadata;

  if (!validationModule.objectHasKey(errorMetadata, "error_kind")) {
    return false;
  }

  return errorMetadata.error_kind === errorKind;
};
