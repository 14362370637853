import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { DeleteSharedNotesModal } from "@/components/modal/delete-shared-notes/DeleteSharedNotesModal";
import { AddToCollectionModal } from "@/components/modal/add-to-collection/AddToCollectionModal";
import { FadeBottom } from "@/components/horizontal-scroll/FadeBottom";
import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { HomePageNotesList } from "./components/NotesListPageList";
import { NotesListPageHeader } from "./components/NotesListPageHeader";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { LoadingOverlay } from "@/components/overlay";
import { TrackedEvent } from "@/domains/metrics";
import { trackEvent } from "@/domains/metrics";

interface NotesListPageProps {}

export const NotesListPage: FC<NotesListPageProps> = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.notesListPage;
  page.listState.useListStateEffects();

  /**
   * Track views of the notes list page.
   */
  useEffect(() => {
    trackEvent(TrackedEvent.NotesView, {
      lens: page.lens,
      sort_by: page.sortBy,
    });
  }, [page.lens, page.sortBy]);

  return (
    <>
      <LoadingOverlay isVisible={page.isLoading} animateIn={false} />
      <AddToCollectionModal store={page.addToCollectionModal} />
      <DeleteSharedNotesModal store={page.deleteSharedNotesModal} />
      <ShareSheetModal store={page.shareSheetModal} />
      <NotesListPageHeader />
      <MdsPanelContainer>
        <HomePageNotesList />
        <FadeBottom />
      </MdsPanelContainer>
    </>
  );
});
