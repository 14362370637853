import { observer } from "mobx-react-lite";
import { toastModule } from "@/modules/toast";
import { api } from "@/modules/api";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";

interface RefreshTopicsForNoteButtonProps {
  noteId: string | undefined;
  spaceAccountId: string;
}

export const RefreshTopicsForNoteButton = observer<RefreshTopicsForNoteButtonProps>(
  function RefreshTopicsForNoteButton({ noteId, spaceAccountId }) {
    async function submitRefreshRequest(noteId: string, spaceAccountId: string) {
      try {
        const response = await api.post(`/v2/related-notes/run-topic-generation-for-note`, {
          body: {
            space_account_id: spaceAccountId,
            note_id: noteId,
          },
        });

        if (response.error) {
          toastModule.triggerToast({
            content: `Failed refresh topics`,
          });
        } else {
          toastModule.triggerToast({
            content: `Topics are being refreshed. This may take a few seconds.`,
          });
        }
      } catch (_error) {
        toastModule.triggerToast({
          content: `Failed to refresh topics`,
        });
      }
    }

    return (
      <MdsIconButton
        onClick={() => (noteId ? submitRefreshRequest(noteId, spaceAccountId) : null)}
        iconKind={MdsIconKind.Redo}
        tooltipConfig={{
          content: "Refresh Topics for this note. Note: This will overwrite any existing topics.",
          placement: MdsTooltipPlacement.Left,
        }}
      />
    );
  }
);
