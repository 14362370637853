import { cacheClientManager } from "@/manager/cache-client";
import { CacheClient } from "@/manager/cache-client/CacheClient";
import { getApiAuthIdentifier } from "@/modules/api/authUtils";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";

/**
 * These are factory functions that we unwrap into a global cache object.
 */
type GlobalCache = {
  get: ReturnType<CacheClient["get"]>;
  set: ReturnType<CacheClient["set"]>;
  del: ReturnType<CacheClient["del"]>;
  clearAll: CacheClient["clearAll"];
};

/**
 * Scopes the cache to the current user.
 */
export const GLOBAL_CACHE = {
  get: async cacheKey => {
    const scopeId = await getApiAuthIdentifier();

    if (!scopeId) {
      logger.warn({ message: "[GLOBAL_CACHE.get] No scopeId found", info: { cacheKey } });

      return null;
    }

    return cacheClientManager.instance().get(scopeId)(cacheKey);
  },
  set: async (cacheKey, payload) => {
    const scopeId = await getApiAuthIdentifier();

    if (!scopeId) {
      logger.warn({
        message: "[GLOBAL_CACHE.set] No scopeId found",
        info: { cacheKey, payload: objectModule.safeAsJson({ payload }) },
      });

      return false;
    }

    return cacheClientManager.instance().set(scopeId)(cacheKey, payload);
  },
  del: async cacheKey => {
    const scopeId = await getApiAuthIdentifier();

    if (!scopeId) {
      logger.warn({ message: "[GLOBAL_CACHE.del] No scopeId found", info: { cacheKey } });

      return false;
    }

    return cacheClientManager.instance().del(scopeId)(cacheKey);
  },
  clearAll: async () => {
    return cacheClientManager.instance().clearAll();
  },
} as GlobalCache;
