import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { NoteObservable } from "@/store/note/NoteObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { SpaceAccountTopicItemObservable } from "@/store/topics/SpaceAccountTopicItemObservable";
import { SpaceAccountTopicModelData } from "@/store/topics/types";
import { WithAppStore } from "@/store/types";
import Dexie, { liveQuery } from "dexie";
import {
  computed,
  makeObservable,
  observable,
  runInAction,
  onBecomeObserved,
  onBecomeUnobserved,
  action,
} from "mobx";
import { Subscription } from "node_modules/react-hook-form/dist/utils/createSubject";

type SpaceAccountTopicItemTuple = [
  space_account_topic_id: Uuid,
  item_id: Uuid,
  space_account_topic_item_id: Uuid,
];

export class SpaceAccountTopicObservable extends BaseSyncModel<SpaceAccountTopicModelData> {
  public modelKind = SyncModelKind.SpaceAccountTopic;

  liveQuerySubscription: Maybe<Subscription>;
  subscribedSpaceAccountTopicItems: SpaceAccountTopicItemTuple[] = [];

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountTopicModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: observable,

      unsubscribeQuery: action,
      initializeQuery: action,
      liveQuerySubscription: observable,
      subscribedSpaceAccountTopicItems: observable,
      allTopicItems: computed,
      allItems: computed,

      title: computed,
      description: computed,
      kind: computed,
      metadata: computed,
    });

    onBecomeObserved(this, "allTopicItems", () => this.initializeQuery());
    onBecomeUnobserved(this, "allTopicItems", () => this.unsubscribeQuery());
    onBecomeObserved(this, "allItems", () => this.initializeQuery());
    onBecomeUnobserved(this, "allItems", () => this.unsubscribeQuery());
  }

  initializeQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.store.spaceAccountTopicItems.localTable
        .where("[space_account_topic_id+item_id+model_id]")
        .between([this.id, Dexie.minKey, Dexie.minKey], [this.id, Dexie.maxKey, Dexie.maxKey])
        .keys();
    }).subscribe({
      next: rows => {
        runInAction(() => {
          this.subscribedSpaceAccountTopicItems = rows as unknown as SpaceAccountTopicItemTuple[];
        });
      },
    });
  }

  unsubscribeQuery() {
    this.liveQuerySubscription?.unsubscribe();
  }

  public get title(): string {
    return this.modelData.title;
  }

  public get description(): string {
    return this.modelData.description;
  }

  public get kind(): string | null {
    return this.modelData.kind;
  }

  public get metadata(): Record<string, string> | null {
    return this.modelData.metadata;
  }

  public get allTopicItems(): SpaceAccountTopicItemObservable[] {
    return this.subscribedSpaceAccountTopicItems
      .map(([_space_account_topic_id, _item_id, space_account_topic_item_id]) =>
        this.store.spaceAccountTopicItems.get(space_account_topic_item_id)
      )
      .filter(item => !!item);
  }

  public get allItems(): NoteObservable[] {
    return this.subscribedSpaceAccountTopicItems
      .map(([_space_account_topic_id, item_id, _model_id]) => this.store.notes.get(item_id))
      .filter(item => !!item);
  }
}
