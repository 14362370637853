import { ApiSchema } from "@/modules/api";
import { ChatMessageSyncModelContext, ChatMessageSyncModelContextValue } from "@/store/chat/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateContextValue = <T extends Record<string, any>>(
  value: unknown,
  requiredFields: (keyof T)[]
): T => {
  if (typeof value !== "object" || value === null) {
    throw new Error("Context value must be an object");
  }

  for (const field of requiredFields) {
    if (!(field in value)) {
      throw new Error(`${String(field)} is required`);
    }
  }

  return value as T;
};

export function validateChatMessageContext(
  chatMessageId: string,
  context: ApiSchema["SubmitChatMessageSyncOperationChatMessageContextValue"]
): ChatMessageSyncModelContext {
  let value: ChatMessageSyncModelContextValue;

  switch (context.kind) {
    case "GLOBAL":
      value = { kind: "GLOBAL", schema_version: 1 };
      break;
    case "COLLECTION_DETAIL_VIEW":
      value = {
        kind: "COLLECTION_DETAIL_VIEW",
        schema_version: 1,
        collection_id: validateContextValue<{ collection_id: string }>(context.value, [
          "collection_id",
        ]).collection_id,
      };
      break;
    case "COLLECTION_LIST_VIEW":
      value = {
        kind: "COLLECTION_LIST_VIEW",
        schema_version: 1,
        collection_ids: validateContextValue<{ collection_ids: string[] }>(context.value, [
          "collection_ids",
        ]).collection_ids,
      };
      break;
    case "NOTE_DETAIL_VIEW":
      value = {
        kind: "NOTE_DETAIL_VIEW",
        schema_version: 1,
        note_id: validateContextValue<{ note_id: string }>(context.value, ["note_id"]).note_id,
      };
      break;
    case "NOTE_LIST_VIEW":
      value = {
        kind: "NOTE_LIST_VIEW",
        schema_version: 1,
        note_ids: validateContextValue<{ note_ids: string[] }>(context.value, ["note_ids"])
          .note_ids,
      };
      break;
    case "SEARCH_VIEW":
      value = {
        kind: "SEARCH_VIEW",
        ...validateContextValue<{
          note_ids: string[];
          collection_ids: string[];
          queryString: string;
        }>(context.value, ["note_ids", "collection_ids", "queryString"]),
        schema_version: 1,
      };
      break;
    default:
      throw new Error(`Invalid context kind: ${(context as { kind: string }).kind}`);
  }

  return {
    ...context,
    chat_message_id: chatMessageId,
    started_at: context.started_at ?? null,
    value,
  };
}
