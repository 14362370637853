import { observer } from "mobx-react-lite";
import { ALL_APP_ROUTES } from "@/app/router/routes";
import { FC, useRef } from "react";

import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom";
import { clientEnvModule } from "@/modules/client-env";
import { logger } from "@/modules/logger";
import { windowModule } from "@/modules/window";

interface AppRouterProps {}

export const AppRouter: FC<AppRouterProps> = observer(() => {
  /**
   * Using a ref here just to ensure that our router never changes.
   */
  const appRouterRef = useRef(
    (() => {
      const isDesktop = clientEnvModule.isDesktop();

      /**
       * In the desktop environment, we use a HashRouter instead of BrowserRouter.
       */
      if (isDesktop) {
        return createHashRouter(ALL_APP_ROUTES);
      }

      return createBrowserRouter(ALL_APP_ROUTES);
    })()
  );

  return <RouterProvider router={appRouterRef.current} />;
});

/**
 * If we have modified the router directly, we just want to force a hard refresh of the page.
 * (Instead of allowing the router to be hot-reloaded).
 */
if (import.meta.hot) {
  /**
   * This is a set of modules which we want to force a hard-refresh on.
   */
  import.meta.hot.accept(["./routes"], newModule => {
    if (newModule) {
      logger.debug({
        message: "[HMR] Stores modified - forcing page reload...",
      });

      windowModule.forceReload();
    }
  });
}
