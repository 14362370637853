import { WithAppStore } from "@/store/types";
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  override,
  reaction,
  runInAction,
} from "mobx";
import { IndexedNoteSyncUpdateValue, INoteObservable, NoteModelData } from "@/store/note/types";
import { Uuid } from "@/domains/global/identifiers";
import { SyncModelKind } from "@/store/sync/types";
import {
  GrantableSyncScopeRoleKind,
  SyncModelPermissionEntryWithStatus,
} from "@/domains/sync-scopes/types";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { filter, uniq } from "lodash-es";
import { NoteCollectionListObservable } from "@/store/collection-items/NoteCollectionListObservable";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";
import { SpaceAccountNoteObservable } from "@/store/recent-items/SpaceAccountNoteObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { IContactModel } from "@/store/contacts/types";
import { getQueuedDocumentUpdates } from "@/store/sync/operations/helpers/notes/getQueuedDocumentUpdates";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { DeleteNoteOperation } from "@/store/sync/operations/notes/DeleteNoteOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { RestoreNoteOperation } from "@/store/sync/operations/notes/RestoreNoteOperation";
import { AddNoteToFavoritesOperation } from "@/store/sync/operations/favorites/AddNoteToFavoritesOperation";
import { RemoveNoteFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveNoteFromFavoritesOperation";
import { MarkNoteViewedOperation } from "@/store/sync/operations/recents/MarkNoteViewedOperation";
import { GrantNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/GrantNoteAclViaSpaceAccountOperation";
import { GrantNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/GrantNoteAclViaEmailAddressOperation";
import { UpdateNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaSpaceAccountOperation";
import { UpdateNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaEmailAddressOperation";
import { UpdateNoteAclViaCollectionOperation } from "@/store/sync/operations/notes/UpdateNoteAclViaCollectionOperation";
import { RevokeNoteAclViaSpaceAccountOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaSpaceAccountOperation";
import { RevokeNoteAclViaEmailAddressOperation } from "@/store/sync/operations/notes/RevokeNoteAclViaEmailAddressOperation";
import { logger } from "@/modules/logger";
import { getPermissionsForNoteSyncModel } from "@/store/sync/operations/helpers/permissions/getPermissionsForModel";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { NoteContentDocumentObservable } from "@/store/note/NoteContentDocumentObservable";
import {
  AsyncData,
  asyncDataFailed,
  asyncDataLoaded,
  asyncDataLoading,
} from "@/domains/async/AsyncData";
import { appRoutes } from "@/app/router";
import { notesModule } from "@/modules/notes";
import { uuidModule } from "@/modules/uuid";
import { NoteTopicListObservable } from "@/store/topics/NoteTopicListObservable";
import {
  MemCommonEditorContext,
  MemCommonEditorFileInfo,
  MemCommonEditorImageInfo,
} from "@mem-labs/common-editor";
import { UploadImageExternalOperation } from "@/store/sync/operations/external/UploadImageExternalOperation";
import { UploadFileExternalOperation } from "@/store/sync/operations/external/UploadFileExternalOperation";
import { liveQuery } from "dexie";
import { ExternalOperationKind } from "@/store/sync/operations/types";
import { EventContext } from "@/domains/metrics/context";

export class NoteObservable extends BaseSyncModel<NoteModelData> implements INoteObservable {
  public collectionList: NoteCollectionListObservable;
  public noteContentDocument: AsyncData<NoteContentDocumentObservable> = asyncDataLoading({});
  public spaceAccountTopics: NoteTopicListObservable;
  public modelKind = SyncModelKind.Note;

  // INDEXES
  public createdAt: string;
  public modifiedAt: string;
  public trashedAt: string | null;
  public lastViewedAt: string;
  public isTrashed: boolean;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: IndexedNoteSyncUpdateValue;
  } & WithAppStore) {
    super({ id, data, store });
    this.collectionList = new NoteCollectionListObservable({ noteId: id, store });
    this.spaceAccountTopics = new NoteTopicListObservable({ noteId: id, store });

    this.createdAt = data.created_at;
    this.modifiedAt = data.modified_at;
    this.trashedAt = data.trashed_at || null;
    this.lastViewedAt = data.last_viewed_at;
    this.isTrashed = data.is_trashed === 1;

    makeObservable(this, {
      // INDEXES
      createdAt: observable,
      modifiedAt: observable,
      trashedAt: observable,
      lastViewedAt: observable,
      isTrashed: observable,

      subscribeToLocal: override,
      modelKind: observable,
      // OBSERVABLES
      noteContentDocument: observable,
      collectionList: observable,
      spaceAccountTopics: observable,

      // OVERRIDES
      isAvailable: override,
      isShared: override,
      permissions: override,

      // PROPERTIES
      receivedAt: computed,
      lastMentionedAt: computed,
      title: computed,
      secondaryTitle: computed,
      path: computed,
      primaryOwner: computed,
      authors: computed,
      metadata: computed,

      // NOTE CONTENT
      noteContentDocumentId: computed,
      isNoteContentDocumentLoaded: computed,
      isNoteContentDocumentValid: computed,
      fetchNoteContentDocument: action,
      remoteContent: computed,
      isNoteContentInOriginalState: computed,
      queuedDocumentUpdates: computed,

      // FAVORITES
      favoriteItemId: computed,
      favoriteItem: computed,
      isFavorited: computed,

      // SPACE ACCOUNT
      spaceAccountNoteId: computed,
      spaceAccountNote: computed,

      // ACTIONS
      updateContentUsingDiff: false,
      saveAsNewNote: action,
      delete: action,
      deleteEmptyNote: action,
      moveToTrash: action,
      restoreFromTrash: action,
      grantAccessViaSpaceAccount: action,
      grantAccessViaEmailAddress: action,
      updateAccessViaSpaceAccount: action,
      updateAccessViaEmailAddress: action,
      updateAccessViaCollection: action,
      revokeAccessViaSpaceAccount: action,
      revokeAccessViaEmailAddress: action,
      toggleFavorite: action,
      addToRecents: action,
      uploadImageAssociatedWithNote: action,
      uploadFileAssociatedWithNote: action,
      generateCommonEditorContext: action,
    });

    onBecomeObserved(this, "isNoteContentDocumentLoaded", () => {
      this.fetchNoteContentDocument();
    });

    reaction(
      () => this.store.noteContentDocuments.get(this.noteContentDocumentId),
      (noteContentDocument, prevNoteContentDocument) => {
        if (noteContentDocument && prevNoteContentDocument != noteContentDocument) {
          this.noteContentDocument = asyncDataLoaded(this.noteContentDocument, noteContentDocument);
        }
      }
    );

    reaction(
      () => ({
        isNoteContentDocumentValid: this.isNoteContentDocumentValid,
        isNoteContentDocumentLoading: this.noteContentDocument.isLoading,
      }),
      ({ isNoteContentDocumentValid, isNoteContentDocumentLoading }) => {
        if (isNoteContentDocumentLoading) return;
        if (!isNoteContentDocumentValid) {
          logger.error({
            message:
              "[SYNC][NoteObservable][deleteEmptyNote] Note content document is invalid. Trying to refetch...",
            info: { noteId: this.id, noteContentDocumentId: this.noteContentDocumentId },
          });
          this.store.noteContentDocuments.debouncedFetch(this.noteContentDocumentId);
        }
      },
      { fireImmediately: true }
    );
  }

  subscribeToLocal() {
    this.localDataSubscription?.unsubscribe();
    this.localDataSubscription = liveQuery(() =>
      this.store.notes.localTable.get(this.id)
    ).subscribe({
      next: data => {
        if (data)
          runInAction(() => {
            this.data = data;
            this.createdAt = data.created_at;
            this.modifiedAt = data.modified_at;
            this.trashedAt = data.trashed_at || null;
            this.lastViewedAt = data.last_viewed_at;
            this.isTrashed = data.is_trashed === 1;
          });
      },
    });
  }

  // PROPERTIES
  // TODO: Implement proper received_at when ready
  get receivedAt(): string | null | undefined {
    return this.modelData.created_at;
  }

  get lastMentionedAt(): string | undefined {
    return undefined;
  }

  get isShared(): boolean {
    const output = super.isShared;
    for (const collection of this.collections) if (collection.isShared) return true;
    return output;
  }

  get isAvailable(): boolean {
    return !this.isDeleted && this.canAccess && !this.isTrashed;
  }

  get permissions(): SyncModelPermissionEntryWithStatus[] {
    return getPermissionsForNoteSyncModel({
      id: this.id,
      remoteData: this.remoteData,
      store: this.store,
      actionQueue: this.store.sync.actionQueue,
    });
  }

  get title(): string {
    return this.modelData.primary_label || UNTITLED_NOTE_TITLE;
  }

  get secondaryTitle(): string {
    return this.modelData.secondary_label || "";
  }

  get path(): string {
    return appRoutes.notesView({ params: { noteId: this.id } }).path;
  }

  get primaryOwner(): IContactModel | undefined {
    const spaceAccountId = this.data.model_data.owned_by_space_account_id;
    const spaceAccount =
      spaceAccountId === this.store.spaceAccounts.myPersonalSpaceAccountId
        ? this.store.spaceAccounts.myPersonalSpaceAccount
        : this.store.contacts.getBySpaceAccountId(spaceAccountId);
    return spaceAccount;
  }

  get authors(): IContactModel[] {
    const spaceAccountIds = uniq([
      this.data.model_data.owned_by_space_account_id,
      ...this.data.model_data.modified_by_space_account_ids,
    ]);
    const getContactObservableByContactSpaceAccountId = (spaceAccountId: string) => {
      if (spaceAccountId === this.store.spaceAccounts.myPersonalSpaceAccountId) {
        return this.store.spaceAccounts.myPersonalSpaceAccount;
      }
      return this.store.contacts.getBySpaceAccountId(spaceAccountId);
    };
    const contacts = spaceAccountIds.map(getContactObservableByContactSpaceAccountId);
    return filter(contacts) as IContactModel[];
  }

  get metadata(): string {
    // TODO: implement
    return "";
  }

  // NOTE CONTENT
  get noteContentDocumentId(): Uuid {
    return resolveNoteContentDocumentSyncModelUuid({ noteId: this.id });
  }

  get isNoteContentDocumentLoaded(): boolean {
    return !this.noteContentDocument.isLoading && this.isNoteContentDocumentValid;
  }

  get isNoteContentDocumentValid(): boolean {
    return (
      !!this.noteContentDocument.data &&
      (this.noteContentDocument.data.modelVersion <= 1 ||
        // After we add any content to a y.js doc it's never going to be empty again,
        // even if the content is removed. So empty encoded_content is invalid.
        !!this.noteContentDocument.data.modelData?.encoded_content)
    );
  }

  async fetchNoteContentDocument() {
    if (this.isNoteContentDocumentLoaded) return;

    const noteContentDocument = await this.store.noteContentDocuments.getAsync(
      this.noteContentDocumentId
    );

    if (noteContentDocument) {
      runInAction(() => {
        this.noteContentDocument = asyncDataLoaded(this.noteContentDocument, noteContentDocument);
      });
      return;
    }

    runInAction(() => {
      this.noteContentDocument = asyncDataLoading(this.noteContentDocument);
    });

    const fetchedNoteContentDocument = await this.store.noteContentDocuments.fetch(
      this.noteContentDocumentId
    );

    runInAction(() => {
      this.noteContentDocument = fetchedNoteContentDocument
        ? asyncDataLoaded(this.noteContentDocument, fetchedNoteContentDocument)
        : asyncDataFailed(this.noteContentDocument);
    });
  }

  get remoteContent(): string | null {
    return this.noteContentDocument.data?.remoteContent || null;
  }

  get isNoteContentInOriginalState() {
    // Not in original state if there are things in the queue
    const queuedDocumentUpdates = this.queuedDocumentUpdates;
    if (queuedDocumentUpdates.length > 0) return false;

    // Versions:
    // 0: Created locally.
    // 1: Synced to server.
    // 2+: Content updates.
    return this.modelVersion <= 1;
  }

  get queuedDocumentUpdates() {
    return getQueuedDocumentUpdates({
      operationsByModelId: this.store.sync.actionQueue.operationsByModelId,
      id: this.id,
    });
  }

  // FAVORITES
  get favoriteItemId(): Uuid {
    return resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.id,
    });
  }

  get favoriteItem(): FavoriteItemObservable | undefined {
    return this.store.favoriteItems.get(this.favoriteItemId);
  }

  get isFavorited(): boolean {
    return this.store.favoriteItems.has(this.favoriteItemId);
  }

  // SPACE ACCOUNT NOTE
  get spaceAccountNoteId(): Uuid {
    return resolveSpaceAccountNoteSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      noteId: this.id,
    });
  }

  get spaceAccountNote(): SpaceAccountNoteObservable | undefined {
    return this.store.spaceAccountNotes.get(this.spaceAccountNoteId);
  }

  // ACTIONS
  public async updateContentUsingDiff({
    encodedContentDiff,
    primaryLabel,
    secondaryLabel,
  }: {
    encodedContentDiff: string | null;
    primaryLabel: string;
    secondaryLabel: string;
  }) {
    // TODO: Handle null properly
    await new UpdateNoteContentUsingDiffOperation({
      store: this.store,
      payload: {
        id: this.id,
        encoded_content_diff: encodedContentDiff || "",
      },
      primaryLabel,
      secondaryLabel,
    }).execute();
  }

  public async saveAsNewNote({ eventContext }: { eventContext: EventContext }) {
    const remoteContent = this.remoteContent;
    const diffs = this.queuedDocumentUpdates.map(update => update.encodedContentDiff);
    const newEncodedContent = notesModule.mergeDiffsWithRemoteContent(remoteContent, diffs);
    const { primaryLabel, secondaryLabel } =
      notesModule.convertEncodedContentToNoteContent(newEncodedContent);
    const newNoteId = uuidModule.generate();
    await this.store.notes.createNote({ noteId: newNoteId, eventContext });
    await new UpdateNoteContentUsingDiffOperation({
      store: this.store,
      payload: {
        id: newNoteId,
        encoded_content_diff: newEncodedContent,
      },
      primaryLabel: primaryLabel || "",
      secondaryLabel: secondaryLabel || "",
    }).execute();
  }

  public async delete() {
    await new DeleteNoteOperation({ store: this.store, payload: { id: this.id } }).execute();
  }

  public async deleteEmptyNote() {
    if (this.isDeleted || this.isTrashed || !this.isOwnedByMe || !this.isNoteContentDocumentLoaded)
      return;

    logger.error({
      message: `[deleteEmptyNote] ${this.id}`,
      info: {
        note: this.modelData,
        remoteContent: this.remoteContent,
        noteContentDocumentIsLoading: this.noteContentDocument.isLoading || null,
        noteContentDocumentModelData: this.noteContentDocument.data?.modelData || null,
        queuedDocumentUpdates: this.queuedDocumentUpdates,
        isNoteContentDocumentLoaded: this.isNoteContentDocumentLoaded,
      },
    });

    if (this.isNoteContentInOriginalState) {
      logger.debug({ message: `[${this.id}] Deleting note` });
      await this.delete();
    } else {
      logger.debug({ message: `[${this.id}] Trashing note` });
      this.moveToTrash();
    }
  }

  public async moveToTrash(args?: { triggerSuccessToast?: boolean }) {
    await new TrashNoteOperation({
      ...args,
      store: this.store,
      payload: { id: this.id },
    }).execute();
  }

  public async restoreFromTrash(args?: { triggerSuccessToast?: boolean }) {
    await new RestoreNoteOperation({
      ...args,
      store: this.store,
      payload: { id: this.id },
    }).execute();
  }

  public async grantAccessViaSpaceAccount({
    roleKind,
    targetSpaceAccountId,
  }: {
    roleKind: GrantableSyncScopeRoleKind;
    targetSpaceAccountId: string;
  }) {
    await new GrantNoteAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
        role_kind: roleKind,
      },
    }).execute();
  }

  public async grantAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new GrantNoteAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public async updateAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new UpdateNoteAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
        role_kind: roleKind,
      },
    }).execute();
  }

  public async updateAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new UpdateNoteAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
        role_kind: roleKind,
      },
    }).execute();
  }

  public async updateAccessViaCollection({
    collectionId,
    roleKind,
  }: {
    collectionId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new UpdateNoteAclViaCollectionOperation({
      store: this.store,
      payload: {
        id: this.id,
        collection_id: collectionId,
        role_kind: roleKind,
      },
    }).execute();
  }

  public async revokeAccessViaSpaceAccount({
    targetSpaceAccountId,
  }: {
    targetSpaceAccountId: string;
  }) {
    await new RevokeNoteAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public async revokeAccessViaEmailAddress({ targetEmailAddress }: { targetEmailAddress: string }) {
    await new RevokeNoteAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public async toggleFavorite() {
    if (this.isFavorited)
      await new RemoveNoteFromFavoritesOperation({
        store: this.store,
        payload: { note_id: this.id },
      }).execute();
    else
      await new AddNoteToFavoritesOperation({
        store: this.store,
        payload: { note_id: this.id },
      }).execute();
  }

  public addToRecents = async () => {
    await new MarkNoteViewedOperation({
      store: this.store,
      payload: { note_id: this.id },
    }).execute();
  };

  public async uploadImageAssociatedWithNote({ info }: { info: MemCommonEditorImageInfo }) {
    await new UploadImageExternalOperation({
      store: this.store,
      payload: {
        info,
      },
    }).execute();
  }

  public async uploadFileAssociatedWithNote({ info }: { info: MemCommonEditorFileInfo }) {
    await new UploadFileExternalOperation({
      store: this.store,
      payload: {
        info,
      },
    }).execute();
  }

  public generateCommonEditorContext(): MemCommonEditorContext {
    const uploadImageOperations = (this.store.sync.actionQueue.operationsByOperationKind.get(
      ExternalOperationKind.UPLOAD_IMAGE
    ) ?? []) as UploadImageExternalOperation[];

    const imageInfoMapping: MemCommonEditorContext["images"] = uploadImageOperations.reduce(
      (acc, operation) => {
        const info = operation.payload.info;

        return {
          ...acc,
          [info.imageId]: { info },
        };
      },
      {} as MemCommonEditorContext["images"]
    );

    const uploadFileOperations = (this.store.sync.actionQueue.operationsByOperationKind.get(
      ExternalOperationKind.UPLOAD_FILE
    ) ?? []) as UploadFileExternalOperation[];

    const fileInfoMapping: MemCommonEditorContext["files"] = uploadFileOperations.reduce(
      (acc, operation) => {
        const info = operation.payload.info;

        return {
          ...acc,
          [info.fileId]: { info },
        };
      },
      {} as MemCommonEditorContext["files"]
    );

    return {
      images: imageInfoMapping,
      files: fileInfoMapping,
    };
  }
}
