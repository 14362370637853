import { MdsDropdownContentList } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { ZIndex } from "@/domains/design/constants";
import { css, cx } from "@/domains/emotion";
import {
  autoUpdate,
  detectOverflow,
  flip,
  FloatingNode,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  size,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  UseFloatingOptions,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { memo, useEffect, useMemo, useState } from "react";

export interface MdsFloatingDropdownContentProps {
  className?: string;
  clientRect?: DOMRect;
  contentListClassName?: string;
  contentList: MdsDropdownContentList;
  placement?: UseFloatingOptions["placement"];
  offsetOptions?: Parameters<typeof offset>;
  onHover?: ({ isHovering, itemId }: { isHovering?: boolean; itemId?: string }) => void;
}

export const MdsFloatingDropdownContent = memo<MdsFloatingDropdownContentProps>(
  function ActionsDropdown({
    className,
    clientRect,
    contentListClassName,
    contentList,
    placement,
    offsetOptions,
    onHover,
  }) {
    const nodeId = useFloatingNodeId();

    const [availableHeight, setAvailableHeight] = useState(0);

    const { x, y, refs, strategy, context } = useFloating({
      whileElementsMounted: autoUpdate,
      nodeId,
      open: true,
      onOpenChange: value => {
        if (value) {
          /** We don't want to open on hover states. */
          return;
        }

        // (onOpenChange)(false);
      },
      placement: placement ?? "bottom-start",
      middleware: [
        offsetOptions ? offset(offsetOptions[0], offsetOptions[1]) : undefined,
        {
          name: "avoidOverflow",
          async fn(middlewareArgs) {
            const overflow = await detectOverflow(middlewareArgs, {
              rootBoundary: "viewport",
            });

            let middlewareResult = {};

            if (overflow.right > 0 || overflow.left > 0) {
              const flipResult = await flip().fn(middlewareArgs);
              middlewareResult = await shift({ padding: 0 }).fn({
                ...middlewareArgs,
                ...flipResult,
              });
            }

            if (overflow.bottom > 0 || overflow.top > 0) {
              middlewareResult = await size({
                rootBoundary: "viewport",
                apply({ availableHeight }) {
                  // For some reason floating-ui is flickering between 9px and the actual height
                  // of the content. Besides the flickering sometimes the 9px locks the height
                  // first, resulting in a very short dropdown. Delay/debounce and updating
                  // @floating-ui didn't fix the issue, so this hack is the most reliable solution
                  // for now.
                  if (availableHeight < 20) return;

                  setAvailableHeight(availableHeight);
                },
              }).fn({
                ...middlewareArgs,
                ...middlewareResult,
              });
            }

            return middlewareResult;
          },
        },
      ],
    });

    useEffect(() => {
      if (!clientRect) return;

      refs.setPositionReference({
        getBoundingClientRect() {
          return clientRect;
        },
      });

      // Recalculate the available height when position or content changes.
      setAvailableHeight(0);
    }, [refs, clientRect, contentList.items.length]);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useHover(context, {
        move: false,
        handleClose: safePolygon(),
        delay: {
          close: 100,
        },
      }),
      useDismiss(context),
    ]);

    const portalContent = useMemo(() => {
      return (
        <div
          className={className}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: ZIndex.Dropdown,
          }}
          {...getFloatingProps()}
        >
          <MdsDropdownContent
            className={cx(
              contentListClassName,
              css({ maxHeight: availableHeight ? `${availableHeight}px` : undefined })
            )}
            contentList={contentList}
            onHover={onHover}
          />
        </div>
      );
    }, [
      availableHeight,
      className,
      contentList,
      contentListClassName,
      getFloatingProps,
      onHover,
      refs.setFloating,
      strategy,
      x,
      y,
    ]);

    if (contentList.items.length === 0) return null;

    return (
      <FloatingNode id={nodeId}>
        <div ref={refs.setReference} {...getReferenceProps()}></div>
        <FloatingPortal>{portalContent}</FloatingPortal>
      </FloatingNode>
    );
  }
);
