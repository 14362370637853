import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import { useLocation } from "react-router";
import memLogoSrc from "@/assets/legacy-images/mem-logo.png";
import { windowModule } from "@/modules/window";

export const GoogleOAuthRedirectDesktopPage: FC = observer(() => {
  const location = useLocation();

  const navigateToTargetUrl = useCallback(() => {
    const targetUrlPath = `mem://google-oauth-redirect${location.search}`;
    const targetUrl = new URL(targetUrlPath);

    windowModule.navigateToExternal({
      url: targetUrl,
    });
  }, [location.search]);

  useEffectOnMount(() => {
    navigateToTargetUrl();
  });

  return (
    <Wrapper>
      <Content>
        <MemLogo src={memLogoSrc} />

        <MdsText size={MdsTextSize.Medium}>Signing in...</MdsText>

        <br />
        <br />

        <MdsButton
          size={MdsButtonSize.Medium}
          variant={MdsButtonVariant.Outlined}
          onClick={navigateToTargetUrl}
          label="Open Mem"
        />
      </Content>
    </Wrapper>
  );
});

const Wrapper = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  flexWrap: "nowrap",
});

const Content = styled.div({
  width: 300,
  height: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  flexWrap: "nowrap",
});

const MemLogo = styled.img({
  width: 40,
  height: 40,
  marginBottom: 20,
});
