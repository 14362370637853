import { useCallback, useMemo, FC, useRef, useLayoutEffect } from "react";

import { css, cx } from "@/domains/emotion";
import { MdsDropdownButtonItem } from "@/design-system/components/dropdown";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import {
  mdsFontSizes,
  mdsLineHeights,
  mdsFontWeights,
} from "@/design-system/foundations/typography";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { motion } from "framer-motion";

export interface MdsDropdownButtonItemComponentProps {
  className?: string;
  item: MdsDropdownButtonItem;
  onOpenChange?: (value: boolean) => void;
  tooltipId: string;
  onHover?: ({ itemId }: { itemId?: string }) => void;
}

export const mdsDropdownButtonItemBaseStyles = css({
  display: "flex",
  fontSize: mdsFontSizes().small,
  lineHeight: mdsLineHeights().small,
  flexDirection: "row",
  alignItems: "center",
  height: "auto",
  fontWeight: mdsFontWeights().regular,
  borderRadius: mdsBorderRadius().medium,
  cursor: "pointer",
  transition: "0.08s ease",
  padding: "6px 8px",
  width: "100%",
  "&:hover": {
    backgroundColor: mdsColors().grey.x50,
  },
  "&:active": {
    backgroundColor: mdsColors().grey.x100,
  },
  "&:disabled": {
    cursor: "none",
    pointerEvents: "none",
  },
});

const invisibleStyles = css({
  opacity: 0,
  width: 0,
});

const keyboardShortcutStyles = css({
  border: `1px solid ${mdsColors().grey.x300}`,
  borderRadius: 4,
  color: mdsColors().grey.x300,
  fontSize: 10,
  fontWeight: 600,
  lineHeight: "16px",
  padding: "0 4px",
});

const motionWrapperStyles = css({
  width: "100%",
  display: "flex",
});

export const MdsDropdownButtonItemComponent: FC<MdsDropdownButtonItemComponentProps> = ({
  className,
  tooltipId,
  item,
  onOpenChange,
  onHover,
}) => {
  const { onClick: onItemClick, id: itemId } = item;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onItemClick({ itemId, event });
      onOpenChange?.(false);
    },
    [onItemClick, itemId, onOpenChange]
  );

  const labelStyles = css({
    marginLeft: item.iconKind ? 8 : undefined,
    fontSize: mdsFontSizes().small,
    lineHeight: mdsLineHeights().small,
    color: item.isDisabled ? mdsColors().grey.x400 : mdsColors().grey.x600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    textAlign: "left",
  });

  const limitedLabelStyles = css({
    maxWidth: "calc(100% - 140px)",
  });

  const labelDetailStyles = css({
    marginLeft: 4,
    fontSize: mdsFontSizes().small,
    lineHeight: mdsLineHeights().small,
    height: mdsLineHeights().small,
    color: item.isDisabled ? mdsColors().grey.x300 : mdsColors().grey.x500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 140,
    textAlign: "right",
  });

  const buttonStyles = useMemo(
    () => cx(className, mdsDropdownButtonItemBaseStyles, item.className),
    [className, item.className]
  );

  const iconStyles = css({
    color: item.isDisabled ? mdsColors().grey.x400 : mdsColors().grey.x600,
  });

  const { iconKind, iconSize } = item;

  const spacerStyles = css({
    flex: 1,
    minWidth: item.isChecked ? 4 : 0,
  });

  const showSelectedLabelDetail = useMemo(() => {
    if (!item.isSelected) {
      return false;
    }

    return Boolean(item.selectedLabelDetail);
  }, [item.isSelected, item.selectedLabelDetail]);

  const icon =
    typeof iconKind === "string" ? (
      <MdsIcon kind={iconKind} innerStyles={{ Icon: { className: iconStyles } }} />
    ) : typeof iconKind === "function" ? (
      iconKind()
    ) : null;
  const wrappedIcon = iconSize ? (
    <div className={css({ height: iconSize, width: iconSize })}>{icon}</div>
  ) : (
    icon
  );

  const ref = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (item.scrollIntoView) ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [item.scrollIntoView]);

  return (
    <motion.div
      className={motionWrapperStyles}
      key={item.id}
      layoutId={item.animate ? item.id : undefined}
      initial={false}
    >
      <button
        ref={ref}
        className={buttonStyles}
        onClick={handleClick}
        disabled={item.isDisabled}
        data-tip={item.tooltip ?? ""}
        data-for={tooltipId}
        onMouseEnter={() => onHover?.({ itemId })}
        onMouseLeave={() => onHover?.({})}
      >
        {wrappedIcon}
        <div className={cx(labelStyles, showSelectedLabelDetail && limitedLabelStyles)}>
          {item.content ? item.content() : item.label}
        </div>
        {showSelectedLabelDetail && (
          <div className={labelDetailStyles}>{` ${item.selectedLabelDetail ?? ""} `}</div>
        )}
        <div className={spacerStyles} />
        <MdsIcon
          kind={MdsIconKind.Check}
          className={item.isChecked ? undefined : invisibleStyles}
        />
        {item.keyboardShortcut && !item.isChecked && (
          <span className={keyboardShortcutStyles}>{item.keyboardShortcut}</span>
        )}
      </button>
    </motion.div>
  );
};
