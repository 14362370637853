import Dexie from "dexie";

import queueConfig from "@/domains/local-db/account/queue/config";
import syncUpdatesConfig from "@/domains/local-db/account/syncUpdates/config";
import syncConfig from "@/domains/local-db/account/sync/config";
import settingsConfig from "@/domains/local-db/account/settings/config";
import { STORE_VERSION } from "@/domains/local-db/account/version";

import {
  getCriticalErrorCode,
  raiseCriticalError,
  setCriticalErrorCode,
} from "@/domains/critical-errors/critical-errors";
import { errorMessagesToCriticalErrorCodes } from "@/domains/critical-errors/error-messages";
import { CriticalErrorCode } from "@/domains/critical-errors/types";
import { Maybe } from "@/domains/common/types";
import { objectModule } from "@/modules/object";
import { logger } from "@/modules/logger";
import { sleep } from "@/domains/event-loop/synchronization";
import { getApiAuthIdentifier } from "@/modules/api/authUtils";

export type LocalDbState = { initialized: boolean };

const STORE_NAME_PREFIX = "mem/accounts/";

let store: Dexie | null;

type ResolveStore = (store: Dexie) => void;

let pendingStoreResolver: Maybe<ResolveStore>;
let pendingStore = new Promise((resolve: ResolveStore) => {
  pendingStoreResolver = resolve;
});

const onCloseStoreListeners = new Set<() => void>();

export const addOnCloseStoreListener = (f: () => void) => {
  onCloseStoreListeners.add(f);
};

export const removeOnCloseStoreListener = (f: () => void) => {
  onCloseStoreListeners.delete(f);
};

const closeStoreIfOpen = () => {
  if (!store) {
    return;
  }
  store.close();
  store = null;
  pendingStore = new Promise((resolve: ResolveStore) => {
    pendingStoreResolver = resolve;
  });
  for (const f of onCloseStoreListeners) {
    f();
  }
};

const onOpenStoreListeners = new Set<() => void>();

export const addOnOpenStoreListener = (f: () => void) => {
  onOpenStoreListeners.add(f);
};

export const removeOnOpenStoreListener = (f: () => void) => {
  onOpenStoreListeners.delete(f);
};

const onOpenStore = () => {
  for (const f of onOpenStoreListeners) {
    f();
  }
};

const createDexieStore = (storeName: string) => {
  logger.debug({
    message: "[CDE][Dexie] Opening store...",
    info: { storeName },
  });
  const store = new Dexie(storeName, { chromeTransactionDurability: "relaxed" });

  store.version(STORE_VERSION).stores({
    [queueConfig.TABLE_NAME]: queueConfig.TABLE_SCHEMA,
    [syncUpdatesConfig.TABLE_NAME]: syncUpdatesConfig.TABLE_SCHEMA,
    [syncConfig.TABLE_NAME]: syncConfig.TABLE_SCHEMA,
    [settingsConfig.TABLE_NAME]: settingsConfig.TABLE_SCHEMA,
  });

  return store.open();
};

let deletingDB = false;

export const deleteCurrentAccountDB = () =>
  new Promise((resolve, reject) => {
    if (!store) {
      console.debug("[deleteCurrentAccountDB] No store");
      return;
    }
    const storeName = store.name;
    deletingDB = true;
    console.debug(`[deleteCurrentAccountDB] Closing ${storeName} ...`);
    closeStoreIfOpen();
    console.debug(`[deleteCurrentAccountDB] Deleting ${storeName} ...`);
    setTimeout(async () => {
      try {
        await Dexie.delete(storeName);
        resolve(undefined);
      } catch (e) {
        reject(e);
      }
    }, 2_000);
  });

export const setDbAccountScope = async (accountId: string | null) => {
  try {
    const storeName = `${STORE_NAME_PREFIX}${accountId}`;

    // No need to reconnect if we're already connected to the correct account store
    if (store?.name === storeName) {
      return;
    }

    // Start by ensuring we don't have an open store
    closeStoreIfOpen();

    // When we're have to no account, we can't open a store
    // When deleting the DB, we cannot open it until refresh
    if (!accountId || deletingDB) {
      return;
    }

    // Try to access data to force early version check.
    try {
      store = await createDexieStore(storeName);

      /** This is just a test to make sure we can access the db. */
      await store.table(queueConfig.TABLE_NAME).get(0);
    } catch (e) {
      setErrorCodeAndRethrowIfErrorIsCritical(e);
    }

    resolveStore();
    onOpenStore();
  } catch (err) {
    logger.error({
      message: "[CDE][Dexie] Failed to switch account ID",
      info: objectModule.safeAsJson({ err, criticalErrorCode: getCriticalErrorCode() }),
    });
    raiseCriticalError({ message: "Failed to select current account on your hard drive." });
  }
};

const resolveStore = () => {
  if (!store) {
    throw new Error("store is not set");
  }
  if (pendingStoreResolver) {
    const resolve = pendingStoreResolver;
    pendingStoreResolver = undefined;
    resolve(store);
  }
  onOpenStatus();
};

export const getStore = async (operationError?: Error) => {
  const criticalErrorCode = getCriticalErrorCode();
  if (criticalErrorCode) {
    if (operationError) {
      setErrorCodeAndRethrowIfErrorIsCritical(operationError);
      throw operationError;
    }
    throw new Error(criticalErrorCode);
  }
  if (store) {
    if (operationError) {
      setErrorCodeAndRethrowIfErrorIsCritical(operationError);
      await reopenStore(store.name);
    }
    const connectionError = detectStoreConnectionError(store);
    if (connectionError) {
      console.error("Store connection error", connectionError);
      await reopenStore(store.name);
    }
    return store;
  }

  return await pendingStore;
};

const detectStoreConnectionError = (store: Dexie) => {
  // Detect browser instability & bugs in IndexedDB.
  // Source: https://github.com/dfahlander/Dexie.js/issues/613#issuecomment-841608979
  const idb = store.backendDB();
  if (idb) {
    try {
      // Check if if connection is still valid.
      idb.transaction(queueConfig.TABLE_NAME, "readwrite").abort();
    } catch (e) {
      return e;
    }
  }
  // return forceErrorsAfterSomeTimeToTestCriticalErrorCodes(errorMessagesToCriticalErrorCodes[0]); // CriticalErrorCode.INCOMPATIBLE_LOCAL_DB_VERSION
  // return forceErrorsAfterSomeTimeToTestCriticalErrorCodes(errorMessagesToCriticalErrorCodes[1]); // CriticalErrorCode.DISK_QUOTA_EXCEEDED
  // return forceErrorsAfterSomeTimeToTestCriticalErrorCodes(errorMessagesToCriticalErrorCodes[2]); // CriticalErrorCode.DB_CONNECTION_LOST_MUST_REFRESH
  // return forceErrorsAfterSomeTimeToTestCriticalErrorCodes(errorMessagesToCriticalErrorCodes[3]); // CriticalErrorCode.READONLY_DB
  // return forceErrorsAfterSomeTimeToTestCriticalErrorCodes(errorMessagesToCriticalErrorCodes[4]); // CriticalErrorCode.DB_CONNECTION_IS_CLOSING, message A
  // return forceErrorsAfterSomeTimeToTestCriticalErrorCodes(errorMessagesToCriticalErrorCodes[5]); // CriticalErrorCode.DB_CONNECTION_IS_CLOSING, message B.
  // return forceErrorsAfterSomeTimeToTestCriticalErrorCodes(errorMessagesToCriticalErrorCodes[6]); // CriticalErrorCode.REOPEN_DB_TIMEOUT.
};

// const forceErrorsAfterSomeTimeToTestCriticalErrorCodes = (() => {
//   const timeout = Date.now() + 10 * 1000;
//   return ({ errorName, text }: { errorName?: string; text?: string }) => {
//     if (Date.now() > timeout) {
//       let inner;
//       if (errorName) {
//         inner = new Error(text);
//         inner.name = errorName;
//       }
//       console.debug(`FORCING [${errorName || ""}] "${text || ""}"`);
//       return new BaseError({ inner, message: text });
//     }
//   };
// })();

const reopenStore = async (storeName: string) => {
  closeStoreIfOpen();

  const minSleepMs = 10;
  let sleepMs = minSleepMs;
  // const timeout = Date.now() + 20000; // 20s for tests.
  const timeout = Date.now() + 120000; // 2 min.
  for (;;) {
    // Try to reopen after: 10, 20, 40 then 80ms.
    await sleep(sleepMs);
    sleepMs = Math.min(80, 2 * sleepMs);

    try {
      store = await createDexieStore(storeName);

      const e = detectStoreConnectionError(store);
      if (e) {
        throw e;
      }

      resolveStore();

      return;
    } catch (err) {
      onOpenStatus(err);
      setErrorCodeAndRethrowIfErrorIsCritical(err, Date.now() > timeout);
    }
  }
};

const timeoutsToTheNextLoggedErrors = new Array<number>();
const maxTimeoutTONextLoggedError = 120000;

const onOpenStatus = (err?: unknown) => {
  if (!err || getCriticalErrorCode()) {
    timeoutsToTheNextLoggedErrors.splice(0, timeoutsToTheNextLoggedErrors.length);
    return;
  }
  const now = Date.now();
  if (timeoutsToTheNextLoggedErrors.length === 0) {
    timeoutsToTheNextLoggedErrors.push(
      now + 10000,
      now + 30000,
      now + 60000,
      now + maxTimeoutTONextLoggedError
    );
  } else {
    const timeout = timeoutsToTheNextLoggedErrors[0];
    if (timeout > now) {
      return;
    }
    if (timeoutsToTheNextLoggedErrors.length == 1) {
      timeoutsToTheNextLoggedErrors[0] = timeout + maxTimeoutTONextLoggedError;
    } else {
      timeoutsToTheNextLoggedErrors.shift();
    }
  }
  console.error("Attempt to reopen DB failed", objectModule.safeAsJson({ err }));
};

const setErrorCodeAndRethrowIfErrorIsCritical = (err: unknown, hasTimedOut?: boolean) => {
  const { inner, name, message } = err as Error & { inner?: { name?: string } };
  let errorCode = hasTimedOut ? CriticalErrorCode.ReopenDBTimeout : undefined;
  for (const { code, errorName, onlyAfterTimeout, text } of errorMessagesToCriticalErrorCodes) {
    if (
      (errorName && (name === errorName || inner?.name === errorName)) ||
      (text && message.indexOf(text) >= 0)
    ) {
      if (!onlyAfterTimeout || hasTimedOut) {
        errorCode = code;
      }
      break;
    }
  }
  if (errorCode && errorCode !== getCriticalErrorCode()) {
    setCriticalErrorCode(errorCode);
    logger.error({
      message: "[CDE][Dexie] Failed to (re)open DB",
      info: objectModule.safeAsJson({ errorCode, hasTimedOut, err }),
    });
    raiseCriticalError({ message: "Critical failure (re)opening DB." });
    throw err;
  }
};

export const isAvailable = () => {
  if (!store) {
    return false;
  }

  return store.isOpen() && !store.hasFailed();
};

export const initializeStore = async (): Promise<LocalDbState> => {
  let accountId: string | undefined;

  try {
    accountId = (await getApiAuthIdentifier()) ?? undefined;
  } catch (_err) {
    // In non-UI threads, the Firebase auth service will not be available.
  }

  if (!accountId) {
    return {
      initialized: false,
    };
  }

  await setDbAccountScope(accountId);

  return {
    initialized: true,
  };
};
