import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { MdsDropdownButton, MdsDropdownIconButton } from "@/design-system/components/dropdown";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { mdsFontSizes, mdsFontWeights } from "@/design-system/foundations/typography";
import { MdsEditableTextField } from "@/design-system/components/text-field/MdsEditableTextField";
import { CollectionsViewPageHeaderShareButton } from "@/pages/collections-view/components/CollectionsViewPageHeaderShareButton";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import styled from "@emotion/styled";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsIconKind } from "@/design-system/components/icon";

interface Props {
  isSidePanel: boolean;
}

export const CollectionsViewPageHeader = observer<Props>(function CollectionsViewPageHeader({
  isSidePanel,
}) {
  const stores = useAppStore();
  const page = isSidePanel
    ? stores.store.sidePanel.collectionsViewPage
    : stores.pageStore.collectionsViewPage;
  const collection = page.collectionObservable;
  const navigator = isSidePanel ? stores.store.sidePanel : stores.store.navigation;

  const collectionTitleInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (collection && !collection.title) collectionTitleInputRef.current?.focus();
  }, [collection]);

  useEffect(() => {
    // Make sure to save the collection when the component unmounts because blur is not guaranteed
    // to be called whe navigating away from the page.
    return () => {
      if (collection && !collection.isDeleted) collection.save();
    };
  }, [collection]);

  if (!collection) return null;
  return (
    <Wrapper>
      <MdsPanelHeader
        icon={<CollectionIcon collectionId={collection.id} />}
        heading={
          <MdsEditableTextField
            ref={collectionTitleInputRef}
            value={collection.title}
            placeholder={"Collection name"}
            onFocus={() => collection.lock()}
            onChange={e => collection.setTitle(e.currentTarget.value)}
            onBlur={async () => await collection.save()}
            onEnterKeyPress={async () => await collection.save()}
            autoFocus={collection?.label === ""}
            className={titleInputStyles}
            readOnly={!collection.canWrite}
          />
        }
        subheading={
          <MdsEditableTextField
            value={collection.description}
            placeholder={"Add a description"}
            onFocus={() => collection.lock()}
            onChange={e => collection.setDescription(e.currentTarget.value)}
            onBlur={async () => {
              await collection.save();
            }}
            onEnterKeyPress={async () => await collection.save()}
            autoFocus={collection?.label === ""}
            className={descriptionInputStyles}
            readOnly={!collection.canWrite}
          />
        }
        actions={
          <>
            <CollectionsViewPageHeaderShareButton
              collection={collection}
              isSidePanel={isSidePanel}
            />
            <MdsDropdownIconButton
              contentList={page.headerDropdownButtonContentList}
              placement="below-right-alignment"
              tooltipConfig={{
                label: "More",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
              }}
            />
          </>
        }
        onBackClick={navigator.goUp}
      />
      <WrapperBottom>
        <div /> {/* TODO: Placeholder for filters */}
        <MdsDropdownButton
          dropdownIcon
          contentList={page.sortOptions}
          label={page.sortLabel}
          iconKind={MdsIconKind.SortAlt}
          iconPosition={MdsButtonIconPosition.Left}
          placement="below-right-alignment"
        />
      </WrapperBottom>
    </Wrapper>
  );
});

const Wrapper = styled.div({});

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing.md,
}));

const titleInputStyles = css({
  width: "100%",
  padding: 0,
  fontSize: mdsFontSizes().small,
  lineHeight: mdsFontSizes().small,
});

const descriptionInputStyles = css({
  width: "100%",
  padding: 0,
  borderRadius: mdsBorderRadius().medium,
  cursor: "text",
  transition: "0.08s ease",

  fontSize: mdsFontSizes().xxsmall,
  lineHeight: mdsFontSizes().small,
  fontWeight: mdsFontWeights().regular,
  color: mdsColors().grey.x500,

  overflow: "hidden",
  "&:placeholder": {
    color: mdsColors().grey.x400,
  },
  "&:not(:read-only):hover, &:not(:read-only):focus": {
    backgroundColor: mdsColors().grey.x25,
    overflow: "auto",
  },
});
