import { MdsItemListRowRenderer } from "@/design-system/components/item-list";
import { MdsItemListItemRowData } from "@/design-system/components/item-list/types";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";

interface TopicItemListProps {
  topicId: string;
  rows: MdsItemListItemRowData[];
}

export const TopicItemList = observer<TopicItemListProps>(({ rows, topicId }) => {
  const { pageStore } = useAppStore();
  const page = pageStore.copilotView;
  return (
    <div>
      {rows.map((row, index) => (
        <div
          key={index}
          onMouseEnter={() => page.setHighlightedRow(row.payload.id, topicId)}
          onMouseLeave={() => page.setHighlightedRow(null, null)}
        >
          <MdsItemListRowRenderer index={index} data={row} />
        </div>
      ))}
    </div>
  );
});
