import { makeObservable, override } from "mobx";
import { SpaceAccountCollectionObservable } from "@/store/recent-items/SpaceAccountCollectionObservable";
import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountCollectionModelData } from "@/store/recent-items/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { Uuid } from "@/domains/global/identifiers";

export class AppStoreSpaceAccountCollectionsStore extends BaseSyncModelStore<
  SpaceAccountCollectionObservable,
  SpaceAccountCollectionModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountCollection, ...injectedDeps });
    makeObservable(this, { recompute: override, createSyncModel: false });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountCollectionModelData>
  ): SpaceAccountCollectionObservable {
    return new SpaceAccountCollectionObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public async recompute(modelId: Uuid) {
    await super.recompute(modelId);
    const data = await this.localTable.get(modelId);
    const collectionId = data?.model_data.collection_id;
    if (collectionId) await this.store.collections.recompute(collectionId);
  }
}
