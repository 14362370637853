import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";

export const removeNotesFromCollection = async ({
  noteIds,
  collection,
  store,
}: {
  noteIds: string[];
  collection: CollectionObservable;
  store: AppStore;
}) => {
  let removed = false;

  for (const noteId of noteIds) {
    const note = await store.notes.getAsync(noteId);
    if (!note) continue;

    await note.collectionList?.removeCollection({
      collectionId: collection.id,
      triggerSuccessToast: noteIds.length === 1,
    });
    removed = true;
  }

  if (!removed || noteIds.length === 1) return;

  toastModule.triggerToast({
    content: <div>Notes removed from {collection.label}.</div>,
  });
};
