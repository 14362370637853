import { action, computed, makeObservable, observable } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MemCommonEditorFileRejectionErrorCode } from "@mem-labs/common-editor";
import { truncate } from "lodash-es";

export class FileUploadRejectedModalStore extends AppSubStore {
  public code: MemCommonEditorFileRejectionErrorCode | null = null;
  public fileName: string | null = null;
  isOpen: boolean = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable<this>(this, {
      fileName: observable,
      code: observable,
      isOpen: observable,
      truncatedFileName: computed,
      errorSummary: computed,
      errorMessage: computed,
      open: action,
      close: action,
    });
  }

  public open({
    code,
    fileName,
  }: {
    code: MemCommonEditorFileRejectionErrorCode;
    fileName: string;
  }) {
    this.code = code;
    this.fileName = fileName;
    this.isOpen = true;
  }

  public close() {
    this.code = null;
    this.fileName = null;
    this.isOpen = false;
  }

  get truncatedFileName() {
    return truncate(this.fileName ?? "File", { length: 30 });
  }

  get errorSummary() {
    return `Failed to upload ${this.truncatedFileName}.`;
  }

  get errorMessage() {
    switch (this.code) {
      case MemCommonEditorFileRejectionErrorCode.FileTooLarge:
        return "The file is too large to upload. Please try again with a smaller file.";
      default:
        return "An error occurred while uploading the file. Please try again.";
    }
  }
}
