import { moduleFactory } from "@/factories/module";
import {
  convertEncodedContentToNoteContent,
  convertMdxToEncodedContent,
  convertMdxToNoteContent,
} from "@/modules/notes/conversions";
import { mergeDiffsWithRemoteContent } from "@/modules/notes/yjs";

export const notesModule = moduleFactory.create({
  convertMdxToNoteContent,
  convertEncodedContentToNoteContent,
  convertMdxToEncodedContent,
  mergeDiffsWithRemoteContent,
});
