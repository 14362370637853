import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsHorizontalDivider } from "@/design-system/components/divider";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { MdsChipInput } from "@/design-system/components/input";
import { CollectionChip } from "@/components/chips/CollectionChip";
import { NoteChip } from "@/components/chips/NoteChip";
import { noop } from "lodash-es";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { ShareSheetPermissionRow } from "@/components/modal/share-sheet/share-sheet-permission-row/ShareSheetPermissionRow";
import { ShareSheetModalProps } from "@/components/modal/share-sheet/ShareSheetModal";
import { useLastNonNullRender } from "@/hooks/useLastNonNullRender";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { MdsTextAlign } from "@/design-system/components/text/types";
import { FadeVerticalScroll } from "@/components/vertical-scroll/FadeVerticalScroll";

export const ShareSheetModalContent = observer<ShareSheetModalProps>(
  function ShareSheetModalContent({ store: { modal: store } }) {
    return useLastNonNullRender(() => {
      if (!store) return null;

      const titleChip = (() => {
        if (store.entityKind === ShareSheetEntityKind.Collection && store.collection) {
          return <CollectionChip collection={store.collection} />;
        }
        if (store.entityKind === ShareSheetEntityKind.Note && store.note) {
          return <NoteChip title={store.note.title || UNTITLED_NOTE_TITLE} />;
        }
      })();
      if (!titleChip) return null;

      const isShareDisabled = !store.chips.length || store.isSharing;
      return (
        <div className={modalStyles}>
          <section className={modalTopStyles}>
            <div className={rowStyles}>
              <div className={titleStyles}>
                <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
                  Share
                </MdsText>
                {titleChip}
              </div>
              <MdsSpacer />
              <MdsIconButton
                variant={MdsIconButtonVariant.Transparent}
                iconKind={MdsIconKind.Exit}
                onClick={store.close}
              />
            </div>
            <div className={rowStyles}>
              <MdsChipInput
                isDisabled={store.isSharing}
                getChips={store.getChips}
                chips={store.chips}
                setChips={store.setChips}
                onSubmit={isShareDisabled ? noop : store.handleSubmit}
                placeholder={
                  store.entityKind === ShareSheetEntityKind.Collection
                    ? "Share with people or emails"
                    : "Share with people, emails, or collections"
                }
              />
              <MdsButton
                isDisabled={isShareDisabled}
                isLoading={store.isSharing}
                label="Share"
                variant={!store.isSharing ? MdsButtonVariant.Primary : MdsButtonVariant.Text}
                onClick={store.handleSubmit}
              />
            </div>
            <FadeVerticalScroll className={scrollableSectionStyles}>
              <div className={aclEntryStyles}>
                {store.aclEntries.map((permission, i) => (
                  <ShareSheetPermissionRow
                    key={i}
                    isLoading={store.isUnsharing}
                    permission={permission}
                    isGuestMode={store.isGuestMode}
                    canRevokeAccess={store.canRevokeAccess}
                    canUpdateAccess={store.canUpdateAccess}
                    handleRevokeAccess={store.handleRevokeAccess}
                    handleUpdateAccess={store.handleUpdateAccess}
                    handleCancelOperation={store.handleCancelOperation}
                    shareSheetModalStore={store}
                  />
                ))}
              </div>
            </FadeVerticalScroll>
          </section>
          <MdsHorizontalDivider />
          <section className={modalBottomStyles}>
            <MdsButton
              className={linkButtonStyles}
              onClick={store.handleCopyCollaborativeLink}
              iconKind={MdsIconKind.LinkSolid}
              variant={MdsButtonVariant.Text}
              label="Copy collaborative link"
            />
            <MdsText size={MdsTextSize.Small} align={MdsTextAlign.Center}>
              Only people added above can use this link
            </MdsText>
          </section>
        </div>
      );
    });
  }
);

const modalStyles = css({
  width: "min(600px, 90vw)",
});

const modalTopStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  padding: "24px",
});

const modalBottomStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  padding: "24px",
  "@media (max-width: 768px)": {
    padding: "16px 24px 24px 24px",
    flexDirection: "column",
    gap: "4px",
  },
});

const rowStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
  justifyContent: "space-between",
  overflow: "hidden",
});

const linkButtonStyles = css({
  ".mds-btn-label, .mds-btn-icon": {
    color: mdsColors().secondary.x400,
  },
});

const aclEntryStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
});

const scrollableSectionStyles = css({
  maxHeight: "max(120px, calc(100vh - 300px))",
});
