import { CollectionsListPageStore } from "@/store/pages/CollectionsListPageStore/CollectionsListPageStore";
import { CollectionsViewPageStore } from "@/store/pages/CollectionsViewPageStore/CollectionsViewPageStore";
import { NotesListPageStore } from "@/store/pages/NotesListPageStore";
import { TrashPageStore } from "@/store/pages/TrashPageStore";
import { PageStoreConstructorArgs } from "@/store/types";
import { AppStore, PublicAppStore } from "@/store";
import { makeAutoObservable } from "mobx";
import { SearchPageStore } from "@/store/pages/SearchPageStore/SearchPageStore";
import { QuickSearchModalStore } from "@/store/pages/QuickSearchModalStore/QuickSearchModalStore";
import { SettingsPageStore } from "@/store/pages/SettingsPageStore";
import { SettingsEmailPreferencesPageStore } from "@/store/pages/SettingsEmailPreferencesPageStore/SettingsEmailPreferencesPageStore";
import { SettingsAccountManagementPageStore } from "@/store/pages/SettingsAccountManagementPageStore/SettingsAccountManagementPageStore";
import { NotesViewPageStore } from "@/store/pages/NotesViewPageStore/NotesViewPageStore";
import { SettingsSubscriptionManagementPageStore } from "@/store/pages/SettingsSubscriptionManagementPageStore";
import { CopilotViewStore } from "@/store/pages/CopilotViewStore";

export class PageStore {
  collectionsListPage: CollectionsListPageStore;
  collectionsViewPage: CollectionsViewPageStore;
  notesListPage: NotesListPageStore;
  notesViewPage: NotesViewPageStore;
  settingsPage: SettingsPageStore;
  settingsAccountManagementPage: SettingsAccountManagementPageStore;
  settingsEmailPreferencesPage: SettingsEmailPreferencesPageStore;
  settingsSubscriptionManagementPage: SettingsSubscriptionManagementPageStore;
  trashPage: TrashPageStore;
  searchPage: SearchPageStore;

  quickSearchModal: QuickSearchModalStore;
  copilotView: CopilotViewStore;

  publicAppStore: PublicAppStore;
  store: AppStore;

  constructor({ publicAppStore, store, api, pusher }: PageStoreConstructorArgs) {
    const injectedDeps = { store, api, pusher };

    this.publicAppStore = publicAppStore;
    this.store = store;

    this.collectionsListPage = new CollectionsListPageStore(injectedDeps);
    this.collectionsViewPage = new CollectionsViewPageStore(injectedDeps);
    this.notesListPage = new NotesListPageStore(injectedDeps);
    this.notesViewPage = new NotesViewPageStore(injectedDeps);
    this.settingsPage = new SettingsPageStore(injectedDeps);
    this.settingsAccountManagementPage = new SettingsAccountManagementPageStore(injectedDeps);
    this.settingsEmailPreferencesPage = new SettingsEmailPreferencesPageStore(injectedDeps);
    this.settingsSubscriptionManagementPage = new SettingsSubscriptionManagementPageStore(
      injectedDeps
    );
    this.trashPage = new TrashPageStore(injectedDeps);
    this.searchPage = new SearchPageStore(injectedDeps);

    this.quickSearchModal = new QuickSearchModalStore(injectedDeps);
    this.copilotView = new CopilotViewStore(injectedDeps);

    makeAutoObservable(this);
  }

  useInitializePageStoreEffects = () => {
    this.notesListPage.initialize();
    this.collectionsListPage.initialize();
  };
}
