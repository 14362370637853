import { FC, useEffect, useMemo, useRef, useState } from "react";
import useMeasure from "react-use/lib/useMeasure";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsFloatingDropdownContent } from "@/design-system/components/dropdown/MdsFloatingDropdownContent";
import { MdsChip } from "@/design-system/components/input/types";
import { mdsColors } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { MdsChipInputChip } from "@/design-system/components/input/MdsChipInputChip";

export interface MdsChipInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onSubmit"> {
  isDisabled?: boolean;
  getChips: (value: string) => Promise<MdsChip[]>;
  chips: MdsChip[];
  setChips: React.Dispatch<React.SetStateAction<MdsChip[]>>;
  onSubmit: (chips: MdsChip[]) => void;
  placeholder?: string;
}

export const MdsChipInput: FC<MdsChipInputProps> = ({
  isDisabled,
  getChips,
  chips,
  setChips,
  onSubmit,
  placeholder,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isHoveringOverAvailableChips, setIsHoveringOverAvailableChips] = useState<boolean>(false);
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState<number>(-1);

  const [availableChips, setAvailableChips] = useState<MdsChip[]>([]);

  useEffect(() => {
    const fetchAvailableChips = async (value: string) => {
      const chips = await getChips(value);
      setAvailableChips(chips);
    };
    fetchAvailableChips(value);
  }, [getChips, value]);

  useEffect(() => {
    if (availableChips.length > 0) setKeyboardSelectedIndex(0);
    else setKeyboardSelectedIndex(-1);
  }, [availableChips]);

  const contentList: MdsDropdownContentList = {
    items: availableChips
      .sort((a, b) => b.orderByString.localeCompare(a.orderByString))
      .map((chip, index) => ({
        id: chip.id,
        kind: MdsDropdownItemKind.Button,
        iconKind: chip.icon ? () => chip.icon?.(34) : undefined,
        label: chip.label,
        content: () => (
          <div className={columnStyles}>
            <div>{chip.label}</div>
            {chip.secondaryLabel && <div className={secondaryStyles}>{chip.secondaryLabel}</div>}
          </div>
        ),
        className: index === keyboardSelectedIndex ? selectedOptionStyles : undefined,
        onClick: () => {
          handleSelectChip(chip);
          setValue("");
        },
      })),
  };

  const handleSelectChip = (chip: MdsChip) => {
    setChips(chips => {
      const hasChip = chips.some(c => c.id === chip.id);
      if (hasChip) return chips;
      return [...chips, chip];
    });
    setIsHoveringOverAvailableChips(false);
    inputRef.current?.focus();
  };

  const handleRemoveChip = (chip: MdsChip) => {
    setChips(chips => chips.filter(c => c.id !== chip.id));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.endsWith(",") || value.endsWith(" ")) {
      const trimmedValue = value.substring(0, value.length - 1).trim();
      const matchingChips = availableChips.filter(chip =>
        chip.searchLabels?.includes(trimmedValue)
      );
      if (matchingChips.length === 1) {
        handleSelectChip(matchingChips[0]);
        setValue("");
        return;
      }
    }
    setValue(e.target.value);
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (availableChips.length === 0 || isHoveringOverAvailableChips) return;
    switch (e.key) {
      case "ArrowUp": {
        e.preventDefault();
        setKeyboardSelectedIndex(
          (keyboardSelectedIndex - 1 + availableChips.length) % availableChips.length
        );
        break;
      }
      case "ArrowDown": {
        e.preventDefault();
        setKeyboardSelectedIndex((keyboardSelectedIndex + 1) % availableChips.length);
        break;
      }
      case "Enter": {
        e.preventDefault();
        handleSelectChip(availableChips[keyboardSelectedIndex]);
        setValue("");
        break;
      }
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter": {
        if (value.length === 0 && chips.length > 0) {
          e.preventDefault();
          onSubmit(chips);
        }
        break;
      }
      case "Backspace": {
        if (value.length === 0 && chips.length > 0) {
          e.preventDefault();
          handleRemoveChip(chips[chips.length - 1]);
        }
        break;
      }
    }
  };

  const handleDropdownHover = ({
    isHovering,
    itemId,
  }: {
    isHovering?: boolean;
    itemId?: string;
  }) => {
    setIsHoveringOverAvailableChips(!!isHovering || !!itemId);
    if (isHovering) setKeyboardSelectedIndex(-1);
  };

  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const floatingStyles = useMemo(
    () =>
      css({
        marginLeft: "-4px",
        marginTop: `${height - 8}px`,
      }),
    [height]
  );

  return (
    <div
      ref={ref}
      className={css(
        containerStyles,
        isFocused && containerFocusedStyles,
        isDisabled && containerDisabledStyles
      )}
      onKeyDown={handleKeydown}
    >
      <MdsFloatingDropdownContent
        onHover={handleDropdownHover}
        className={floatingStyles}
        contentList={contentList}
      />
      {chips.map(chip => (
        <MdsChipInputChip
          key={chip.id}
          chip={chip}
          onRemoveChip={() => handleRemoveChip(chip)}
          isDisabled={isDisabled}
        />
      ))}
      <input
        className={cx(inputStyles, isDisabled && inputDisabledStyles)}
        placeholder={chips.length ? undefined : placeholder}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        autoFocus
        readOnly={isDisabled}
        ref={inputRef}
        {...props}
      />
    </div>
  );
};

const selectedOptionStyles = css({
  background: mdsColors().grey.x50,
});

const containerStyles = css({
  flex: 1,
  position: "relative",
  borderRadius: "4px",
  padding: "3px",
  border: `1px solid ${mdsColors().grey.x200}`,
  background: mdsColors().transparent,
  display: "flex",
  flexWrap: "wrap",
  minHeight: "40px",
  alignItems: "center",
  minWidth: 0,
});

const containerFocusedStyles = css({
  padding: "2px",
  border: `2px solid ${mdsColors().blue.x400}`,
  color: mdsColors().grey.x700,
  background: mdsColors().white,
});

const containerDisabledStyles = css({
  border: `1px solid ${mdsColors().grey.x100}`,
  background: mdsColors().grey.x25,
});

const inputStyles = css({
  outline: "none",
  border: "none",
  flex: 1,
  minWidth: "128px",
  fontSize: "14px",
  lineHeight: "20px",
  height: "24px",
  padding: "4px",
  margin: "2px",
  borderRadius: 0,
  color: mdsColors().grey.x700,
  background: mdsColors().transparent,
  textOverflow: "ellipsis",
  "&:focus": {
    color: mdsColors().grey.x700,
    background: mdsColors().white,
  },
});

const inputDisabledStyles = css({
  color: mdsColors().grey.x500,
  background: mdsColors().grey.x25,
});

const columnStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const secondaryStyles = css({
  fontSize: "13px",
  color: "rgb(104, 110, 125)",
});
