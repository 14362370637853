import { Uuid } from "@/domains/global/identifiers";
import { SearchFullTextEntry, SearchSuggestion } from "@/domains/search";
import uFuzzy from "@leeoniya/ufuzzy";
import { searchSort } from "@/modules/search-sort";
import { AppStore } from "@/store";

export class Search {
  private readonly store: AppStore;
  private readonly fuzzy: uFuzzy;

  constructor({ store }: { store: AppStore }) {
    this.store = store;
    this.fuzzy = new uFuzzy();
  }

  public get db() {
    const memDb = this.store.memDb;
    if (!memDb) {
      throw new Error("MemDB is not initialized");
    }

    return memDb;
  }

  public async forSuggestions(
    str: string,
    sortOption: "mentions" | "default" = "default",
    and?: (item: SearchSuggestion) => boolean,
    limit: number = 100
  ): Promise<SearchSuggestion[]> {
    if (!str) {
      return [];
    }

    let sortBy = "sortKey";
    if (sortOption === "mentions") {
      sortBy = "mentionKey";
    }

    const words = this.getAllWords(str);
    let query = this.db.searchSuggestions.where("labelWords").startsWithAnyOf(words);

    if (and) {
      query = query.and(and);
    }

    const results = await query
      .filter(suggestion => words.every(word => suggestion.label.includes(word)))
      .distinct()
      .reverse()
      .sortBy(sortBy);

    return results.slice(0, limit);
  }

  public async thruFullText(
    str: string,
    _sortOption: "default" = "default",
    and?: (item: SearchFullTextEntry) => boolean,
    offset: number = 0,
    limit: number = 100
  ): Promise<SearchFullTextEntry[]> {
    const sortKey = "sortKey";
    let query = this.db.searchFullText.orderBy(sortKey);

    const words = this.getAllWords(str);

    if (and) {
      query = query.and(and);
    }

    const results = await query
      .filter(suggestion => words.every(word => suggestion.content.includes(word)))
      .distinct()
      .reverse()
      .offset(offset)
      .limit(limit)
      .toArray();

    return results;
  }

  // TODO: extract this to a separate module
  public inMemory(query: string, items: SearchSuggestion[]): SearchSuggestion[] {
    const haystack = items.map(item => item.label);
    const indexes = this.fuzzy.filter(haystack, query);

    return indexes?.map(index => items[index]).sort(searchSort) || [];
  }

  public async updateSuggestion(suggestion: SearchSuggestion) {
    if (!suggestion.isAvailable) {
      await this.remove(suggestion.modelId);
      return;
    }

    await this.db.searchSuggestions.put(this.calculateIndexes(suggestion));
  }

  public async updateFullTextEntry(offlineEntry: SearchFullTextEntry) {
    if (!offlineEntry.isAvailable) {
      await this.remove(offlineEntry.modelId);
      return;
    }

    await this.db.searchFullText.put(offlineEntry);
  }

  public async remove(modelId: Uuid) {
    await this.db.transaction("rw", [this.db.searchSuggestions, this.db.searchFullText], () => {
      this.db.searchSuggestions.delete(modelId);
      this.db.searchFullText.delete(modelId);
    });
  }

  private calculateIndexes(suggestion: SearchSuggestion) {
    return {
      ...suggestion,
      label: suggestion.label.toLowerCase(),
      labelWords: this.getAllWords(suggestion.label),
    };
  }

  private getAllWords(text: string) {
    // TODO: in case we need to implement more complex cases from former knowledge grape / search in classic
    // https://github.com/mem-labs/mem/blob/classic/domains/frontend/mem-client/src/services/search/SearchManager.ts
    return [...new Set(text.toLowerCase().match(/\w+/g) || [])];
  }
}
