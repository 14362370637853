import { MdsButton } from "@/design-system/components/button";
import styled from "@emotion/styled";

export const MdsPanelHeaderButton = styled(MdsButton)(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.xsmall,
  height: 32,
  boxSizing: "border-box",
  padding: "0 12px",
}));
