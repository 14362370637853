import { useVerticalScroll } from "@/domains/react/useVerticalScroll";
import styled from "@emotion/styled";
import { ReactNode } from "react";

interface FadeVerticalScrollProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const FadeVerticalScroll = ({ children, className }: FadeVerticalScrollProps) => {
  const { scrollContainerRef, scrollState, handleScroll } = useVerticalScroll();
  return (
    <Wrapper>
      <ScrollableSection ref={scrollContainerRef} onScroll={handleScroll} className={className}>
        {children}
      </ScrollableSection>
      {scrollState.bottomVisible && <FadeBottom />}
      {scrollState.topVisible && <FadeTop />}
    </Wrapper>
  );
};

const Wrapper = styled.div({
  position: "relative",
  height: "100%",
  width: "100%",
});

const ScrollableSection = styled.div({
  overflowY: "auto",
  scrollbarWidth: "none",
  width: "100%",
  height: "100%",

  "::-webkit-scrollbar": {
    display: "none",
  },
});

const FadeBottom = styled.div({
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "28px",
  width: "100%",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.854167) 85.42%, #ffffff 100%)",
  userSelect: "none",
  pointerEvents: "none",
});

const FadeTop = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  height: "28px",
  width: "100%",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.854167) 85.42%, #ffffff 100%)",
  transform: "rotate(180deg)",
  userSelect: "none",
  pointerEvents: "none",
});
