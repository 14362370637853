import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { logger } from "@/modules/logger";
import { AppStore } from "@/store/AppStore";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import {
  SyncModelData,
  SyncUpdate,
  SyncUpdateValue,
  SyncModelKind,
  OptimisticSyncUpdate,
} from "@/store/sync/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { liveQuery, Table } from "dexie";
import {
  ObservableMap,
  ObservableSet,
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  runInAction,
} from "mobx";
import { computedFn, ILazyObservable, lazyObservable } from "mobx-utils";
import { Subscription } from "node_modules/react-hook-form/dist/utils/createSubject";

export abstract class BaseSyncModelStore<
  SyncModel extends BaseSyncModel<ModelData>,
  ModelData extends SyncModelData,
> extends AppSubStore {
  public pool: ObservableMap<Uuid, SyncModel | undefined> = new ObservableMap();

  public ids: ObservableSet<Uuid> = new ObservableSet();
  public idsSubscription: Maybe<Subscription>;
  public modelKind: SyncModelKind;

  private bulkRemoteUpdatesPool: SyncUpdateValue<SyncModelData>[] = [];
  public constructor({
    modelKind,
    ...injectedDeps
  }: { modelKind: SyncModelKind } & AppSubStoreArgs) {
    super(injectedDeps);
    this.modelKind = modelKind;

    makeObservable<
      this,
      | "loadModel"
      | "createLazyObservable"
      | "bulkRemoteUpdatesPool"
      | "grantAccessToModelIfItExistsLocally"
      | "revokeAccessToModelIfItExistsLocally"
    >(this, {
      subscribeToIds: true,
      all: computed,
      computeIndexes: true,
      getAsync: true,
      idsSubscription: true,
      ids: true,
      has: true,
      createLazyObservable: true,
      db: computed,
      modelKind: observable,

      get: false,
      pool: observable,
      loadModel: action,

      remoteTable: computed,
      localTable: computed,
      processSyncUpdate: action,
      recompute: action,

      doesNotExist: false,
      createSyncModel: false,

      resetSync: action,
      flushRemote: action,
      bulkRemoteUpdatesPool: false,
      dryProcessSyncUpdate: true,
      grantAccessToModelIfItExistsLocally: false,
      revokeAccessToModelIfItExistsLocally: false,
    });

    onBecomeObserved(this, "has", () => this.subscribeToIds());
    onBecomeObserved(this, "all", () => this.subscribeToIds());
  }

  public subscribeToIds() {
    if (this.idsSubscription) return;
    this.idsSubscription = liveQuery(() => this.localTable.toCollection().primaryKeys()).subscribe({
      next: ids => runInAction(() => this.ids.replace(ids)),
    });
  }

  public get db() {
    const memDb = this.store.memDb;
    if (!memDb) throw new Error("MemDB is not initialized");
    return memDb;
  }

  public doesNotExist = (id?: string) => {
    const model = id && this.get(id);
    return this.store.sync.isUpToDate && id ? !model || model.isDeleted : undefined;
  };

  abstract createSyncModel(updateValue: SyncUpdateValue<ModelData>): SyncModel;

  public get remoteTable() {
    return this.db.mappedTables[this.modelKind].remote as Table<SyncUpdateValue<ModelData>>;
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<SyncUpdateValue<ModelData>>;
  }

  public has = computedFn((id: Uuid): boolean => {
    return this.ids.has(id);
  });

  public get all() {
    // DO NOT USE UNLESS NECESSARY
    return Array.from(this.ids)
      .map(id => this.get(id))
      .filter(model => !!model);
  }

  public get = computedFn((id: Uuid): SyncModel | undefined => {
    if (this.pool.has(id)) return this.pool.get(id);
    return this.createLazyObservable(id).current();
  });

  private createLazyObservable(id: Uuid): ILazyObservable<SyncModel | undefined> {
    return lazyObservable((sink: (value: SyncModel | undefined) => void) =>
      this.loadModel(id, sink)
    );
  }

  public async getAsync(id: Uuid) {
    if (this.pool.has(id)) return this.pool.get(id);

    const data = await this.localTable.get(id);
    if (!data) return undefined;

    const model = this.createSyncModel(data);
    runInAction(() => this.pool.set(id, model));
    return model;
  }

  private async loadModel(id: Uuid, sink: (value: SyncModel | undefined) => void) {
    const data = await this.localTable.get(id);
    if (!data) {
      sink(undefined);
      return;
    }
    const model = this.createSyncModel(data);
    runInAction(() => this.pool.set(id, model));
    sink(model);
  }

  public async processSyncUpdate(
    update: SyncUpdate<ModelData>,
    _options: { hydrating?: boolean } = {}
  ) {
    switch (update.kind) {
      case "ACL_UPSERTED":
      case "UPSERTED": {
        const updateValue = update.value as SyncUpdateValue<ModelData>;
        // in case model was re-shared with us
        this.grantAccessToModelIfItExistsLocally(updateValue.model_id);

        // DEXIE REFACTOR TODO: Check model version first
        await this.remoteTable?.put(updateValue, updateValue.model_id);
        break;
      }
      case "DELETED": {
        const modelId = update.value.model_id;
        await this.remoteTable?.delete(modelId);
        break;
      }
      case "ACL_REVOKED": {
        const modelId = update.value.model_id;
        this.revokeAccessToModelIfItExistsLocally(modelId);
        await this.remoteTable?.delete(modelId);
        break;
      }
    }

    await this.recompute(update.value.model_id);
  }

  // TODO: relator to make it reusable with regular processSyncUpdate
  public dryProcessSyncUpdate(update: SyncUpdate<ModelData>): boolean {
    switch (update.kind) {
      case "ACL_UPSERTED":
      case "UPSERTED": {
        // DEXIE REFACTOR TODO: Check model version first
        const value = update.value as SyncUpdateValue<ModelData>;
        this.bulkRemoteUpdatesPool.push(value);
        return true;
      }
      case "DELETED":
      case "ACL_REVOKED": {
        // noop
        return false;
      }
    }

    return false;
  }

  public resetSync() {
    this.pool.clear();
    // TODO: Reset Dexie tables?
  }

  public computeIndexes(_params: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<ModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ModelData>[];
  }): Record<string, unknown> {
    return {};
  }

  public async recompute(modelId: Uuid) {
    const remoteData = await this.remoteTable.get(modelId);
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_id: modelId })
      .sortBy("locally_committed_at");

    const lastOptimisticUpdate = optimisticUpdates.at(-1);
    if (lastOptimisticUpdate?.kind === "DELETED" || lastOptimisticUpdate?.kind === "ACL_REVOKED") {
      logger.debug({
        message: `[BASE SYNC MODEL STORE] deleting / revoking access`,
        info: { modelId, modelKind: this.modelKind },
      });

      await this.localTable.delete(modelId);
      runInAction(() => this.pool.delete(modelId));
      return;
    }

    const data = lastOptimisticUpdate?.value || remoteData;
    if (!data) {
      // Case: remoteData is deleted and there are no optimistic updates\
      await this.localTable.delete(modelId);
      runInAction(() => this.pool.delete(modelId));
      return;
    }

    const indexes = this.computeIndexes({ store: this.store, remoteData, optimisticUpdates });

    const dataWithIndexes: SyncUpdateValue<ModelData> & Record<string, unknown> = {
      ...(data as SyncUpdateValue<ModelData>),
      ...indexes,
    };

    await this.localTable.put(dataWithIndexes, dataWithIndexes.model_id);

    // this is a noop update to retrigger get for observables that previously returned undefined in the case they they become defined through the recompute
    if (!this.pool.has(modelId)) {
      runInAction(() => this.pool.set(modelId, undefined));
      runInAction(() => this.pool.delete(modelId));
    }
  }

  public async flushRemote() {
    const values = this.bulkRemoteUpdatesPool as SyncUpdateValue<ModelData>[];
    await this.remoteTable.bulkPut(values);
    this.bulkRemoteUpdatesPool = [];
  }

  private grantAccessToModelIfItExistsLocally(modelId: Uuid) {
    const model = this.get(modelId);
    model?.setCanAccess(true);
  }

  private revokeAccessToModelIfItExistsLocally(modelId: Uuid) {
    const model = this.get(modelId);
    model?.setCanAccess(false);
  }
}
