import { Maybe } from "@/domains/common/types";
import { AppStore } from "@/store";

export interface SyncErrorModalFields {
  title?: React.ReactNode;
  message?: React.ReactNode;
  resetActionLabel?: string | null;
  extraActionButtons?: React.ReactNode;
  includeContactSupportButton?: boolean;
  modalActionHandler?: () => Promise<void>;
}

export type SyncErrorModalFieldsGenerator = (store: AppStore) => SyncErrorModalFields;

export abstract class AbstractSyncErrorModalFieldsGenerator implements SyncErrorModalFields {
  protected store: AppStore;

  abstract get message(): React.ReactNode;

  constructor(store: AppStore) {
    this.store = store;
  }

  get title(): Maybe<React.ReactNode> {
    return;
  }

  get resetActionLabel(): string | null {
    return "Got it";
  }

  get includeContactSupportButton() {
    return false;
  }

  async modalActionHandler() {
    await this.store.resetStorageAndReload();
  }
}
