import { appRoutes } from "@/app/router/appRoutes";
import { routingModule } from "@/modules/routing";
import { AppStoreNavigationStore } from "@/store/navigation/AppStoreNavigationStore";

export type INavigationStore = Pick<
  AppStoreNavigationStore,
  "stack" | "goBack" | "goToCollections" | "goToNotes" | "goToExploreTopicDetail"
>;

const PATHS = {
  COLLECTIONS_VIEW: appRoutes.collectionsView({ params: { collectionId: "" } }).path,
  NOTES_VIEW: appRoutes.notesView({ params: { noteId: "" } }).path,
  COLLECTIONS: appRoutes.collections({}).path,
  NOTES: appRoutes.notesList({}).path,
  SEARCH: appRoutes.search({}).path,
  TRASH: appRoutes.trash({}).path,
  CHAT: appRoutes.chat({}).path,
  EXPLORE_TOPIC_DETAIL: appRoutes.exploreTopicDetail({ params: { topicId: "" } }).path,
} as const;

const COLLECTIONS_VIEW_BACK_PATHS = [PATHS.COLLECTIONS, PATHS.CHAT];
const NOTES_VIEW_BACK_PATHS = [
  PATHS.COLLECTIONS_VIEW,
  PATHS.SEARCH,
  PATHS.NOTES,
  PATHS.TRASH,
  PATHS.CHAT,
  PATHS.EXPLORE_TOPIC_DETAIL,
];

const isPathOf = (path: string, targetPath: string): boolean => path.startsWith(targetPath);

const isInBackPaths = (path: string, backPaths: string[]): boolean =>
  backPaths.some(backPath => isPathOf(path, backPath));

export const goUp = (store: INavigationStore): void => {
  const { stack } = store;

  if (stack.length < 2) {
    store.goToNotes();
    return;
  }

  const currentPath = routingModule.parsePathname(stack[stack.length - 1]);
  const previousPath = routingModule.parsePathname(stack[stack.length - 2]);

  if (isPathOf(currentPath, PATHS.COLLECTIONS_VIEW)) {
    if (isInBackPaths(previousPath, COLLECTIONS_VIEW_BACK_PATHS)) {
      store.goBack();
      return;
    }

    store.goToCollections();
    return;
  }

  if (isPathOf(currentPath, PATHS.NOTES_VIEW)) {
    if (isInBackPaths(previousPath, NOTES_VIEW_BACK_PATHS)) {
      if (isPathOf(previousPath, PATHS.EXPLORE_TOPIC_DETAIL)) {
        const topicId = previousPath.split("/").pop() ?? "";
        store.goToExploreTopicDetail({ topicId });
      } else {
        store.goBack();
      }
      return;
    }

    store.goToNotes();
    return;
  }

  store.goToNotes();
};
