import { observer } from "mobx-react-lite";
import { FC } from "react";
import { SidebarExpanded } from "@/components/layout/components/sidebar/SidebarExpanded";
import { SidebarCollapsed } from "@/components/layout/components/sidebar/SidebarCollapsed";
import { AnimatePresence, motion } from "framer-motion";
import { SIDEBAR_VARIANTS, SidebarVariant } from "@/components/layout/components/sidebar/constants";

export interface SidebarProps {
  currentSidebarVariant: SidebarVariant;
}

export const Sidebar: FC<SidebarProps> = observer(({ currentSidebarVariant }) => {
  return (
    <motion.div
      layout
      initial={false}
      animate={currentSidebarVariant}
      variants={SIDEBAR_VARIANTS}
      style={{ overflow: "hidden", flexShrink: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <AnimatePresence mode="wait">
        {currentSidebarVariant === SidebarVariant.Collapsed ? (
          <SidebarCollapsed key="collapsed" />
        ) : (
          <SidebarExpanded key="expanded" />
        )}
      </AnimatePresence>
    </motion.div>
  );
});
