import { appRoutes } from "@/app/router";
import { clientEnvModule } from "@/modules/client-env";
import { buildAppWindowUrl } from "@/modules/window/build-url";

export const getWindowGoogleIdentityOAuthRedirectUrl = () => {
  const isDesktop = clientEnvModule.isDesktop();

  if (isDesktop) {
    return buildAppWindowUrl({
      path: appRoutes.googleOAuthRedirectDesktop({}).path,
    }).toString();
  }

  return buildAppWindowUrl({
    path: appRoutes.googleOAuthRedirect({}).path,
  }).toString();
};
