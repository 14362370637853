import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsText, MdsTextProps, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import styled from "@emotion/styled";
import { css } from "@/domains/emotion";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsTabBarHorizontal } from "@/design-system/components/tab-bar-horizontal/MdsTabBarHorizontal";

interface MdsPanelHeaderFilter {
  label: string;
  onClick: () => void;
  isActive: boolean;
}

interface MdsPanelHeaderProps {
  icon: MdsIconKind | React.ReactNode;
  heading: React.ReactNode;
  subheading?: React.ReactNode;

  headingTextProps?: Omit<MdsTextProps, "children">;
  subheadingTextProps?: Omit<MdsTextProps, "children">;

  filters?: MdsPanelHeaderFilter[];
  actions?: React.ReactNode;
  onBackClick?: () => void;
  onLeftSectionClick?: () => void;
}

export const MdsPanelHeader = ({
  icon,
  heading,
  subheading,
  headingTextProps = {
    size: MdsTextSize.Large,
    lineHeight: MdsTextSize.Large,
    weight: MdsTextWeight.SemiBold,
    color: mdsColors().grey.x600,
  },
  subheadingTextProps = {
    size: MdsTextSize.XXSmall,
    lineHeight: MdsTextSize.XXSmall,
    color: mdsColors().grey.x400,
  },
  onBackClick,
  onLeftSectionClick,
  actions,
  filters,
}: MdsPanelHeaderProps) => {
  return (
    <Wrapper>
      <WrapperTop>
        <LeftSection>
          {onBackClick && <MdsIconButton iconKind={MdsIconKind.LeftArrow} onClick={onBackClick} />}
          <span onClick={onLeftSectionClick}>
            {typeof icon === "string" ? (
              <MdsIcon
                kind={icon as MdsIconKind}
                className={iconWrapperStyles}
                innerStyles={{ Icon: { className: iconStyles } }}
              />
            ) : (
              icon
            )}
          </span>
          <HeadingSection onClick={onLeftSectionClick}>
            <MdsText numberOfLines={1} {...headingTextProps}>
              {heading}
            </MdsText>
            {!!subheading && typeof subheading === "string" && (
              <MdsText numberOfLines={1} {...subheadingTextProps}>
                {subheading}
              </MdsText>
            )}
            {!!subheading && typeof subheading !== "string" && subheading}
          </HeadingSection>
        </LeftSection>
        {actions && <ActionsSection>{actions}</ActionsSection>}
      </WrapperTop>
      {filters && filters.length > 0 && (
        <WrapperBottom>
          <MdsTabBarHorizontal
            tabs={filters.map(filter => ({
              id: filter.label,
              label: filter.label,
              onClick: filter.onClick,
              isActive: filter.isActive,
            }))}
          />
        </WrapperBottom>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderBottom: `1px solid ${theme.colors.grey.x50}`,
}));

const WrapperTop = styled.div(({ theme }) => ({
  height: 64,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  gap: theme.spacing.sm,
  paddingInline: theme.spacing.md,
}));

const WrapperBottom = styled.div(({ theme }) => ({
  height: 40,
  flexShrink: 0,
  display: "flex",
  paddingInline: theme.spacing.md,
}));

const LeftSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.sm,
  cursor: "pointer",
}));

const HeadingSection = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
});

const ActionsSection = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "6px",
});

const iconWrapperStyles = css({
  height: 24,
  width: 24,
});

const iconStyles = css({
  fontSize: 20,
});
