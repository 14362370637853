import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { DeleteCollectionModalStore } from "@/components/modal/delete-collection/DeleteCollectionModalStore";
import { mdsColors, mdsFontWeights } from "@/design-system/foundations";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";

export interface DeleteCollectionModalProps {
  state: DeleteCollectionModalStore;
}

export const DeleteCollectionModal = observer<DeleteCollectionModalProps>(({ state }) => {
  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={state.isOpen && !!state.collection}
      handleCloseModal={() => state.close()}
    >
      <div className={modalContainerStyles}>
        <div className={headerStyles}>
          <div className={titleStyles}>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              Permanently delete “{state.collection?.title || UNTITLED_COLLECTION_TITLE}”?
            </MdsText>
          </div>
          <MdsSpacer />
          <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => state.close()} />
        </div>
        <div className={contentStyles}>
          <MdsText
            lineHeight={MdsTextSize.Small}
            color={mdsColors().grey.x600}
            size={MdsTextSize.Small}
          >
            Make sure you want to permanently delete this collection.{" "}
            <span className={warningTextStyles}>You cannot undo this action.</span>
          </MdsText>
        </div>
        <div className={buttonSectionStyles}>
          <MdsButton
            label="Cancel"
            variant={MdsButtonVariant.TextTertiary}
            onClick={() => state.close()}
          />
          <MdsButton
            label="Delete collection"
            variant={MdsButtonVariant.Danger}
            onClick={async () => await state.handleSubmit()}
          />
        </div>
      </div>
    </MdsModal>
  );
});

const modalContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "24px",
  maxWidth: 500,
});

const headerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const contentStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const warningTextStyles = css({
  fontWeight: mdsFontWeights().semiBold,
  color: mdsColors().grey.x600,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 8,
});
