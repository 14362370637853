import { css } from "@/domains/emotion";
import { MdsIcon } from "@/design-system/components/icon";
import { MdsIconKind } from "@/design-system/components/icon/types";
import styled from "@emotion/styled";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { Text } from "react-aria-components";
import { CollectionItemSubtitle } from "@/components/collection-item-subtitle";
import { mdsColors } from "@/design-system/foundations";
import { CollectionItemMode } from "@/components/note/editor/top-bar/organize";
import { buildAsyncData } from "@/domains/async/AsyncData";

// This component is used in the Organize dropdown to show Collections and
// the button to create a new Collection.

// TODO: This component is a 2-line menu-item component in Figma. We should use the React version when its available.

interface CollectionItemProps {
  collectionId: string;
  title: string;
  noteCount: number;
  subtitle?: string;
  isShared?: boolean;
  mode: CollectionItemMode;
  isSelected: boolean;
  isCollected: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const CollectionItem = ({
  collectionId,
  title,
  noteCount,
  subtitle,
  isShared,
  mode,
  onClick,
  isSelected,
  isCollected,
  onMouseEnter,
  onMouseLeave,
}: CollectionItemProps) => {
  return (
    <Wrapper
      isSelected={isSelected}
      isCollected={isCollected}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      mode={mode}
      role="button"
      aria-label={`${mode === "select" ? "Select collection: " + title : "Create collection: " + title}`}
      data-collection-item
    >
      <LeftSide>
        {mode === "select" && <CollectionIcon collectionId={collectionId} />}
        {mode === "create" && (
          <PlusIconWrapper>
            <MdsIcon kind={MdsIconKind.Plus} />
          </PlusIconWrapper>
        )}
        <Content>
          <TitleRow>
            <CollectionTitle>
              {mode === "create" && <>&ldquo;</>}
              {title}
              {mode === "create" && <>&rdquo;</>}
            </CollectionTitle>
            {isShared && (
              <MdsIcon
                kind={MdsIconKind.Shared}
                innerStyles={{ Icon: { className: sharingIconStyles } }}
              />
            )}
          </TitleRow>
          <CollectionItemSubtitle
            sizeData={buildAsyncData({ data: noteCount })}
            itemTypeName="note"
            description={subtitle}
          />
        </Content>
      </LeftSide>
      <RightSide>{isCollected && <MdsIcon kind={MdsIconKind.Check} />}</RightSide>
    </Wrapper>
  );
};

const CollectionTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontStyle: "normal",
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xxsmall,
  textOverflow: "ellipsis",
}));

const sharingIconStyles = css({
  fontSize: "10px",
  color: mdsColors().grey.x500,
});

const Wrapper = styled.div<{
  isSelected: boolean;
  mode: "select" | "create";
  isCollected: boolean;
}>(({ theme, isSelected, mode, isCollected }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
  padding: theme.spacing.sm,
  cursor: "pointer",
  borderRadius: theme.borderRadius.mediumLarge,
  backgroundColor: isSelected ? theme.colors.grey.x25 : theme.colors.transparent,
  marginTop: mode === "create" ? theme.spacing.xs : undefined,
  opacity: isCollected ? 0.4 : 1,
}));

const LeftSide = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.sm,
}));

const RightSide = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingRight: theme.spacing.sm,
}));

const Content = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: theme.spacing.xxs,
}));

const TitleRow = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.sm,
}));

const PlusIconWrapper = styled.div({
  width: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
