import Icons from "@/assets/icons/Icons";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

interface ChatHeaderProps {
  inSidePanel?: boolean;
}

export const ChatHeader = observer<ChatHeaderProps>(({ inSidePanel }) => {
  const { store } = useAppStore();
  const isOnTopOfMainPanel = store.sidePanel.isSidePanelOpen && !store.sidePanel.canRenderSplitView;
  const shouldRenderBackButton = inSidePanel && isOnTopOfMainPanel;

  return (
    <MdsPanelHeader
      heading="Mem"
      headingTextProps={{
        size: MdsTextSize.Small,
        lineHeight: MdsTextSize.XSmall,
        weight: MdsTextWeight.Medium,
      }}
      icon={<MemIcon />}
      onBackClick={shouldRenderBackButton ? store.sidePanel.goUp : undefined}
    />
  );
});

const MemIcon = styled(Icons.Mem)({
  height: "32px",
  width: "32px",
});
