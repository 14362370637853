import { useTransition, animated } from "@react-spring/web";
import React, { ReactNode, FC } from "react";

import { AnimationConfig } from "@/design-system/components/animation/types";
import { _parseAnimationTransitionConfig } from "@/design-system/components/animation/utils/parseAnimationTransitionConfig";
import { SHARED_ANIMATION_DIV_STYLES } from "@/design-system/components/animation/utils/sharedAnimationStyles";
import { cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";

export interface ToggleAnimatorProps extends EmotionClassStyles {
  toggled: boolean;
  falseElement: ReactNode;
  trueElement: ReactNode;
  animationConfig?: AnimationConfig;
}

/**
 * Starts by showing the `falseState` element.
 *
 * When `toggle` flips from false to true, we start fading out `falseState`.
 *
 * After `falseState` fades out, we hide it (display=none) then begin fading-in
 * the `trueState`.
 */
export const ToggleAnimator: FC<ToggleAnimatorProps> = ({
  toggled,
  falseElement,
  trueElement,
  animationConfig,
  className,
}) => {
  const transitionConfig = _parseAnimationTransitionConfig({
    animationConfig,
  });

  const transitions = useTransition(toggled, {
    initial: { itemVisibility: 1 },
    from: { itemVisibility: 0 },
    enter: { itemVisibility: 1 },
    leave: { itemVisibility: 0 },
    delay: animationConfig?.delay ?? 0,
    config: transitionConfig,
  });

  const combinedStyles = cx(SHARED_ANIMATION_DIV_STYLES, className);

  return transitions(({ itemVisibility }, isToggled) => {
    if (!isToggled) {
      return (
        <animated.div
          style={{
            display: itemVisibility.to(val => (val > 0.5 ? "block" : "none")),

            opacity: itemVisibility.to({ range: [0.5, 1], output: [0, 1] }),
          }}
          className={combinedStyles}
        >
          {falseElement}
        </animated.div>
      );
    }

    return (
      <animated.div
        style={{
          display: itemVisibility.to(val => (val > 0.5 ? "block" : "none")),
          opacity: itemVisibility.to({ range: [0.5, 1], output: [0, 1] }),
        }}
        className={combinedStyles}
      >
        {trueElement}
      </animated.div>
    );
  });
};
