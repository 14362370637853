import { toUint8Array } from "js-base64";

/**
 * We convert the encoded content to a byte array, and then to a blob.
 */
export const convertEncodedFileContentToBlob = ({
  encodedFileContent,
  fileMimeType,
}: {
  encodedFileContent: string;
  fileMimeType: string;
}): Blob => {
  const byteArray = toUint8Array(encodedFileContent);

  const blob = new Blob([byteArray], { type: fileMimeType });

  return blob;
};
