import { RecentsList } from "@/components/layout/components/RecentsList";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsIconButton,
  MdsIconButtonSize,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import { MdsModal } from "@/design-system/components/modal";
import { mdsColors } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { motion, AnimatePresence, AnimationProps } from "framer-motion";
import { CSSProperties, useEffect } from "react";
import { SuggestedSearchesList } from "@/components/layout/components/SuggestedSearchesList";
import { QuickSearchModalSearchBar } from "@/store/pages/SearchPageStore/QuickSearchModal/QuickSearchModalSearchBar";
import { QuickSearchModalNotePreview } from "@/store/pages/SearchPageStore/QuickSearchModal/QuickSearchModalNotePreview";
import { trackEvent, TrackedEvent } from "@/domains/metrics";

const initialModelStyles: AnimationProps["initial"] = {
  backdropFilter: "blur(2.5px) grayscale(0%) contrast(100%)",
};

const animateModalStyles: AnimationProps["animate"] = {
  backdropFilter: "blur(6.0px) grayscale(70%) contrast(80%)",
};

const initialContentStyles: AnimationProps["initial"] = {
  transform: "scale(0.98) translateY(8px)",
};

const animateContentStyles: AnimationProps["initial"] = {
  transform: "scale(1) translateY(0)",
};

export const QuickSearchModal = observer(function QuickSearchModal() {
  const { pageStore } = useAppStore();
  const state = pageStore.quickSearchModal;

  const combinedScrimStyles = cx(scrimStyles);

  /**
   * Track views of the quick search modal.
   */
  useEffect(() => {
    if (state.isOpen) {
      trackEvent(TrackedEvent.QuickSearchView);
    }
  }, [state.isOpen]);

  return (
    <AnimatePresence>
      {state.isOpen && (
        <MdsModal
          showOverflow
          fullscreen
          rootElementId="root"
          isModalOpen={state.isOpen}
          handleCloseModal={state.close}
          modalContentStyles={customModalContentStyles}
          modalOverlayStyles={customModalOverlayStyles}
        >
          <motion.div
            className={combinedScrimStyles}
            onKeyDown={state.handleKeyDown}
            onClick={state.close}
            initial={{ ...initialModelStyles, opacity: 0 }}
            animate={{ ...animateModalStyles, opacity: 1 }}
            exit={{ ...initialModelStyles, opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <div className={closeButtonStyles}>
              <MdsIconButton
                variant={MdsIconButtonVariant.Transparent}
                size={MdsIconButtonSize.Small}
                onClick={state.close}
                iconKind={MdsIconKind.Exit}
              />
            </div>
            <AnimatePresence>
              <motion.div
                className={sectionWrapperStyles}
                initial={{ ...initialContentStyles }}
                animate={{ ...animateContentStyles }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
              >
                <QuickSearchModalNotePreview noteObservable={state.selectedNoteObservable} />
                <QuickSearchModalSearchBar />
                {state.shouldShowRecentsSection && (
                  <RecentsList
                    className={cx(itemSectionStyles, mediaQueryStyles)}
                    selectedItemClassName={quickSearchSelectedItemStyles}
                    limit={4}
                    onClick={() => {
                      if (state.shouldShowRecentsSection) {
                        state.close();
                      }
                    }}
                    onHover={state.handleHoveringOverItems}
                    calculateSelectedItemIndex={state.calculateSelectedItemIndex}
                    registerSelectedItemHandler={state.registerSelectedItemHandler}
                  />
                )}
                {state.shouldShowSuggestionsSection && (
                  <SuggestedSearchesList
                    className={cx(itemSectionStyles, mediaQueryStyles)}
                    currentSearchQuery={state.value}
                    onClick={() => {
                      if (state.shouldShowSuggestionsSection) {
                        state.close();
                      }
                    }}
                    limit={4}
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </MdsModal>
      )}
    </AnimatePresence>
  );
});

const customModalContentStyles: CSSProperties = {
  backgroundColor: "rgba(255, 255, 255, 0)",
  background: "rgba(255, 255, 255, 0)",
  border: "none",
};

const customModalOverlayStyles: CSSProperties = {
  backgroundColor: "rgba(255, 255, 255, 0)",
  background: "rgba(255, 255, 255, 0)",
};

const scrimStyles = css({
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  position: "relative",
  width: "100%",
  background:
    "radial-gradient(ellipse 200% 100% at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.6) 100%)",
});

const closeButtonStyles = css({
  position: "absolute",
  right: "16px",
  top: "16px",
});

const sectionWrapperStyles = css({
  height: "100%",
});

const mediaQueryStyles = css({
  "@media (max-width: 812px)": {
    minWidth: "unset",
    maxWidth: "unset",
    width: "calc(100vw - 32px)",
  },
});

const itemSectionStyles = css({
  backgroundColor: "rgba(255, 255, 255, 0)",
  border: "none",
  boxShadow: "none",
  height: "auto",
  margin: "0 auto",
  maxWidth: "764px",
  width: "764px",
  userSelect: "none",
});

const quickSearchSelectedItemStyles = css({
  background: mdsColors().grey.x50,
});
