import { Optional } from "@/domains/common/types";
import { urlParamsModule } from "@/modules/url-params";
import { SearchEngineParams } from "@/modules/url-params/search-engine-params/types";
import { uuidModule } from "@/modules/uuid";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import {
  FavoriteItemModelData,
  FavoriteItemUpsertedSyncUpdateValue,
} from "@/store/favorite-items/types";
import { SavedSearchObservable } from "@/store/saved-searches/SavedSearchObservable";
import {
  SavedSearchModelData,
  SavedSearchUpsertedSyncUpdateValue,
} from "@/store/saved-searches/types";
import {
  BaseSyncOperation,
  BaseSyncOperationParams,
} from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IAddSavedSearchToFavoritesOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { generateKeyBetween } from "fractional-indexing";

export class AddSavedSearchToFavoritesOperation extends BaseSyncOperation<IAddSavedSearchToFavoritesOperation> {
  private savedSearch?: SavedSearchObservable;
  private favoriteItem?: FavoriteItemObservable;
  private savedSearchQueryString: string;

  get operationKind(): SyncOperationKind {
    return "ADD_SAVED_SEARCH_TO_FAVORITES";
  }

  get savedSearchParams(): SearchEngineParams {
    return urlParamsModule.search.parse({
      searchQueryStr: this.savedSearchQueryString,
    });
  }

  get savedSearchLabel() {
    return this.savedSearchParams.queryString;
  }

  get successToastMessage() {
    return <>Pinned “{this.savedSearchLabel}” to sidebar</>;
  }

  get favoriteItemId() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    return uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.saved_search_id,
    });
  }

  // Need to override constructor to make sort_key and saved_search_id optional
  constructor({
    store,
    payload: providedPayload,
    ...params
  }: Omit<BaseSyncOperationParams<IAddSavedSearchToFavoritesOperation>, "payload"> & {
    payload: Optional<
      IAddSavedSearchToFavoritesOperation["payload"],
      "schema_version" | "sort_key" | "saved_search_id"
    >;
  }) {
    const savedSearchQueryString = providedPayload.saved_search_query_string;
    const spaceAccountId = store.spaceAccounts.myPersonalSpaceAccountId;
    const savedSearchId = uuidModule.resolveSavedSearchSyncModelUuid({
      spaceAccountId,
      queryString: savedSearchQueryString,
    });
    const lastItemSortKey = store.favoriteItems.sortedFavoriteItems.at(-1)?.sortKey || null;
    const payload = {
      ...providedPayload,
      saved_search_id: providedPayload.saved_search_id ?? savedSearchId,
      sort_key: providedPayload.sort_key ?? generateKeyBetween(lastItemSortKey, null),
      schema_version: providedPayload.schema_version ?? 1,
    };
    super({ ...params, store, payload });
    this.savedSearchQueryString = savedSearchQueryString;
  }

  async execute() {
    this.savedSearch = await this.store.savedSearches.getAsync(this.payload.saved_search_id);
    this.favoriteItem = await this.store.favoriteItems.getAsync(this.favoriteItemId);

    await super.execute();
  }

  public generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[] {
    const optimisticUpdates: OptimisticSyncUpdate<FavoriteItemModelData | SavedSearchModelData>[] =
      [];

    const favoriteItemUpsertedUpdate = this.generateOptimisticFavoriteItemUpsertedUpdate();
    const savedSearchUpsertedUpdate = this.generateOptimisticSavedSearchUpsertedUpdate();

    if (favoriteItemUpsertedUpdate) {
      optimisticUpdates.push(favoriteItemUpsertedUpdate);
    }

    if (savedSearchUpsertedUpdate) {
      optimisticUpdates.push(savedSearchUpsertedUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.savedSearches.recompute(this.payload.saved_search_id);
    await this.store.favoriteItems.recompute(this.favoriteItemId);
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(this.getToastMessage(), SyncErrorHandlingType.RetryWithLimit);
  }

  protected getToastMessage() {
    return `“${this.savedSearchLabel}” search results could not be pinned to the sidebar. If this error continues, please contact support.`;
  }

  protected generateOptimisticFavoriteItemUpsertedUpdate() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const favoriteItem = this.favoriteItem;

    const favoriteItemUpsertedValue: FavoriteItemUpsertedSyncUpdateValue = {
      model_id: this.favoriteItemId,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItem ? favoriteItem.modelVersion : 0,
      model_data: {
        space_account_id: spaceAccountId,
        item_id: this.payload.saved_search_id,
        item_kind: "SAVED_SEARCH",
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        sort_key: this.payload.sort_key,
      },
      model_scopes: favoriteItem
        ? favoriteItem.modelScopes
        : [generateDefaultOwnerScopes({ store: this.store })],
    };

    const favoriteItemUpsertedUpdate: OptimisticSyncUpdate<FavoriteItemModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: favoriteItemUpsertedValue,
    };

    return favoriteItemUpsertedUpdate;
  }

  protected generateOptimisticSavedSearchUpsertedUpdate() {
    const savedSearch = this.savedSearch;
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;

    const savedSearchUpsertedValue: SavedSearchUpsertedSyncUpdateValue = {
      model_id: this.payload.saved_search_id,
      model_kind: "SAVED_SEARCH",
      model_version: savedSearch ? savedSearch.modelVersion : 0,
      model_data: {
        space_account_id: spaceAccountId,
        query_string: this.payload.saved_search_query_string,
        label: this.payload.saved_search_label,
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
      },
      model_scopes: savedSearch
        ? savedSearch.modelScopes
        : [generateDefaultOwnerScopes({ store: this.store })],
    };

    const savedSearchUpsertedUpdate: OptimisticSyncUpdate<SavedSearchModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: savedSearchUpsertedValue,
    };

    return savedSearchUpsertedUpdate;
  }
}
