import { Base64 } from "js-base64";
import { externalBindingsModule } from "@mem-labs/common-editor";

interface ExtractedNoteContent {
  primaryLabel: string | null;
  secondaryLabel: string | null;
  mdx: string;
  plaintext: string;
  encodedContent: string;
}

export function convertMdxToNoteContent(mdxContent: string): ExtractedNoteContent {
  const encodedContent = convertMdxToEncodedContent(mdxContent);
  return convertEncodedContentToNoteContent(encodedContent);
}

export function convertMdxToEncodedContent(mdxContent: string): string {
  const base64MdxContent = Base64.encode(mdxContent);

  return externalBindingsModule.convertBase64MdxToEncodedContent({
    base64MdxContent,
  });
}

export function convertEncodedContentToNoteContent(encodedContent: string): ExtractedNoteContent {
  const { primaryLabel, secondaryLabel, plaintext, mdx } =
    externalBindingsModule.extractNoteContentFromEncodedContent({
      encodedContent,
    });

  return {
    primaryLabel,
    secondaryLabel,
    mdx,
    plaintext,
    encodedContent,
  };
}
