import { RuntimeBaseError } from "@/domains/errors/kinds/RuntimeBaseError";
import { ErrorWithMessage } from "@/domains/errors/types";
import { toString } from "lodash-es";

export class RuntimeUnreachableCaseError extends RuntimeBaseError {
  constructor({ message, value }: { value: unknown | undefined } & ErrorWithMessage) {
    const parsedValue = (() => {
      try {
        return toString(value);
      } catch (_unknownErr) {
        return "Unknown value";
      }
    })();

    super({
      message: `[UnreachableCaseError] ${message} value=${parsedValue}`,
    });
  }
}
