import { clientEnvModule } from "@/modules/client-env";
import { electronModule } from "@/modules/electron";
import { URL } from "url";

export const navigateToInternalWindowUrl = async ({ url }: { url: URL }): Promise<void> => {
  const isDesktop = clientEnvModule.isDesktop();

  if (isDesktop) {
    await electronModule.navigateToInternalUrl({ url });
    return;
  }

  // eslint-disable-next-line no-restricted-properties
  window.location.replace(url.toString());
  return;
};

export const navigateToExternalWindowUrl = async ({ url }: { url: URL }): Promise<void> => {
  const isDesktop = clientEnvModule.isDesktop();

  if (isDesktop) {
    await electronModule.navigateToExternalUrl({ url });
    return;
  }

  // eslint-disable-next-line no-restricted-properties
  window.location.replace(url.toString());
  return;
};

export const openWindowUrlInNewTab = async ({ url }: { url: URL }): Promise<Window | null> => {
  const isDesktop = clientEnvModule.isDesktop();

  if (isDesktop) {
    await electronModule.navigateToExternalUrl({ url });
    return null;
  }

  // eslint-disable-next-line no-restricted-properties
  const windowProxy = window.open(url.toString(), "_blank");

  return windowProxy;
};
