import { ChatSectionHeader } from "@/store/chat/types";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

export interface SectionHeaderProps {
  sectionHeader: ChatSectionHeader;
}

export const SectionHeader = observer<SectionHeaderProps>(function SectionHeader({
  sectionHeader,
}) {
  return (
    <SectionContainer>
      <Label>{sectionHeader.title}</Label>
    </SectionContainer>
  );
});

const SectionContainer = styled.div({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  paddingBottom: "2rem",
});

const Label = styled.span(({ theme }) => ({
  color: theme.colors.chat.header,
  display: "inline",
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: 500,
}));
