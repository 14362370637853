import { action, computed, makeObservable, observable } from "mobx";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MemCommonEditorImageRejectionErrorCode } from "@mem-labs/common-editor";
import { truncate } from "lodash-es";

export class ImageUploadRejectedModalStore extends AppSubStore {
  public code: MemCommonEditorImageRejectionErrorCode | null = null;
  public imageName: string | null = null;
  isOpen: boolean = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable<this>(this, {
      imageName: observable,
      code: observable,
      isOpen: observable,
      truncatedImageName: computed,
      errorSummary: computed,
      errorMessage: computed,
      open: action,
      close: action,
    });
  }

  public open({
    code,
    imageName,
  }: {
    code: MemCommonEditorImageRejectionErrorCode;
    imageName: string;
  }) {
    this.code = code;
    this.imageName = imageName;
    this.isOpen = true;
  }

  public close() {
    this.code = null;
    this.imageName = null;
    this.isOpen = false;
  }

  get truncatedImageName() {
    return truncate(this.imageName ?? "Image", { length: 20 });
  }

  get errorSummary() {
    return `Failed to upload ${this.truncatedImageName}.`;
  }

  get errorMessage() {
    switch (this.code) {
      case MemCommonEditorImageRejectionErrorCode.ImageTooLarge:
        return "The image is too large to upload. Please try again with a smaller image.";
      default:
        return "An error occurred while uploading the image. Please try again.";
    }
  }
}
