import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { mdsSpacings, PanelMode, mdsPanelModeBreakpoints } from "@/design-system/foundations";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";
import { SidebarMobile } from "@/components/layout/components/sidebar/SidebarMobile";
import { FC, useState } from "react";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsButtonShape } from "@/design-system/components/button/types";
import { TopBarSidebarSection } from "@/components/layout/components/TopBarSidebarSection";
import { SidebarVariant } from "@/components/layout/components/sidebar/constants";
import { TOP_BAR_HEIGHT } from "@/components/layout/components/constants";
import { TopBarProfileImage } from "@/components/layout/components/TopBarProfileImage";
import { EventContext } from "@/domains/metrics/context";

interface TopBarProps {
  currentSidebarVariant: SidebarVariant;
  shouldHideSidebarToggle: boolean;
  toggleSidebar: () => void;
}

export const TopBar: FC<TopBarProps> = observer(
  ({ currentSidebarVariant, shouldHideSidebarToggle, toggleSidebar }) => {
    const { store, pageStore } = useAppStore();

    const [isPopUpBarOpen, setIsPopUpBarOpen] = useState(false);
    const togglePopUpBar = () => setIsPopUpBarOpen(prev => !prev);
    const isNarrowView = !!store.interface.matchesPanelModeBreakpoint.get(PanelMode.Narrow);

    const isChatPath = !!useMatch(appRoutes.chat({ starPattern: true }));
    const isChatToggleDisabled = isChatPath;
    const searchQuery = pageStore.searchPage.activeSearchEngineParams.queryString;

    const { isChatActive, isCopilotActive, isMainPanelOpen, isSidePanelOpen, sidePanelWidth } =
      store.sidePanel;

    const showFullTabButtons = isMainPanelOpen && isSidePanelOpen;

    // TODO: flags aren't working, but Copilot should be enabled for everyone in the 2.0 Alpha now.
    const isCopilotEnabled = true;
    // const isCopilotEnabled = store.spaceAccountFeatureFlags.getFlagStatus("copilot");

    return (
      <>
        {isNarrowView && isPopUpBarOpen && (
          <SidebarMobile onClick={() => setIsPopUpBarOpen(false)} />
        )}
        <Wrapper>
          <LeftSection>
            <TopBarSidebarSection
              isNarrowView={isNarrowView}
              currentSidebarVariant={currentSidebarVariant}
              shouldHideSidebarToggle={shouldHideSidebarToggle}
              toggleSidebar={toggleSidebar}
              navigateBack={store.navigation.goBack}
              navigateForward={store.navigation.goForward}
              canNavigateBack={store.navigation.canGoBack}
              canNavigateForward={store.navigation.canGoForward}
            />
            {isNarrowView && (
              <>
                <TopBarProfileImage togglePopUpBar={togglePopUpBar} />
                <MdsIconButton
                  iconKind={MdsIconKind.ComposeInline}
                  onClick={() =>
                    store.notes.composeNewNote({ eventContext: EventContext.GlobalActions })
                  }
                />
              </>
            )}
            <SearchButton
              label={searchQuery || (isNarrowView ? "Search or ask" : "Search or ask your Mem")}
              iconKind={MdsIconKind.Search}
              variant={MdsButtonVariant.TextTertiaryOutlined}
              onClick={
                searchQuery
                  ? () => pageStore.quickSearchModal.openWithQuery({ query: searchQuery })
                  : pageStore.quickSearchModal.toggleIsOpen
              }
            />
          </LeftSection>
          <RightSection style={{ width: showFullTabButtons ? sidePanelWidth : undefined }}>
            {showFullTabButtons ? (
              <>
                {isCopilotEnabled && (
                  <SidePanelButton
                    label="Copilot"
                    iconKind={MdsIconKind.Copilot}
                    variant={MdsButtonVariant.Transparent}
                    shape={MdsButtonShape.Square}
                    isSelected={isCopilotActive}
                    onClick={() => store.sidePanel.goToCopilot()}
                  />
                )}
                <SidePanelButton
                  label="Chat"
                  iconKind={MdsIconKind.Message}
                  variant={MdsButtonVariant.Transparent}
                  shape={MdsButtonShape.Square}
                  isSelected={isChatActive}
                  onClick={() => store.sidePanel.goToChat()}
                />
              </>
            ) : (
              <>
                {isCopilotEnabled && (
                  <MdsIconButton
                    iconKind={MdsIconKind.Copilot}
                    selected={isSidePanelOpen && isCopilotActive}
                    tooltipConfig={{
                      content: "Copilot",
                      placement: MdsTooltipPlacement.Bottom,
                    }}
                    onClick={() => store.sidePanel.goToCopilot()}
                  />
                )}
                <MdsIconButton
                  iconKind={MdsIconKind.Message}
                  selected={isSidePanelOpen && isChatActive}
                  tooltipConfig={{
                    content: "Chat",
                    placement: MdsTooltipPlacement.Bottom,
                  }}
                  onClick={() => store.sidePanel.goToChat()}
                />
              </>
            )}
            <MdsSpacer />
            {showFullTabButtons && (
              <MdsIconButton
                iconKind={MdsIconKind.ArrowRightFromLine}
                tooltipConfig={{
                  content: isSidePanelOpen ? "Close sidebar" : "Open sidebar",
                  placement: MdsTooltipPlacement.Bottom,
                }}
                onClick={store.sidePanel.toggle}
                isDisabled={isChatToggleDisabled}
              />
            )}
          </RightSection>
        </Wrapper>
      </>
    );
  }
);

const Wrapper = styled.div({
  width: "100%",
  paddingTop: mdsSpacings().sm,
  paddingBottom: mdsSpacings().sm,
  paddingRight: mdsSpacings().sm,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: mdsSpacings().sm,
  height: `${TOP_BAR_HEIGHT}px`,
  WebkitAppRegion: "drag",
  overflow: "hidden",

  [`@media ${mdsPanelModeBreakpoints()[PanelMode.Narrow]}`]: {
    flexWrap: "nowrap",
    gap: 0,
  },
});

const LeftSection = styled.div({
  height: "32px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  overflow: "hidden",
  flexGrow: 1,
});

const RightSection = styled.div(({ theme }) => ({
  height: "32px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.xsPlus,
  overflow: "hidden",
}));

const SearchButton = styled(MdsButton)(({ theme }) => ({
  height: "32px",
  flexGrow: 1,
  maxWidth: 360,
  alignItems: "flex-start",
  backgroundColor: theme.colors.lilac.pale,
  fontWeight: theme.fontWeights.regular,
  [`@media ${mdsPanelModeBreakpoints()[PanelMode.Narrow]}`]: {
    maxWidth: "none",
    flexGrow: 1,
    margin: "0 8px",
  },
}));

const SidePanelButton = styled(MdsButton, {
  shouldForwardProp: prop => prop !== "isSelected",
})(({ isSelected, theme }) => ({
  backgroundColor: isSelected ? theme.colors.lilac.pale : undefined,
  borderRadius: theme.borderRadius.mediumPlus,
  boxSizing: "border-box",
  lineHeight: theme.lineHeights.xsmall,
  padding: `${theme.spacing.sm} ${theme.spacing.smd}`,
}));
