import { Optional } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { clientEnvModule } from "@/modules/client-env";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store/AppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import { IExternalOperation } from "@/store/sync/operations/types";
import { SyncModelData, OptimisticSyncUpdate } from "@/store/sync/types";

export interface BaseExternalOperationParams<T extends IExternalOperation = IExternalOperation> {
  store: AppStore;
  payload: Optional<T["payload"], "schema_version">;
  operationId?: Uuid;
  committedAt?: string;
  latestSpaceAccountSequenceId?: number;
  triggerSuccessToast?: boolean;
}

export abstract class BaseExternalOperation<
  ExternalOperation extends IExternalOperation,
> extends BaseSyncOperationGeneric<ExternalOperation> {
  protected store: AppStore;
  abstract get operationKind(): ExternalOperation["operation_kind"];
  abstract executeExternalOperation(): Promise<void>;

  constructor({
    store,
    payload,
    operationId,
    committedAt,
    latestSpaceAccountSequenceId,
    triggerSuccessToast,
  }: BaseExternalOperationParams<ExternalOperation>) {
    super({
      store,
      payload,
      operationId,
      committedAt,
      latestSpaceAccountSequenceId,
      triggerSuccessToast,
    });

    this.store = store;
  }

  public generateSyncOperation(): ExternalOperation {
    const operation: ExternalOperation = {
      id: this.operationId,
      client_id: clientEnvModule.clientId(),
      locally_committed_at: this.committedAt,
      operation_kind: this.operationKind,
      payload: this.payload,
    } as ExternalOperation;

    return operation;
  }

  public generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[] {
    return [];
  }

  get successToastMessage(): React.ReactNode {
    return null;
  }

  public async execute(): Promise<void> {
    const syncOp = this.generateSyncOperation();

    if (!syncOp) return;
    await this.store.sync.actionQueue.push(this);

    const optimisticUpdates = this.generateOptimisticUpdates();
    for (const optimisticUpdate of optimisticUpdates) {
      await this.store.sync.actionQueue.applyOptimisticUpdate(syncOp.id, optimisticUpdate);
    }

    const toastContent = this.successToastMessage;
    if (toastContent) {
      toastModule.triggerToast({ content: toastContent, toastId: this.operationId });
    }
  }
}
