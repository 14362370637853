import { observer } from "mobx-react-lite";
import { useMemo, useCallback } from "react";
import { useAppStore } from "@/store";
import { APP_CONFIG } from "@/domains/global/config";
import { uuidModule } from "@/modules/uuid";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { AccountProfileNavbarCardContent } from "./AccountProfileNavbarCardContent";
import { windowModule } from "@/modules/window";

export const AccountProfileNavbarCard = observer(({ onHide }: { onHide?: () => void }) => {
  const { store } = useAppStore();

  const handleMobile = useCallback(() => {
    onHide?.();
    /**
     * @TODO - Implement this.
     */
    alert("TODO - Implement this");
  }, [onHide]);

  const handleLogOut = useCallback(() => {
    onHide?.();
    store.navigation.goToLogOut();
  }, [onHide, store.navigation]);

  const handleOpenSettings = useCallback(() => {
    onHide?.();
    store.navigation.goToSettings();
  }, [onHide, store.navigation]);

  const handleOpenImports = useCallback(() => {
    onHide?.();
    store.navigation.goToImportFrom1dot0();
  }, [onHide, store.navigation]);

  const handleOpenIntegrations = useCallback(() => {
    onHide?.();
    store.navigation.goToIntegrations();
  }, [onHide, store.navigation]);

  const handleTrashClick = useCallback(() => {
    onHide?.();
    store.navigation.goToTrash();
  }, [onHide, store.navigation]);

  const handleNavigateToAdminPanel = useCallback(() => {
    onHide?.();
    store.navigation.goToAdmin();
  }, [onHide, store.navigation]);

  const handleToggleDebugging = useCallback(() => {
    onHide?.();
    store.sync.actionQueue.toggleDebugging();
  }, [onHide, store.sync.actionQueue]);

  const handleToggleNoteContentPreloading = useCallback(async () => {
    onHide?.();
    store.noteContentDocuments.togglePreloading();
  }, [onHide, store.noteContentDocuments]);

  const handleResetSync = useCallback(async () => {
    await store.resetStorageAndReload();
  }, [store]);

  const handlePushFailedSyncOperation = useCallback(() => {
    const operation = new TrashNoteOperation({
      store,
      payload: { id: "invalid-id" },
    });
    store.sync.actionQueue.push(operation);
    onHide?.();
  }, [onHide, store]);

  const handlePushPermissionDeniedSyncOperation = useCallback(() => {
    const operation = new UpdateCollectionOperation({
      store,
      payload: {
        id: uuidModule.generate(),
        title: "Test Title",
        description: "Test Description",
      },
    });
    store.sync.actionQueue.push(operation);
    onHide?.();
  }, [onHide, store]);

  const handleNavigateToHelpAndSupport = useCallback(() => {
    windowModule.openInNewTab({ url: new URL(APP_CONFIG.URLS.HELP_AND_SUPPORT) });
  }, []);

  const handlePauseSync = useCallback(() => {
    store.sync.actionQueue.pause();
  }, [store.sync.actionQueue]);

  const handleResumeSync = useCallback(() => {
    store.sync.actionQueue.resume();
  }, [store.sync.actionQueue]);

  const { profileDisplayName, profileEmailAddress, profilePhotoUrl } = store.account.myAccount;
  const { inboundSyncState, outboundSyncState } = store.sync;
  const { isPreloadingDisabled: isNoteContentPreloadingDisabled } = store.noteContentDocuments;

  const isDebugMode = store.debug.debugModeEnabled;

  const handlers = useMemo(
    () => ({
      handleMobile,
      handleLogOut,
      handleOpenSettings,
      handleOpenImports,
      handleOpenIntegrations,
      handleTrashClick,
      handleNavigateToAdminPanel,
      handleToggleDebugging,
      handleResetSync,
      handleToggleNoteContentPreloading,
      handlePushFailedSyncOperation,
      handlePushPermissionDeniedSyncOperation,
      handleNavigateToHelpAndSupport,
      handlePauseSync,
      handleResumeSync,
    }),
    [
      handleMobile,
      handleLogOut,
      handleOpenSettings,
      handleOpenImports,
      handleOpenIntegrations,
      handleTrashClick,
      handleNavigateToAdminPanel,
      handleToggleDebugging,
      handleResetSync,
      handleToggleNoteContentPreloading,
      handlePushFailedSyncOperation,
      handlePushPermissionDeniedSyncOperation,
      handleNavigateToHelpAndSupport,
      handlePauseSync,
      handleResumeSync,
    ]
  );

  return (
    <AccountProfileNavbarCardContent
      isDebugMode={isDebugMode}
      accountInfo={{
        profilePhotoUrl,
        profileDisplayName,
        profileEmailAddress,
      }}
      handlers={handlers}
      syncInfo={{
        inboundSyncState,
        outboundSyncState,
        isNoteContentPreloadingDisabled,
      }}
    />
  );
});
