import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import EmptyCollections from "@/assets/images/empty-collections.svg";
import { MdsButtonShape, MdsButtonSize } from "@/design-system/components/button/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { EmptyContainer, EmptyText } from "@/components/styling/empty";
import { LensKind } from "@/modules/lenses/types";
import { EventContext } from "@/domains/metrics/context";

const COPY: Record<LensKind, string> = {
  [LensKind.All]:
    "You haven't created any notes. To import your notes from Mem 1.0, click 'Import from Mem 1.0' from your profile dropdown.",
  [LensKind.AddedByMe]:
    "You haven't created any notes. To import your notes from Mem 1.0, click 'Import from Mem 1.0' from your profile dropdown.",
  [LensKind.SharedWithMe]: "No notes have been shared with you",
} as const;

interface NotesListPageListEmptyProps {
  area: LensKind;
}

export const NotesListPageListEmpty = observer<NotesListPageListEmptyProps>(({ area }) => {
  const { store } = useAppStore();
  const copy = COPY[area];

  return (
    <EmptyContainer dataTestId="notes-list-page-list-empty">
      <img src={EmptyCollections} alt={copy} />
      <EmptyText>{copy}</EmptyText>
      {area !== LensKind.SharedWithMe && (
        <MdsButton
          label="Create note"
          variant={MdsButtonVariant.FilledDark}
          shape={MdsButtonShape.Square}
          size={MdsButtonSize.Medium}
          onClick={() => store.notes.composeNewNote({ eventContext: EventContext.NotesActions })}
          iconKind={MdsIconKind.ComposeInline}
        />
      )}
    </EmptyContainer>
  );
});
